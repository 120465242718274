<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <el-form :model="params" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
                <div class="card_tit">智能危废箱信息</div>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="所属企业">
                            <el-select placeholder="请选择所属企业" v-model="params.enterpriseId" clearable filterable
                                ref="enterpriseNameRef" :disabled="isDisabled">
                                <el-option v-for="item in enterpriseOptionArr" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="设备版本" prop="deviceVersion">
                            <el-select clearable filterable v-model="params.deviceVersion" placeholder="请选择设备版本">
                                <el-option label="智能危废箱1.0" value="1"></el-option>
                                <el-option label="智能危废箱2.0" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="设备型号" prop="deviceModel">
                            <el-select clearable filterable v-model="params.deviceModel" placeholder="请选择设备型号">
                                <el-option label="50L" value="1"></el-option>
                                <el-option label="200L" value="2"></el-option>
                                <el-option label="1T" value="3"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="display: flex;">
                        <el-form-item label="设备名称" prop="deviceName">
                            <el-input clearable v-model="params.deviceName" placeholder="请输入设备名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="display: flex;">
                        <el-form-item label="危废类别" prop="categoryCode">
                            <el-select clearable filterable v-model="params.categoryCode" @change="updateSub1"
                                placeholder="请选择危废类别">
                                <el-option v-for="item in arr" :key="item.value" :label="item.value"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="display: flex;">
                        <el-form-item label="危废类别名称">
                            <el-input v-model="params.categoryName" placeholder="请输入危废类别名称" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="display: flex;">
                        <el-form-item label="危废代码" prop="harmWasteCode">
                            <el-select clearable filterable v-model="params.harmWasteCode" @change="updateSub2"
                                placeholder="请选择废物代码">
                                <el-option v-for="item in subArr" :key="item.value" :label="item.value"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="display: flex;">
                        <el-form-item label="废物名称">
                            <el-input v-model="params.harmWasteName" placeholder="请输入废物名称" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </template>
        <template v-slot:footer>
            <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
            <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
        </template>
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/deviceApi";
import * as server2 from '@/api/systemApi';

export default {
    name: "addDiagle",
    props: {
        enterpriseOptionArr: {
            type: Array,
            default: null
        },
        deviceType: {
            type: String,
            default: null
        },
        editData: {
            type: Object,
            default: null
        }
    },
    components: {
        HcDiaglePage,
        HcButton,
    },
    watch: {
        '$route.query': function (item) {
            this.params = {
                deviceType: '1',//设备类型
                deviceName: '',//设备名称
                deviceModel: '',//设备型号
                deviceVersion: '',//设备版本
                categoryCode: '',//废物类别
                categoryName: '',//废物类别名称
                harmWasteCode: '',//危废代码
                harmWasteName: '',//危废名称
                enterpriseId: '',
                deviceNo: '',//设备编号
            }
            if (this.$route.query.type == 'deviceAdd') {
                console.log(this.$route.query.enterpriseId, 'pppppppppp')
                this.params.enterpriseId = this.$route.query.enterpriseId
                let data = {'enterpriseId':this.$route.query.enterpriseId,'enterpriseName':this.$route.query.enterpriseName}
                this.setEnterpriseInfo(data);
            }
            if (this.$route.query.type == 'alarmEdit') {
                console.log(this.$route.query.enterpriseId, 'pppppppppp')
                this.params.enterpriseId = this.$route.query.enterpriseId
                let data = {'enterpriseId':this.$route.query.enterpriseId,'enterpriseName':this.$route.query.enterpriseName}
                this.setEnterpriseInfo(data);
            }
        }
    },
    mounted() {
        if (this.$route.query.type == 'deviceAdd') {
            console.log(this.$route.query.enterpriseId, 'pppppppppp')
            this.params.enterpriseId = this.$route.query.enterpriseId
            let data = {'enterpriseId':this.$route.query.enterpriseId,'enterpriseName':this.$route.query.enterpriseName}
            this.setEnterpriseInfo(data);
        }
        if (this.$route.query.type == 'alarmEdit') {
                console.log(this.$route.query.enterpriseId, 'pppppppppp')
                this.params.enterpriseId = this.$route.query.enterpriseId
                let data = {'enterpriseId':this.$route.query.enterpriseId,'enterpriseName':this.$route.query.enterpriseName}
                this.setEnterpriseInfo(data);
            }
        this.getCategoryCode();
    },
    data() {
        return {
            userObj: JSON.parse(sessionStorage.getItem('userInfo')),//从sessionStorage拿出用户信息,
            isDisabled: false,//企业下拉框是否可用
            arr: [],//所有类别
            subArr: [],//选择的类别下所有的代码

            params: {
                deviceType: '1',//设备类型
                deviceName: '',//设备名称
                deviceModel: '',//设备型号
                deviceVersion: '',//设备版本
                categoryCode: '',//废物类别
                categoryName: '',//废物类别名称
                harmWasteCode: '',//危废代码
                harmWasteName: '',//危废名称
                enterpriseId: '',
                deviceNo: '',
                userId: '',//记录修改人
            },

            dialogVisible: false,
            rules: {
                deviceName: [
                    { required: true, message: "请输入设备名称", trigger: "blur" },
                ],
                deviceModel: [
                    { required: true, message: "请选择设备型号", trigger: "change" },
                ],
                deviceVersion: [
                    { required: true, message: "请选择设备版本", trigger: "change" },
                ],
                categoryCode: [
                    { required: true, message: "请选择废物类别", trigger: "change" },
                ],
                harmWasteCode: [
                    { required: true, message: "请选择危废代码", trigger: "change" },
                ],
            },
            dialogType: '',
            tableData: []
        };
    },
    methods: {
        showDiagle(data) {
            this.$refs.hcDiaglePage.showLog = true;
            this.params.deviceType = '1';//设备类型
            this.params.deviceName = '';//设备名称
            this.params.deviceModel = '';//设备型号
            this.params.deviceVersion = '';//设备版本
            this.params.categoryCode = '';//废物类别
            this.params.categoryName = '';//废物类别名称
            this.params.harmWasteCode = '';//危废代码
            this.params.harmWasteName = '';//危废名称
            // this.params.enterpriseId = '';
            this.params.deviceNo = '';
            this.params.userId = '';
            this.$parent.detailTit = '新增智能危废箱'
            this.dialogType = data
            if (this.dialogType == 'edit') {
                this.$parent.detailTit = '编辑智能危废箱'
                console.log(this.editData, '编辑数据')
                this.params.deviceName = this.editData.deviceName;//设备名称
                this.params.deviceModel = this.editData.deviceModel;//设备型号
                this.params.deviceVersion = this.editData.deviceVersion;//设备版本
                this.params.categoryCode = this.editData.categoryCode;//废物类别
                this.params.categoryName = this.editData.categoryName;//废物类别名称
                this.params.harmWasteCode = this.editData.harmWasteCode;//危废代码
                this.params.harmWasteName = this.editData.harmWasteName;//危废名称
                this.params.enterpriseId = this.editData.enterpriseId;
                this.params.deviceNo = this.editData.deviceNo;
            }
        },
        // 产废企业详情进入，没有企业列表选择企业名称
        setEnterpriseInfo(obj) {
            console.log(obj,'产废企业详情进入，没有企业列表选择企业名称')
            this.params.enterpriseId = obj.enterpriseId;//企业编号
            this.enterpriseOptionArr = [{ 'value': obj.enterpriseId,'label':obj.enterpriseName }];
            this.isDisabled = true;
        },
        add(params) {
            server.add(params).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '新增成功',
                        type: 'success'
                    });
                    this.$refs.hcDiaglePage.enter();//关闭弹框  
                    this.$parent.queryList()//调用列表页面，刷新列表
                    this.$nextTick(() => {
                        if (this.$route.query.type == 'deviceAdd') {
                            this.$router.push({
                                name: 'wasteProduction',
                                query: {
                                    type: 'deviceAdd2'
                                }
                            })
                        }
                    })
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: 'warning'
                    });
                });
        },
        edit(params) {
            server.updateDevice(params).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                    this.$refs.hcDiaglePage.enter();//关闭弹框  
                    this.$parent.queryList()//调用列表页面，刷新列表
                    this.$nextTick(() => {
                        if (this.$route.query.type == 'alarmEdit') {
                            this.$router.push({
                                name: 'wasteProduction',
                                query: {
                                    type: 'deviceAdd2'
                                }
                            })
                        }
                    });

                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: 'warning'
                    });
                });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.dialogType == 'edit') {
                        this.params.userId = this.userObj.userId;//记录修改人
                        this.edit(this.params)
                        console.log(this.params, '找id')
                    } else {
                        this.params.userId = this.userObj.userId;//记录修改人
                        this.add(this.params) //调用新增接口
                    }
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            if (this.$route.query.type == 'deviceAdd') {
                this.$router.push({
                    name: 'wasteProduction',//新增跳转到合同列表页面
                })
            }
            if (this.$route.query.type == 'alarmEdit') {
                this.$router.push({
                    name: 'wasteProduction',//新增跳转到合同列表页面
                    query: {
                        type: 'alarmEdit2'
                    }
                })
            }
            this.isDisabled = false;
            this.$refs[formName].resetFields();
            this.$refs.hcDiaglePage.close();
        },

        //废物类别选择后更新废物代码选择集
        updateSub1(categoryCode) {
            //设置类别名称
            let obj = this.arr.find((item) => {
                return item.value === categoryCode;
            });
            this.params.categoryName = obj.label;

            //设置类别代码
            this.params.harmWasteCode = '';
            let newParams = { 'categoryCode': categoryCode };
            this.getWasteCode(newParams);
        },

        //废物代码选择后更新废物名称
        updateSub2(harmWasteCode) {
            //设置废物名称
            this.params.harmWasteName = this.params.categoryName + harmWasteCode;

            /* TODO 以后要改成使用实际设置值，先暂时还用拼接方式
            let obj = {};
            obj = this.subArr.find((item) => {
                return item.value === harmWasteCode;
            });
            this.params.harmWasteName = obj.label; 
            */
        },

        //请求危废类别数据（一级）
        async getCategoryCode() {
            let res = await server2.categoryCodeList();
            if (res.code == 200) {
                this.arr = res.data;
            }
        },
        //请求废物代码数据（二级）
        async getWasteCode(params) {
            let res = await server2.wasteCodeList(params);
            if (res.code == 200) {
                this.subArr = res.data;
            }
        },
    },
};
</script>

<style scoped lang="less">
.addressClass {
    display: flex;

    .addressDiv {
        cursor: pointer;
    }

    .addressIcon {
        font-size: 24px;
        color: #E6A23C;
        margin-left: 6px;
    }

    span {
        font-size: 14px;
        color: #E6A23C;
    }
}

.card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}

::v-deep .el-input--small .el-input__inner {
    width: 300px;
}

.demo-ruleForm {
    margin-top: 20px;
}
</style>
  