<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="菜单名称" prop="userId">
                            <el-input v-model="ruleForm.userId"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="上级菜单" prop="userName">
                            <el-select v-model="ruleForm.userName" clearable filterable></el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="菜单URL">
                            <el-input v-model="ruleForm.enterpriseType">

                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="排序">

                            <el-input-number v-model="ruleForm.enterpriseId" controls-position="right"
                                @change="handleChange"></el-input-number>

                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="菜单图标">
                            <el-input v-model="ruleForm.roleId">

                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </template>
        <template v-slot:footer>
            <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
            <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
            
        </template>
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/systemApi";

export default {
    name: "editDiagle",
    props: {
        editData: {
            type: Object,
            default: null
        }
    },
    components: {
        HcDiaglePage,
        HcButton,
    },
    mounted() {
    },

    data() {
        return {
            ruleForm: {
                userId: "",//菜单名称
                userName: "",//上级菜单
                enterpriseType: "",//菜单URL
                enterpriseId: 0,//排序
                enterpriseName: "",//菜单图标

            },
            params: {
                pageSize: 20,
                pageNum: 1,
                total: null,
                totalPage: null,
            },
            rules: {
                userId: [
                    { required: true, message: "请输入菜单名称", trigger: "blur" },
                ],
                userName: [
                    { required: true, message: "请选择上级菜单", trigger: "change" },
                ],
            },
        };
    },
    methods: {
        handleChange(value) {
            console.log(value);
        },
        showDiagle(data) {
            this.$refs.hcDiaglePage.showLog = true;
            // 判断弹框是编辑页面还是新增页面或者是详情页面
            this.dialogType = data
            if (this.dialogType == 'edit') {
                console.log(this.editData, 'hello1')
                this.ruleForm.userId = this.editData.userId;//菜单名称
                this.ruleForm.userName = this.editData.userName;//上级菜单
                this.ruleForm.enterpriseType = this.editData.enterpriseType;//菜单URL
                this.ruleForm.enterpriseId = this.editData.enterpriseId;//排序
                this.ruleForm.roleId = this.editData.roleId;//菜单图标
            }
        },
        add(params) {
            server.addUser(params).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '新增成功',
                        type: 'success'
                    });
                    this.$refs.hcDiaglePage.enter();//关闭弹框  
                    this.$parent.getUserList(this.params)//调用列表页面，刷新列表
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: 'warning'
                    });
                });
        },
        edit(params) {
            if (this.dialogType == 'edit') {
                server.editUser(params).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            message: '编辑成功',
                            type: 'success'
                        });
                        this.$refs.hcDiaglePage.enter();//关闭弹框  
                        this.$parent.getUserList(this.params)//调用列表页面，刷新列表
                    } else {
                        this.$message({
                            message: res.message,
                            type: 'warning'
                        });
                    }
                })
                    .catch((err) => {
                        this.$message({
                            message: err.message,
                            type: 'warning'
                        });
                    });
            }

        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.dialogType == 'edit') {
                        this.edit(this.ruleForm)//调用编辑接口
                    } else {
                        this.add(this.ruleForm) //调用新增接口
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$refs.hcDiaglePage.close();
            this.ruleForm = {
                //清空表单数据
            }
        },
    },
};
</script>
  
<style scoped lang="less">
.card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}

::v-deep .el-input--small .el-input__inner {
    width: 300px;
}

::v-deep .el-textarea__inner {
    width: 800px;
}
::v-deep .el-input-number--small{
    width: 300px;
}
</style>
  