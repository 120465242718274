<template>
  <div class="HcBotton_box">
    <el-button v-bind="$attrs" v-on="$listeners"
      :class="type == 'primary' ? 'primary_botton' : type == 'info' ? 'info_botton' : type == 'refresh' ? 'refresh_botton' : type == 'cancel' ? 'cancel_botton' : null">
      <slot></slot>
    </el-button>
  </div>
</template>

<script>
export default {
  name: "index",
  //使用该组件，需传入type值，为字符串类型，传入'primary'为蓝色按钮，传入'info'为黄色按钮
  props: {
    type: {
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="less">
.HcBotton_box {
  .primary_botton {
    background: #00BFFF;
    color: #ffffff;
  }

  .info_botton {
    background: #EDC13F;
    color: #ffffff;
  }

  .refresh_botton{
    background: #F2F3F5;
    color: #00BFFF;
  }
  .cancel_botton{
    background: #F2F3F5;
    color: #808080;
  }

  ::v-deep .el-button {
    padding: 10px 20px;
    border: none;
  }
}
</style>
