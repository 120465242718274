<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
    </template>
    <template v-slot:center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="标题" prop="knowledgeName">
              <el-input v-model="ruleForm.knowledgeName" clearable placeholder="请输入标题"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属栏目" prop="knowledgeParentId">
              <el-select v-model="ruleForm.knowledgeParentId" clearable filterable placeholder="请选择所属栏目">
                <el-option v-for="item in titleList" :key="item.knowledgeParentId" :label="item.knowledgeParentName"
                  :value="item.knowledgeParentId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="附件" prop="ossId">
              <HcUploadFile v-model="ruleForm.ossId"></HcUploadFile>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-slot:footer>
      
      <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
    </template>
  </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import HcUploadFile from "@/components/HcUploadFile/index.vue";

import * as server from "@/api/knowledgeApi";

export default {
  name: "editDiagle",
  props: {
    editData: {
      type: Object,
      default: null
    }
  },
  components: {
    HcDiaglePage,
    HcButton,
    HcUploadFile,
  },
  mounted() {
  },

  data() {
    return {
      parentId:'',//存一下接收到的id
      userObj: JSON.parse(sessionStorage.getItem('userInfo')),//从sessionStorage拿出用户信息,
      titleList: [],//所属栏目下拉框
      ruleForm: {
        knowledgeName: '',//题目
        knowledgeParentId: '',//所属栏目-父级目录id
        ossId: '',//附件地址
        knowledgeInfoNo:'',//知识库序列号
        createUser:'',//创建人
      },
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        knowledgeParentId: '1',//给定默认目录id
      },
      rules: {
        knowledgeName: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
        knowledgeParentId: [
          { required: true, message: "请选择所属栏目", trigger: "change" },
        ],
        ossId: [
          { required: true, message: "请上传文件", trigger: "change" },
        ],

      },
    };
  },
  methods: {
    showDiagle(data) {
      this.getKnowledgeParentList()
      this.$refs.hcDiaglePage.showLog = true;
      // 判断弹框是编辑页面还是新增页面或者是详情页面
      this.dialogType = data
      if (this.dialogType == 'edit') {
        console.log(this.editData, 'hello1111111111')
        this.ruleForm.knowledgeName = this.editData.knowledgeName;//标题
        this.ruleForm.knowledgeParentId = this.editData.knowledgeParentId;//所属栏目名称
        this.parentId = this.editData.knowledgeParentId;
        this.ruleForm.ossId = this.editData.ossId;//附件
        this.ruleForm.knowledgeInfoNo = this.editData.knowledgeInfoNo;//知识库序列号
      }
      else if (this.dialogType == 'add') {
        this.ruleForm.knowledgeName = '';//标题
        this.ruleForm.knowledgeParentId =  '';//所属栏目名称
        this.parentId = '';
        this.ruleForm.ossId = '';//附件
        this.ruleForm.knowledgeInfoNo = '';//知识库序列号
      }
    },
    //获取左侧目录
    getKnowledgeParentList() {
      this.loading = true //请求时打开加载中
      server.knowledgeParentList().then((res) => {
        if (res.code == 200) {
          this.loading = false //成功关闭加载中
          // 将获取的list赋值给table
          this.titleList = res.data
          console.log(this.titleList)
        }
      }).catch((err) => {
        setTimeout(() => {//失败2秒后关闭加载中
          this.loading = false
        }, 2000)
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
    },
    add(params) {
      server.addContent(params).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.$refs.hcDiaglePage.enter();//关闭弹框  
          this.params.knowledgeParentId = this.ruleForm.knowledgeParentId
          this.$parent.getKnowledgelist(this.params)//调用列表页面，刷新列表
          this.$parent.titleClick('type',this.params.knowledgeParentId)
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: 'warning'
          });
        });
    },
    edit(params) {
      if (this.dialogType == 'edit') {
        server.updataKnowledge(params).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            });
            this.$refs.hcDiaglePage.enter();//关闭弹框  
            this.params.knowledgeParentId = this.parentId
            this.$parent.getKnowledgelist(this.params)//调用列表页面，刷新列表
            
            
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
          .catch((err) => {
            this.$message({
              message: err.message,
              type: 'warning'
            });
          });
      }

    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType == 'edit') {
            this.ruleForm.createUser = this.userObj.userName
            this.edit(this.ruleForm)//调用编辑接口
            console.log(this.ruleForm,'ffff')
          } if (this.dialogType == 'add') {
            this.ruleForm.createUser = this.userObj.userName
            this.add(this.ruleForm) //调用新增接口
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$refs.hcDiaglePage.close();
      this.ruleForm = {
        knowledgeName: '',//题目
        knowledgeParentId: '',//所属栏目-父级目录id
        ossId: '',//附件地址
        knowledgeInfoNo:'',//知识库序列号
      }
    },
  },
};
</script>
  
<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}
::v-deep .el-input.el-input--small.el-input--suffix{
width: 300px;
}
::v-deep .el-textarea__inner {
  width: 800px;
}

.addressClass {
  display: flex;

  .addressDiv {
    cursor: pointer;
  }

  .addressIcon {
    font-size: 24px;
    color: #E6A23C;
    margin-left: 6px;
  }

  span {
    font-size: 14px;
    color: #E6A23C;
  }
}
.demo-ruleForm{
  margin-top: 20px;
}
</style>
  