<template>
  <div class="guide" ref="guide">
    <div class="wrap">
      <div class="box">
        <div class="image">
          <img src="@/assets/pt.png" alt="">
        </div>
        <div class="title">
          <span class="name">智能危废监控云平台</span>
          <i class="el-icon-download icon" @click="handleDownload(ossId1)"></i>
        </div>
      </div>
      <div class="box">
        <div class="image">
          <img src="@/assets/cf.png" alt="">
        </div>
        <div class="title">
          <span class="name">产废端小程序</span>
          <i class="el-icon-download icon" @click="handleDownload(ossId2)"></i>
        </div>
      </div>
      <div class="box">
        <div class="image">
          <img src="@/assets/ys.png" alt="">
        </div>
        <div class="title">
          <span class="name">运输端小程序</span>
          <i class="el-icon-download icon" @click="handleDownload(ossId3)"></i>
        </div>
      </div>
      <div class="box">
        <div class="image">
          <img src="@/assets/sc.png" alt="">
        </div>
        <div class="title">
          <span class="name">处置端小程序</span>
          <i class="el-icon-download icon" @click="handleDownload(ossId4)"></i>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'guide',
  data() {
    return {
      //默认配置好的，直接下载，如需替换文档内容，直接上传替换桶里即可，ossId不用动
      ossId1:'1661180776958201858',//平台
      ossId2:'1661180943824392194',//产废端
      ossId3:'1661180992390238210',//运输端
      ossId4:'1661181030193500161',//处置端
    }
  },
  mounted() {
    this.getHeight()
  },
  
  methods: {
    getHeight() {//动态计算中间内容部分高度
      let bodyHeight = document.body.clientHeight
      let contentHeight = bodyHeight - 148
      console.log(bodyHeight, contentHeight, 'height')
      this.$refs.guide.style.height = contentHeight + 'px'
      sessionStorage.setItem('guideHeight', contentHeight)
    },
    // 下载附件
    handleDownload(ossId) {
        this.downloadOss(ossId);
    },
  }

}
</script>
  
<style scoped lang="less">
.guide {
  min-height: 500px;
  background: #ffffff;
}

.wrap {
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: space-evenly
}

.box {
  width: 23%;
  margin-top: 32px;
  box-sizing: border-box;
  border: 1px solid #E7E7E7;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.box:hover {
  box-shadow: 0px 4px 10px 0px rgba(178, 178, 178, 0.3);
}

.image {
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
}

.title {
  font-size: 16px;
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.icon {
  display: none;
}

.name {
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.image>img {
  width: 100%;
  height: 100%;
}

.box:hover>.title>.icon {
  color: #00BFFF;
  font-size: 24px;
  padding-right: 20px;
  display: block;
  cursor: pointer;
}

.box:hover>.title>.name {
  color: #00BFFF;
  margin-left: 44px;
}
</style>
  