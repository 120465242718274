<template>
    <div class="hc_pagination">
        <el-pagination  :page-sizes="[20, 50, 100, 200]" layout="total, sizes, prev, pager, next, jumper" v-bind="$attrs" v-on="$listeners" background>
        </el-pagination>
    </div>
</template>
<script>
export default {
    name: 'hc_pagination',
    props:{
      
    },
    data() {
        return {
         
        }
    },
    methods: {
     
    }
}

</script>
<style lang="less" scoped>
::v-deep .el-pager li.active {
    background-color: #00BFFF;
    color: #ffffff;
    border-radius: 4px;
    min-width: 28px;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #00BFFF;
}
</style>