import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
  {
    path: "/screen",
    name: "screen",
    component: () => import("@/views/screen/index.vue"),
  },
  {
    path: "/",
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          title: "首页",
          activation: true,
          componentName: "home",
        },

        component: () => import("@/views/weclome/index.vue"),
      },
      {
        path: "/wasteProduction",
        name: "wasteProduction",
        meta: {
          title: "产废企业列表",
          activation: true,
          componentName: "wasteProduction",
        },
        component: () =>
          import("@/views/business/wasteProduction/wasteProduction.vue"),
      },

      {
        path: "/transport",
        name: "transport",
        meta: {
          title: "运输企业列表",
          activation: true,
          componentName: "transport",
        },

        component: () => import("@/views/business/transport/transport.vue"),
      },

      {
        path: "/hazardousWaste",
        name: "hazardousWaste",
        meta: {
          title: "收集企业列表",
          activation: true,
          componentName: "hazardousWaste",
        },
        component: () =>
          import("@/views/business/hazardousWaste/hazardousWaste.vue"),
      },
      {
        path: "/handle",
        name: "handle",
        meta: {
          title: "处置企业列表",
          activation: true,
          componentName: "handle",
        },
        component: () => import("@/views/business/handle/handle.vue"),
      },
      {
        path: "/ledger",
        name: "ledger",
        meta: {
          title: "企业台账导入",
          activation: true,
          componentName: "ledger",
        },
        component: () => import("@/views/business/ledger/ledger.vue"),
      },
      //合同管理
      {
        path: "/contractList",
        name: "contractList",
        meta: {
          title: "合同列表",
          activation: true,
          componentName: "contractList",
        },
        component: () => import("@/views/contract/contractList.vue"),
      },
      //管理计划
      {
        path: "/planList",
        name: "planList",
        meta: {
          title: "计划列表",
          activation: true,
          componentName: "planList",
        },
        component: () => import("@/views/managePlan/planList.vue"),
      },
      //联单管理
      {
        path: "/jointOrderList",
        name: "jointOrderList",
        meta: {
          title: "联单列表",
          activation: true,
          componentName: "jointOrderList",
        },
        component: () => import("@/views/jointOrder/jointOrderList.vue"),
      },
      {
        path: "/pendingApprovalList",
        name: "pendingApprovalList",
        meta: {
          title: "待审批联单",
          activation: true,
          componentName: "pendingApprovalList",
        },
        component: () => import("@/views/jointOrder/pendingApprovalList.vue"),
      },
      {
        path: "/fiveMustCheckList",
        name: "fiveMustCheckList",
        meta: {
          title: "待五必查联单",
          activation: true,
          componentName: "fiveMustCheckList",
        },
        component: () => import("@/views/jointOrder/fiveMustCheckList.vue"),
      },
      {
        path: "/pendingInStorageList",
        name: "pendingInStorageList",
        meta: {
          title: "待入库联单",
          activation: true,
          componentName: "pendingInStorageList",
        },
        component: () => import("@/views/jointOrder/pendingInStorageList.vue"),
      },
      //设备管理
      {
        path: "/overview",
        name: "overview",
        meta: {
          title: "智能危废箱总览",
          activation: true,
          componentName: "overview",
        },
        component: () => import("@/views/device/overview2.vue"),
      },
      {
        path: "/binList",
        name: "binList",
        meta: {
          title: "智能危废箱列表",
          activation: true,
          componentName: "binList",
        },
        component: () => import("@/views/device/binList.vue"),
      },
      {
        path: "/storageList",
        name: "storageList",
        meta: {
          title: "危废贮存列表",
          activation: true,
          componentName: "storageList",
        },
        component: () => import("@/views/device/storageList.vue"),
      },
      //库存管理
      {
        path: "/inventory",
        name: "inventory",
        meta: {
          title: "实时库存",
          activation: true,
          componentName: "inventory",
        },
        component: () => import("@/views/inventory/inventory/inventory.vue"),
      },
      {
        path: "/storage",
        name: "storage",
        meta: {
          title: "入库记录",
          activation: true,
          componentName: "storage",
        },
        component: () => import("@/views/inventory/storage/storage.vue"),
      },
      {
        path: "/outbound",
        name: "outbound",
        meta: {
          title: "出库记录",
          activation: true,
          componentName: "outbound",
        },
        component: () => import("@/views/inventory/outbound/outbound.vue"),
      },
      {
        path: "/inventoryStat",
        name: "inventoryStat",
        meta: {
          title: "库存统计",
          activation: true,
          componentName: "inventoryStat",
        },
        component: () => import("@/views/inventory/inventoryStat.vue"),
      },
      //知识库
      {
        path: "/knowledgeList",
        name: "knowledgeList",
        meta: {
          title: "知识列表",
          activation: true,
          componentName: "knowledgeList",
        },
        component: () => import("@/views/knowledge/knowledgeList.vue"),
      },
      {
        path: "/user",
        name: "user",
        meta: {
          title: "用户管理",
          activation: true,
          componentName: "user",
        },
        component: () => import("@/views/system/user/user.vue"),
      },
      {
        path: "/role",
        name: "role",
        meta: {
          title: "角色管理",
          activation: true,
          componentName: "role",
        },
        component: () => import("@/views/system/role/role.vue"),
      },
      {
        path: "/menus",
        name: "menus",
        meta: {
          title: "菜单管理",
          activation: true,
          componentName: "menus",
        },
        component: () => import("@/views/system/menus/menus.vue"),
      },
      {
        path: "/dictionary",
        name: "dictionary",
        meta: {
          title: "字典管理",
          activation: true,
          componentName: "dictionary",
        },
        component: () => import("@/views/system/dictionary/dictionary.vue"),
      },
      {
        path: "/directory",
        name: "directory",
        meta: {
          title: "危废名录",
          activation: true,
          componentName: "directory",
        },
        component: () => import("@/views/system/directory/directory.vue"),
      },
      {
        path: "/guide",
        name: "guide",
        meta: {
          title: "操作指南",
          activation: true,
          componentName: "guide",
        },
        component: () => import("@/views/system/guide.vue"),
      },
      //报警管理
      {
        path: "/alarmList",
        name: "alarmList",
        meta: {
          title: "报警列表",
          activation: true,
          componentName: "alarmList",
        },
        component: () => import("@/views/alarm/alarmList.vue"),
      },
      {
        path: "/alarmStat",
        name: "alarmStat",
        meta: {
          title: "报警统计",
          activation: true,
          componentName: "alarmStat",
        },
        component: () => import("@/views/alarm/alarmStat.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
// 导航守卫：使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === "/login") next();
  const tokenStr = window.localStorage.getItem("token");
  // 增加判断条件
  if (!tokenStr && to.path !== "/login") return next("/login");
  next();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
