<template>
  <div class="binList">
    <!-- 设备管理-智能危废箱列表 -->
    <HcListMain>
      <template v-slot:header_from>
        <el-form
          ref="form"
          :model="params"
          label-width="120px"
          size="small"
          :inline="true"
        >
          <el-form-item label="当前所属企业">
            <el-select
              v-model="params.enterpriseId"
              clearable
              filterable
              placeholder="请选择当前所属企业"
            >
              <el-option
                v-for="item in enterpriseOptionArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属区域">
            <HcAreaSelect @area="getArea" ref="HcAreaSelect"></HcAreaSelect>
          </el-form-item>
          <el-form-item label="设备ID">
            <el-input
              v-model="params.deviceId"
              clearable
              placeholder="请输入设备ID"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备型号">
            <el-select
              v-model="params.deviceModel"
              clearable
              filterable
              placeholder="请选择设备型号"
            >
              <el-option label="50L" value="1"></el-option>
              <el-option label="200L" value="2"></el-option>
              <el-option label="1T" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="在线状态">
            <el-select
              v-model="params.onlineStates"
              clearable
              filterable
              placeholder="请选择在线状态"
            >
              <el-option label="在线" value="1"></el-option>
              <el-option label="离线" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定状态">
            <el-select
              v-model="params.bindStates"
              clearable
              filterable
              placeholder="请选择绑定状态"
            >
              <el-option label="已绑定" value="1"></el-option>
              <el-option label="未绑定" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备状态">
            <el-select
              v-model="params.deviceStates"
              clearable
              filterable
              placeholder="请选择设备状态"
            >
              <el-option
                v-for="item in MAP2JSON(global.DeviceStatusMap)"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:header_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-search"
          class="button"
          size="small"
          @click="queryList"
          >查询</HcButton
        >
        <HcButton
          :type="'refresh'"
          icon="el-icon-refresh"
          size="small"
          @click="reset"
          >重置</HcButton
        >
      </template>
      <template v-slot:content_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-plus"
          size="small"
          @click="openAdd"
          >新增</HcButton
        >
      </template>
      <template v-slot:content_table>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :height="tableHeight"
          v-loading="loading"
          element-loading-text="加载中"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="enterpriseName"
            label="当前所属企业"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="townAddress"
            label="所属区域"
            min-width="200"
          ></el-table-column>
          <el-table-column label="设备版本" width="130">
            <template slot-scope="scope">
              {{ global.DeviceVersionMap.get(scope.row.deviceVersion) }}
            </template>
          </el-table-column>
          <el-table-column label="设备型号" width="80">
            <template slot-scope="scope">
              {{ global.DeviceModelMap.get(scope.row.deviceModel) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="deviceName"
            label="设备名称"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="categoryCode"
            label="危废类别"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="categoryName"
            label="危废类别名称"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="harmWasteCode"
            label="废物代码"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="harmWasteName"
            label="废物名称"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="deviceId"
            label="设备ID"
            width="220"
          ></el-table-column>
          <el-table-column label="当前存储量(kg)" width="130">
            <template slot-scope="scope">
              <!-- <span v-if="scope.row.storageNum == null || scope.row.storageNum == ' '">0.00</span> -->
              <span
                v-if="
                  scope.row.storageNum != null || scope.row.storageNum != ' '
                "
                >{{ scope.row.storageNum }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="设备经/纬度" width="200">
            <template slot-scope="scope">
              {{ scope.row.deviceLocationLongitude }} /
              {{ scope.row.deviceLocationLatitude }}
            </template>
          </el-table-column>
          <el-table-column label="在线状态" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.onlineStates == '1'"
                ><span style="color: #67c23a"
                  ><span class="dot_green"></span>在线</span
                ></span
              >
              <span v-if="scope.row.onlineStates == '0'"
                ><span style="color: #909399"
                  ><span class="dot_grey"></span>离线</span
                ></span
              >
            </template>
          </el-table-column>
          <el-table-column label="设备状态" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.deviceStates == '1'"
                ><el-tag type="success" size="medium">正常</el-tag></span
              >
              <span v-if="scope.row.deviceStates == '2'"
                ><el-tag type="warning" size="medium">报警</el-tag></span
              >
              <span v-if="scope.row.deviceStates == '3'"
                ><el-tag type="danger" size="medium">故障</el-tag></span
              >
            </template>
          </el-table-column>
          <el-table-column label="绑定状态" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.deviceId == null || scope.row.deviceId == ''"
                ><span style="color: #f56c6c">未绑定</span></span
              >
              <span
                v-if="scope.row.deviceId != null && scope.row.deviceId != ''"
                ><span style="color: #409eff">已绑定</span></span
              >
            </template>
          </el-table-column>
          <el-table-column label="开启/关闭" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.isOn === '1'">开启</span>
              <span v-if="scope.row.isOn === '0'">关闭</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                @click="openDetailDiagle(scope.row)"
                type="text"
                size="default"
                >详情</el-button
              >
              <el-button type="text" size="default" @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-popconfirm
                title="是否确定删除所选数据？"
                @confirm="removeOne(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="text"
                  size="default"
                  class="button_text_del"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-slot:content_pag>
        <HcPagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNum"
          :page-size="params.pageSize"
          :total="params.total"
        >
        </HcPagination>
      </template>
    </HcListMain>

    <!-- 设备新增页面 -->
    <deviceAdd
      ref="deviceAdd"
      :deviceType="1"
      :editData="editData"
      :enterpriseOptionArr="enterpriseOptionArr"
    >
    </deviceAdd>

    <!-- 详情页面 -->
    <detailDiagle ref="detailDiagle"></detailDiagle>
  </div>
</template>

<script>
import HcButton from "@/components/HcButton/index.vue";
import HcInput from "@/components/HcInput/index.vue";
import HcListMain from "@/components/HcListMain/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import HcAreaSelect from "@/components/HcAreaSelect/index.vue";

import * as server from "@/api/deviceApi";
import * as server2 from "@/api/planApi";

import deviceAdd from "./pages/deviceAdd.vue"; //新增智能危废箱
import detailDiagle from "./pages/detailDiagle.vue"; //详情页面

export default {
  components: {
    HcButton,
    HcInput,
    HcListMain,
    HcPagination,
    HcAreaSelect,
    deviceAdd,
    detailDiagle,
  },
  name: "binList",
  data() {
    return {
      enterpriseOptionArr: [],
      switchvalue: true,
      checkList: [],
      tableData: [],
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        enterpriseId: "",
        deviceModel: "", //设备型号
        deviceStates: "", //设备状态
        deviceId: "", //设备ID
        bindStates: "", //绑定状态
        onlineStates: "", //在线状态
        deviceType: "1", //设备类型：1智能危废箱，2贮存处
        enterpriseProvince: "", //省
        enterpriseCity: "", //市
        enterpriseRegion: "", //区
        enterpriseTown: "", //街道镇
      },
      loading: false, //加载中
      detailTit: "",
      editData: null, //编辑页面数据,
      mainHeight: null, //中间高度
      tableHeight: null, //表格高度
    };
  },
  provide() {
    return {
      title: () => this.detailTit,
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
    },
    handleSizeChange(data) {
      this.params.pageSize = data; //每页多少条
      this.getList(this.params); //重新请求获取table的接口
    },
    handleCurrentChange(data) {
      this.params.pageNum = data; //当前多少页
      this.getList(this.params); //重新获取table的接口
    },
    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1; //将当前页面设置为第一页
      this.getList(this.params);
    },
    reset() {
      this.params.enterpriseId = "";
      this.params.deviceModel = "";
      this.params.deviceStates = "";
      this.params.deviceId = "";
      this.params.bindStates = "";
      this.params.onlineStates = "";
      this.params.deviceType = "1";
      this.params.enterpriseProvince = ""; //省
      this.params.enterpriseCity = ""; //市
      this.params.enterpriseRegion = ""; //区
      this.params.enterpriseTown = ""; //镇
      this.params.pageSize = 20;
      this.params.pageNum = 1;
      this.$refs.HcAreaSelect.rest();
      this.queryList();
    },

    // 获取table表格数据
    getList(params) {
      this.loading = true; //请求时打开加载中
      server
        .list(params)
        .then((res) => {
          this.loading = false; //成功关闭加载中
          // 将获取的list赋值给table
          this.tableData = res.data.list;

          // 设置分页的数据
          this.params.totalPage = res.data.totalPage;
          this.params.total = res.data.total;
          this.params.pageNum = this.params.pageNum;
          this.params.pageSize = this.params.pageSize;
        })
        .catch((err) => {
          setTimeout(() => {
            //失败2秒后关闭加载中
            this.loading = false;
          }, 2000);
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });
    },

    // 新增设备信息
    openAdd() {
      this.detailTit = "新增设备信息";
      this.$refs.deviceAdd.showDiagle();
    },

    // 编辑设备信息
    edit(row) {
      this.detailTit = "编辑设备信息";
      this.editData = row;
      console.log(this.editData, "编辑设备信息");
      setTimeout(() => {
        this.$refs.deviceAdd.showDiagle("edit");
      });
    },

    //删除列表中的一条信息
    removeOne(row) {
      server
        .remove({
          ids: row.deviceNo,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success",
            });
            this.queryList();
          } else {
            this.$message({
              showClose: true,
              message: res.message,
              type: "warning",
            });
          }
        });
    },

    // 获取产废企业下拉框数据
    async getEnterpriseOption() {
      let params = { enterpriseType: "1" };
      await server2.allEnterpriseList(params).then((res) => {
        this.enterpriseOptionArr = res.data;
      });
    },

    // 打开详情页面
    openDetailDiagle(row) {
      this.editData = row;
      this.detailTit = "智能危废箱详情";
      setTimeout(() => {
        this.$refs.detailDiagle.showDiagle("detail");
        this.$refs.detailDiagle.queryDeviceDetail({ deviceNo: row.deviceNo });
        this.$refs.detailDiagle.queryCurrentDate({ deviceId: row.deviceId });
        this.$refs.detailDiagle.queryHistoryMonitor({
          deviceId: row.deviceId,
          pageSize: 20,
          pageNum: 1,
        });
        this.$refs.detailDiagle.queryDeviceAlarm({
          deviceId: row.deviceId,
          pageSize: 20,
          pageNum: 1,
        });
        this.$refs.detailDiagle.queryDeviceTrouble({
          deviceId: row.deviceId,
          pageSize: 20,
          pageNum: 1,
        });
      });
    },

    // 省市区镇选择
    getArea(val) {
      this.params.enterpriseProvince = val.enterpriseProvince; //省
      this.params.enterpriseCity = val.enterpriseCity; //市
      this.params.enterpriseRegion = val.enterpriseRegion; //区
      this.params.enterpriseTown = val.enterpriseTown; //镇
    },
  },
  mounted() {
    //页面挂载时第一次调用接口请求
    this.getEnterpriseOption();
    this.queryList();
    this.mainHeight = sessionStorage.getItem("listMainHeight"); //获取中间区域的高度

    // 详情
    if (this.$route.query.type == "alarmDetail") {
      const data = JSON.parse(window.sessionStorage.getItem("alarmDetail"));
      this.editData = data;
      this.detailTit = "智能危废箱详情";
      setTimeout(() => {
        this.$refs.detailDiagle.showDiagle("detail");
        this.$refs.detailDiagle.queryDeviceDetail({ deviceNo: data.deviceNo });
        this.$refs.detailDiagle.queryCurrentDate({ deviceId: data.deviceId });
        this.$refs.detailDiagle.queryHistoryMonitor({
          deviceId: data.deviceId,
          pageSize: 20,
          pageNum: 1,
        });
        this.$refs.detailDiagle.queryDeviceAlarm({
          deviceId: data.deviceId,
          pageSize: 20,
          pageNum: 1,
        });
        this.$refs.detailDiagle.queryDeviceTrouble({
          deviceId: data.deviceId,
          pageSize: 20,
          pageNum: 1,
        });
      });
    }
    // 从报警列表过来-详情
    if (this.$route.query.type == "tabPage") {
      const data = JSON.parse(window.sessionStorage.getItem("tabPage"));
      // debugger
      this.editData = data;
      console.log(data, "editData111111");
      this.detailTit = "智能危废箱详情";
      setTimeout(() => {
        this.$refs.detailDiagle.showDiagle("detail");
        this.$refs.detailDiagle.queryDeviceDetail({ deviceNo: data.deviceNo });
        this.$refs.detailDiagle.queryCurrentDate({ deviceId: data.deviceId });
        this.$refs.detailDiagle.queryHistoryMonitor({
          deviceId: data.deviceId,
          pageSize: 20,
          pageNum: 1,
        });
        this.$refs.detailDiagle.queryDeviceAlarm({
          deviceId: data.deviceId,
          pageSize: 20,
          pageNum: 1,
        });
        this.$refs.detailDiagle.queryDeviceTrouble({
          deviceId: data.deviceId,
          pageSize: 20,
          pageNum: 1,
        });
      });
    }
    // 新增
    if (this.$route.query.type == "deviceAdd") {
      this.$refs.deviceAdd.showDiagle();
    }
    // 编辑
    if (this.$route.query.type == "alarmEdit") {
      const data = JSON.parse(window.sessionStorage.getItem("alarmEdit"));
      this.editData = data;
      setTimeout(() => {
        this.$refs.deviceAdd.showDiagle("edit");
      });
    }
  },
  watch: {
    mainHeight: function (item) {
      //通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 260;
    },
    "$route.query": function (item) {
      //路由改变刷新列表
      server
        .list({
          pageNum: "1",
          pageSize: "20",
          deviceType: "1",
        })
        .then((res) => {
          this.tableData = res.data.list;
        })
        .catch((err) => {
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });

      // 详情
      if (item.type == "alarmDetail") {
        const data = JSON.parse(window.sessionStorage.getItem("alarmDetail"));
        this.editData = data;
        this.detailTit = "智能危废箱详情";
        setTimeout(() => {
          this.$refs.detailDiagle.showDiagle("detail");
          this.$refs.detailDiagle.queryDeviceDetail({
            deviceNo: data.deviceNo,
          });
          this.$refs.detailDiagle.queryCurrentDate({ deviceId: data.deviceId });
          this.$refs.detailDiagle.queryHistoryMonitor({
            deviceId: data.deviceId,
            pageSize: 20,
            pageNum: 1,
          });
          this.$refs.detailDiagle.queryDeviceAlarm({
            deviceId: data.deviceId,
            pageSize: 20,
            pageNum: 1,
          });
          this.$refs.detailDiagle.queryDeviceTrouble({
            deviceId: data.deviceId,
            pageSize: 20,
            pageNum: 1,
          });
        });
      }
      // 从报警列表过来-详情
      if (this.$route.query.type == "tabPage") {
        const data = JSON.parse(window.sessionStorage.getItem("tabPage"));
        this.editData = data;
        console.log(
          data,
          this.editData,
          "this.editDatathis.editDatathis.editData"
        );
        this.detailTit = "智能危废箱详情";
        setTimeout(() => {
          this.$refs.detailDiagle.showDiagle("detail");
          this.$refs.detailDiagle.queryDeviceDetail({
            deviceNo: data.deviceNo,
          });
          this.$refs.detailDiagle.queryCurrentDate({ deviceId: data.deviceId });
          this.$refs.detailDiagle.queryHistoryMonitor({
            deviceId: data.deviceId,
            pageSize: 20,
            pageNum: 1,
          });
          this.$refs.detailDiagle.queryDeviceAlarm({
            deviceId: data.deviceId,
            pageSize: 20,
            pageNum: 1,
          });
          this.$refs.detailDiagle.queryDeviceTrouble({
            deviceId: data.deviceId,
            pageSize: 20,
            pageNum: 1,
          });
        });
      }
      // 新增
      if (this.$route.query.type == "deviceAdd") {
        this.$refs.deviceAdd.showDiagle();
      }
      // 编辑
      if (this.$route.query.type == "alarmEdit") {
        const data = JSON.parse(window.sessionStorage.getItem("alarmEdit"));
        this.editData = data;
        setTimeout(() => {
          this.$refs.deviceAdd.showDiagle("edit");
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.binList {
  min-height: 500px;
  background: #ffffff;
}

.button_div {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}

.button {
  padding: 0 10px 0 0;
}

//表格里的 删除按钮
.button_text_del {
  color: #f56c6c !important;
  padding-left: 10px;
}

/deep/.el-button--primary {
  background: #00bfff;
}

.dot_green {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #67c23a;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
}

.dot_grey {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #909399;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
}
</style>
