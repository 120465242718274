import { render, staticRenderFns } from "./tab2.vue?vue&type=template&id=2ee693a1&scoped=true&"
import script from "./tab2.vue?vue&type=script&lang=js&"
export * from "./tab2.vue?vue&type=script&lang=js&"
import style0 from "./tab2.vue?vue&type=style&index=0&id=2ee693a1&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ee693a1",
  null
  
)

export default component.exports