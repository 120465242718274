<template>
  <!-- 首页 -->
  <div class="home" ref="home">
    <div class="home_top">
      <div class="top_left">
        <div><img src="../../assets/userImg.png" /></div>

        <div class="left_detail">
          <div class="left_tit">{{ timeS }} {{ userObj.userName }}</div>
          <div class="left_cent">
            <div>
              <i class="el-icon-office-building"></i
              >{{ userObj.enterpriseName }}
            </div>
            <div>
              <i class="el-icon-user shouji"></i
              >{{ global.EnterpriseTypeMap.get(userObj.enterpriseType) }}
            </div>
          </div>
        </div>
      </div>
      <div class="top_right">
        <div class="right_li">
          <div class="li_tit">实时库存</div>
          <div class="li_num">{{ stockObj.sskc }}<span> 吨</span></div>
        </div>
        <div class="right_li right_li2">
          <div class="li_tit">入库量</div>
          <div class="li_num">{{ stockObj.rkl }}<span> 吨</span></div>
        </div>
        <div class="right_li right_li2">
          <div class="li_tit">出库量</div>
          <div class="li_num">{{ stockObj.ckl }}<span> 吨</span></div>
        </div>
      </div>
    </div>
    <div class="home_cent">
      <el-row :gutter="16">
        <el-col :span="16">
          <div class="liandan">
            <div class="liandan_tit">
              <div>联单情况</div>
              <!-- TODO:hover样式还没 -->
              <div @click="goOrder('jointOrderList', '3')">
                <i class="el-icon-more"></i>
              </div>
            </div>
            <el-row class="liandan_cen" :gutter="36">
              <el-col :span="6">
                <div
                  class="liandan_li liandanBack"
                  @click="goOrder('jointOrderList', '3')"
                >
                  <img src="../../assets/zongshu.png" />
                  <div class="liandan_li_detail">
                    <div class="detail_tit">{{ sheetObj.sumNum }}</div>
                    <div class="detail_cen">联单总数</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6" class="">
                <div
                  class="liandan_li liandanBack"
                  @click="goOrder('pendingApprovalList')"
                >
                  <img src="../../assets/daishenpi.png" />
                  <div class="liandan_li_detail">
                    <div class="detail_tit">{{ sheetObj.dspNum }}</div>
                    <div class="detail_cen">待审批联单</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div
                  class="liandan_li liandanBack"
                  @click="goOrder('jointOrderList', '1')"
                >
                  <img src="../../assets/jinxingzhong.png" />
                  <div class="liandan_li_detail">
                    <div class="detail_tit">{{ sheetObj.jxzNum }}</div>
                    <div class="detail_cen">进行中联单</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div
                  class="liandan_li liandanBack"
                  @click="goOrder('jointOrderList', '2')"
                >
                  <img src="../../assets/yibanjie.png" />
                  <div class="liandan_li_detail">
                    <div class="detail_tit">{{ sheetObj.ybjNum }}</div>
                    <div class="detail_cen">已办结联单</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="liandan">
            <div class="liandan_tit">
              <div>合同情况</div>
              <div></div>
            </div>
            <div class="liandan_chart">
              <div style="padding-top: 20px; height: 130px; line-height: 130px">
                <el-progress
                  type="dashboard"
                  :percentage="Number(agreementObj.agreementProportion)"
                  :color="colors"
                  :stroke-width="16"
                  stroke-linecap="square"
                ></el-progress>
              </div>
              <div>
                <div class="liandan_chart_num">
                  {{ agreementObj.agreementNumber }}/{{ agreementObj.total }}
                </div>
                <div class="liann_chart_detail">
                  <div class="lian_chart_li"></div>
                  <div class="lian_chart_li_zhanbi">
                    履行合同占比{{ agreementObj.agreementProportion }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="home_cent">
      <el-row :gutter="16">
        <el-col :span="16">
          <div class="liandan liandan2">
            <div class="liandan_tit">
              <div>联单情况</div>
              <div>
                <el-date-picker
                  v-model="params.dataTime"
                  type="year"
                  placeholder="选择年度"
                  @change="chooseYear"
                  value-format="yyyy"
                  size="medium"
                  :clearable="false"
                ></el-date-picker>
              </div>
            </div>
            <el-row class="liandan_cen">
              <div id="yearChart" style="width: 50vw; height: 460px"></div>
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="liandan liandan2">
            <div class="liandan_tit">
              <div>本年度申报量排行TOP10</div>
              <div class="liandan_tab">
                <div
                  :class="tabValue == '1' ? 'tab1' : 'tab2'"
                  @click="clickTab('1')"
                >
                  产废企业
                </div>
                <div
                  :class="tabValue == '1' ? 'tab2' : 'tab1'"
                  @click="clickTab('2')"
                >
                  危废类型
                </div>
              </div>
            </div>
            <div class="liandan_cen">
              <!-- <el-popover placement="top-start" title="标题" width="200" trigger="hover"
                content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                <el-button slot="reference">hover 激活</el-button>
              </el-popover> -->

              <el-table
                :data="tableData"
                style="width: 100%"
                height="470"
                :show-header="false"
              >
                <el-table-column label="排名" width="50">
                  <template slot-scope="scope">
                    <img
                      v-if="scope.row.ranking == 1"
                      src="../../assets/yi.png"
                    />
                    <img
                      v-else-if="scope.row.ranking == 2"
                      src="../../assets/er.png"
                    />
                    <img
                      v-else-if="scope.row.ranking == 3"
                      src="../../assets/san.png"
                    />
                    <div v-else>{{ scope.row.ranking }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="占比">
                  <template slot-scope="scope">
                    <div class="titleS">
                      <span>{{ scope.row.name }}</span
                      ><span
                        >申报量
                        <span class="numS">{{ scope.row.declareNum }}</span
                        >吨</span
                      >
                    </div>
                    <div class="aaa">
                      <el-popover
                        trigger="hover"
                        placement="top"
                        popper-class="declare_per"
                      >
                        <p style="margin: 0px; text-align: center">
                          占比: {{ scope.row.per }} %
                        </p>
                        <div slot="reference">
                          <el-progress
                            :show-text="false"
                            :stroke-width="16"
                            :percentage="Number(scope.row.per)"
                            color="#00BFFF"
                          ></el-progress>
                        </div>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import HcListMain from "@/components/HcListMain/index.vue";
import yi from "@/assets/yi.png";

import * as server from "@/api/homeApi";
import * as server2 from "@/api/loginApi";

export default {
  name: "home",
  components: {
    HcListMain,
  },
  data() {
    return {
      tabValue: "1",
      tableData: [],
      yi: yi,
      timeS: "",
      userObj: JSON.parse(sessionStorage.getItem("userInfo")), //从sessionStorage拿出用户信息,
      agreementObj: {
        total: 0,
        agreementNumber: 0,
        agreementProportion: 0,
      },
      stockObj: {
        sskc: 0,
        rkl: 0,
        ckl: 0,
      },
      sheetObj: {
        sumNum: 0,
        dspNum: 0,
        jxzNum: 0,
        ybjNum: 0,
      },
      params: {
        dataTime: "",
        declareType: "1", //申报类型：1产废企业；2危废类型
      },
      colors: [{ color: "#00BFFF", percentage: 100 }],
      mainHeight: null, //中间高度
    };
  },
  mounted() {
    this.getTimeS();
    this.getStockList();
    this.getAgreementList();
    this.getSheetList();
    this.getSheetChart();
    this.getDeclareList();

    server2.getLoginByToken().then((res) => {
      this.userObj = res.data.userBo;
      window.sessionStorage.setItem(
        "userInfo",
        JSON.stringify(res.data.userBo)
      );
    });
    this.mainHeight = sessionStorage.getItem("listMainHeight"); //获取中间区域的高度
  },
  methods: {
    //获取时间问候语
    getTimeS() {
      this.timeS = this.getTimeState();
    },

    //查询库存数据情况
    getStockList() {
      server.stockList().then((res) => {
        this.stockObj = res.data;
      });
    },

    //查询合同情况
    getAgreementList() {
      server.agreementList().then((res) => {
        this.agreementObj = res.data;
      });
    },

    //查询联单数据情况
    getSheetList() {
      server.sheetList().then((res) => {
        this.sheetObj = res.data;
      });
    },

    //查询联单统计情况
    getSheetChart() {
      server.sheetChart(this.params).then((res) => {
        this.liandanFun(res.data);
      });
    },

    //查询申报量情况
    getDeclareList() {
      server.declareList(this.params).then((res) => {
        this.tableData = res.data;
      });
    },

    //联单统计情况
    liandanFun(data) {
      let _that = this;
      let myChart = this.$echarts.init(document.getElementById("yearChart"));
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "rgb(126,199,255)",
            },
          },
          formatter: function (params) {
            var html = _that.params.dataTime + "年" + params[0].name + "<br>";
            // params[i].marker：对应数据的圆点样式
            for (var i = 0; i < params.length - 1; i++) {
              html +=
                params[i].marker +
                params[i].seriesName +
                "：" +
                params[i].value +
                "单" +
                "<br>";
            }
            //最后一个百分比比数据 添加%
            html +=
              params[i].marker +
              params[i].seriesName +
              "：" +
              params[i].value +
              "%<br>";
            return html;
          },
        },
        color: ["#00BFFF", "#10c188"],
        legend: {
          show: true,
          itemWidth: 30,
          itemHeight: 10,
        },
        grid: {
          top: "20%",
          left: "5%",
          right: "7%",
          bottom: "15%",
        },
        xAxis: [
          {
            type: "category",
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
          },
        ],
        yAxis: [
          {
            name: "单位：单",
            type: "value",
            nameTextStyle: {
              // color: "rgba(112, 151, 184, 1)",
              fontSize: 12,
              // padding: [0, 60, 0, 0],
              lineHeight: 40,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
          {
            type: "value",
            name: "",
            // min: -100,
            // max: 100,
            splitLine: {
              show: false,
            },
            // interval: 25,
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],
        series: [
          {
            name: "联单数量",
            type: "bar",
            barWidth: "20%",
            symbol: "circle",
            smooth: true,
            data: data.seriesList1,
          },
          {
            name: "环比增长率",
            type: "line",
            showSymbol: true,
            symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
            smooth: true,
            yAxisIndex: 1,
            data: data.seriesList2,
          },
        ],
      };
      myChart.setOption(option);

      //自适应大小
      window.addEventListener("resize", () => {
        if (myChart) {
          myChart.resize();
        }
      });
    },

    //切换申报量排行
    clickTab(data) {
      this.tabValue = data;
      this.params.declareType = data;
      this.getDeclareList();
    },

    //联单统计切换年度
    chooseYear() {
      this.getSheetChart();
    },

    //时间状态
    getTimeState() {
      // 获取当前时间
      let timeNow = new Date();

      // 获取当前小时
      let hours = timeNow.getHours();

      // 设置年份
      this.params.dataTime = timeNow.getFullYear() + "";

      // 设置默认文字
      let state = ``;

      // 判断当前时间段
      if (hours >= 0 && hours <= 12) {
        state = `上午好，`;
      } else if (hours > 12 && hours <= 24) {
        state = `下午好，`;
      }
      return state;
    },

    // 跳转到联单列表（指定状态）
    goOrder(data, type) {
      console.log(data, type, "跳转到联单列表（指定状态）");
      let sheetParams = {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        sheetInfoId: "",
        producterEnterpriseName: "",
        transformEnterpriseName: "",
        receiveEnterpriseName: "",
        transformTimeStart: "",
        transformTimeEnd: "",
        receiveTimeStart: "",
        receiveTimeEnd: "",
        categoryCode: "", //废物类别
        harmWasteCode: "", //危废代码
        checkedStatus: [], //选中的联单状态
        enterpriseProvince: "", //省
        enterpriseCity: "", //市
        enterpriseRegion: "", //区
        enterpriseTown: "", //街道镇
      };
      let authorityList = JSON.parse(sessionStorage.getItem("authority"));
      // console.log(JSON.parse(sessionStorage.getItem('authority')), 'authority')
      let authorityListLianDan = 0;
      if (data == "jointOrderList") {
        for (var i = 0; i < authorityList.length; i++) {
          if (authorityList[i] == "12") {
            authorityListLianDan = 12;
          }
        }
        console.log(authorityListLianDan, "首页点联单判断是否跳转");
        if (authorityListLianDan == 12) {
          if (type == "1") {
            sheetParams.checkedStatus = ["2", "3", "4", "5"]; //选中的联单状态（进行中）
          }
          if (type == "2") {
            sheetParams.checkedStatus = ["6"]; //选中的联单状态（已办结）
          }
          if (type == "3") {
            sheetParams.checkedStatus = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
            ]; //选中的联单状态（全部）
          }
          let sheetParamsData = JSON.stringify(sheetParams);
          window.sessionStorage.setItem("welcome", sheetParamsData);
          this.$router.push({
            name: data,
            query: {
              type: "welcome",
            },
          });
        } else {
          this.$message({
            showClose: true,
            message: "暂未开通此页面权限",
            type: "warning",
          });
        }
      }

      if (data == "pendingApprovalList") {
        for (var j = 0; j < authorityList.length; j++) {
          if (authorityList[j] == "13") {
            authorityListLianDan = 13;
          }
        }
        console.log(authorityListLianDan, "首页点联单判断是否跳转");
        if (authorityListLianDan == 13) {
          let sheetParamsData = JSON.stringify(sheetParams);
          window.sessionStorage.setItem("welcome", sheetParamsData);
          this.$router.push({
            name: data,
            query: {
              type: "welcome",
            },
          });
        } else {
          this.$message({
            showClose: true,
            message: "暂未开通此页面权限",
            type: "warning",
          });
        }
      }
      // console.log(authorityList.length, 'authorityList.length',authorityList,'authorityList')
    },
  },
  watch: {
    $route: function (item) {
      this.getSheetChart();
    },
    mainHeight: function (item) {
      //通过监听中间高度动态给table赋值高度
      let temheight = Number(item) + 40;
      console.log(this.mainHeight, temheight, "mainheight2222222222222");
      this.$refs.home.style.height = temheight + "px";
    },
  },
};
</script>

<style scoped lang="less">
.home {
  height: 760px;
  overflow-y: auto;
  overflow-x: hidden;

  .home_top {
    height: 162px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;

    .top_left {
      display: flex;
      align-items: center;
      margin-left: 28px;

      .left_detail {
        margin-left: 24px;
      }

      .left_tit {
        font-size: 24px;
        color: #666;
      }

      .left_cent {
        font-size: 16px;
        color: #bbb;
        display: flex;
        height: 32px;
        line-height: 32px;

        .shouji {
          margin-left: 28px;
        }
      }
    }

    .top_right {
      display: flex;
      align-items: center;
      width: 600px;

      .right_li2 {
        border-left: 1px solid #d8d8d8;
        padding-left: 30px;
      }

      .right_li {
        flex: 1;

        .li_tit {
          font-size: 16px;
          color: #939393;
        }

        .li_num {
          font-size: 32px;
          color: #666666;

          span {
            font-size: 16px;
            color: #bbbbbb;
          }
        }
      }
    }
  }

  .home_cent {
    margin-top: 16px;

    .liandan {
      background-color: #fff;
      border-radius: 4px;
      height: 172px;
      padding: 26px 30px;

      .liandan_tit {
        font-size: 16px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
      }

      .liandan_cen {
        margin-top: 30px;
      }

      .liandan_li {
        display: flex;
        align-items: center;
        cursor: pointer;

        .liandan_li_detail {
          margin-left: 20px;

          .detail_tit {
            font-size: 32px;
            color: #666;
          }

          .detail_cen {
            font-size: 16px;
            color: #939393;
          }
        }
      }
    }

    .liandan2 {
      height: 518px;
    }

    .liandan_chart {
      display: flex;
      align-items: center;
      // width: 320px;
      justify-content: space-around;

      .liandan_chart_num {
        font-size: 32px;
        color: #666;
      }

      .liann_chart_detail {
        display: flex;
        align-items: center;
        line-height: 40px;
      }

      .lian_chart_li {
        width: 10px;
        height: 10px;
        background-color: #00bfff;
        margin-right: 6px;
      }
    }
  }

  .liandan_tab {
    display: flex;
    width: 200px;
    justify-content: space-between;

    .tab1 {
      width: 88px;
      height: 32px;
      border: 1px solid rgba(0, 191, 255, 0.5);
      background-color: rgba(0, 191, 255, 0.1);
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      color: #00bfff;
      cursor: pointer;
    }

    .tab2 {
      width: 88px;
      height: 32px;
      border: 1px solid rgba(220, 223, 230, 1);
      background-color: #fff;
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      color: #939393;
      cursor: pointer;
    }
  }

  .liandanBack {
    background-color: #f0fbff;
    // margin: 24px;
    padding: 24px;
  }

  .el-icon-more {
    cursor: pointer;
  }

  .el-icon-more :hover {
    color: #00bfff;
  }

  @media screen and (max-width: 1366px) {
    .liandan_li {
      padding: 10px;

      img {
        width: 32px;
      }

      .liandan_li_detail {
        .detail_tit {
          font-size: 24px !important;
        }

        .detail_cen {
          font-size: 12px !important;
        }
      }
    }

    .liandan_tit {
      font-size: 14px !important;
    }

    .tab1 {
      width: 60px;
    }

    .lian_chart_li_zhanbi {
      font-size: 12px;
    }
  }

  .titleS {
    display: flex;
    justify-content: space-between;

    .numS {
      color: #00bfff;
    }
  }

  .el-picker-panel__footer .el-picker-panel__link-btn.el-button--text {
    display: none;
  }

  /*   ::v-deep .el-progress{
    justify-content: space-around;
  }  */
}

::v-deep .el-popover.el-popper.declare_per {
  min-width: 50px !important;
}
</style>
