<template>
  <div class="tab1">
    <div class="tabTit title_top">智能危废箱信息</div>

    <el-descriptions class="margin-top" :column="2" border>
      <el-descriptions-item>
        <template slot="label"> 所属产废企业 </template>
        {{ editData.enterpriseName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 台账ID </template>
        {{ editData.deviceNo }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 设备ID </template>
        {{ editData.deviceId }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 设备版本 </template>
        {{ global.DeviceVersionMap.get(editData.deviceVersion) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 设备型号 </template>
        {{ global.DeviceModelMap.get(editData.deviceModel) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 设备名称 </template>
        {{ editData.deviceName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 危废类别 </template>
        {{ editData.categoryCode }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 危废类别名称 </template>
        {{ editData.categoryName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 废物代码 </template>
        {{ editData.harmWasteCode }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 废物名称 </template>
        {{ editData.harmWasteName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 创建时间 </template>
        {{ editData.createTime }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 设备经/纬度 </template>
        <div v-if="editData.deviceLocationLongitude">
          {{ editData.deviceLocationLongitude }} /
          {{ editData.deviceLocationLatitude }}
        </div>
        <div v-else-if="editData.lng">
          {{ editData.lng }} / {{ editData.lat }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 在线状态 </template>
        <span v-if="editData.onlineStates == '1'"
          ><span style="color: #67c23a"
            ><span class="dot_green"></span>在线</span
          ></span
        >
        <span v-if="editData.onlineStates == '0'"
          ><span style="color: #909399"
            ><span class="dot_grey"></span>离线</span
          ></span
        >
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 设备状态 </template>
        <span v-if="editData.deviceStates == '1'"
          ><el-tag type="success" size="medium">正常</el-tag></span
        >
        <span v-if="editData.deviceStates == '2'"
          ><el-tag type="warning" size="medium">报警</el-tag></span
        >
        <span v-if="editData.deviceStates == '3'"
          ><el-tag type="danger" size="medium">故障</el-tag></span
        >
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 绑定状态 </template>
        <span
          v-if="
            editData.enterpriseName == null || editData.enterpriseName == ''
          "
          ><span style="color: #f56c6c">未绑定</span></span
        >
        <span
          v-if="
            editData.enterpriseName != null && editData.enterpriseName != ''
          "
          ><span style="color: #409eff">已绑定</span></span
        >
      </el-descriptions-item>
    </el-descriptions>

    <enterpriseDetail :enterpriseId="editData.enterpriseId"></enterpriseDetail>

    <div class="tabTit">实时监测数据</div>
    <el-table :data="currentDate" border style="width: 100%" height="auto">
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        width="50"
      ></el-table-column>

      <el-table-column prop="weight" label="重量(kg)"></el-table-column>
      <el-table-column label="重量变化值(kg)">
        <template slot-scope="scope">
          <div v-if="scope.row.addWeight > '0'" class="onclass">
            {{ scope.row.addWeight }}
          </div>
          <div v-if="scope.row.addWeight <= '0'" class="offclass">
            {{ scope.row.addWeight }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="electric" label="电量(%)"></el-table-column>
      <el-table-column prop="humidity" label="温度(℃)"></el-table-column>
      <el-table-column prop="temperature" label="湿度(%)"></el-table-column>
      <el-table-column label="信号">
        <template slot-scope="scope">
          <div class="signal_style" v-if="scope.row.signalName == '无信号'">
            {{ scope.row.signalName }}
          </div>
          <div class="signal_style1" v-else>{{ scope.row.signalName }}</div>
        </template>
      </el-table-column>
      <el-table-column label="已倾倒/未倾倒">
        <template slot-scope="scope">
          <div v-if="scope.row.pour == '未倾倒'" class="onclass">
            {{ scope.row.pour }}
          </div>
          <div v-if="scope.row.pour == '已倾倒'" class="offclass">
            {{ scope.row.pour }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="开启/关闭" prop="status"></el-table-column>
      <el-table-column
        prop="lastUpdateTime"
        label="更新时间"
        width="200"
      ></el-table-column>
      <el-table-column prop="dormantTime" label="休眠时间(s)"></el-table-column>
    </el-table>

    <div class="tabTit">历史监测数据</div>

    <el-table :data="HistoryMonitor" border style="width: 100%" height="300">
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        width="50"
      ></el-table-column>

      <el-table-column prop="weight" label="重量(kg)"></el-table-column>
      <el-table-column label="重量变化值(kg)">
        <template slot-scope="scope">
          <div v-if="scope.row.addWeight > '0'" class="onclass">
            {{ scope.row.addWeight }}
          </div>
          <div v-if="scope.row.addWeight <= '0'" class="offclass">
            {{ scope.row.addWeight }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="electric" label="电量(%)"></el-table-column>
      <el-table-column prop="temperature" label="温度(℃)"></el-table-column>
      <el-table-column prop="humidity" label="湿度(%)"></el-table-column>
      <el-table-column label="信号">
        <template slot-scope="scope">
          <div class="signal_style" v-if="scope.row.signalName == '无信号'">
            {{ scope.row.signalName }}
          </div>
          <div class="signal_style1" v-else>{{ scope.row.signalName }}</div>
        </template>
      </el-table-column>
      <el-table-column label="已倾倒/未倾倒">
        <template slot-scope="scope">
          <div v-if="scope.row.pour == '未倾倒'" class="onclass">
            {{ scope.row.pour }}
          </div>
          <div v-if="scope.row.pour == '已倾倒'" class="offclass">
            {{ scope.row.pour }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="开启/关闭" prop="status"></el-table-column>
      <el-table-column
        prop="lastUpdateTime"
        label="更新时间"
        width="200"
      ></el-table-column>
      <el-table-column prop="dormantTime" label="休眠时间(s)"></el-table-column>
    </el-table>
    <HcPagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="params.pageNum"
      :page-size="params.pageSize"
      :total="params.total"
      class="pagination"
    >
    </HcPagination>

    <div class="tabTit">报警数据</div>
    <el-table :data="DeviceAlarm" border style="width: 100%" height="300">
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        width="50"
      ></el-table-column>
      <el-table-column prop="warnType" label="报警类型"></el-table-column>
      <el-table-column prop="threshold" label="报警阈值"></el-table-column>
      <el-table-column prop="warnValue" label="报警数值"></el-table-column>
      <el-table-column prop="warnDate" label="报警时间"></el-table-column>
    </el-table>
    <HcPagination
      @size-change="handleSizeChange2"
      @current-change="handleCurrentChange2"
      :current-page="params2.pageNum"
      :page-size="params2.pageSize"
      :total="params2.total"
      class="pagination"
    >
    </HcPagination>

    <div class="tabTit">故障数据</div>
    <el-table :data="DeviceTrouble" border style="width: 100%" height="300">
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        width="50"
      ></el-table-column>
      <el-table-column prop="statusCode" label="故障代码"></el-table-column>
      <el-table-column prop="depict" label="故障描述"></el-table-column>
      <el-table-column prop="suggest" label="建议收处方式"></el-table-column>
      <el-table-column prop="onlineTime" label="故障时间"></el-table-column>
    </el-table>
    <HcPagination
      @size-change="handleSizeChange3"
      @current-change="handleCurrentChange3"
      :current-page="params3.pageNum"
      :page-size="params3.pageSize"
      :total="params3.total"
      class="pagination"
    >
    </HcPagination>
  </div>
</template>
<script>
import enterpriseDetail from "@/components/HcEnteMation/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";

export default {
  name: "tab1",
  components: {
    enterpriseDetail,
    HcPagination,
  },
  data() {
    return {
      mainHeight: null,
      tab1Height: null,
      dialogVisible: false,
      tableData: [],
      params: {
        deviceId: "",
        pageSize: 20,
        pageNum: 1,
        total: 0,
      },
      params2: {
        deviceId: "",
        pageSize: 20,
        pageNum: 1,
        total: 0,
      },
      params3: {
        deviceId: "",
        pageSize: 20,
        pageNum: 1,
        total: 0,
      },
    };
  },
  props: {
    editData: {
      type: Object,
      default: null,
    },
    currentDate: {
      type: Array,
      default: null,
    },
    HistoryMonitor: {
      type: Array,
      default: null,
    },
    DeviceAlarm: {
      type: Array,
      default: null,
    },
    DeviceTrouble: {
      type: Array,
      default: null,
    },
  },
  methods: {
    indexMethod(index) {
      return index + 1;
    },

    // 历史监测数据
    handleSizeChange(data) {
      this.params.pageSize = data; //每页多少条
      this.params.deviceId = this.editData.deviceId;
      this.$parent.$parent.$parent.queryHistoryMonitor(this.params);
    },
    handleCurrentChange(data) {
      this.params.pageNum = data; //当前多少页
      this.params.deviceId = this.editData.deviceId;
      console.log(data, this.params.deviceId, "历史监测数据---分页");
      this.$parent.$parent.$parent.queryHistoryMonitor(this.params);
    },
    // 报警监测数据
    handleCurrentChange2(data) {
      this.params2.pageNum = data; //当前多少页
      this.params2.deviceId = this.editData.deviceId;
      this.$parent.$parent.$parent.queryDeviceAlarm(this.params2);
    },
    handleSizeChange2(data) {
      this.params2.pageSize = data; //每页多少条
      this.params2.deviceId = this.editData.deviceId;
      this.$parent.$parent.$parent.queryDeviceAlarm(this.params2);
    },
    // 故障数据
    handleCurrentChange3(data) {
      this.params3.pageNum = data; //当前多少页
      this.params3.deviceId = this.editData.deviceId;
      this.$parent.$parent.$parent.queryDeviceTrouble(this.params3);
    },
    handleSizeChange3(data) {
      this.params3.pageSize = data; //每页多少条
      this.params3.deviceId = this.editData.deviceId;
      this.$parent.$parent.$parent.queryDeviceTrouble(this.params3);
    },
  },
};
</script>

<style lang="less" scoped>
.tab1 {
  overflow: scroll;

  .tabTit {
    padding: 16px 0px;
  }

  /deep/.el-button--primary {
    background: #00bfff;
  }
}

.title_top {
  margin-top: -20px;
}

.dot_green {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #67c23a;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
}

.dot_grey {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #909399;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.signal_style {
  padding: 2px 0px;
  background-color: #f4f4f5;
  border-radius: 4px;
  width: 56px;
  text-align: center;
  border: 1px solid #e9e9eb;
  color: #909399;
}

.signal_style1 {
  padding: 2px 0px;
  background-color: #ecf5ff;
  border-radius: 4px;
  width: 32px;
  text-align: center;
  border: 1px solid #d9ecff;
  color: #409eff;
}

.onclass {
  color: #409eff;
}

.offclass {
  color: #f56c6c;
}

::v-deep .el-descriptions-row th {
  width: 10vw;
}
</style>
