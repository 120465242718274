<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
    </template>
    <template v-slot:center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
        <div class="card_tit">出库基本信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="危废类别">
              <el-input v-model="ruleForm.categoryCode" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="废物代码">
              <el-input v-model="ruleForm.harmWasteCode" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="废物名称">
              <el-input v-model="ruleForm.harmWasteName" disabled> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出库量(吨)">
              <el-input v-model="ruleForm.actualTimeStock" disabled>

              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="出库时间" prop="outboundTime">
              <el-date-picker v-model="ruleForm.outboundTime" type="datetime" placeholder="请选择出库时间"
                format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="处置企业名称" prop="disposalEnterpriseId">
              <el-select v-model="ruleForm.disposalEnterpriseId" clearable filterable placeholder="请选择处置企业名称"
                @change="getenterpriseType(ruleForm.disposalEnterpriseId)">
                <el-option v-for="item in enterpriseName" :key="item.enterpriseId" :label="item.enterpriseName"
                  :value="item.enterpriseId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="处置类型">
              <el-select v-model="ruleForm.disposalType" disabled placeholder="请选择处置类型">
                <el-option label="焚烧" value="1"></el-option>
                <el-option label="填埋" value="2"></el-option>
                <el-option label="利用" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="card_tit">出库明细</div>
      <el-table :data="tableData" border style="width: 100%" :height="tableHeight">
        <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
        <el-table-column prop="stockInfoId" label="入库编号" width="200"></el-table-column>
        <el-table-column label="联单编号" width="200">
          <template slot-scope="scope">
            <div @click="goSheet(scope.row)" class="deviceIdStyle">{{ scope.row.sheetInfoId }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="producterEnterpriseName" label="产废企业名称"></el-table-column>
        <el-table-column prop="categoryCode" label="危废类别" width="150"></el-table-column>
        <el-table-column prop="harmWasteCode" label="废物代码" width="150"></el-table-column>
        <el-table-column prop="harmWasteName" label="废物名称" width="150"></el-table-column>
        <el-table-column prop="receiveWeight" label="入库量(吨)" width="100"></el-table-column>
        <el-table-column prop="storeTime" label="入库时间" width="180"></el-table-column>
      </el-table>
      <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.pageNum"
        :page-size="params.pageSize" :total="params.total" class="pagination">
      </HcPagination>
    </template>
    <template v-slot:footer>

      <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
    </template>
  </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from '@/api/stockApi';

export default {
  name: "editDiagle",
  props: {
    editData: {
      type: Object,
      default: null
    }
  },

  components: {
    HcDiaglePage,
    HcButton,
    HcPagination
  },

  data() {
    return {
      mainHeight: null,//中间高度
      tableHeight: null,//表格高度
      tableData: [],
      enterpriseName: [],
      ruleForm: {
        pageSize: 20,
        pageNum: 1,
        categoryCode: "",//危废类别
        harmWasteCode: "",//废物代码
        harmWasteName: "",//废物名称
        actualTimeStock: "",//出库量
        outboundTime: "",//出库时间
        disposalEnterpriseId: "",//处置企业名称
        disposalType: '',//处置类型
        sheetInfoId: "",//联单编号
        outboundInfoId: "",//出库编号
        stockInfoId: "",//入库编号
      },
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        categoryCode: '',
        harmWasteCode: '',
      },
      rules: {
        outboundTime: [
          { required: true, message: "请选择出库时间", trigger: "change" },
        ],
        disposalEnterpriseId: [
          { required: true, message: "请选择处置企业名称", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1
    },
    handleSizeChange(data) {
      this.params.pageSize = data//每页多少条
      this.getList(this.params)//重新请求获取table的接口
    },
    handleCurrentChange(data) {
      this.params.pageNum = data//当前多少页
      this.getList(this.params)//重新获取table的接口
    },
    showDiagle(data) {
      this.$refs.hcDiaglePage.showLog = true;
      // 判断弹框是编辑页面还是新增页面或者是详情页面
      this.dialogType = data
      if (this.dialogType == 'edit') {
        console.log(this.editData, '出库')
        this.ruleForm.categoryCode = this.editData.categoryCode;//危废类别
        this.ruleForm.harmWasteCode = this.editData.harmWasteCode;//废物代码
        this.ruleForm.harmWasteName = this.editData.harmWasteName;//废物名称
        this.ruleForm.actualTimeStock = this.editData.actualTimeStock;//出库量
        this.ruleForm.stockInfoId = this.editData.stockInfoId;//入库编号
        this.ruleForm.sheetInfoId = this.editData.sheetInfoId;//联单编号
        this.ruleForm.producterEnterpriseName = this.editData.producterEnterpriseName;//产废企业名称
        this.params.categoryCode = this.editData.categoryCode;//根据类别查表格数据
        this.params.harmWasteCode = this.editData.harmWasteCode
      }
      this.getList(this.params)
      this.disposalEnterpriseNamelist()
    },
    //出库页面表格数据
    getList(params) {
      server.stockList(params).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.list
          // 设置分页的数据
          this.params.totalPage = res.data.totalPage;
          this.params.total = res.data.total;
          this.params.pageNum = this.params.pageNum;
          this.params.pageSize = this.params.pageSize;
        }
        this.params.categoryCode = this.tableData[0].categoryCode
        console.log(this.tableData.length, '查出库页表格数据')
      })
        .catch((err) => {
          setTimeout(() => {//失败2秒后关闭加载中
            this.loading = false
          }, 2000)
          this.$message({
            showClose: true,
            message: err.message,
            type: 'warning'
          });
        })
    },
    // 处置企业名称-下拉框
    disposalEnterpriseNamelist() {
      server.disposalEnterpriseNamelist().then(res => {
        if (res.code == 200) {
          this.enterpriseName = res.data
        }
      })
        .catch((err) => {
          setTimeout(() => {//失败2秒后关闭加载中
            this.loading = false
          }, 2000)
          this.$message({
            showClose: true,
            message: err.message,
            type: 'warning'
          });
        })
    },
    //获取处置类型
    getenterpriseType(data) {
      console.log(data, '获取下拉框返回数据')
      let tempType = this.enterpriseName.find(
        item => item.enterpriseId === data
      )
      this.ruleForm.disposalType = tempType.disposalType
      console.log(this.ruleForm.disposalType, '类型')
    },
    // 出库
    outbound(params) {
      server.outboundById(params).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '出库成功',
            type: 'success'
          });
          this.$refs.hcDiaglePage.enter();//关闭弹框  
          this.$parent.queryList()//调用列表页面，刷新列表
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: 'warning'
          });
        });
    },
    // 提交
    submitForm(formName) {
      for (let i = 0; i < this.tableData.length; i++) {
        this.ruleForm.sheetInfoId = this.tableData[i].sheetInfoId;//联单编号
        this.ruleForm.stockInfoId = this.tableData[i].stockInfoId;//入库编号
        this.ruleForm.outboundInfoId = this.editData.outboundInfoId;//出库编号
        this.ruleForm.categoryCode = this.editData.categoryCode;
        this.ruleForm.harmWasteCode = this.editData.harmWasteCode;
        console.log(this.ruleForm.stockInfoId, "入库编号");
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.outbound(this.ruleForm); //调用新增接口
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
      this.ruleForm = {
        pageSize: 20,
        pageNum: 1,
        categoryCode: "",//危废类别
        harmWasteCode: "",//废物代码
        harmWasteName: "",//废物名称
        actualTimeStock: "",//出库量
        outboundTime: "",//出库时间
        disposalEnterpriseId: "",//处置企业名称
        disposalType: '',//处置类型
        sheetInfoId: "",//联单编号
        outboundInfoId: "",//出库编号
        stockInfoId: "",//入库编号
      }

    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$refs.hcDiaglePage.close();
      // this.ruleForm.outboundTime = "";//出库时间
      // this.ruleForm.disposalEnterpriseId = "";//处置企业名称
      // this.ruleForm.disposalType = '';
      this.ruleForm = {
        pageSize: 20,
        pageNum: 1,
        categoryCode: "",//危废类别
        harmWasteCode: "",//废物代码
        harmWasteName: "",//废物名称
        actualTimeStock: "",//出库量
        outboundTime: "",//出库时间
        disposalEnterpriseId: "",//处置企业名称
        disposalType: '',//处置类型
        sheetInfoId: "",//联单编号
        outboundInfoId: "",//出库编号
        stockInfoId: "",//入库编号
      }
    },
    // 点击联单ID获取当前行数据
    goSheet(row) {
      let data = JSON.stringify(row)
      sessionStorage.setItem('inventoryDetail', data)
      this.$router.push({
        name: 'jointOrderList',//跳转到联单详情
        query: {
          type: 'inventoryDetail'
        }
      })
    },
    // 获取
    getTimes() {
      this.getTimesInterval()
    },
    getTimesInterval: function () {
      let _this = this;
      let year = new Date().getFullYear(); //获取当前时间的年份
      let month = new Date().getMonth() + 1; //获取当前时间的月份
      let day = new Date().getDate(); //获取当前时间的天数
      let hours = new Date().getHours(); //获取当前时间的小时
      let minutes = new Date().getMinutes(); //获取当前时间的分数
      let seconds = new Date().getSeconds(); //获取当前时间的秒数
      //当小于 10 的是时候，在前面加 0
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      //拼接格式化当前时间
      _this.ruleForm.outboundTime = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }

  },
  created() {
    this.getTimes()
  },
  mounted() {
    this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
  },
  beforeDestroy() {
    if (this.ruleForm.outboundTime) {
      clearInterval(this.getTimesInterval);
    }
  },
  watch: {
    'tableData': function (item) {
      this.getTimes()
    },
    'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 470
    }
  }
};
</script>

<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}

// 联单
.deviceIdStyle {
  color: #409EFF;
  cursor: pointer;
}

::v-deep .el-input--small .el-input__inner {
  width: 300px;
}

::v-deep .el-input--small {
  width: 300px;
}

::v-deep .el-textarea__inner {
  width: 800px;
}

.addressClass {
  display: flex;

  .addressDiv {
    cursor: pointer;
  }

  .addressIcon {
    font-size: 24px;
    color: #E6A23C;
    margin-left: 6px;
  }

  span {
    font-size: 14px;
    color: #E6A23C;
  }

}

.demo-ruleForm {
  margin-top: 20px;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 300px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>
