<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <div class="center_detail">
                <el-tabs v-model="activeName2" type="card">
                    <tab1 :editData="detailObj" :currentDate="currentDate" :HistoryMonitor="HistoryMonitor"
                        :DeviceAlarm="DeviceAlarm" :DeviceTrouble="DeviceTrouble" ref="tab1"></tab1>
                </el-tabs>
            </div>
        </template>
    </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import tab1 from "../components/tab1.vue";
import * as server from '@/api/deviceApi';

export default {
    name: "detailDiagle",
    components: {
        HcDiaglePage,
        HcButton,
        tab1,
    },
    watch: {
        dialogType(item) {
            //监听弹框类型变化
            //console.log(item, "hello");
            if (item == "edit") {
                //表示是编辑页面
                //console.log(this.editData, "hello1");
                /* this.ruleForm.enterpriseName = this.editData.enterpriseName;
                this.ruleForm.enterpriseType = this.editData.enterpriseType;
                this.ruleForm.industry = this.editData.industry;
                this.ruleForm.wasteScale = this.editData.wasteScale;
                this.ruleForm.enterpriseProvince = this.editData.enterpriseProvince;
                this.ruleForm.enterpriseCity = this.editData.enterpriseCity;
                this.ruleForm.enterpriseRegion = this.editData.enterpriseRegion;
                this.ruleForm.enterpriseAddress = this.editData.enterpriseAddress;
                this.ruleForm.longilatde = `${this.editData.longitude},${this.editData.latitude}`;
                this.ruleForm.longitude = this.editData.longitude;
                this.ruleForm.latitude = this.editData.latitude;
                this.ruleForm.creditCode = this.editData.creditCode;
                this.ruleForm.legalRepresent = this.editData.legalRepresent;
                this.ruleForm.phone = this.editData.phone;
                this.ruleForm.accountBank = this.editData.accountBank;
                this.ruleForm.account = this.editData.account;
                this.ruleForm.contactor = this.editData.contactor;
                this.ruleForm.telephone = this.editData.telephone;
                this.ruleForm.email = this.editData.email;
                this.ruleForm.remark = this.editData.remark;
                this.ruleForm.isEia = this.editData.isEia; */
            }
        },
    },
    data() {
        return {
            dialogType: "",
            activeName2: "tab1",
            detailObj: {
                enterpriseName: '',
                deviceVersion: '',
                onlineStates: '',
                deviceNo: '',
                categoryCode: '',
                harmWasteName: '',
                createTime: '',
                enterpriseId: '',
                deviceModel: '',//设备型号
                deviceStates: '',//设备状态
                deviceId: '',//设备ID
                bindStates: '',//绑定状态
                onlineStates: '',//在线状态
                deviceType: '1',//设备类型：1智能危废箱，2贮存处
            },
            currentDate: [],//实时监测数据
            HistoryMonitor: [],//历史监测数据
            DeviceTrouble: [],//设备故障检测
        };
    },
    methods: {
        showDiagle(data) {
            this.$refs.hcDiaglePage.showLog = true;
            // 判断弹框是编辑页面还是新增页面或者是详情页面
            this.dialogType = data;

            /* this.detailObj.enterpriseName = this.editData.enterpriseName;
            this.detailObj.enterpriseType = this.editData.enterpriseType;
            this.detailObj.industry = this.editData.industry;
            this.detailObj.wasteScale = this.editData.wasteScale;
            this.detailObj.enterpriseAddress = this.editData.enterpriseAddress;
            this.detailObj.longilatde = `${this.editData.longitude},${this.editData.latitude}`;
            this.detailObj.longitude = this.editData.longitude;
            this.detailObj.latitude = this.editData.latitude;
            this.detailObj.creditCode = this.editData.creditCode;
            this.detailObj.legalRepresent = this.editData.legalRepresent;
            this.detailObj.phone = this.editData.phone;
            this.detailObj.accountBank = this.editData.accountBank;
            this.detailObj.account = this.editData.account;
            this.detailObj.contactor = this.editData.contactor;
            this.detailObj.telephone = this.editData.telephone;
            this.detailObj.email = this.editData.email;
            this.detailObj.remark = this.editData.remark;
            this.detailObj.isEia = this.editData.isEia; */

        },
        // 大屏进来查询设备详细信息
        queryDeviceDetail(params) {
            server.deviceDetail(params).then((res) => {
                if (res.code == 200) {
                    this.detailObj = res.data;
                }
            })
        },
        // 实时监测数据
        queryCurrentDate(params) {
            this.currentDate = []
            server.queryCurrentDate(params).then((res) => {
                if (res.code == 200) {
                    //console.log(res.data, '实时监测数据')
                    this.currentDate.push(res.data)
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        // 历史监测数据
        queryHistoryMonitor(params) {
            server.queryHistoryMonitor(params).then((res) => {
                if (res.code == 200) {
                    this.HistoryMonitor = res.data.list
                    //console.log(this.HistoryMonitor, '历史监测数据')
                    this.$refs.tab1.params.pageSize = res.data.pageSize
                    this.$refs.tab1.params.pageNum = res.data.pageNum
                    this.$refs.tab1.params.total = res.data.total
                }
            }).catch(err => {
                console.log(err)
            })
        },
        // 报警数据检测
        queryDeviceAlarm(params) {
            server.queryDeviceAlarm(params).then((res) => {
                if (res.code == 200) {
                    this.DeviceAlarm = res.data.list
                    console.log(this.DeviceAlarm, '报警数据检测')
                    this.$refs.tab1.params2.pageSize = res.data.pageSize
                    this.$refs.tab1.params2.pageNum = res.data.pageNum
                    this.$refs.tab1.params2.total = res.data.total
                }
            }).catch(err => {
                console.log(err)
            })
        },
        // 设备故障检测
        queryDeviceTrouble(params) {
            this.DeviceTrouble = []
            server.queryDeviceTrouble(params).then((res) => {
                if (res.code == 200) {
                    // this.DeviceTrouble = res.data
                    this.DeviceTrouble = res.data.list
                    console.log(this.DeviceTrouble, '故障检测')
                    this.$refs.tab1.params3.pageSize = res.data.pageSize
                    this.$refs.tab1.params3.pageNum = res.data.pageNum
                    this.$refs.tab1.params3.total = res.data.total
                }
            }).catch(err => {
                console.log(err)
            })
        },

        resetForm(formName) {
            this.detailObj = {
                enterpriseName: '',
                deviceVersion: '',
                onlineStates: '',
                deviceNo: '',
                categoryCode: '',
                harmWasteName: '',
                createTime: '',
                enterpriseId: '',
                deviceModel: '',//设备型号
                deviceStates: '',//设备状态
                deviceId: '',//设备ID
                bindStates: '',//绑定状态
                onlineStates: '',//在线状态
                deviceType: '1',//设备类型：1智能危废箱，2贮存处
            };
            this.currentDate = [];//实时监测数据
            this.HistoryMonitor = [];//历史监测数据
            this.DeviceTrouble = [];//设备故障检测

            if (this.$route.query.type == 'alarmDetail') {
                this.$router.push({
                    name: 'wasteProduction',//新增跳转到合同列表页面
                })
            }
            else if (this.$route.query.type == 'tabPage') {
                this.$router.push({
                    name: 'alarmList',//新增跳转到合同列表页面
                })
            } else {
                this.$refs.hcDiaglePage.close();
            }
        },
    },
};
</script>

<style scoped lang="less">
::v-deep .el-tabs--card>.el-tabs__header {
    border-bottom: 0px
}

.center_detail {
    height: 100%;
    background: white;
}
</style>
