<template>
    <div class="HcSelect">
       <slot></slot>
      <el-select
          v-bind="$attrs" v-on="$listeners"
        >
      </el-select>
    </div>
  </template>
  <script>
  export default {
    name: "index",
    methods:{
    
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  