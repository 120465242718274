<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <!-- 联单操作：审批、五必查、入库 -->
            <steps :activeNo="activeNo" :editData="editData"></steps>
            <processTable :tableData="tableData"></processTable>
            <sheetDetail :editData="editData"></sheetDetail>
            <enterpriseInfoYS v-if="editData.status != 1" :enterpriseId="editData.transformEnterpriseId"
                :editData="editData"></enterpriseInfoYS>

            <div v-if="editData.status == 1">
                <div class="tabTit">审批情况</div>
                <el-radio v-model="approvalVal" label="1">同意</el-radio>
                <el-radio v-model="approvalVal" label="0">不同意</el-radio>

                <div v-if="approvalVal == '1'" class="tabTit">填写派车信息</div>
                <el-form v-if="approvalVal == '1'" :model="params" :rules="rulesY" ref="ruleForm" label-width="120px"
                    class="demo-ruleForm" size="small">
                    <el-row>
                        <el-col :span="8" style="display: flex;">
                            <el-form-item label="运输企业名称" prop="transformEnterpriseId">
                                <el-select v-model="params.transformEnterpriseId" clearable filterable @change="chooseId"
                                    v-loading="loading" @clear="setValueNull" placeholder="请选择运输企业名称">
                                    <el-option v-for="item in enterpriseOptionArr" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" style="display: flex;">
                            <el-form-item label="联系人">
                                <el-input v-model="contactor" :disabled="true" placeholder="请输入联系人"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" style="display: flex;">
                            <el-form-item label="联系电话">
                                <el-input v-model="telephone" :disabled="true" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="运输时间" prop="transformTime">
                                <el-date-picker v-model="params.transformTime" type="datetime" placeholder="请选择运输时间"
                                    format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" style="display: flex;">
                            <el-form-item label="运输车辆" prop="transportation">
                                <el-select clearable filterable v-model="params.transportation" placeholder="请选择运输车辆">
                                    <el-option v-for="item in carOptionArr" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" style="display: flex;">
                            <el-form-item label="运输司机" prop="transformDriver">
                                <el-select clearable filterable v-model="params.transformDriver" placeholder="请选择运输司机">
                                    <el-option v-for="item in driverOptionArr" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>

                <div v-if="approvalVal == '0'" class="tabTit">驳回原因</div>
                <el-form v-if="approvalVal == '0'" :model="params" :rules="rulesN" ref="ruleForm" label-width="120px"
                    class="demo-ruleForm" size="small">
                    <el-form-item label="驳回原因" prop="remark">
                        <el-input type="textarea" :rows="2" v-model="params.remark" placeholder="请输入驳回原因"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div v-if="editData.status == 4">
                <div class="tabTit">填写五必查信息</div>
                <el-form ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
                    <el-table :data="fiveCheckTableData" border style="width: 100%">
                        <el-table-column prop="no" label="序号" width="50"></el-table-column>
                        <el-table-column prop="name" label="检查项目"></el-table-column>
                        <el-table-column label="检查情况">
                            <template slot-scope="scope">
                                <el-radio label="1" v-model="scope.row.result">是</el-radio>
                                <el-radio label="0" v-model="scope.row.result">否</el-radio>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form>
            </div>

            <div v-if="editData.status == 5">
                <fiveCheckTable :editData="editData"></fiveCheckTable>
                <div class="tabTit">填写收集企业信息</div>
                <el-row>
                    <el-col style="display: flex;">
                        <el-form :model="params3" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm"
                            size="small">
                            <el-form-item label="接收量(kg)" prop="receiveWeight">
                                <!-- <el-input v-model="params3.receiveWeight" width="200px" placeholder="请输入接收量"></el-input> -->
                                <el-input-number v-model="params3.receiveWeight" :controls="false" :min="0" :precision="2"
                                    clearable placeholder="请输入接收量"></el-input-number>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
            </div>
        </template>
        <template v-slot:footer>
            <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
            <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
        </template>
    </HcDiaglePage>
</template>
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import steps from '../components/steps.vue';
import processTable from '../components/processTable.vue';
import sheetDetail from '../components/sheetDetail.vue';
import enterpriseInfoYS from '../components/enterpriseInfoYS.vue';
import fiveCheckTable from '../components/fiveCheckTable.vue';

import * as server from '@/api/sheetApi';
import * as server2 from '@/api/planApi';

export default {
    name: "editDiagle",
    components: {
        HcDiaglePage, HcButton, steps, processTable, sheetDetail, enterpriseInfoYS, fiveCheckTable,
    },
    data() {
        return {
            loading: true,
            tableData: [],
            activeNo: 0,
            approvalVal: '1',//审批结果
            params: {
                sheetInfoNo: '',
                sheetInfoId: '',
                processUser: '',//审核用户
                processStatus: '',//审核结果
                producterEnterpriseId: '',//产废企业
                transformEnterpriseId: '',//运输企业
                transformDriver: '',//运输司机
                transportation: '',//运输车辆
                transformTime: '',//运输时间
                remark: '',//驳回原因
                receiveEnterpriseId: '',//收集企业账号
            },
            params2: {
                sheetInfoNo: '',
                checkUser: '',//五必查用户
                result1: '',//结果1
                result2: '',//结果2
                result3: '',//结果3
                result4: '',//结果4
                result5: '',//结果5
            },
            params3: {
                sheetInfoNo: '',
                storeUser: '',//入库用户
                receiveWeight: '',//接收量
            },
            rulesY: {
                transformEnterpriseId: [
                    { required: true, message: "请选择运输企业名称", trigger: "change" },
                ],
                transportation: [
                    { required: true, message: "请选择运输车辆", trigger: "change" },
                ],
                transformDriver: [
                    { required: true, message: "请选择运输司机", trigger: "change" },
                ],
                transformTime: [
                    { required: true, message: "请选择运输时间", trigger: "change" },
                ],
            },
            rulesN: {
                remark: [
                    { required: true, message: "请输入驳回原因", trigger: "blur" },
                ],
            },
            rules: {
                receiveWeight: [
                    { required: true, message: "请输入接收量", trigger: "blur" },
                ],
            },
            dialogType: '',
            enterpriseOptionArr: [],//运输企业
            carOptionArr: [],//车辆企业
            driverOptionArr: [],//司机企业
            contactor: '',//联系人
            telephone: '',//联系电话
            fiveCheckTableData: [
                { "no": "1", "name": "车辆是否具有有效行驶证、营运证", "result": "1" },
                { "no": "2", "name": "驾驶人、押运人是否具有有效资质证件", "result": "1" },
                { "no": "3", "name": "运输车辆、罐式运输罐体、可移动储柜、罐箱是否在检验合格有效期内", "result": "1" },
                { "no": "4", "name": "所充装或者转载的危险货物是否与危险货物运单标明的事项一致", "result": "1" },
                { "no": "5", "name": "所充装的危险货物是否在罐式车辆罐体的适装介质列表范围内，或者满足可移动罐柜导则、罐箱适用代码的要求", "result": "1" },
            ]
        }
    },
    props: {
        editData: {
            type: Object,
            default: null
        },
    },
    methods: {
        showDiagle(data) {
            this.$refs.hcDiaglePage.showLog = true;

            this.init();

            // 判断弹框是编辑页面还是新增页面或者是详情页面
            this.dialogType = data;
            if (this.dialogType == 'edit') {
                //审批
                if (this.editData.status == '1') {
                    this.getEnterpriseOption();
                }
                //五必查确认
                if (this.editData.status == '4') {

                }
                //入库确认
                if (this.editData.status == '5') {

                }
            }
        },

        // 获取运输企业下拉框数据
        async getEnterpriseOption() {
            let param = { enterpriseType: '2' };
            await server2.allEnterpriseList(param).then((res) => {
                this.enterpriseOptionArr = res.data;
                this.loading = false;
            })
        },

        //运输企业选择后更新车辆司机择集
        chooseId(id) {
            if (id == '') {

            } else {
                this.getEnterpriseDeatil(id);
                this.getDriverAption(id);
                this.getCarAption(id);
            }
        },

        // 获取司机下拉框数据
        getDriverAption(id) {
            let param = { enterpriseId: id };
            server2.allDriverList(param).then((res) => {
                this.driverOptionArr = res.data
            })
        },

        // 获取车辆下拉框数据
        getCarAption(id) {
            let param = { enterpriseId: id };
            server2.allCarList(param).then((res) => {
                this.carOptionArr = res.data
            })
        },

        //运输企业的信息
        getEnterpriseDeatil(id) {
            server2.enterpriseDetail({
                enterpriseId: id,
            }).then((res) => {
                this.contactor = res.data.contactor;
                this.telephone = res.data.telephone;
            })
        },

        //清空运输企业时触发
        setValueNull() {
            this.contactor = '';
            this.telephone = '';
            this.driverOptionArr = [];
            this.carOptionArr = [];
            this.params.transportation = '';
            this.params.transformDriver = '';
        },

        //初始化各组件数据
        init() {
            this.tableData = [];
            let status = parseInt(this.editData.status);

            let tr1 = {"processNo":"联单申请","user":this.editData.createUser,"updateTime":this.editData.createTime,"enterpriseType":"产废企业","enterpriseName":this.editData.producterEnterpriseName};
            let tr2 = {"processNo":"运输确认","user":this.editData.processUser,"updateTime":this.editData.processTime,"enterpriseType":"收集企业","enterpriseName":this.editData.receiveEnterpriseName};
            let tr3 = {"processNo":"危废上车","user":this.editData.confirmUser,"updateTime":this.editData.confirmTime,"enterpriseType":"产废企业","enterpriseName":this.editData.producterEnterpriseName};
            let tr4 = {"processNo":"联单运输","user":this.editData.transportUser,"updateTime":this.editData.transportTime,"enterpriseType":"运输企业","enterpriseName":this.editData.transformEnterpriseName};
            let tr5 = {"processNo":"五必查确认","user":this.editData.checkUser,"updateTime":this.editData.checkTime,"enterpriseType":"收集企业","enterpriseName":this.editData.receiveEnterpriseName};
            let tr6 = {"processNo":"入库确认","user":this.editData.storeUser,"updateTime":this.editData.storeTime,"enterpriseType":"收集企业","enterpriseName":this.editData.receiveEnterpriseName};
            let tr7 = {"processNo":"联单办结","user":this.editData.storeUser,"updateTime":this.editData.storeTime,"enterpriseType":"收集企业","enterpriseName":this.editData.receiveEnterpriseName};
            
            if(status == 1 || status == 7 || status == 8){
                this.tableData.push(tr1);

                this.editData.processTime = '';
                this.editData.confirmTime = '';
                this.editData.checkTime = '';
                this.editData.storeTime = '';
            }else{
                this.activeNo = status - 1;
            }

            //入库确认后，全是蓝色
            if(status == 6){
                this.activeNo = 7;
            }

            if(status == 2){
                this.tableData.push(tr1);
                this.tableData.push(tr2);

                this.editData.confirmTime = '';
                this.editData.checkTime = '';
                this.editData.storeTime = '';
            }
            if(status == 3){
                this.tableData.push(tr1);
                this.tableData.push(tr2);
                this.tableData.push(tr3);

                this.editData.checkTime = '';
                this.editData.storeTime = '';
            }
            if(status == 4){
                this.tableData.push(tr1);
                this.tableData.push(tr2);
                this.tableData.push(tr3);
                this.tableData.push(tr4);

                this.editData.checkTime = '';
                this.editData.storeTime = '';
            }
            if(status == 5){
                this.tableData.push(tr1);
                this.tableData.push(tr2);
                this.tableData.push(tr3);
                this.tableData.push(tr4);
                this.tableData.push(tr5);

                this.editData.storeTime = '';
            }
            if(status == 6){
                this.tableData.push(tr1);
                this.tableData.push(tr2);
                this.tableData.push(tr3);
                this.tableData.push(tr4);
                this.tableData.push(tr5);
                this.tableData.push(tr6);
                this.tableData.push(tr7);
            }
            if(status == 8){
                this.params.remark = this.editData.remark;
            }
        },

        //审批
        approveWait(params) {
            let roleStr = JSON.parse(sessionStorage.getItem('userInfo')).enterpriseType;
            if (roleStr != "3") {
                this.$message({
                    message: '审批失败，请登录收集企业账号进行审批！',
                    type: 'warning'
                });
                return;
            }

            this.params.processStatus = this.approvalVal;
            this.params.sheetInfoNo = this.editData.sheetInfoNo;
            this.params.sheetInfoId = this.editData.sheetInfoId;
            let userId = JSON.parse(sessionStorage.getItem('userInfo')).userId;
            this.params.processUser = userId;

            let enterpriseId = JSON.parse(sessionStorage.getItem('userInfo')).enterpriseId;
            this.params.receiveEnterpriseId = enterpriseId;

            //加上联单的产废企业，用于发消息
            this.params.producterEnterpriseId = this.editData.producterEnterpriseId;

            server.approveWait(params).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '审批完成',
                        type: 'success'
                    });
                    this.resetForm('ruleForm');
                    this.$parent.queryList()//调用列表页面，刷新列表
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: 'warning'
                    });
                });
        },

        //五必查
        approveCheck() {
            for (let i in this.fiveCheckTableData) {
                this.params2['result' + (Number(i) + 1)] = this.fiveCheckTableData[i].result;
            }
            this.params2.sheetInfoNo = this.editData.sheetInfoNo;

            let userId = JSON.parse(sessionStorage.getItem('userInfo')).userId;
            this.params2.checkUser = userId;
            server.approveCheck(this.params2).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '五必查确认通过',
                        type: 'success'
                    });
                    this.resetForm('ruleForm');
                    this.$parent.queryList()//调用列表页面，刷新列表
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: 'warning'
                    });
                });
        },

        //入库
        approveInStorage() {
            this.params3.sheetInfoNo = this.editData.sheetInfoNo;
            let userId = JSON.parse(sessionStorage.getItem('userInfo')).userId;
            this.params3.storeUser = userId;
            server.approveInStorage(this.params3).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '成功入库',
                        type: 'success'
                    });
                    this.resetForm('ruleForm');
                    this.$parent.queryList()//调用列表页面，刷新列表
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: 'warning'
                    });
                });
        },

        submitForm(formName) {
            //审批
            if (this.editData.status == '1') {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.approveWait(this.params);
                    } else {
                        return false;
                    }
                });
            }

            //五必查确认
            if (this.editData.status == '4') {
                this.approveCheck();
            }

            //入库确认
            if (this.editData.status == '5') {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.approveInStorage(this.params3);
                    } else {
                        return false;
                    }
                });
            }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$refs.hcDiaglePage.close();
            //清空表单数据
            this.params = {
                sheetInfoNo: '',
                sheetInfoId: '',
                processUser: '',//审核用户
                processStatus: '',//审核结果
                producterEnterpriseId: '',//产废企业
                transformEnterpriseId: '',//运输企业
                transformDriver: '',//运输司机
                transportation: '',//运输车辆
                transformTime: '',//运输时间
                remark: '',//驳回原因
                receiveEnterpriseId: '',//收集企业账号
            };
            this.params2 = {
                sheetInfoNo: '',
                checkUser: '',//五必查用户
                result1: '',//结果1
                result2: '',//结果2
                result3: '',//结果3
                result4: '',//结果4
                result5: '',//结果5
            };
            this.params3 = {
                sheetInfoNo: '',
                storeUser: '',//入库用户
                receiveWeight: '',//接收量
            };
            this.tableData = [];
            this.activeNo = 0;
            this.approvalVal = '1';//审批结果
            this.carOptionArr = [];
            this.driverOptionArr = [];
            this.contactor = '';
            this.telephone = '';
            this.fiveCheckTableData = [
                { "no": "1", "name": "车辆是否具有有效行驶证、营运证", "result": "1" },
                { "no": "2", "name": "驾驶人、押运人是否具有有效资质证件", "result": "1" },
                { "no": "3", "name": "运输车辆、罐式运输罐体、可移动储柜、罐箱是否在检验合格有效期内", "result": "1" },
                { "no": "4", "name": "所充装或者转载的危险货物是否与危险货物运单标明的事项一致", "result": "1" },
                { "no": "5", "name": "所充装的危险货物是否在罐式车辆罐体的适装介质列表范围内，或者满足可移动罐柜导则、罐箱适用代码的要求", "result": "1" },
            ];
        },
    },
    mounted() {

    },
}
</script>

<style lang="less" scoped>
.tabTit {
    padding: 16px 0px;
}

::v-deep .footer {
    margin-top: 20px;
    margin-bottom: 72px;
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}

::v-deep .el-input--small .el-input__inner {
    width: 300px;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 300px;
}

::v-deep .el-loading-spinner {
    top: 40%;
    margin-top: -10px;
}
::v-deep .el-loading-spinner .circular {
    height: 30px;
    width: 30px;
}
</style>