import {
    httpRequest,
    baseUrl
} from '../utils/request'
export {
    knowledgeParentList,
    knowledgelist,
    deleteKnowledge,
    addContent,
    updataKnowledge,
    addParentName,
    updataParentName,
    deleteParentName
}
//查左侧目录
const knowledgeParentList = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/knowledge/knowledgeParentList',
        method: 'post',
        params
    })
)

// 查全部列表
const knowledgelist = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/knowledge/knowledgelist',
        method: 'post',
        params
    })
)

// 删除文章
const deleteKnowledge = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/knowledge/deleteKnowledge',
        method: 'post',
        params
    })
)

// 新增内容
const addContent = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/knowledge/addContent',
        method: 'post',
        params
    })
)

// 编辑内容
const updataKnowledge = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/knowledge/updataKnowledge',
        method: 'post',
        params
    })
)

// 新增目录
const addParentName = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/knowledge/addParentName',
        method: 'post',
        params
    })
)

// 编辑目录
const updataParentName = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/knowledge/updataParentName',
        method: 'post',
        params
    })
)
// 删除目录
const deleteParentName = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/knowledge/deleteParentName',
        method: 'post',
        params
    })
)