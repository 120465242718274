<template>
    <div class="tab2">
        <div class="operate">
            <div></div>
            <div class="search">
                <el-form ref="form" :model="params" label-width="80px" size="small" :inline="true">
                    <el-form-item label="">
                        <el-input clearable v-model="params.enterpriseId"></el-input>
                    </el-form-item>
                </el-form>
                <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small">查询</HcButton>
                <HcButton :type="'refresh'" icon="el-icon-refresh " size="small" class="rest">重置</HcButton>
            </div>
        </div>
        <el-table :data="tableData" border style="width: 100%" height="450" v-loading="loading">
            <el-table-column type="index" :index="indexMethod" label="序号" width="50">
            </el-table-column>
            <el-table-column prop="enterpriseId" label="收集编号">
            </el-table-column>
            <el-table-column prop="enterpriseName" label="收集编号码">
            </el-table-column>
            <el-table-column prop="enterpriseAddress" label="收集点名称">
            </el-table-column>
       
            <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                    <el-button @click="openDetailDiagle(scope.row)" type="text" size="default">详情</el-button>
                    <el-button type="text" size="default" @click="editTable(scope.row)">编辑</el-button>
                    <el-popconfirm title="是否确定删除所选数据？" @confirm="removeenterprise(scope.row)">
                        <el-button slot="reference" type="text" size="default" class="button_text_del">
                            删除
                        </el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 16px;text-align: right;">
            <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="params.pageNum" :page-size="params.pageSize" :total="params.total" class="pagination">
            </HcPagination>
        </div>

    </div>
</template>
<script>
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";


export default {
    name: 'tab2',
    components: { HcPagination, HcButton },
    data() {
        return {
            tableData: [],
            loading: false,
            params: {
                enterpriseId: '',
                pageSize: 20,
                pageNum: 1,
                total: null,
                totalPage: null,

            },
        }
    },
    methods: {
        //table序号
        indexMethod(index) {
            return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
        },
        handleSizeChange(data) {
            //每页多少条
            console.log(data);
        },
        handleCurrentChange(data) {
            //当前多少页
            console.log(data);
        },
    }
}
</script>

<style  lang="less" scoped>
.tab2 {

    ::v-deep .el-pagination {
        text-align: right !important;
        margin-top: 16px !important;
    }

    .operate {
        display: flex;
        justify-content: space-between;

        .search {
            display: flex;
        }

        .rest {
            margin-left: 8px;
        }
    }
}
.button_text_del {
  color: #F56C6C !important;
  padding-left: 10px;
}
</style>