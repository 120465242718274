<template>
  <div class="user">
    <!-- 用户管理 -->
    <HcListMain>
      <template v-slot:header_from>
        <el-form ref="form" :model="params" label-width="80px" size="small" :inline="true">
          <el-form-item label="企业类型">
            <el-select v-model="params.enterpriseType" clearable filterable placeholder="请选择企业类型"
              @change="getenterpriseType()">
              <el-option v-for="item in MAP2JSON(global.EnterpriseTypeMap)" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业名称">
            <el-select v-model="params.enterpriseId" placeholder="请选择企业名称" clearable filterable>
              <el-option v-for="item in enterpriseName" :key="item.value" :label="item.label" :value="item.value">
              </el-option></el-select>
          </el-form-item>
          <el-form-item label="用户账号">
            <el-input v-model="params.userId" clearable placeholder="请输入用户账号"></el-input>
          </el-form-item>
          <el-form-item label="用户名称">
            <el-input v-model="params.userName" clearable placeholder="请输入用户名称">
            </el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:header_button>
        <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="queryList">查询</HcButton>
        <HcButton :type="'refresh'" icon="el-icon-refresh" size="small" @click="reset">重置</HcButton>
      </template>
      <template v-slot:content_button>
        <HcButton :type="'primary'" icon="el-icon-plus" class="button" size="small" @click="addDiagle">新增</HcButton>
        <!-- <HcButton :type="'info'" icon="el-icon-refresh" size="small">导出</HcButton> -->
      </template>
      <template v-slot:content_table>
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading"
          element-loading-text="加载中">
          <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
          <el-table-column prop="userId" label="用户账号" width="150"></el-table-column>
          <el-table-column prop="userName" label="用户名称" width="150"></el-table-column>
          <el-table-column prop="enterpriseType" label="企业类型" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.enterpriseType == '1' ? '产废企业' : '' }}</span>
              <span>{{ scope.row.enterpriseType == '2' ? '运输企业' : '' }}</span>
              <span>{{ scope.row.enterpriseType == '3' ? '收集企业' : '' }}</span>
              <span>{{ scope.row.enterpriseType == '4' ? '处置企业' : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="enterpriseName" label="企业名称" width="200"></el-table-column>
          <el-table-column prop="roleName" label="角色" min-width="100">

          </el-table-column>
          <el-table-column prop="telephone" label="手机号码" width="130"></el-table-column>
          <el-table-column prop="email" label="邮箱" width="150"></el-table-column>
          <el-table-column prop="userStatus" label="用户状态" width="120">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.userStatus" active-color="#409EFF" inactive-color="#CDD0D6" active-text="启用"
                inactive-text="禁用" :active-value="'1'" :inactive-value="'0'"
                @change="changeSwitch($event, scope.row.userId)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="lastLoginTime" label="最后登录时间" width="180"></el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="default" @click="editTable(scope.row)">编辑</el-button>
              <el-popconfirm title="是否确定删除所选数据？" @confirm="deleteUser(scope.row)">
                <el-button slot="reference" type="text" size="default" v-if="scope.row.defaultRole != null"></el-button>
                <el-button slot="reference" type="text" size="default" class="button_text_del"
                  v-if="scope.row.defaultRole == null">删除</el-button>

              </el-popconfirm>
              <el-popconfirm title="是否重置密码？" @confirm="resetpassword(scope.row)">
                <el-button slot="reference" type="text" size="default" class="resetpassword">重置密码</el-button>
              </el-popconfirm>
              <!-- <el-button type="text" size="default" @click="resetpassword(scope.row)" class="resetpassword">重置密码</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-slot:content_pag>
        <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.pageNum"
          :page-size="params.pageSize" :total="params.total">
        </HcPagination>
      </template>
    </HcListMain>
    <!-- 编辑页面 -->
    <editDiagle ref="editDiagle" :editData="editData"></editDiagle>
  </div>
</template>
  
<script>
import HcButton from '@/components/HcButton/index.vue';
import HcInput from '@/components/HcInput/index.vue';
import HcListMain from "@/components/HcListMain/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import editDiagle from "./pages/editDiagle.vue";//编辑页面
import * as server from '@/api/systemApi';

export default {
  components: {
    HcButton,
    HcInput,
    HcListMain,
    HcPagination,
    editDiagle,
  },
  name: 'user',
  data() {
    return {
      enterpriseName: [],
      tableData: [],
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        enterpriseType: '',//企业类型
        enterpriseId: '',//企业名称
        userId: '',//用户账号
        userName: '',//用户名称
      },
      loading: false,//加载中
      detailTit: '新增用户',
      editData: null,//编辑页面数据,
      mainHeight: null,//中间高度
      tableHeight: null,//表格高度
    }
  },
  provide() {
    //依赖注入，传递值 使用函数的形式可以修改值
    return {
      title: () => this.detailTit,
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1
    },
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(data) { //每页多少条
      console.log(data)
      this.params.pageSize = data//赋值给params
      this.getUserList(this.params)//重新请求获取table的接口

    },
    handleCurrentChange(data) {//当前多少页
      console.log(data)
      this.params.pageNum = data//当前页
      this.getUserList(this.params)//重新获取table的接口

    },
    //switch状态开关的设置
    changeSwitch($event, userId) {
      let pramas = {
        userId: userId,
        status: $event
      }
      server.updateUserStatus(pramas).then(res => {

      })
    },
    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1//将当前页面设置为第一页
      this.getUserList(this.params)
    },

    // 获取table表格数据
    getUserList(params) {
      this.loading = true //请求时打开加载中
      server.userList(params).then((res) => {
        if (res.code == 200) {
          this.loading = false //成功关闭加载中
          // 将获取的list赋值给table
          this.tableData = res.data.list
          // 设置分页的数据
          this.params.pageNum = res.data.pageNum //当前第几页
          this.params.pageSize = res.data.pageSize//每页显示多少条
          this.params.total = res.data.total//共多少条
          this.params.totalPage = res.data.totalPage
        }

      }).catch((err) => {
        setTimeout(() => {//失败2秒后关闭加载中
          this.loading = false
        }, 2000)
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })

    },
    //删除列表中的一条信息
    deleteUser(row) {
      console.log(row, 'handle_remove')
      server.deleteUser({
        userId: row.userId
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.getUserList(this.params)//重新请求获取table的接口
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "warning",
          });
        }
      }).catch((err) => {
        this.$message({
          message: err.message,
          type: 'warning'
        });
      });
    },
    // 重置密码
    resetpassword(row) {
      console.log(row, '重置密码')
      server.resetPassword({
        userId: row.userId
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            // message: res.message,
            message: '重置密码成功',
            type: 'success'
          });
          this.getUserList(this.params)//重新请求获取table的接口
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
    },
    // 新增弹框
    addDiagle() {
      // 调用打开弹框的方法
      this.$refs.editDiagle.showDiagle('add');
      this.detailTit = '新增用户'
    },
    editTable(row) {//打开编辑页面
      // 编辑页面数据
      this.editData = row
      setTimeout(() => {
        this.$refs.editDiagle.showDiagle('edit');
      });
    },

    // 根据企业类型查企业名称-部分
    getenterpriseType() {
      server.enterpriseList({
        enterpriseType: this.params.enterpriseType
      }).then(res => {
        if (res.code == 200) {
          this.enterpriseName = res.data
          // console.log(this.enterpriseName,'getenterpriseType')
        }
      })
    },
    // 重置按钮
    reset() {
      this.params.enterpriseType = ""; //企业类型
      this.params.enterpriseId = ""; //企业名称
      this.params.userId = "";//用户账号
      this.params.userName = "";//用户名称
      this.getenterpriseType()
      // 重置企业名称下拉框
      this.queryList()
    },
  },
  mounted() {
    //页面挂载时第一次调用接口请求
    this.getUserList(this.params)
    // 获取企业类型下拉框-全部
    this.getenterpriseType()
    this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
  },
  watch: {
    'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
      console.log(item, '高度')
      this.tableHeight = item - 226
    },
    'params.enterpriseType': function (item) {
      this.params.enterpriseId = ''
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.user {
  min-height: 500px;
  background: #ffffff;
}

.button_div {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}

.button {
  padding: 0 10px 0 0;
}

//表格里的 删除按钮
.button_text_del {
  color: #F56C6C !important;
  padding-left: 10px;
}

/deep/.el-switch__label {
  display: none;
  width: 50px;
  position: absolute;
}

/deep/.el-switch__label.is-active {
  display: block;
}

/deep/.el-switch__label--right {
  left: 40px;
}

/deep/.el-switch__label--left {
  left: 50px;
}

.resetpassword {
  padding-left: 10px;
}
</style>
  