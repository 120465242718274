<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <div class="center_detail">
                <el-tabs v-model="activeName2" type="card" @tab-click="handleClick" class="eltab">
                    <el-tab-pane label="企业详情" name="tab1">
                        <tab1 :editData="editData" ref="tab1" class="tabstyle"></tab1>
                    </el-tab-pane>
                    <el-tab-pane label="危废列表" name="tab2">
                        <tab2 :editData="editData" ref="tab2" :screen="screen" class="tabstyle"></tab2>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="危废产生收集点" name="tab3">
                        <tab3></tab3>
                    </el-tab-pane> -->
                    <el-tab-pane label="合同列表" name="tab4">
                        <tab4 :editData="editData" ref="tab4" :screen="screen" class="tabstyle"></tab4>
                    </el-tab-pane>
                    <el-tab-pane label="管理计划" name="tab5">
                        <tab5 :editData="editData" ref="tab5" :screen="screen" class="tabstyle"></tab5>
                    </el-tab-pane>
                    <el-tab-pane label="联单列表" name="tab6">
                        <tab6 :editData="editData" ref="tab6" class="tabstyle"></tab6>
                    </el-tab-pane>
                    <el-tab-pane label="走访记录" name="tab7">
                        <tab7 :editData="editData" ref="tab7" :screen="screen" class="tabstyle"></tab7>
                    </el-tab-pane>
                    <el-tab-pane label="智能危废箱列表" name="tab8">
                        <tab8 :editData="editData" ref="tab8" :screen="screen" class="tabstyle"></tab8>
                    </el-tab-pane>
                    <el-tab-pane label="危废贮存列表" name="tab9">
                        <tab9 :editData="editData" :screen="screen" ref="tab9" class="tabstyle"></tab9>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </template>
        <template v-slot:footer>

        </template>

    </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";

import tab1 from "../components/tab1.vue";
import tab2 from "../components/tab2.vue";
import tab3 from "../components/tab3.vue";
import tab4 from "../components/tab4.vue";
import tab5 from "../components/tab5.vue";
import tab6 from "../components/tab6.vue";
import tab7 from "../components/tab7.vue";
import tab8 from "../components/tab8.vue";
import tab9 from "../components/tab9.vue";

export default {
    name: "detailDiagle",
    props: {
        editData: {
            type: Object,
            default: null,
        },
        screen: {
            type: String,
            default: ''
        }
    },
    components: {
        HcDiaglePage,
        HcButton,
        tab1,
        tab2,
        tab3,
        tab4,
        tab5,
        tab6,
        tab7,
        tab8,
        tab9,
    },
    data() {
        return {
            dialogType: "",
            activeName2: "tab1",
        };
    },
    methods: {
        showDiagle(data) {
            this.$refs.hcDiaglePage.showLog = true;
            this.dialogType = data;
        },
        handleClick(tab, event) {
            let tabName = this.activeName2
            switch (tabName) {
                case 'tab2':
                    this.$refs.tab2.getList(this.$refs.tab2.params)
                    break;
                case 'tab4':
                    this.$refs.tab4.getContractList(this.$refs.tab4.params)
                    break
                case 'tab5':
                    this.$refs.tab5.getList(this.$refs.tab5.params);
                    break
                case 'tab6':
                    this.$refs.tab6.getList(this.$refs.tab6.params)
                    break
                case 'tab7':
                    this.$refs.tab7.getList(this.$refs.tab7.params)
                    break
                case 'tab8':
                    this.$refs.tab8.getList(this.$refs.tab8.params)
                    break
                case 'tab9':
                    this.$refs.tab9.getList(this.$refs.tab9.params)
                    break
            }
        },
        resetForm(formName) {
            this.activeName2 = "tab1";
            this.$refs.hcDiaglePage.close();
        },
    },
};
</script>

<style scoped lang="less">
::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item {
    background-color: #f3f8ff !important;
    margin: 0 !important;
    border-radius: 0 !important;
    color: #808080;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    color: #00bfff !important;
    background-color: #fff !important;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: 1px solid #e4e7ed !important;
    width: 100%;
    background-color: #f3f8ff !important;
}

::v-deep .center {
    padding: 0px
}

.tabstyle {
    padding: 0 10px 0 20px;
}

::v-deep .footer {
    display: none;
}

::v-deep .el-tabs__header {
    margin: 0px;
}

// ::v-deep .body{
//     top: 45px;
// }

.eltab {
    top: -15px;
    position: relative;
}
::v-deep .tabTit{
    padding-top: 0px;
}
</style>
