<template>
    <div class="tab2">
        <div class="operate">
            <HcButton :type="'primary'" icon="el-icon-plus" class="button" size="small" v-if="screen !== 'screen'"
                @click="tab7AddOpenMet">新增
            </HcButton>
            <div v-else></div>
            <div class="search">
                <el-form ref="form" :model="params" label-width="80px" size="small" :inline="true">
                    <el-form-item label="">
                        <el-input clearable v-model="params.fuzzyField"></el-input>
                    </el-form-item>
                </el-form>
                <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="searchTable">查询
                </HcButton>
                <HcButton :type="'refresh'" icon="el-icon-refresh " size="small" class="rest" @click="restFrom">重置
                </HcButton>
            </div>
        </div>
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading">
            <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
            <el-table-column prop="enterpriseName" label="企业名称"></el-table-column>
            <el-table-column prop="userId" label="走访人账号" width="220"></el-table-column>
            <el-table-column prop="interviewTime" label="走访时间"></el-table-column>
            <el-table-column prop="interviewDescript" label="走访原因"></el-table-column>
            <el-table-column fixed="right" label="操作" width="150" v-if="screen !== 'screen'">
                <template slot-scope="scope">
                    <el-button @click="tab7DetailOpenMet(scope.row)" type="text" size="default">详情</el-button>
                    <el-button type="text" size="default" @click="tab7EditOpenMet(scope.row)">编辑</el-button>
                    <el-popconfirm title="是否确定删除所选数据？" @confirm="removeInterview(scope.row)">
                        <el-button slot="reference" type="text" size="default" class="button_text_del">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 50px 0px;text-align: right;">
            <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="params.pageNum" :page-size="params.pageSize" :total="params.total" class="pagination">
            </HcPagination>
        </div>

    </div>
</template>
<script>
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/businessApi";

export default {
    name: 'tab7',
    components: { HcPagination, HcButton },
    props: {
        editData: {
            type: Object,
        },
        screen: {
            type: String
        }
    },
    inject: ['tab7AddOpenMet', 'tab7DetailOpenMet', 'tab7EditOpenMet'],
    data() {
        return {
            mainHeight: null,//中间高度
            tableHeight: null,//表格高度
            tableData: [],
            loading: false,
            params: {
                enterpriseId: this.editData.enterpriseId,
                pageSize: 20,
                pageNum: 1,
                total: null,
                totalPage: null,
                fuzzyField: '',//模糊查询字段
            },
        }
    },
    methods: {
        // 获取table表格数据
        getList(params) {
            this.loading = true; //请求时打开加载中
            server.interviewList(params).then((res) => {
                if (res.code == 200) {
                    this.loading = false; //成功关闭加载中
                    // 将获取的list赋值给table
                    this.tableData = res.data.list;
                    // 设置分页的数据
                    this.params.pageNum = res.data.pageNum;
                    this.params.pageSize = res.data.pageSize;
                    this.params.total = res.data.total;
                    this.params.totalPage = res.data.totalPage;
                }
            })
                .catch((err) => {
                    setTimeout(() => {
                        //失败2秒后关闭加载中
                        this.loading = false;
                    }, 2000);
                    this.$message({
                        showClose: true,
                        message: err.message,
                        type: "warning",
                    });
                });
        },
        //查询列表
        searchTable() {
            this.getList(this.params)
        },
        //重置
        restFrom() {
            this.params.fuzzyField = '';
            this.getList(this.params);
        },
        //table序号
        indexMethod(index) {
            return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
        },
        handleSizeChange(data) {
            this.params.pageSize = data; //每页多少条
            this.getList(this.params); //重新请求获取table的接口
        },
        handleCurrentChange(data) {
            this.params.pageNum = data; //当前多少页
            this.getList(this.params); //重新请求获取table的接口
        },
        openTab7Add() {
            this.$parent.$parent.tab7AddOpen();
            this.detailTit = '企业详情'
        },
        openTab7Detail(row) {
            this.$parent.$parent.tab7DetailOpen();
            this.detailTit = '产废企业详情'
        },
        openTab7Edit(row) {
            this.$parent.$parent.tab7EditOpen();
            this.detailTit = '产废企业详情'
        },
        //删除一条数据
        removeInterview(row) {
            server.removeInterview({
                ids: row.interviewNo
            }).then(res => {
                if (res.code == 200) {
                    this.$message({
                        showClose: true,
                        message: "删除成功",
                        type: "success",
                    });
                    this.getList(this.params)//重新请求获取table的接口
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: "warning",
                    });
                }
            })
        },
    },
    mounted() {
        this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
    },
    watch: {
        'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
            this.tableHeight = item - 244
        }
    }
}

</script>
<style  lang="less" scoped>
.tab2 {

    ::v-deep .el-pagination {
        text-align: right !important;
        margin-top: 16px !important;
    }

    .operate {
        display: flex;
        justify-content: space-between;

        .search {
            display: flex;
        }

        .rest {
            margin-left: 8px;
        }
    }
}

//表格里的 删除按钮
.button_text_del {
    color: #F56C6C !important;
    padding-left: 10px;
}

/deep/.el-button--primary {
    background: #00BFFF;
}
</style>