<template>
  <div class="handle">
    <!-- 处置企业列表 -->
    <HcListMain>
      <template v-slot:header_from>
        <el-form
          ref="form"
          :model="params"
          label-width="80px"
          size="small"
          :inline="true"
        >
          <el-form-item label="企业编号">
            <el-input
              v-model="params.enterpriseId"
              clearable
              placeholder="请输入企业编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业名称">
            <el-input
              v-model="params.enterpriseName"
              clearable
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属区域">
            <HcAreaSelect @area="getArea" ref="HcAreaSelect"></HcAreaSelect>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:header_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-search"
          class="button"
          size="small"
          @click="queryList"
          >查询</HcButton
        >
        <HcButton
          :type="'refresh'"
          icon="el-icon-refresh"
          size="small"
          @click="reset"
          >重置</HcButton
        >
      </template>
      <template v-slot:content_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-plus"
          class="button"
          size="small"
          @click="addDiagle"
          >新增</HcButton
        >
        <el-popconfirm
          title="是否确认导出列表数据？"
          @confirm="exportExcel"
          placement="right"
        >
          <HcButton
            slot="reference"
            :type="'info'"
            icon="el-icon-upload2"
            size="small"
            >导出</HcButton
          >
        </el-popconfirm>
      </template>
      <template v-slot:content_table>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :height="tableHeight"
          v-loading="loading"
          element-loading-text="加载中"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="enterpriseId"
            label="企业编号"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="enterpriseName"
            label="企业名称"
            width="190"
          ></el-table-column>
          <el-table-column
            prop="townAddress"
            label="所属区域"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="enterpriseAddress"
            label="详细地址"
            show-overflow-tooltip
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="contactor"
            label="联系人"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="telephone"
            label="联系电话"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="licenceNo"
            label="经营许可证"
            width="150"
          ></el-table-column>
          <el-table-column prop="disposalType" label="处置类型" width="150">
            <template slot-scope="scope">
              {{ global.DisposalTypeMap.get(scope.row.disposalType) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                @click="openDetailDiagle(scope.row)"
                type="text"
                size="default"
                >详情</el-button
              >
              <el-button
                type="text"
                size="default"
                @click="editTable(scope.row)"
                >编辑</el-button
              >
              <el-popconfirm
                title="是否确定删除所选数据？"
                @confirm="removeenterprise(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="text"
                  size="default"
                  class="button_text_del"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-slot:content_pag>
        <HcPagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNum"
          :page-size="params.pageSize"
          :total="params.total"
        >
        </HcPagination>
      </template>
    </HcListMain>

    <!-- 编辑页面 -->
    <editDiagle
      ref="editDiagle"
      :baiDuMapDetail="baiDuMapDetail"
      :editData="editData"
    ></editDiagle>

    <!-- 详情页面 -->
    <detailDiagle ref="detailDiagle" :editData="editData"></detailDiagle>

    <!-- 调用百度弹框 -->
    <baiduMap ref="baiduMap" @mapDetail="mapDetail"></baiduMap>

    <!-- tab3页面的新增 -->
    <tab3Add ref="tab3Add" :editData="editData"></tab3Add>
    <tab3Detail ref="tab3Detail" :detailData="detailData"></tab3Detail>
    <tab3Edit ref="tab3Edit" :detailData="detailData"></tab3Edit>
  </div>
</template>

<script>
import HcButton from "@/components/HcButton/index.vue";
import HcInput from "@/components/HcInput/index.vue";
import HcListMain from "@/components/HcListMain/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import HcAreaSelect from "@/components/HcAreaSelect/index.vue";

import editDiagle from "./pages/editDiagle.vue"; //编辑页面
import baiduMap from "../wasteProduction/pages/baiduMap.vue"; //编辑页面百度地图
import detailDiagle from "./pages/detailDiagle.vue"; //详情页面
import tab3Add from "./components/components/tab3Add.vue";
import tab3Detail from "./components/components/tab3Detail.vue";
import tab3Edit from "./components/components/tab3Edit.vue";
import * as server from "@/api/businessApi";

export default {
  components: {
    HcButton,
    HcInput,
    HcListMain,
    HcPagination,
    HcAreaSelect,
    editDiagle,
    baiduMap,
    detailDiagle,
    tab3Add,
    tab3Detail,
    tab3Edit,
  },
  name: "handle",
  data() {
    return {
      tableData: [],
      params: {
        enterpriseType: "4", //收集单位
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        enterpriseId: "", //企业编号
        enterpriseName: "", //企业名称
        enterpriseProvince: "", //省
        enterpriseCity: "", //市
        enterpriseRegion: "", //区
        enterpriseTown: "", // 街道镇
      },
      loading: false, //加载中
      baiDuMapDetail: {
        address: "",
        longilatde: "",
      },
      detailTit: "新增处置企业",
      editData: null, //编辑页面数据,
      detailData: null, //详情页数据
      mainHeight: null, //中间高度
      tableHeight: null, //表格高度
    };
  },
  provide() {
    //依赖注入，传递值 使用函数的形式可以修改值
    return {
      title: () => this.detailTit,
      tab3AddOpenMet: () => this.tab3AddOpen(),
      tab3DetailOpenMet: (data) => this.tab3DetailOpen(data),
      tab3EditOpenMet: (data) => this.tab3EditOpen(data),
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
    },
    handleSizeChange(data) {
      //每页多少条
      this.params.pageSize = data; //赋值给params
      this.getEnterPrise(this.params); //重新请求获取table的接口
    },
    handleCurrentChange(data) {
      //当前多少页
      this.params.pageNum = data; //当前页
      this.getEnterPrise(this.params); //重新获取table的接口
    },

    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1; //将当前页面设置为第一页
      this.getEnterPrise(this.params);
    },

    // 重置按钮
    reset() {
      this.params.enterpriseId = ""; //企业编号
      this.params.enterpriseName = ""; //企业名称
      this.params.enterpriseProvince = ""; //省
      this.params.enterpriseCity = ""; //市
      this.params.enterpriseRegion = ""; //区
      this.params.enterpriseTown = ""; //镇
      this.params.pageSize = 20;
      this.params.pageNum = 1;
      this.$refs.HcAreaSelect.rest();
      this.queryList();
    },

    // 获取table表格数据
    getEnterPrise(params) {
      this.loading = true; //请求时打开加载中
      server
        .enterprise(params)
        .then((res) => {
          if (res.code == 200) {
            this.loading = false; //成功关闭加载中
            // 将获取的list赋值给table
            this.tableData = res.data.list;

            // 设置分页的数据
            this.params.totalPage = res.data.totalPage; //共多少页
            this.params.total = res.data.total; //共多少条
            this.params.pageNum = this.params.pageNum; //当前第几页
            this.params.pageSize = this.params.pageSize; //每页显示多少条
            this.params.enterpriseType = "4";
          }
        })
        .catch((err) => {
          setTimeout(() => {
            //失败2秒后关闭加载中
            this.loading = false;
          }, 2000);
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });
    },
    //删除列表中的一条信息
    removeenterprise(row) {
      server
        .removeenterprise({
          ids: row.enterpriseId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success",
            });
            this.getEnterPrise(this.params); //重新请求获取table的接口
          } else {
            this.$message({
              showClose: true,
              message: res.message,
              type: "warning",
            });
          }
        });
    },
    // 新增弹框
    addDiagle() {
      this.$refs.editDiagle.showDiagle();
      this.detailTit = "新增处置企业";
    },
    //打开编辑页面
    editTable(row) {
      this.detailTit = "编辑处置企业";
      this.editData = row;
      setTimeout(() => {
        this.$refs.editDiagle.showDiagle("edit"); //打开编辑页面
      });
    },
    // 打开详情页面
    openDetailDiagle(row) {
      this.detailTit = "处置企业详情";
      this.editData = row;
      setTimeout(() => {
        this.$refs.detailDiagle.showDiagle("detail");
      });
    },
    // 打开百度地图
    baiduMapOpen() {
      // 修改弹框头部名称
      this.$refs.baiduMap.showBaiduMap(); //打开百度地图弹框
    },
    mapDetail(data) {
      this.baiDuMapDetail = data;
      console.log(data, "地图信息");
    },

    //打开tab3新增
    tab3AddOpen() {
      this.$refs.tab3Add.showtab3Add(); //打开tab3Add弹框
    },
    //打开tab3详情页
    tab3DetailOpen(data) {
      this.detailData = data;
      setTimeout(() => {
        this.$refs.tab3Detail.showtab3Detail(); //打开tab3Detail详情
      });
    },
    //打开tab3编辑页
    tab3EditOpen(data) {
      this.detailData = data;
      setTimeout(() => {
        this.$refs.tab3Edit.showtab3Edit(); //打开tab3Edit详情
      });
    },

    //从detailDiagle页面传一个tab2页面的 刷新table列表的方法过来
    //下一步：去detailDiagle页面的tab2调用的地方写一个ref传值
    getSclbList(params) {
      this.$refs.detailDiagle.$refs.tab3.getList(params);
    },

    //导出表格
    exportExcel() {
      this.download(
        "/cloud/enterprise/export",
        this.params,
        `处置企业数据_${new Date().getTime()}.xlsx`
      );
    },

    // 省市区镇选择
    getArea(val) {
      this.params.enterpriseProvince = val.enterpriseProvince; //省
      this.params.enterpriseCity = val.enterpriseCity; //市
      this.params.enterpriseRegion = val.enterpriseRegion; //区
      this.params.enterpriseTown = val.enterpriseTown; //镇
    },
  },
  mounted() {
    this.getEnterPrise(this.params);
    this.mainHeight = sessionStorage.getItem("listMainHeight"); //获取中间区域的高度
  },
  watch: {
    mainHeight: function (item) {
      //通过监听中间高度动态给table赋值高度
      console.log(item, "高度");
      this.tableHeight = item - 226;
    },
  },
};
</script>

<style scoped lang="less">
.handle {
  min-height: 500px;
  background: #ffffff;
}

.button_div {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}

.button {
  padding: 0 10px 0 0;
}

//表格里的 删除按钮
.button_text_del {
  color: #f56c6c !important;
  padding-left: 10px;
}

/deep/.el-button--primary {
  background: #00bfff;
}
</style>
