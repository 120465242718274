import {httpRequest,baseUrl} from '../utils/request'
export {
    enterpriseDetail,allEnterpriseList,allCarList,allDriverList,
    list,add,edit,remove,
    wasteProductList,addWasteProduct,removeWasteProduct,
    wasteStorageList,addWasteStorage,removeWasteStorage,
    allWasteList
}
// 查询企业详细信息
const enterpriseDetail=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/enterprise/enterpriseDetail',
       method:'post' ,
       params
    })
)

// 查询车辆下拉框
const allCarList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/enterprise/allCarList',
       method:'post' ,
       params
    })
)

// 查询司机下拉框
const allDriverList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/enterprise/allDriverList',
       method:'post' ,
       params
    })
)

// 查询企业下拉框
const allEnterpriseList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/enterprise/allEnterpriseList',
       method:'post' ,
       params
    })
)

// 查询计划列表
const list=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/plan/list',
       method:'post' ,
       params
    })
)

// 新增计划信息
const add=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/plan/add',
        method:'post' ,
        params
     })
)

// 编辑计划信息
const edit=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/plan/edit',
        method:'post' ,
        params
     })
)

// 删除计划信息
const remove=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/plan/remove',
       method:'post' ,
       params
    })
)

// 查询危险废物产生列表
const wasteProductList=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/plan/wasteProductList',
        method:'post' ,
        params
     })
)

// 新增危险废物产生信息
const addWasteProduct=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/plan/addWasteProduct',
        method:'post' ,
        params
     })
)

// 删除危险废物产生信息
const removeWasteProduct=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/plan/removeWasteProduct',
       method:'post' ,
       params
    })
)

// 查询危险废品贮存（收集）列表
const wasteStorageList=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/plan/wasteStorageList',
        method:'post' ,
        params
     })
)

// 新增危险废品贮存（收集）信息
const addWasteStorage=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/plan/addWasteStorage',
        method:'post' ,
        params
     })
)

// 删除危险废品贮存（收集）信息
const removeWasteStorage=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/plan/removeWasteStorage',
       method:'post' ,
       params
    })
)


// 查询危废类别下拉框
const allWasteList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/wasteInfo/allWasteList',
       method:'post' ,
       params
    })
)