<template>
  <div class="HcInput">
     <slot></slot>
    <el-input
        v-bind="$attrs" v-on="$listeners"
      >
    </el-input>
  </div>
</template>
<script>
export default {
  name: "index",
  methods:{
  
  }
}
</script>

<style scoped>

</style>
