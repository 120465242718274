<template>
  <!-- 管理计划 -> 新增计划 ->新增危险废物产生 -->
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm"></i>
    </template>
    <template v-slot:center>
      <div class="tabTit">新增危险废物产生情况</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="170px" class="demo-ruleForm" size="small">
        <el-row>
          <el-col :span="12" style="display: flex;">
            <el-form-item label="产生源名称" prop="productSourceName">
              <el-input v-model="ruleForm.productSourceName" placeholder="请输入产生源名称" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex;">
            <el-form-item label="产生源类型" prop="productSourceType">
              <el-select v-model="ruleForm.productSourceType" placeholder="请选择产生源类型" clearable filterable>
                <el-option label="生产性产生源" value="1"></el-option>
                <el-option label="非生产性产生源" value="2"></el-option></el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex;">
            <el-form-item label="产生源代码" prop="productSourceCode">
              <el-input v-model="ruleForm.productSourceCode" placeholder="请输入产生源代码" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" style="display: flex;">
            <el-form-item label="危废类别" prop="categoryCode">
              <el-select clearable filterable v-model="ruleForm.categoryCode" @change="updateSub1" placeholder="请选择危废类别">
                <el-option v-for="item in arr" :key="item.value" :label="item.value" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="危废类别名称">
              <el-input v-model="ruleForm.categoryName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex;">
            <el-form-item label="废物代码" prop="harmWasteCode">
              <el-select clearable filterable v-model="ruleForm.harmWasteCode" @change="updateSub2" placeholder="请选择废物代码">
                <el-option v-for="item in subArr" :key="item.value" :label="item.value" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="危险特性" prop="harmFeature">
              <el-input v-model="ruleForm.harmFeature" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex;">
            <el-form-item label="物理性状" prop="physicalCharacter">
              <el-select v-model="ruleForm.physicalCharacter" placeholder="请选择物理性状" clearable filterable>
                <el-option label="固态" value="1"></el-option>
                <el-option label="液态" value="2"></el-option>
                <el-option label="半固态" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex;">
            <el-form-item label="本年度计划产生量(吨)" prop="mpsQuantity">
              <!-- <el-input v-model="ruleForm.mpsQuantity" clearable></el-input> -->
              <el-input-number v-model="ruleForm.mpsQuantity" :controls="false" :min="0" :precision="2"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex;">
            <el-form-item label="上年度实际产生量(吨)" prop="actualQuantity">
              <!-- <el-input v-model="ruleForm.actualQuantity" clearable></el-input> -->
              <el-input-number v-model="ruleForm.actualQuantity" :controls="false" :min="0"
                :precision="2"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-slot:footer>
      <HcButton :type="'cancel'" size="small" @click="resetForm()">取消</HcButton>
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
    </template>
  </HcDiaglePage>
</template>
      
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from '@/components/HcButton/index.vue';
import * as server2 from '@/api/systemApi';

export default {
  name: "addWasteProduct",
  components: {
    HcDiaglePage,
    HcButton
  },
  data() {
    return {
      arr: [],//所有类别
      subArr: [],//选择的类别下所有的代码
      ruleForm: {
        productSourceName: "",
        productSourceType: "",
        productSourceCode: "",
        categoryCode: "",//废物类别
        categoryName: "",//废物类别名称
        harmWasteCode: "",//危废代码
        physicalCharacter: "",//物理性状
        harmFeature: "",//危险特性
        mpsQuantity: "",//本年度计划产生量(吨)
        actualQuantity: "",//上年度实际产生量(吨)
      },
      rules: {
        productSourceName: [
          { required: true, message: "请填写产生源名称", trigger: "blur" },
        ],
        productSourceType: [
          { required: true, message: "请选择产生源类型", trigger: "change" },
        ],
        productSourceCode: [
          { required: true, message: "请填写产生源代码", trigger: "blur" },
        ],
        categoryCode: [
          { required: true, message: "请选择废物类别", trigger: "change" },
        ],
        harmWasteCode: [
          { required: true, message: "请选择危废代码", trigger: "change" },
        ],
        physicalCharacter: [
          { required: true, message: "请选择物理性状", trigger: "change" },
        ],
        mpsQuantity: [
          { required: true, message: "请填写本年度计划产生量", trigger: "blur" },
        ],
        actualQuantity: [
          { required: true, message: "请填写上年度实际产生量", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    showAddWasteProduct() {
      this.getCategoryCode();
      this.$refs.hcDiaglePage.showLog = true;
      this.ruleForm = {
        productSourceName: "",
        productSourceType: "",
        productSourceCode: "",
        categoryCode: "",
        harmWasteCode: "",
        categoryName: "",
        physicalCharacter: "",
        harmFeature: "",
        mpsQuantity: "",
        actualQuantity: "",
      };
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$message({
            message: '添加危险废物产生情况成功',
            type: 'success'
          });

          //本页面不提交到数据库，在外层提交数据库
          this.$emit('change', this.ruleForm);
          this.$refs.hcDiaglePage.enter();//关闭弹框

        } else {
          return false;
        }
      });
    },

    resetForm() {
      this.$refs.hcDiaglePage.close();
      this.$parent.detailTit = '新增管理计划'
    },

    //废物类别选择后更新废物代码选择集
    updateSub1(categoryCode) {
      //设置类别名称
      let obj = this.arr.find((item) => {
          return item.value === categoryCode;
      });
      this.ruleForm.categoryName = obj.label;

      //设置类别代码
      this.ruleForm.harmWasteCode = '';
      this.ruleForm.harmFeature = '';
      let newParams = {'categoryCode':categoryCode};
      this.getWasteCode(newParams);
    },

    //废物代码选择后更新废物危险特性
    updateSub2(harmWasteCode) {
      //设置废物危险特性
      let option = this.subArr.find((item) => {
        return item.value === harmWasteCode;
      });
      this.ruleForm.harmFeature = option.harmFeature;
    },

    //请求危废类别数据（一级）
    async getCategoryCode() {
      let res = await server2.categoryCodeList();
      if (res.code == 200) {
        this.arr = res.data;
      }
    },
    //请求废物代码数据（二级）
    async getWasteCode(params) {
      let res = await server2.wasteCodeList(params);
      if (res.code == 200) {
        this.subArr = res.data;
      }
    },
  },
};
</script>
      
<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}

::v-deep .el-input-number .el-input__inner {
  text-align: left;
}

::v-deep .el-input--small .el-input__inner {
  width: 300px;
}

::v-deep .el-textarea__inner {
  width: 800px;
}

.tabTit {
  padding-bottom: 16px;
  margin-top: 20px;
}
</style>
      