<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
    </template>
    <template v-slot:center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户账号" prop="userId" v-if="dialogType == 'edit'">
              <el-input v-model="ruleForm.userId" disabled placeholder="请输入用户账号"></el-input>
            </el-form-item>
            <el-form-item label="用户账号" prop="userId" v-if="dialogType == 'add'">
              <el-input v-model="ruleForm.userId" clearable placeholder="请输入用户账号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户名称" prop="userName">
              <el-input v-model="ruleForm.userName" clearable placeholder="请输入用户名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属企业类型" prop="enterpriseType">
              <el-select v-model="ruleForm.enterpriseType" clearable filterable @change="getenterpriseType()"
                placeholder="请选择所属企业类型">
                <el-option v-for="item in MAP2JSON(global.EnterpriseTypeMap)" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属企业名称" prop="enterpriseId">
              <el-select v-model="ruleForm.enterpriseId" clearable filterable placeholder="请选择所属企业名称">
                <el-option v-for="item in enterpriseName" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属角色" prop="roleId">
              <el-select v-model="ruleForm.roleId" clearable filterable placeholder="请选择所属角色">
                <el-option v-for="item in roleNameList" :key="item.roleId" :label="item.roleName"
                  :value="item.roleId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="telephone">
              <el-input v-model="ruleForm.telephone" clearable placeholder="请输入手机号码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="ruleForm.email" clearable placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="登录密码">
              <el-input v-model="ruleForm.password" show-password @input="changeMessage($event)"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
    </template>
    <template v-slot:footer>
      <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
    </template>
  </HcDiaglePage>
</template>

<script>
import aes from "@/utils/aes";
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/systemApi";
import { nextTick } from 'vue';

export default {
  name: "editDiagle",
  props: {
    editData: {
      type: Object,
      default: null
    }
  },

  components: {
    HcDiaglePage,
    HcButton,
  },
  mounted() {
  },

  data() {
    const checkTelephone = (rule, value, callback) => {
      const reg = /^(((\d{3,4}-)?[0-9]{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/;
      if (!value) {
        callback();
      }
      setTimeout(() => {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的手机号码格式"));
        }
      }, 100);
    };
    const checkEmail = (rule, value, callback) => {
      // const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      const reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      if (!value) {
        callback();
      }
      setTimeout(() => {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };
    return {
      enterpriseName: [],
      roleNameList: [],
      ruleForm: {
        pageSize: 20,
        pageNum: 1,
        userId: "",//用户账号
        userName: "",//用户名称
        enterpriseType: "",//企业类型
        enterpriseId: "",//企业名称-id
        enterpriseName: "",//企业名称-name
        roleId: "",//所属角色
        telephone: "",//手机号码
        email: "",//邮箱
        // password: "",//密码
      },
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
      },
      rules: {
        userId: [
          { required: true, message: "请输入用户账号", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请输入用户名称", trigger: "change" },
        ],
        enterpriseType: [
          { required: true, message: "请选择所属企业类型", trigger: "change" },
        ],
        enterpriseId: [
          { required: true, message: "请选择所属企业名称", trigger: "change" },
        ],
        roleId: [
          { required: true, message: "请选择所属角色", trigger: "change" },
        ],
        telephone: [
          { required: false, trigger: "blur", validator: checkTelephone, }
        ],
        email: [
          { required: false, trigger: 'blur', validator: checkEmail }
        ],
      },
    };
  },
  watch: {
    'ruleForm.enterpriseType': function (item) {
      console.log(item, 'enterpriseType')
      this.ruleForm.enterpriseId = "";//企业名称-name
    }
  },
  methods: {
    // 强制更新测试文本信息框的值
    changeMessage() {
      this.$forceUpdate();
    },
    showDiagle(data) {
      this.$refs.hcDiaglePage.showLog = true;
      this.ruleForm.userId = "";//用户账号
      this.ruleForm.userName = "";//用户名称
      this.ruleForm.enterpriseType = "";//企业类型
      this.ruleForm.enterpriseId = "";//企业名称-id
      this.ruleForm.enterpriseName = "";//企业名称-name
      this.ruleForm.roleId = "";//所属角色
      this.ruleForm.telephone = "";//手机号码
      this.ruleForm.email = "";//邮箱
      // 判断弹框是编辑页面还是新增页面或者是详情页面
      this.dialogType = data
      if (this.dialogType == 'edit') {
        this.$parent.detailTit = '编辑用户'
        console.log(this.editData, 'hello1')
        this.ruleForm.userId = this.editData.userId;//用户账号
        this.ruleForm.userName = this.editData.userName;//用户名称
        this.ruleForm.enterpriseType = this.editData.enterpriseType;//企业类型
        this.ruleForm.roleId = this.editData.roleId;//企业类型
        this.ruleForm.telephone = this.editData.telephone;//电话
        this.ruleForm.email = this.editData.email;//邮箱
        this.$nextTick(() => {
          this.ruleForm.enterpriseId = this.editData.enterpriseId;//企业名称
        })
      }
      this.getenterpriseType(this.ruleForm.enterpriseType)
      this.queryRoleNameList()
    },
    add(params) {
      server.addUser(params).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.$refs.hcDiaglePage.enter();//关闭弹框  
          this.$parent.getUserList(this.params)//调用列表页面，刷新列表
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: 'warning'
          });
        });
    },
    edit(params) {
      if (this.dialogType == 'edit') {
        server.editUser(params).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            });
            this.$refs.hcDiaglePage.enter();//关闭弹框  
            this.$parent.getUserList(this.params)//调用列表页面，刷新列表
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
          .catch((err) => {
            this.$message({
              message: err.message,
              type: 'warning'
            });
          });
      }

    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType == 'edit') {
            this.edit(this.ruleForm)//调用编辑接口
          } else {
            this.add(this.ruleForm) //调用新增接口
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$refs.hcDiaglePage.close();
      this.ruleForm.userId = "";//用户账号
      this.ruleForm.userName = "";//用户名称
      this.ruleForm.enterpriseType = "";//企业类型
      this.ruleForm.enterpriseId = "";//企业名称-id
      this.ruleForm.enterpriseName = "";//企业名称-name
      this.ruleForm.roleId = "";//所属角色
      this.ruleForm.telephone = "";//手机号码
      this.ruleForm.email = "";//邮箱
    },
    // 根据企业类型查企业名称-部分
    getenterpriseType() {
      server.enterpriseList({
        enterpriseType: this.ruleForm.enterpriseType
      }).then(res => {
        if (res.code == 200) {
          this.enterpriseName = res.data
          // console.log(this.enterpriseName,'getenterpriseType')
        }
      })
    },
    // 查询所属角色-角色管理中已经创建的
    queryRoleNameList() {
      server.queryRoleNameList().then(res => {
        if (res.code == 200) {
          this.roleNameList = res.data
        }
        console.log(this.roleNameList, 'roleNameList')

      })
    }
  },
};
</script>

<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}

::v-deep .el-input--small .el-input__inner {
  width: 300px;
}

::v-deep .el-input--small {
  width: 300px;
}

::v-deep .el-textarea__inner {
  width: 800px;
}

.addressClass {
  display: flex;

  .addressDiv {
    cursor: pointer;
  }

  .addressIcon {
    font-size: 24px;
    color: #E6A23C;
    margin-left: 6px;
  }

  span {
    font-size: 14px;
    color: #E6A23C;
  }
}

.demo-ruleForm {
  margin-top: 20px;
}
</style>
