<template>
  <div class="contractList">
    <!-- 合同管理-合同列表 -->
    <HcListMain>
      <template v-slot:header_from>
        <el-form
          ref="form"
          :model="params"
          label-width="80px"
          size="small"
          :inline="true"
        >
          <el-form-item label="合同编号">
            <el-input
              v-model="params.agreementInfoId"
              clearable
              placeholder="请输入合同编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业名称">
            <el-select
              v-model="params.enterpriseId"
              clearable
              filterable
              placeholder="请选择企业名称"
            >
              <el-option
                v-for="item in enterpriseOptionArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="签订日期">
            <el-date-picker
              v-model="timeScope"
              type="daterange"
              range-separator="至"
              start-placeholder="请选择开始日期"
              end-placeholder="请选择结束日期"
              value-format="yyyy-MM-dd"
              @change="datePickerChange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="合同状态">
            <el-select
              v-model="params.agreementStatus"
              clearable
              filterable
              placeholder="请选择合同状态"
            >
              <el-option label="未生效" value="1"></el-option>
              <el-option label="履行中" value="2"></el-option>
              <el-option label="已失效" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:header_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-search"
          class="button"
          size="small"
          @click="queryList"
          >查询</HcButton
        >
        <HcButton
          :type="'refresh'"
          icon="el-icon-refresh "
          size="small"
          @click="reset"
          >重置</HcButton
        >
      </template>
      <template v-slot:content_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-plus"
          class="button"
          size="small"
          @click="openContracAdd"
          >新增</HcButton
        >
      </template>
      <template v-slot:content_table>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :height="tableHeight"
          v-loading="loading"
          element-loading-text="加载中"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="enterpriseName"
            label="企业名称"
          ></el-table-column>
          <el-table-column
            prop="agreementInfoId"
            label="合同编号"
          ></el-table-column>
          <el-table-column
            prop="signDate"
            label="签订时间"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="effectDate"
            label="生效日期"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="endDate"
            label="截止日期"
            width="120"
          ></el-table-column>
          <el-table-column label="押金状态" width="120">
            <template slot-scope="scope">
              <el-tag
                type="success"
                size="medium"
                v-if="scope.row.deviceFeePayStatus == 0"
                >未支付</el-tag
              >
              <el-tag
                type="warning"
                size="medium"
                v-if="scope.row.deviceFeePayStatus == 1"
                >已支付</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="serviceFeeExpirationTime"
            label="服务到期时间"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            width="180"
          ></el-table-column>
          <el-table-column label="合同状态" width="120">
            <template slot-scope="scope">
              <el-tag
                type="success"
                size="medium"
                v-if="scope.row.agreementStatus == 2"
                >履行中</el-tag
              >
              <el-tag
                type="warning"
                size="medium"
                v-if="scope.row.agreementStatus == 1"
                >未生效</el-tag
              >
              <el-tag
                type="danger"
                size="medium"
                v-if="scope.row.agreementStatus == 3"
                >已失效</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                @click="openContracDetail(scope.row)"
                type="text"
                size="default"
                >详情</el-button
              >
              <span v-if="scope.row.agreementStatus == '1'">
                <el-button
                  type="text"
                  size="default"
                  @click="openContracEdit(scope.row)"
                  class="button_text_edit"
                  >编辑</el-button
                >
                <el-popconfirm
                  title="是否确定删除所选数据？"
                  @confirm="removeContractList(scope.row)"
                >
                  <el-button
                    slot="reference"
                    type="text"
                    size="default"
                    class="button_text_del"
                    >删除</el-button
                  >
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-slot:content_pag>
        <HcPagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNum"
          :page-size="params.pageSize"
          :total="params.total"
        >
        </HcPagination>
      </template>
    </HcListMain>

    <!-- 新增、编辑合同 -->
    <contracAdd
      ref="contracAdd"
      :enterpriseOptionArr="enterpriseOptionArr"
      :editData="editData"
    ></contracAdd>

    <!-- 合同详情 -->
    <contracDetail ref="contracDetail" :editData="editData"></contracDetail>
  </div>
</template>

<script>
import HcButton from "@/components/HcButton/index.vue";
import HcInput from "@/components/HcInput/index.vue";
import HcListMain from "@/components/HcListMain/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";

import contracAdd from "./pages/contracAdd.vue";
import contracDetail from "./pages/contracDetail.vue"; //详情页面

import * as server from "@/api/contractApi";
import * as server2 from "@/api/planApi";

export default {
  components: {
    HcButton,
    HcInput,
    HcListMain,
    HcPagination,
    contracAdd,
    contracDetail,
  },
  name: "contractList",
  data() {
    return {
      tableData: [],
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        agreementInfoId: "", //合同编号
        enterpriseId: "", //企业编号
        signStartDate: "", //查询签订开始日期
        signEndDate: "", //查询签订结束日期
        agreementStatus: "", //合同状态
      },
      enterpriseOptionArr: [], //获取的企业名称数组
      timeScope: [],
      loading: false, //加载中
      detailTit: "新增合同信息",
      editData: null,
      mainHeight: null, //中间高度
      tableHeight: null, //表格高度
    };
  },
  provide() {
    //依赖注入，传递值 使用函数的形式可以修改值
    return {
      title: () => this.detailTit,
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
    },
    handleSizeChange(data) {
      this.params.pageSize = data; //每页多少条
      this.getContractList(this.params); //重新获取table的接口
    },
    handleCurrentChange(data) {
      this.params.pageNum = data; //当前多少页
      this.getContractList(this.params); //重新获取table的接口
    },
    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1; //将当前页面设置为第一页
      this.getContractList(this.params);
    },

    // 重置按钮
    reset() {
      this.params.agreementInfoId = ""; //合同编号
      this.params.enterpriseId = ""; //企业编号
      this.timeScope = [];
      this.params.signStartDate = ""; //签订开始时间
      this.params.signEndDate = ""; //签订结束时间
      this.params.agreementStatus = ""; //合同状态
      this.queryList();
    },

    // 获取table表格数据
    getContractList(params) {
      this.loading = true; //请求时打开加载中
      server
        .contractList(params)
        .then((res) => {
          if (res.code == 200) {
            this.loading = false; //成功关闭加载中
            // 将获取的list赋值给table
            this.tableData = res.data.list;

            // 设置分页的数据
            this.params.pageNum = res.data.pageNum;
            this.params.pageSize = res.data.pageSize;
            this.params.total = res.data.total;
            this.params.totalPage = res.data.totalPage;
          }
        })
        .catch((err) => {
          setTimeout(() => {
            //失败2秒后关闭加载中
            this.loading = false;
          }, 2000);
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });
    },
    //删除列表中的一条信息
    removeContractList(row) {
      console.log(row, "transport_remove");
      server
        .getDelete({
          agreementInfoId: row.agreementInfoId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.queryList(); //重新请求获取table的接口
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success",
            });
          } else {
            this.$message({
              showClose: true,
              message: res.message,
              type: "warning",
            });
          }
        });
    },

    // 新增合同信息
    openContracAdd() {
      this.detailTit = "新增合同信息";
      this.$refs.contracAdd.showDiagle("add");
    },

    // 打开编辑页面
    openContracEdit(row) {
      this.detailTit = "编辑合同信息";
      this.editData = row;
      setTimeout(() => {
        this.$refs.contracAdd.showDiagle("edit");
      });
    },

    // 打开详情
    openContracDetail(row) {
      this.detailTit = "合同信息详情";
      this.editData = row;
      setTimeout(() => {
        this.$refs.contracDetail.showDiagle("detail");
      });
    },

    // 获取企业下拉框数据
    async getEnterpriseOption() {
      let params = { enterpriseType: "" };
      await server2.allEnterpriseList(params).then((res) => {
        this.enterpriseOptionArr = res.data;
      });
    },

    //时间范围选择
    datePickerChange(daterange) {
      this.params.signStartDate = "";
      this.params.signEndDate = "";
      if (daterange[0]) {
        this.params.signStartDate = daterange[0] + " 00:00:00";
        this.params.signEndDate = daterange[1] + " 23:59:59";
      }
    },
  },

  mounted() {
    // 获取企业名称
    this.getEnterpriseOption();

    //页面挂载时第一次调用接口请求
    this.queryList();
    this.mainHeight = sessionStorage.getItem("listMainHeight"); //获取中间区域的高度

    if (this.$route.query.type == "wasteTab4Add") {
      //路由传值，如果是从产废企业列表详情新增进来，直接打开新增页面
      this.detailTit = "新增合同信息";
      let data = JSON.parse(window.sessionStorage.getItem("wasteTab4Add"));
      this.$refs.contracAdd.showDiagle(data, "wasteTab4Add");
    } else if (this.$route.query.type == "wasteTab4Detail") {
      //如果是从产废企业列表详情tab4详情进来，直接打开详情页面
      const data = JSON.parse(window.sessionStorage.getItem("wasteTab4Detail"));
      this.openContracDetail(data);
    } else if (this.$route.query.type == "wasteTab4Edit") {
      const data = JSON.parse(window.sessionStorage.getItem("wasteTab4Edit"));
      this.openContracEdit(data);
    } else if (this.$route.query.type == "tabContract") {
      const data = JSON.parse(window.sessionStorage.getItem("tabContract"));
      let data2 = [];
      server
        .contractList({
          pageNum: "1",
          pageSize: "20",
          agreementInfoId: data.agreementInfoId,
        })
        .then((res) => {
          data2 = res.data.list;
          this.openContracDetail(res.data.list[0]);
        })
        .catch((err) => {
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });
    }
  },

  watch: {
    mainHeight: function (item) {
      //通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 226;
    },
    "$route.query": function (item) {
      // TODO：这里好像没用到，添加筛选条件后，从别的页面过来，会重新查询所有数据，导致筛选错误，先去掉
      // 路由改变时调用list接口
      // server.contractList({
      //   pageNum: '1',
      //   pageSize: '20',
      // }).then((res) => {
      //   if (res.code == 200) {
      //     this.loading = false //成功关闭加载中
      //     // 将获取的list赋值给table
      //     this.tableData = res.data.list
      //   }
      // }).catch((err) => {
      //   setTimeout(() => {//失败2秒后关闭加载中
      //     this.loading = false
      //   }, 2000)
      //   this.$message({
      //     showClose: true,
      //     message: err.message,
      //     type: 'warning'
      //   });
      // })

      //使用watch监听路由信息，如果是从产废企业详情过来，直接打开新增页面
      if (item.type == "wasteTab4Add") {
        this.detailTit = "新增合同信息";
        let data = JSON.parse(window.sessionStorage.getItem("wasteTab4Add"));
        this.$refs.contracAdd.showDiagle(data, "wasteTab4Add");
      } else if (this.$route.query.type == "wasteTab4Detail") {
        const data = JSON.parse(
          window.sessionStorage.getItem("wasteTab4Detail")
        );
        this.openContracDetail(data);
      } else if (this.$route.query.type == "wasteTab4Edit") {
        const data = JSON.parse(window.sessionStorage.getItem("wasteTab4Edit"));
        this.openContracEdit(data);
      } else if (this.$route.query.type == "tabContract") {
        const data = JSON.parse(window.sessionStorage.getItem("tabContract"));
        let data2 = [];
        server
          .contractList({
            pageNum: "1",
            pageSize: "20",
            agreementInfoId: data.agreementInfoId,
          })
          .then((res) => {
            data2 = res.data.list;
            this.openContracDetail(res.data.list[0]);
          })
          .catch((err) => {
            this.$message({
              showClose: true,
              message: err.message,
              type: "warning",
            });
          });
      }
    },
  },
};
</script>

<style scoped lang="less">
.contractList {
  min-height: 500px;
  background: #ffffff;
}

.button {
  padding: 0 10px 0 0;
}

//表格里的 删除按钮
.button_text_del {
  color: #f56c6c !important;
  padding-left: 10px;
}

.button_text_edit {
  padding-left: 10px;
}

/deep/.el-button--primary {
  background: #00bfff;
}
</style>
