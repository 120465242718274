<template>
  <div class="ledger" ref="ledger">
    <!-- 企业管理-企业台账导入 -->
    <div class="tip">
      <span style="color: #00BFFF;">请先下载导入模板，按格式修改后导入</span>
      <HcButton :type="'primary'" icon="el-icon-download" class="button" size="small" @click="importTemplate">下载模板</HcButton>
    </div>
    <HcButton :type="'info'" icon="el-icon-folder-add" class="button" size="small" @click="handleImport">上传文件</HcButton>

    <!-- 导入对话框 -->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body>
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx, .xls"
        :headers="upload.headers"
        :action="upload.url + '?updateSupport=' + upload.updateSupport"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip text-center" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import HcButton from '@/components/HcButton/index.vue';

export default {
  components: { HcButton },
  name: 'ledger',
  data() {
    return {
      params: {
        enterpriseId: '',
      },
      // 导入参数
      upload: {
        open: false,// 是否显示弹出层（用户导入）
        title: "",// 弹出层标题（用户导入）
        isUploading: false,// 是否禁用上传
        updateSupport: 0,// 是否更新已经存在的用户数据
        headers: { token: window.localStorage.getItem("token"), },
        url: process.env.VUE_APP_BASE_URL + "/cloud/enterprise/importEnterpriseData"// 上传的地址
      },
    };
  },
  methods: {
    // 导入
    handleImport() {
      this.upload.title = "企业台账导入";
      this.upload.open = true;
    },

    // 下载模板
    importTemplate() {
      this.download('/cloud/enterprise/exportTemplate', {}, `企业台账模板2023.xlsx`)
    },

    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },

    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.data + "</div>", "导入结果", { dangerouslyUseHTMLString: true });
    },

    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },
    getHeight() {//动态计算中间内容部分高度
      let bodyHeight = document.body.clientHeight
      let contentHeight = bodyHeight - 168
      console.log(bodyHeight, contentHeight, 'height')
      this.$refs.ledger.style.height = contentHeight + 'px'
      sessionStorage.setItem('ledgerHeight', contentHeight)
    }
  },
  mounted() {
    this.getHeight()
  },
}
</script>

<style scoped lang="less">
.ledger{
  min-height: 500px;
  background: #ffffff;
  padding: 10px;
}

.tip{
  height: 87px;
  line-height: 87px;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  background-color: #CCF2FF;
}

::v-deep .el-upload-list__item {
  border: 1px solid #e4e7ed;
}
</style>