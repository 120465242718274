<template>
    <div class="tab2" ref="tabBox">
        <div id="sclDiv" style="width:100%;height: 450px;"></div>
        <div class="tabTit">年收处量列表</div>
        <el-table :data="tableData" border style="width: 100%" height="450" v-loading="loading">
            <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
            <el-table-column prop="year" label="年度"></el-table-column>
            <el-table-column prop="scale" label="核准收集量(吨)"></el-table-column>
            <el-table-column prop="actualCount" label="已收集量(吨)"></el-table-column>
            <el-table-column prop="remain" label="剩余收集量(吨)"></el-table-column>
        </el-table>
    </div>
</template>
<script>
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/businessApi";

export default {
    name: 'tab2',
    components: { HcPagination, HcButton },
    props: {
        editData: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            tableData: [],
            loading: false,
            params: {
                enterpriseId: this.editData.enterpriseId,
            },
        }
    },
    methods: {
        // 获取table表格数据
        getSclStat(params) {
            this.loading = true; //请求时打开加载中
            server.sclStat(params).then((res) => {
                if (res.code == 200) {
                    this.loading = false; //成功关闭加载中

                    this.tableData = res.data.EnterpriseCount;
                    this.showChart(res.data);
                }
            })
                .catch((err) => {
                    setTimeout(() => {
                        //失败2秒后关闭加载中
                        this.loading = false;
                    }, 2000);
                    this.$message({
                        showClose: true,
                        message: err.message,
                        type: "warning",
                    });
                });
        },
        //table序号
        indexMethod(index) {
            return index + 1;
        },

        // 绘制折线图
        showChart(data) {
            var myChart = this.$echarts.init(document.getElementById("sclDiv"));
            let option = {
                tooltip: {
                    trigger: 'axis',
                },
                color: ['#00BFFF', '#5470C6', '#ec808d'],
                legend: {
                    show: true,
                    data: ['核准收集量(吨)', '已收集量(吨)', '剩余收集量(吨)'],
                    itemWidth: 30,
                    itemHeight: 10,
                    textStyle: {
                        color: 'rgba(147, 147, 147, 1)',
                        fontSize: 14,
                        padding: [0, 8, 0, 8]
                    }
                },
                grid: {
                    top: '15%',
                    left: '5%',
                    right: '5%',
                    bottom: '10%',
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgb(41,188,245)',
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: 'rgba(112, 151, 184, 1)',
                                fontSize: 12,
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        data: data.yearList,
                    }
                ],
                yAxis: [
                    {
                        name: "单位：吨",
                        nameTextStyle: {
                            color: "rgba(112, 151, 184, 1)",
                            fontSize: 12,
                            padding: [0, 60, 0, 0],
                            lineHeight: 40
                        },
                        type: 'value',
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: 'rgba(112, 151, 184, 1)',
                                fontSize: 14
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                    }
                ],
                series: [
                    {
                        name: '核准收集量(吨)',
                        type: 'line',
                        symbol: 'circle',
                        smooth: true,
                        showSymbol: true,
                        data: data.scaleList
                    },
                    {
                        name: '已收集量(吨)',
                        type: 'line',
                        symbol: 'circle',
                        smooth: true,
                        showSymbol: true,
                        data: data.actualList
                    },
                    {
                        name: '剩余收集量(吨)',
                        type: 'line',
                        symbol: 'circle',
                        smooth: true,
                        showSymbol: true,
                        data: data.remainList
                    },
                ]
            };
            myChart.setOption(option);

            //自适应大小
            window.addEventListener("resize", () => {
                if (myChart) {
                    myChart.resize();
                }
            });
        },
    },
}

</script>
<style  lang="less" scoped>
.tab2 {
    padding: 0 10px 0 20px;
    margin-bottom: 80px;

    .tabTit {
        padding-bottom: 16px;
    }

    ::v-deep .el-pagination {
        text-align: right !important;
        margin-top: 16px !important;
    }

}
</style>