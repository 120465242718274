<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
    </template>
    <template v-slot:center>
      <div class="detail">
        <div class="wfx">
          <img
            :src="
              editData.deviceStates == '1' && editData.deviceModel == '1'
                ? wfx50_zc
                : editData.deviceStates == '2' && editData.deviceModel == '1'
                ? wfx50_bj
                : editData.deviceStates == '3' && editData.deviceModel == '1'
                ? wfx50_gz
                : editData.deviceStates == '1' && editData.deviceModel == '2'
                ? wfx200_zc
                : editData.deviceStates == '2' && editData.deviceModel == '2'
                ? wfx200_bj
                : editData.deviceStates == '3' && editData.deviceModel == '2'
                ? wfx200_gz
                : editData.deviceStates == '1' && editData.deviceModel == '3'
                ? wfx1000_zc
                : editData.deviceStates == '2' && editData.deviceModel == '3'
                ? wfx1000_bj
                : editData.deviceStates == '3' && editData.deviceModel == '3'
                ? wfx1000_gz
                : null
            "
          />
        </div>
        <el-descriptions class="margin-top" :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 当前所属企业 </template>
            {{ editData.enterpriseName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 设备ID </template>
            {{ editData.deviceId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 设备名称 </template>
            {{ editData.deviceName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 设备版本 </template>
            {{ global.DeviceVersionMap.get(editData.deviceVersion) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 设备型号 </template>
            {{ global.DeviceModelMap.get(editData.deviceModel) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 废物代码 </template>
            {{ editData.harmWasteCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 危废类别 </template>
            {{ editData.categoryCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 危废类别名称 </template>
            {{ editData.categoryName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">状态</template>
            <span v-if="editData.deviceStates == '1'"
              ><el-tag type="success" size="medium">正常</el-tag></span
            >
            <span v-if="editData.deviceStates == '2'"
              ><el-tag type="warning" size="medium">报警</el-tag></span
            >
            <span v-if="editData.deviceStates == '3'"
              ><el-tag type="danger" size="medium">故障</el-tag></span
            >
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="echartMain">
        <div class="list">
          <div class="listTop">
            <div><i class="el-icon-s-data iconFin2"></i><span>信号</span></div>
            <div class="xinhaoImg">
              <img
                :src="
                  params.signal == '1'
                    ? xinhao
                    : params.signal == '2'
                    ? xinhao2
                    : params.signal == '3'
                    ? xinhao3
                    : params.signal == '4'
                    ? xinhao4
                    : null
                "
              />
            </div>
          </div>
          <div class="listCent">
            <span v-if="params.signal == '1'">强</span>
            <span v-if="params.signal == '2'">中</span>
            <span v-if="params.signal == '3'">弱</span>
            <span v-if="params.signal == '4'">无信号</span>
          </div>
        </div>
        <div class="list list2">
          <div class="listTop">
            <div>
              <i
                :class="{
                  'el-icon-warning iconErr': params.pour == '1',
                  'el-icon-success iconFin': params.pour == '0',
                }"
              ></i
              ><span>倾倒</span>
            </div>
            <div class="xinhaoImg">
              <img
                :src="
                  params.pour == '1'
                    ? qingdao2
                    : params.pour == '0'
                    ? qingdao
                    : null
                "
              />
            </div>
          </div>
          <div class="listCent">
            <span v-if="params.pour == '0'">未倾倒</span>
            <span v-if="params.pour == '1'">已倾倒</span>
          </div>
        </div>
        <div class="list list3">
          <div class="listTop">
            <div>
              <i
                :class="{
                  'el-icon-warning iconErr': !params.electricFlag,
                  'el-icon-success iconFin': params.electricFlag,
                }"
              ></i
              ><span>电量</span>
            </div>
            <div class="xinhaoImg">
              <img :src="params.electricFlag ? dianliang : dianliang2" />
            </div>
          </div>
          <div>
            <div class="progressData">{{ params.electric }}%</div>
            <el-progress
              :percentage="Number(params.electric)"
              :stroke-width="12"
              :show-text="false"
              :color="colorsE"
            ></el-progress>
          </div>
        </div>
        <div class="list">
          <div class="listTop">
            <div><i class="el-icon-location iconFin2"></i><span>gps</span></div>
            <div class="xinhaoImg"><img :src="gps" alt="" /></div>
          </div>
          <div class="listCent2">
            <div class="listCent2li">
              <span>可见卫星数量</span>
              <span class="liData">{{ params.gpsVisible }}个</span>
            </div>
            <div class="listCent2li">
              <span>定位卫星数</span>
              <span class="liData">{{ params.gpsPosition }}个</span>
            </div>
            <div class="listCent2li">
              <span>信噪比</span>
              <span class="liData">{{ params.gpsSnr }}dB</span>
            </div>
          </div>
        </div>
      </div>
      <div class="echartMain">
        <div class="list_two">
          <div class="listTop">
            <div>
              <i
                :class="{
                  'el-icon-warning iconErr': !params.temperatureFlag,
                  'el-icon-success iconFin': params.temperatureFlag,
                }"
              ></i
              ><span>温度</span>
            </div>
            <div class="xinhaoImg">
              <img :src="params.temperatureFlag ? wendu : wendu2" alt="" />
            </div>
          </div>
          <div class="listCent2">
            <div class="cent_num">{{ params.temperature }}℃</div>
            <div class="cen_num_scope">
              <!-- {{ editData.deviceThresholdInfo.temperatureThresholdMin }}℃ ~ {{
                                editData.deviceThresholdInfo.temperatureThresholdMax }}℃ -->
              ＜ {{ temperatureThresholdMax }} ℃
            </div>
          </div>
          <div class="progress">
            <div
              id="wenduDiv"
              style="width: 100%; height: 200px; padding-top: 0px"
            ></div>

            <!-- <div class="circleBox">
                            <el-progress type="circle" :stroke-width="22" width="180" :percentage="params.temperature"
                                :color="colorsT" stroke-linecap="square" :format="perFormat1" />
                        </div> -->
          </div>
        </div>
        <div class="list_two list_two_min">
          <div class="listTop">
            <div>
              <i class="el-icon-s-marketing iconFin2"></i><span>湿度</span>
            </div>
            <div class="xinhaoImg"><img :src="shidu" alt="" /></div>
          </div>
          <div class="listCent2">
            <div class="cent_num">{{ params.humidity }}%</div>
            <div class="cen_num_scope">
              <!-- {{ editData.deviceThresholdInfo.humidityThresholdMin }}% ~ {{
                                editData.deviceThresholdInfo.humidityThresholdMax }}% -->
            </div>
          </div>
          <div class="progress">
            <div
              id="shiduDiv"
              style="width: 100%; height: 200px; padding-top: 0px"
            ></div>

            <!-- <div class="circleBox">
                            <el-progress type="circle" :stroke-width="22" width="180" :percentage="params.humidity"
                                :color="colorsH" stroke-linecap="square" :format="perFormat2" />
                        </div> -->
          </div>
        </div>
        <div class="list_two">
          <div class="listTop">
            <div>
              <i
                :class="{
                  'el-icon-warning iconErr': !params.weightFlag,
                  'el-icon-success iconFin': params.weightFlag,
                }"
              ></i
              ><span>重量</span>
            </div>
            <div class="xinhaoImg">
              <img :src="params.weightFlag ? zhongliang : zhongliang2" alt="" />
            </div>
          </div>
          <div class="listCent2">
            <div class="cent_num">{{ params.weight }}kg</div>
            <div class="cen_num_scope">
              <!-- {{ editData.deviceThresholdInfo.weightThresholdMin }}kg ~ {{
                                editData.deviceThresholdInfo.weightThresholdMax }}kg -->
              ＜ {{ weightThresholdMax }} kg
            </div>
          </div>
          <div class="circleBox">
            <img
              v-if="Number(params.weight) > Number(weightThresholdMax)"
              :src="wfzl_yc"
              alt=""
              class="bgstyle"
            />
            <span
              v-if="Number(params.weight) > Number(weightThresholdMax)"
              class="yichang"
              >异常</span
            >
          </div>
          <!-- <div class="circleBox">
                        <img v-if="Number(params.weight) < Number(weightThresholdMin)" :src="wfzl_yc" alt=""
                            class="bgstyle">
                        <span v-if="Number(params.weight) < Number(weightThresholdMin)" class="yichang">异常</span>
                    </div> -->
          <div class="circleBox">
            <!-- <img v-if="Number(weightThresholdMin) < Number(params.weight) && Number(params.weight) < Number(weightThresholdMax)"
                            :src="wfzl_zc" alt="" class="bgstyle">
                        <span
                            v-if="Number(weightThresholdMin) < Number(params.weight) && Number(params.weight) < Number(weightThresholdMax)"
                            class="zhengchang">正常</span> -->
            <img
              v-if="Number(params.weight) < Number(weightThresholdMax)"
              :src="wfzl_zc"
              alt=""
              class="bgstyle"
            />
            <span
              v-if="Number(params.weight) < Number(weightThresholdMax)"
              class="zhengchang"
              >正常</span
            >
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <!-- <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
            <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton> -->
    </template>
  </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";

import gps from "../../../assets/gps.png";
import shidu from "../../../assets/shidu.png";

import xinhao from "../../../assets/xinhao.png";
import xinhao2 from "../../../assets/xinhao2.png";
import xinhao3 from "../../../assets/xinhao3.png";
import xinhao4 from "../../../assets/xinhao4.png";

import qingdao from "../../../assets/qingdao.png";
import qingdao2 from "../../../assets/qingdao2.png";

import dianliang from "../../../assets/dianliang.png";
import dianliang2 from "../../../assets/dianliang2.png";

import wendu from "../../../assets/wendu.png";
import wendu2 from "../../../assets/wendu2.png";

import zhongliang from "../../../assets/zhongliang.png";
import zhongliang2 from "../../../assets/zhongliang2.png";

import wfx50_zc from "../../../assets/wfx50_zc.png"; //危废箱正常
import wfx50_gz from "../../../assets/wfx50_gz.png"; //危废箱故障
import wfx50_bj from "../../../assets/wfx50_bj.png"; //危废箱报警

import wfx200_zc from "../../../assets/wfx200_zc.png"; //危废箱正常
import wfx200_gz from "../../../assets/wfx200_gz.png"; //危废箱故障
import wfx200_bj from "../../../assets/wfx200_bj.png"; //危废箱报警

import wfx1000_zc from "../../../assets/wfx1000_zc.png"; //危废箱正常
import wfx1000_gz from "../../../assets/wfx1000_gz.png"; //危废箱故障
import wfx1000_bj from "../../../assets/wfx1000_bj.png"; //危废箱报警

import wfzl_zc from "../../../assets/wfzl_zc.png"; //重量正常
import wfzl_yc from "../../../assets/wfzl_yc.png"; //重量异常

import * as server from "@/api/deviceApi";

export default {
  name: "check",
  components: {
    HcDiaglePage,
    HcButton,
  },
  data() {
    return {
      dialogType: "",
      activeName2: "tab1",

      //不会变的
      gps: gps,
      shidu: shidu,

      //会变的
      xinhao: xinhao,
      xinhao2: xinhao2,
      xinhao3: xinhao3,
      xinhao4: xinhao4,
      qingdao: qingdao,
      qingdao2: qingdao2,
      dianliang: dianliang,
      dianliang2: dianliang2,
      wendu: wendu,
      wendu2: wendu2,
      zhongliang: zhongliang,
      zhongliang2: zhongliang2,
      // 50
      wfx50_zc: wfx50_zc,
      wfx50_gz: wfx50_gz,
      wfx50_bj: wfx50_bj,
      // 200
      wfx200_zc: wfx200_zc,
      wfx200_gz: wfx200_gz,
      wfx200_bj: wfx200_bj,
      // 1000
      wfx1000_zc: wfx1000_zc,
      wfx1000_gz: wfx1000_gz,
      wfx1000_bj: wfx1000_bj,

      // 重量背景图
      wfzl_zc: wfzl_zc,
      wfzl_yc: wfzl_yc,
      params: {
        deviceNo: "",
        deviceId: "",
        deviceName: "",
        enterpriseId: "",
        weight: "", //重量
        electric: "", //电量
        humidity: "", //湿度
        temperature: "", //温度
        signal: "", //信号
        pour: "", //倾倒
        gpsVisible: "", //可见卫星数
        gpsPosition: "", //定位卫星数
        gpsSnr: "", //信噪比

        weightFlag: true, //重量是否报警
        electricFlag: true, //电量是否报警
        temperatureFlag: true, //温度是否报警
      },

      //阈值大小
      temperatureThresholdMax: 0,
      weightThresholdMin: 0,
      weightThresholdMax: 0,

      colorsE: [],
      /* colorsT: [],
             colorsH: [], */

      editData: {},
    };
  },
  methods: {
    showDiagle(data) {
      this.$refs.hcDiaglePage.showLog = true;
      // 判断弹框是编辑页面还是新增页面或者是详情页面

      //当前设备
      /* this.params.enterpriseId = data.enterpriseId;
            this.params.deviceNo = data.deviceNo;
            this.params.deviceId = data.deviceId;
            this.params.deviceName = data.deviceName; */

      //let newParams = { 'deviceId': this.editData.deviceId, 'deviceModel': data.deviceModel };
      //this.queryDevicePerformance(newParams);
    },

    perFormat1(percentage) {
      return "温度" + "\n" + this.params.temperature + "℃";
    },

    perFormat2(percentage) {
      return "湿度" + "\n" + this.params.humidity + "%";
    },

    // 查询设备详细信息
    queryDeviceDetail(params) {
      server.deviceDetail(params).then((res) => {
        if (res.code == 200) {
          this.editData = res.data;
          let newParams = {
            deviceId: this.editData.deviceId,
            deviceModel: this.editData.deviceModel,
          };
          this.queryDevicePerformance(newParams);
        }
      });
    },

    //TODO 后期要做成实时的
    //查询设备性能
    queryDevicePerformance(newParams) {
      server
        .performanceInfo(newParams)
        .then((res) => {
          if (res.code == 200) {
            //阈值标准
            this.temperatureThresholdMax = Number(
              res.data.deviceThresholdInfo.temperatureThresholdMax
            );
            this.weightThresholdMax = Number(
              res.data.deviceThresholdInfo.weightThresholdMax
            );
            this.weightThresholdMin = Number(
              res.data.deviceThresholdInfo.weightThresholdMin
            );

            this.maxtemp = Number(
              res.data.deviceThresholdInfo.temperatureThresholdMax
            );
            //this.mintemp = Number(res.data.deviceThresholdInfo.temperatureThresholdMin);

            let minE = Number(
              res.data.deviceThresholdInfo.electricThresholdMin
            );
            let maxE = Number(
              res.data.deviceThresholdInfo.electricThresholdMax
            );
            this.colorsE = [
              { color: "#F56C6C", percentage: minE },
              { color: "#00BFFF", percentage: maxE },
              { color: "#F56C6C", percentage: 100 },
            ];

            /* let minT = Number(res.data.deviceThresholdInfo.temperatureThresholdMin);
                    let maxT = Number(res.data.deviceThresholdInfo.temperatureThresholdMax);
                    this.colorsT = [
                        { color: '#F56C6C', percentage: minT },
                        { color: '#00BFFF', percentage: maxT },
                        { color: '#F56C6C', percentage: 100 }
                    ]; */

            /* let minH = Number(res.data.deviceThresholdInfo.humidityThresholdMin);
                    let maxH = Number(res.data.deviceThresholdInfo.humidityThresholdMax);
                    this.colorsH = [
                        { color: '#F56C6C', percentage: minH },
                        { color: '#00BFFF', percentage: maxH },
                        { color: '#F56C6C', percentage: 100 }
                    ]; */

            this.params.weight = res.data.weight;
            this.params.electric = res.data.electric;
            this.params.humidity = res.data.humidity;
            this.params.temperature = res.data.temperature;
            this.params.signal = res.data.signal;
            this.params.pour = res.data.pour;
            this.params.gpsVisible = res.data.gpsVisible;
            this.params.gpsPosition = res.data.gpsPosition;
            this.params.gpsSnr = res.data.gpsSnr;

            this.params.weightFlag = res.data.weightFlag;
            this.params.electricFlag = res.data.electricFlag;
            this.params.temperatureFlag = res.data.temperatureFlag;
            this.$nextTick(() => {
              this.wenduFun();
              this.shiduFun();
            });
          }
        })
        .catch((err) => {
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });
    },

    //温度echart
    wenduFun() {
      var myChart1 = this.$echarts.init(document.getElementById("wenduDiv"));
      let option = {
        series: [
          {
            name: "Pressure",
            type: "gauge",
            min: -20,
            max: 80,
            splitNumber: 10, //刻度间隔10
            progress: {
              show: true, //显示动画
            },
            radius: "100%",
            axisLine: {
              lineStyle: {
                width: 10, //仪表盘的宽度
              },
            },
            // 长的刻度线
            splitLine: {
              length: 8,
              distance: 2,
              lineStyle: {
                width: 1,
                color: "#BBBBBB",
              },
            },
            pointer: {
              icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z", //仪表盘 指针 样式
              width: 4, ///仪表盘 指针 针宽度
              offsetCenter: [0, "0%"],
              itemStyle: {
                color: "inherit",
              },
            },
            // 仪表盘的刻度线
            axisTick: {
              distance: 0,
              length: 0,
              lineStyle: {
                color: "#000",
                width: 1,
              },
            },
            // 刻度数
            axisLabel: {
              distance: 14,
              color: "#BBBBBB",
              fontSize: 16,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 16,
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              fontSize: 0,
              offsetCenter: [0, "80%"],
              formatter: "{value} dBmV",
              color: "inherit",
            },
            data: [
              {
                value: Number(this.params.temperature),
              },
            ],
          },
        ],
      };
      var ranValue = Number(this.params.temperature);
      console.log(this.deviceThresholdInfo, "hhhhhh");
      if (this.deviceThresholdInfo) {
        var maxtemp = Number(this.deviceThresholdInfo.temperatureThresholdMax);
        var mintemp = Number(this.deviceThresholdInfo.temperatureThresholdMin);
      }

      //console.log(ranValue, maxtemp, mintemp, '温度')
      // option.series[0].data[0].value = this.params.temperature;
      if (ranValue > maxtemp) {
        option.series[0].detail.color = "#F56C6C";
        option.series[0].color = "#F56C6C";
      } else {
        option.series[0].detail.color = "#00BFFF";
        option.series[0].color = "#00BFFF";
      }
      myChart1.setOption(option, true);

      //自适应大小
      window.addEventListener("resize", () => {
        if (myChart1) {
          myChart1.resize();
        }
      });
    },
    //湿度echart
    shiduFun() {
      var myChart2 = this.$echarts.init(document.getElementById("shiduDiv"));
      let option = {
        series: [
          {
            name: "Pressure",
            type: "gauge",
            min: 0,
            max: 100,
            splitNumber: 10, //刻度间隔10
            progress: {
              show: true, //显示动画
            },
            radius: "100%",
            axisLine: {
              lineStyle: {
                width: 10, //仪表盘的宽度
              },
            },
            // 长的刻度线
            splitLine: {
              length: 8,
              distance: 2,
              lineStyle: {
                width: 1,
                color: "#BBBBBB",
              },
            },
            pointer: {
              show: true,
              showAbove: true,
              icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z", //仪表盘 指针 样式
              width: 4, ///仪表盘 指针 针宽度
              offsetCenter: [0, "0%"],
              itemStyle: {
                color: "inherit",
              },
            },
            // 仪表盘的刻度线
            axisTick: {
              distance: 0,
              length: 0,
              lineStyle: {
                color: "#000",
                width: 1,
              },
            },
            // 刻度数
            axisLabel: {
              distance: 14,
              color: "#BBBBBB",
              fontSize: 16,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 16,
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              fontSize: 0,
              offsetCenter: [0, "80%"],
              formatter: "{value} dBmV",
              color: "inherit",
            },
            data: [
              {
                value: Number(this.params.humidity),
              },
            ],
          },
        ],
      };
      var ranValue = Number(this.params.humidity);
      if (this.deviceThresholdInfo) {
        var maxshidu = Number(this.deviceThresholdInfo.humidityThresholdMax);
        var minshidu = Number(this.deviceThresholdInfo.humidityThresholdMin);
      }

      // console.log(ranValue, maxshidu, minshidu, '湿度')
      // option.series[0].data[0].value = this.params.humidity;
      // if (ranValue < minshidu) {
      //     option.series[0].detail.color = "#F56C6C";
      //     option.series[0].color = "#F56C6C";
      // } else if (ranValue > maxshidu) {
      //     option.series[0].detail.color = "#F56C6C";
      //     option.series[0].color = "#F56C6C";
      // }
      // else {
      option.series[0].detail.color = "#00BFFF";
      option.series[0].color = "#00BFFF";
      // }
      myChart2.setOption(option);

      //自适应大小
      window.addEventListener("resize", () => {
        if (myChart2) {
          myChart2.resize();
        }
      });
    },
    resetForm() {
      this.params = {
        deviceNo: "",
        deviceId: "",
        deviceName: "",
        enterpriseId: "",
        weight: "", //重量
        electric: "", //电量
        humidity: "", //湿度
        temperature: "", //温度
        signal: "", //信号
        pour: "", //倾倒
        gpsVisible: "", //可见卫星数
        gpsPosition: "", //定位卫星数
        gpsSnr: "", //信噪比

        weightFlag: true, //重量是否报警
        electricFlag: true, //电量是否报警
        temperatureFlag: true, //温度是否报警
      };

      this.colorsE = [];
      /* this.colorsT = [];
            this.colorsH = []; */

      this.deviceThresholdInfo = {};
      this.editData = {};

      this.$refs.hcDiaglePage.close();
    },
  },
};
</script>

<style scoped lang="less">
.detail {
  display: flex;
  margin-top: 20px;

  ::v-deep .el-descriptions {
    width: 100%;
  }

  .wfx {
    width: 230px;
    height: 236px;
    border: 1px solid #dbdbdb;
    text-align: center;

    img {
      margin-top: 20px;
    }
  }
}

.echartMain {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .list {
    flex: 1;
    height: 140px;
    border: 1px solid #dbdbdb;
    padding: 20px;

    .iconFin {
      color: #67c23a;
      margin-right: 8px;
    }

    .iconFin2 {
      color: #d6d6d6;
      margin-right: 8px;
    }

    .iconErr {
      color: #f56c6c;
      margin-right: 8px;
    }

    .listTop {
      display: flex;
      justify-content: space-between;

      .xinhaoImg {
        width: 64px;
        height: 64px;
      }
    }

    .listCent {
      font-size: 32px;
      color: #666666;
    }

    .listCent2 {
      .listCent2li {
        display: flex;
        justify-content: space-between;
        width: 140px;
        height: 28px;
        line-height: 28px;

        .liData {
          color: #666666;
          font-weight: 500;
        }
      }
    }

    .progressData {
      font-size: 24px;
    }
  }

  .list2 {
    margin: 0 20px;
  }

  .list3 {
    margin-right: 20px;
  }

  .list_two {
    flex: 1;
    height: 320px;
    border: 1px solid #dbdbdb;
    padding: 20px;

    .iconFin {
      color: #67c23a;
      margin-right: 8px;
    }

    .iconFin2 {
      color: #d6d6d6;
      margin-right: 8px;
    }

    .iconErr {
      color: #f56c6c;
      margin-right: 8px;
    }

    .listTop {
      display: flex;
      justify-content: space-between;

      .xinhaoImg {
        width: 64px;
        height: 64px;
      }
    }

    .listCent2 {
      .cent_num {
        font-size: 32px;
        color: #666666;
      }

      .cen_num_scope {
        font-size: 14px;
        color: #bbbbbb;
      }
    }
  }

  .list_two_min {
    margin: 0 20px;
  }
}

.circleBox {
  width: 100%;
  // height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .yichang {
    position: absolute;
    color: #f56c6c;
    font-size: 16px;
  }

  .zhengchang {
    position: absolute;
    color: #00bfff;
    font-size: 16px;
  }

  ::v-deep .overView .waste_production .body {
    height: 100% !important;
  }
}

::v-deep .el-descriptions-row th {
  width: 10vw;
}
</style>
