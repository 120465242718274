<template>
  <!--报警管理-报警列表 -->
  <div class="alarmList">
    <HcListMain>
      <template v-slot:content_table>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="满负荷" name="tab1">
            <tabPage :activeName="activeName" ref="tab1" :openDetail="openDetail" :openEditFun="openEdit"></tabPage>
          </el-tab-pane>
          <el-tab-pane label="无废预警" name="tab2">
            <tabPage :activeName="activeName" ref="tab2" :openDetail="openDetail" :openEditFun="openEdit"></tabPage>
          </el-tab-pane>
          <el-tab-pane label="超期预警" name="tab3">
            <tabPage :activeName="activeName" ref="tab3" :openDetail="openDetail" :openEditFun="openEdit"></tabPage>
          </el-tab-pane>
          <el-tab-pane label="减量预警" name="tab4">
            <tabPage :activeName="activeName" ref="tab4" :openDetail="openDetail" :openEditFun="openEdit"></tabPage>
          </el-tab-pane>
          <el-tab-pane label="故障预警" name="tab5">
            <tabPage :activeName="activeName" ref="tab5" :openDetail="openDetail" :openEditFun="openEdit"></tabPage>
          </el-tab-pane>
          <el-tab-pane label="电量预警" name="tab6">
            <tabPage :activeName="activeName" ref="tab6" :openDetail="openDetail" :openEditFun="openEdit"></tabPage>
          </el-tab-pane>
          <el-tab-pane label="倾倒预警" name="tab7">
            <tabPage :activeName="activeName" ref="tab7" :openDetail="openDetail" :openEditFun="openEdit"></tabPage>
          </el-tab-pane>
          <el-tab-pane label="高温预警" name="tab8">
            <tabPage :activeName="activeName" ref="tab8" :openDetail="openDetail" :openEditFun="openEdit"></tabPage>
          </el-tab-pane>
          <el-tab-pane label="申报预警" name="tab9">
            <tabWarning :activeName="activeName" ref="tab9" :openDetail="openDetail" :openEditFun="openEdit"></tabWarning>
          </el-tab-pane>
          <el-tab-pane label="合同到期" name="tab10">
            <tabContract :activeName="activeName" ref="tab10" :openDetail="openDetail" :openEditFun="openEdit"></tabContract>
          </el-tab-pane>
        </el-tabs>
      </template>
    </HcListMain>
    <!-- 处理、详情操作 -->
    <handleDetail ref="handleDetail" :editData="editData"></handleDetail>
  </div>
</template>
  
<script>
import HcListMain from "@/components/HcListMain/index.vue";
import tabPage from './pages/tabPage.vue';
import tabWarning from "./pages/tabWarning.vue";
import tabContract from './pages/tabContract.vue'
import handleDetail from './components/handleDetail.vue'
export default {
  name: 'alarmList',
  components: {
    HcListMain,
    tabPage,
    tabWarning,
    tabContract,
    handleDetail
  },
  data() {
    return {
      activeName: 'tab1',
      detailTit: '',
      editData: ''
    }
  },
  provide() {
    return {
      title: () => this.detailTit,
    };
  },
  methods: {
    handleClick(val) {
      this.activeName = 'tab' + (Number(val.index) + 1);
      console.log(this.activeName, 'activeName')
      if (this.activeName == 'tab1') {
        this.$nextTick(() => {
          this.$refs.tab1.queryList()
        })

      } else if (this.activeName == 'tab2') {
        this.$nextTick(() => {
          this.$refs.tab2.queryList()
        })
      } else if (this.activeName == 'tab3') {
        this.$nextTick(() => {
          this.$refs.tab3.queryList()
        })
      } else if (this.activeName == 'tab4') {
        this.$nextTick(() => {
          this.$refs.tab4.queryList()
        })
      } else if (this.activeName == 'tab5') {
        this.$nextTick(() => {
          this.$refs.tab5.queryList()

        })
      } else if (this.activeName == 'tab6') {
        this.$nextTick(() => {
          this.$refs.tab6.queryList()

        })
      } else if (this.activeName == 'tab7') {
        this.$nextTick(() => {
          this.$refs.tab7.queryList()
        })
      } else if (this.activeName == 'tab8') {
        this.$nextTick(() => {
          this.$refs.tab8.queryList()
        })
      } else if (this.activeName == 'tab9') {
        this.$nextTick(() => {
          this.$refs.tab9.queryList()
        })
      } else if (this.activeName == 'tab10') {
        this.$nextTick(() => {
          this.$refs.tab10.queryList()

        })
      }
    },
    openDetail(data) {
      this.detailTit = '详情'
      this.editData = data;
      this.$refs.handleDetail.showDiagle(data);
    },
    openEdit(data) {
      this.editData = data;
      this.detailTit = '处理';
      this.$refs.handleDetail.showDiagle(data);
    }
  },
  mounted() {
    // this.handleClick({ 'index': 0 });
    this.$nextTick(() => { //默认进来加载tab1，并调用接口
      this.$refs.tab1.getList({
        warnType: '1',
        pageSize: 20,
        pageNum: 1,
      })
    })
  }
}
</script>
  
<style scoped lang="less">
.alarmList {
  min-height: 500px;
  background: #ffffff;

  ::v-deep .list_main .content {
    margin-top: 0px !important;
  }

  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item {
    background-color: #f3f8ff !important;
    margin: 0 !important;
    border-radius: 0 !important;
    color: #808080;
  }

  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    color: #00bfff !important;
    background-color: #fff !important;
  }

  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: 1px solid #e4e7ed !important;
    width: 100%;
    background-color: #f3f8ff !important;
  }
}
</style>
  