import {httpRequest,baseUrl} from '../utils/request'
export {
    list,approveWait,approveCheck,approveInStorage,remove,fiveMustCheckResult,
}

// 查询联单列表
const list=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/sheet/list',
       method:'post' ,
       params
    })
)

// 查询联单五必查信息
const fiveMustCheckResult=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/sheet/fiveMustCheckResult',
       method:'post' ,
       params
    })
)

// 审批待审核联单信息
const approveWait=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/sheet/approveWait',
       method:'post' ,
       params
    })
)

// 审批待五必查联单信息
const approveCheck=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/sheet/approveCheck',
       method:'post' ,
       params
    })
)

// 审批待入库联单信息
const approveInStorage=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/sheet/approveInStorage',
       method:'post' ,
       params
    })
)

// 删除联单信息
const remove=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/sheet/remove',
       method:'post' ,
       params
    })
)
