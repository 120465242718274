<template>
    <!-- 联单详情中  收集企业  信息展示 -->
    <div class="enterpriseInfoSJ">
        <div class="tabTit">收集企业信息</div>
        <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
                <template slot="label">
                    收集企业名称
                </template>
                {{ enterpriseDeailObj.enterpriseName }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    所属区域
                </template>
                {{ enterpriseDeailObj.townAddress }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    详细地址
                </template>
                {{ enterpriseDeailObj.enterpriseAddress }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    联系人
                </template>
                {{ enterpriseDeailObj.contactor }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                   联系电话
                </template>
                {{ enterpriseDeailObj.telephone }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    接收量(kg)
                </template>
                {{ editData.receiveWeight}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    收处时间
                </template>
                {{ editData.storeTime}}
            </el-descriptions-item>
        </el-descriptions>
    </div>
</template>
<script>
import * as server from '@/api/planApi';

export default {
    name: 'enterpriseInfoSJ',
    data() {
        return {
            enterpriseDeailObj: {}
        }
    },
    props: {
        enterpriseId: {
            type: String,
            default: '' 
        },
        editData: {
            type: String,
            default: '' 
        }
    },
    methods: {
        getEnterpriseDeatil() {
            if(this.enterpriseId == ''){

            }else{
                server.enterpriseDetail({
                    enterpriseId: this.enterpriseId,
                }).then((res) => {
                    this.enterpriseDeailObj = res.data;
                })
            }
        },
    },
    mounted() {
        this.getEnterpriseDeatil();
    },
    watch: {
        enterpriseId: function () {
            this.getEnterpriseDeatil();
        },
    },
}
</script>

<style type="less" scoped>
.tabTit {
    padding: 16px 0px;
}
::v-deep .el-descriptions-row th {
    width: 10vw;
}

</style>