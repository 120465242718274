<template>
  <div class="list_main" ref="list_main">
    <div class="header">
      <div>
        <slot name="header_from"> </slot>
      </div>
      <div class="header_button">
        <slot name="header_button"> </slot>
      </div>
    </div>
    <div class="content" ref="content">
      <div class="content_button">
        <slot name="content_button"></slot>
      </div>
      <slot name="content_table"></slot>
      <div class="content_pag">
        <slot name="content_pag"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "listMain",
  data() {
    return {};
  },
  mounted() {
    this.getHeight();
  },
  methods: {
    getHeight() {
      //动态计算中间内容部分高度
      let bodyHeight = document.body.clientHeight;
      let contentHeight = bodyHeight - 188;
      console.log(bodyHeight, contentHeight, "height");
      this.$refs.list_main.style.height = contentHeight + "px";
      sessionStorage.setItem("listMainHeight", contentHeight);
    },
  },
};
</script>
<style lang="less" scoped>
.list_main {
  padding: 20px 20px;
  // display: flex;
  position: relative;

  .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    ::v-deep .el-input--small .el-input__inner {
      width: 205px;
    }

    .header_button {
      display: flex;
    }
  }

  // .content {
  // margin-top: 20px;
  // }

  .content_button {
    display: flex;
  }

  ::v-deep .el-button {
    margin-bottom: 18px;
    margin-top: 18px;
  }

  ::v-deep .el-table thead th {
    background-color: #eeeeee;
  }

  ::v-deep .el-table .el-table__cell {
    padding: 0px;
  }

  ::v-deep .el-table thead {
    height: 50px;
  }

  ::v-deep .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #dbdbdb;
  }

  ::v-deep .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #eff0f2;
    border-right: 1px solid #dbdbdb;
  }

  .content_pag {
    text-align: right;
    margin-top: 20px;
  }
}
</style>
