<template>
  <div class="menu_box">
    <div style=" cursor:pointer" @click="isOpen" :class="isCollapse == false ? 'collapse_icon' : 'collapse_icon2'">
      <i
        :class="{ 'el-icon-s-fold': isCollapse == false ? true : false, 'el-icon-s-unfold': isCollapse == true ? true : false }"></i>
    </div>

    <el-menu :default-active="String(activeNav)" router class="el-menu-vertical-demo" :collapse="isCollapse"
      @select="handleSelect">
      <template v-for="(item, index) in menu">
        <!--没有子菜单-->
        <template v-if="item.children && item.children.length === 0">
          <div class="noChild">
            <el-menu-item :index="item.path">
              <template>
                <i :class="item.icon"></i>
                <span slot="title"> {{ item.title }}</span>
              </template>
            </el-menu-item>
          </div>

        </template>
        <!-- 带有子菜单 -->
        <el-submenu :index="index+''" v-else>
          <template slot="title">
            <i :class="[item.icon, index == selectIndex && isCollapse == true ? 'select_menu' : '']"></i>
            <span slot="title"> {{ item.title }}</span>
          </template>
          <template v-for="child in item.children">
            <el-menu-item :key="child.path" :index="child.path">
              {{ child.title }}
            </el-menu-item>
          </template>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import * as serve from '../../api/loginApi'
export default {
  name: 'HomeView',
  props: {
    activeNav: {
      type: String
    }
  },
  data() {
    return {
      isCollapse: false,
      menu: [],
      selectIndex: ''
    }
  },
  components: {

  },
  mounted() {
    if (window.sessionStorage.getItem('menu')) {
      this.menu = JSON.parse(window.sessionStorage.getItem('menu'))//从sessionStorage拿出菜单
      this.menu.map((item, index) => {
        item.children.map((item2) => {
          if (this.$route.path == item2.path) {
            this.selectIndex = index
          }
        })
      })
    } else {
      serve.getLoginByToken().then((res) => {
        this.menu = res.data.menuInfo;
        window.sessionStorage.setItem('userInfo', JSON.stringify(res.data.userBo))
        console.log(res.data.userBo, 'userBo')
        this.menu.map((item, index) => {
          item.children.map((item2) => {
            if (this.$route.path == item2.path) {
              this.selectIndex = index
            }
          })
        })
      })
    }

    console.log(this.$route.path, 'route')
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // 选中菜单
    handleSelect(key, keyPath) {
      console.log(key, keyPath, 'hello');
      // this.menu.map((item, index) => {
      //   item.children.map((item2) => {
      //     if (key == item2.path) {
      //       console.log(index)
      //       this.selectIndex = index
      //     }
      //   })
      // })
    },
    isOpen() { //判断左侧栏是否展开或收缩
      if (this.isCollapse == false) {
        this.isCollapse = true
      } else {
        this.isCollapse = false
      }
    },
  },
  watch: {
    $route: function (newItem, oldItem) {
      console.log(newItem, 'newItem')
      if (newItem.path == '/home') {
        this.selectIndex = ''
      } else {
        if (window.sessionStorage.getItem('menu')) {

          this.menu.map((item, index) => {
            item.children.map((item2) => {
              if (this.$route.path == item2.path) {
                this.selectIndex = index
              }
            })
          })

        } else {
          serve.getLoginByToken().then((res) => {
            this.menu = res.data.menuInfo;
            window.sessionStorage.setItem('userInfo', JSON.stringify(res.data.userBo))
            this.menu.map((item, index) => {
              item.children.map((item2) => {
                if (this.$route.path == item2.path) {
                  this.selectIndex = index
                }
              })
            })
          })
        }
      }

    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  background-color: #fff;
  margin-top: 60px;
}

::v-deep .el-menu-vertical-demo {
  background-color: #fff;
  border: 0px;
  padding: 0;
  margin-top: 60px;
}

::v-deep .el-menu-item.is-active {
  background: rgba(0, 191, 255, 0.2);
  background: rgba(0, 191, 255, 0.2);
  color: #00BFFF;
  border-left: 5px solid #00BFFF;
  padding-left: 36px !important;
}


::v-deep .el-menu-item {
  color: #808080;
}

.collapse_icon {
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #eeeeee;
  text-align: center;
  position: fixed;
  top: 60px;
  z-index: 99;
  width: 200px;
  background-color: #fff;
  transition: all .2s;
}

.noChild {

  // padding-left: 15px !important;
  ::v-deep .el-menu-item.is-active {
    background: rgba(0, 191, 255, 0.2);
    background: rgba(0, 191, 255, 0.2);
    color: #00BFFF;
    border-left: 5px solid #00BFFF;
    padding-left: 15px !important;
  }

}

.collapse_icon2 {
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #eeeeee;
  text-align: center;
  position: fixed;
  top: 60px;
  z-index: 99;
  width: 60px;
  background-color: #fff;
  transition: all .2s;
}

::v-deep .el-submenu__title {
  color: #808080;
  // padding: 0 30px 0 0!important;
}


.select_menu {
  color: #00BFFF;
}
</style>
