import {httpRequest,baseUrl} from '../utils/request'
export {
    list,batchFix,handleWarn,allDeviceList,
    warnQuantityStatistics,queryDeviceWarnRank,queryWarnTypeStatistics,queryDeviceWarnAnalysis
}

// 查询报警列表
const list=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/warn/warnList',
       method:'post' ,
       params
    })
)

// 一键处理告警
const batchFix=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/warn/batchHandleWarn',
        method:'post' ,
        params
     })
)

// 单个处理告警
const handleWarn=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/warn/handleWarn',
        method:'post' ,
        params
     })
)

// 报警数量统计
const warnQuantityStatistics=()=>(
    httpRequest({
        url:baseUrl+'/cloud/warn/warnQuantityStatistics',
        method:'post' ,
     })
)

// 设备报警次数排行
const queryDeviceWarnRank=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/warn/queryDeviceWarnRank',
        method:'post' ,
        params
     })
)

//报警类型统计
const queryWarnTypeStatistics=()=>(
    httpRequest({
        url:baseUrl+'/cloud/warn/queryWarnTypeStatistics',
        method:'post' ,
     })
)

//设备报警分析
const queryDeviceWarnAnalysis=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/warn/queryDeviceWarnAnalysis',
        method:'post' ,
        params
     })
)

// 查询设备下拉框
const allDeviceList=()=>(
    httpRequest({
       url:baseUrl+'/cloud/device/allDeviceList',
       method:'post' ,
    })
)
