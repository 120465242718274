<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm()"></i>
    </template>
    <template v-slot:center>
      <div class="card_tit">出库基本信息</div>
      <div class="tab1">
        <el-descriptions class="margin-top" :column="2" border>
          <el-descriptions-item>
            <template slot="label">
              出库编号
            </template>
            {{ editData.outboundInfoId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              危废类别
            </template>
            {{ editData.categoryCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              废物代码
            </template>
            {{ editData.harmWasteCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              废物名称
            </template>
            {{ editData.harmWasteName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              出库量(吨)
            </template>
            {{ editData.receiveWeight }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              出库时间
            </template>
            {{ editData.outboundTime }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              处置企业名称
            </template>
            {{ editData.disposalEnterpriseName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              处置类型
            </template>
            {{ editData.disposalType == '1' ? '焚烧' : editData.disposalType == '2' ? '填埋' : editData.disposalType ==
              '3' ? '利用' : null }}
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="card_tit2">出库明细</div>
      <el-table :data="tableData" border style="width: 100%" :height="tableHeight">
        <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
        <el-table-column prop="stockInfoId" label="入库编号" width="200"></el-table-column>
        <el-table-column label="联单编号" width="200">
          <template slot-scope="scope">
            <div @click="goSheet(scope.row)" class="deviceIdStyle">{{ scope.row.sheetInfoId }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="producterEnterpriseName" label="产废企业名称"></el-table-column>
        <el-table-column prop="categoryCode" label="危废类别" width="150"></el-table-column>
        <el-table-column prop="harmWasteCode" label="废物代码" width="150"></el-table-column>
        <el-table-column prop="harmWasteName" label="废物名称" width="150"></el-table-column>
        <el-table-column prop="receiveWeight" label="入库量(吨)" width="100"></el-table-column>
        <el-table-column prop="storeTime" label="入库时间" width="180"></el-table-column>
      </el-table>
      <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.pageNum"
        :page-size="params.pageSize" :total="params.total" class="pagination">
      </HcPagination>
    </template>
    <template v-slot:footer>

      <HcButton :type="'cancel'" size="small" @click="resetForm()">取消</HcButton>
      <HcButton :type="'primary'" size="small" @click="resetForm()">确定</HcButton>
    </template>
  </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import * as server from '@/api/stockApi';

export default {
  name: "editDiagle",
  props: {
    editData: {
      type: Object,
      default: null
    }
  },

  components: {
    HcDiaglePage,
    HcButton,
    HcPagination
  },
  mounted() {
  },

  data() {
    return {
      mainHeight: null,//中间高度
      tableHeight: null,//表格高度
      tableData: [],
      enterpriseName: [],
      ruleForm: {
        pageSize: 20,
        pageNum: 1,
        categoryCode: "",//危废类别
        harmWasteCode: "",//废物代码
        harmWasteName: "",//废物名称
        receiveWeight: "",//出库量
        outboundTime: "",//出库时间
        disposalEnterpriseName: "",//处置企业名称
        sheetInfoId: "",//联单编号
        outboundInfoId: "",//出库编号
        disposalEnterpriseId: "",//处置企业id

      },
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        sheetInfoId: '',
      },
      rules: {
        outboundTime: [
          { required: true, message: "选择出库时间", trigger: "change" },
        ],
        disposalEnterpriseName: [
          { required: true, message: "请选择处置企业名称", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1
    },
    handleSizeChange(data) {
      this.params.pageSize = data//每页多少条
      this.getList(this.params)//重新请求获取table的接口
    },
    handleCurrentChange(data) {
      this.params.pageNum = data//当前多少页
      this.getList(this.params)//重新获取table的接口
    },
    showDiagle(data) {
      this.$refs.hcDiaglePage.showLog = true;
      // 判断弹框是编辑页面还是新增页面或者是详情页面
      this.dialogType = data
      if (this.dialogType == 'detail') {
        console.log(this.editData, '出库')
        this.ruleForm.categoryCode = this.editData.categoryCode;//危废类别
        this.ruleForm.harmWasteCode = this.editData.harmWasteCode;//废物代码
        this.ruleForm.harmWasteName = this.editData.harmWasteName;//废物名称
        this.ruleForm.receiveWeight = this.editData.receiveWeight;//出库量
        this.ruleForm.stockInfoId = this.editData.stockInfoId;//入库编号
        this.ruleForm.sheetInfoId = this.editData.sheetInfoId;//联单编号
        this.ruleForm.producterEnterpriseName = this.editData.producterEnterpriseName;//产废企业名称
        this.params.sheetInfoId = this.editData.sheetInfoId;//根据联单编号查表格数据
      }
      this.getList(this.params)
      this.disposalEnterpriseNamelist()
    },
    //出库页面表格数据
    getList(params) {
      server.stockList(params).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.list
          // 设置分页的数据
          this.params.totalPage = res.data.totalPage;
          this.params.total = res.data.total;
          this.params.pageNum = this.params.pageNum;
          this.params.pageSize = this.params.pageSize;
        }
      })
        .catch((err) => {
          setTimeout(() => {//失败2秒后关闭加载中
            this.loading = false
          }, 2000)
          this.$message({
            showClose: true,
            message: err.message,
            type: 'warning'
          });
        })
    },
    // 处置企业名称-下拉框
    disposalEnterpriseNamelist() {
      server.disposalEnterpriseNamelist().then(res => {
        if (res.code == 200) {
          this.enterpriseName = res.data
        }
        console.log(this.enterpriseName, 'enterpriseName')
      })
        .catch((err) => {
          setTimeout(() => {//失败2秒后关闭加载中
            this.loading = false
          }, 2000)
          this.$message({
            showClose: true,
            message: err.message,
            type: 'warning'
          });
        })
    },
    // 出库
    outbound(params) {
      server.outboundById(params).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '出库成功',
            type: 'success'
          });
          this.$refs.hcDiaglePage.enter();//关闭弹框  
          this.$parent.queryList()//调用列表页面，刷新列表
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: 'warning'
          });
        });
    },
    // 提交
    submitForm(formName) {
      this.ruleForm.sheetInfoId = this.editData.sheetInfoId;//联单编号
      this.ruleForm.outboundInfoId = this.editData.outboundInfoId;//出库编号
      console.log(this.ruleForm.sheetInfoId, "联单编号");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.outbound(this.ruleForm);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.hcDiaglePage.close();
    },
    // 点击联单ID获取当前行数据
    goSheet(row) {
      console.log(row, '获取当前行数据')
      let data = JSON.stringify(row)
      sessionStorage.setItem('outboundDetail', data)
      this.$router.push({
        name: 'jointOrderList',//跳转到联单详情
        query: {
          type: 'outboundDetail'
        }
      })
    }
  },
  mounted() {
    this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
  },
  watch: {
    'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 471
    }
  }
};
</script>

<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
  margin-top: 20px;
}

.card_tit2 {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
  margin-top: 20px;
}

.deviceIdStyle {
  color: #409EFF;
  cursor: pointer;
}

::v-deep .el-input--small .el-input__inner {
  width: 300px;
}

::v-deep .el-input--small {
  width: 300px;
}

::v-deep .el-textarea__inner {
  width: 800px;
}

.addressClass {
  display: flex;

  .addressDiv {
    cursor: pointer;
  }

  .addressIcon {
    font-size: 24px;
    color: #E6A23C;
    margin-left: 6px;
  }

  span {
    font-size: 14px;
    color: #E6A23C;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

::v-deep .el-descriptions-row th {
  width: 10vw;
}
</style>
