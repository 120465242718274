<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
    </template>
    <template v-slot:center>
      <div class="center_detail">
        <div class="tab1">
          <div class="tabTit">
            基本信息
            <el-tag
              type="success"
              size="medium"
              v-if="editData.agreementStatus == 2"
              >履行中</el-tag
            >
            <el-tag
              type="warning"
              size="medium"
              v-if="editData.agreementStatus == 1"
              >未生效</el-tag
            >
            <el-tag
              type="danger"
              size="medium"
              v-if="editData.agreementStatus == 3"
              >已失效</el-tag
            >
          </div>

          <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
              <template slot="label"> 企业名称 </template>
              {{ editData.enterpriseName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 企业编号 </template>
              {{ editData.enterpriseId }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 所属区域 </template>
              <!-- {{ detail.enterpriseProvince }}{{ detail.enterpriseCity }}{{ detail.enterpriseRegion }}{{
                                detail.enterpriseTown }} -->
              <HcAreaSelect
                ref="HcAreaSelect"
                :selectedArea="detail"
                placeholder="请选择所属区域"
                :fatherDisabled="true"
              ></HcAreaSelect>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 详细地址 </template>
              {{ editData.enterpriseAddress }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 联系人 </template>
              {{ editData.contactor }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 联系电话 </template>
              {{ editData.telephone }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 签订时间 </template>
              {{ editData.signDate }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 开始时间 </template>
              {{ editData.effectDate }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 结束时间 </template>
              {{ editData.endDate }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 合同附件 </template>
              <el-button
                size="mini"
                type="text"
                @click="handleDownload(editData.agreementFilePath)"
              >
                {{ editData.agreementFileName }}
              </el-button>
            </el-descriptions-item>
          </el-descriptions>

          <div class="tabTit">设备费用情况</div>
          <div style="width: 156px">
            <el-tabs v-model="feeActiveName" type="card">
              <el-tab-pane label="押金" name="1"></el-tab-pane>
              <el-tab-pane label="购买费" name="2"></el-tab-pane>
            </el-tabs>
          </div>

          <div v-show="feeActiveName == '1'">
            <el-descriptions class="margin-top" :column="2" border>
              <el-descriptions-item>
                <template slot="label"> 押金缴纳金额(元) </template>
                {{ editData.deviceFeeAmount }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 缴纳状态 </template>
                <span v-if="editData.deviceFeePayStatus == 0">未支付</span>
                <span v-else>已支付</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 缴纳时间 </template>
                <span v-if="editData.deviceFeePayStatus == 0"></span>
                <span v-else>{{ editData.deviceFeePayTime }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 支付方式 </template>
                <span v-if="editData.deviceFeePayStatus == 0"></span>
                <span v-else>{{
                  global.PayTypeMap.get(editData.deviceFeePayType)
                }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 凭证附件 </template>
                <span v-if="editData.deviceFeePayStatus == 0"></span>
                <span v-else>
                  <el-button
                    size="mini"
                    type="text"
                    @click="handleDownload(editData.deviceFeePayCredit)"
                  >
                    {{ editData.deviceFeePayCreditName }}
                  </el-button>
                </span>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div v-show="feeActiveName == '2'">
            <el-descriptions class="margin-top" :column="2" border>
              <el-descriptions-item>
                <template slot="label"> 购买费缴纳金额(元) </template>
                {{ editData.deviceBuyFeeAmount }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 缴纳状态 </template>
                <span v-if="editData.deviceBuyFeePayStatus == 0">未支付</span>
                <span v-else>已支付</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 缴纳时间 </template>
                <span v-if="editData.deviceBuyFeePayStatus == 0"></span>
                <span v-else>{{ editData.deviceBuyFeePayTime }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 支付方式 </template>
                <span v-if="editData.deviceBuyFeePayStatus == 0"></span>
                <span v-else>{{
                  global.PayTypeMap.get(editData.deviceBuyFeePayType)
                }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 凭证附件 </template>
                <span v-if="editData.deviceBuyFeePayStatus == 0"></span>
                <span v-else>
                  <el-button
                    size="mini"
                    type="text"
                    @click="handleDownload(editData.deviceBuyFeePayCredit)"
                  >
                    {{ editData.deviceBuyFeePayCreditName }}
                  </el-button>
                </span>
              </el-descriptions-item>
            </el-descriptions>
          </div>

          <div class="tabTit">服务费用情况</div>
          <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
              <template slot="label"> 服务费(元) </template>
              {{ editData.serviceFeeAmount }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 缴纳状态 </template>
              <span v-if="editData.serviceFeePayStatus == 0">未支付</span>
              <span v-else>已支付</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 缴纳时间 </template>
              <span v-if="editData.serviceFeePayStatus == 0"></span>
              <span v-else>{{ editData.serviceFeePayTime }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 到期时间 </template>
              <span v-if="editData.serviceFeePayStatus == 0"></span>
              <span v-else>{{ editData.serviceFeeExpirationTime }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 支付方式 </template>
              <span v-if="editData.serviceFeePayStatus == 0"></span>
              <span v-else>{{
                global.PayTypeMap.get(editData.serviceFeePayType)
              }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 凭证附件 </template>
              <span v-if="editData.serviceFeePayStatus == 0"></span>
              <span v-else>
                <el-button
                  size="mini"
                  type="text"
                  @click="handleDownload(editData.serviceFeePayCredit)"
                >
                  {{ editData.serviceFeePayCreditName }}
                </el-button>
              </span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </template>
  </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import HcAreaSelect from "@/components/HcAreaSelect/index.vue";
export default {
  name: "detailDiagle",
  props: {
    editData: {
      type: Object,
      default: null,
    },
  },
  components: {
    HcDiaglePage,
    HcButton,
    HcAreaSelect,
  },
  data() {
    return {
      feeActiveName: "1",
      dialogType: "",
      detail: [],
    };
  },
  mounted() {},
  methods: {
    //table序号
    showDiagle(data) {
      this.$refs.hcDiaglePage.showLog = true;
      this.dialogType = data;

      this.detail[0] = this.editData.enterpriseProvince;
      this.detail[1] = this.editData.enterpriseCity;
      this.detail[2] = this.editData.enterpriseRegion;
      this.detail[3] = this.editData.enterpriseTown;
    },

    resetForm(formName) {
      this.feeActiveName = "1";
      if (this.$route.query.type == "wasteTab4Detail") {
        this.$router.push({
          name: "wasteProduction", //新增跳转到合同列表页面
        });
      } else if (this.$route.query.type == "tabContract") {
        this.$router.push({
          name: "alarmList", //新增跳转到报警列表页面
        });
      }
      this.$refs.hcDiaglePage.close();
    },

    // 下载附件
    handleDownload(ossId) {
      this.downloadOss(ossId);
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  background-color: #f3f8ff !important;
  margin: 0 !important;
  border-radius: 0 !important;
  color: #808080;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: #00bfff !important;
  background-color: #fff !important;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed !important;
  width: 100%;
  background-color: #f3f8ff !important;
}

.tab1 {
  .tabTit {
    padding: 16px 0;
  }

  .yinye {
    display: flex;
    margin: 24px 0;

    .yinye_img {
      margin-left: 16px;
    }
  }

  ::v-deep .el-descriptions-item__label.is-bordered-label {
    background-color: #f5f5f5;
  }
}

::v-deep .el-descriptions-row th {
  width: 10vw;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  color: #606266;
  border: none;
  padding: 0;
}
::v-deep .el-cascader--small {
  width: 100%;
}
::v-deep .el-input.is-disabled .el-input__icon {
  display: none;
}
</style>
