import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import * as echarts from "echarts";
import BaiduMap from "vue-baidu-map";

import { download } from "@/utils/request";
import { downloadOss } from "@/utils/request";
import { MAP2JSON } from "@/json/const";
import { newMessage } from '@/utils/resetMessage';

//引入全局常量
import constant from "@/json/const";
import BaiduMapComponent from "@/components/VueBaiduMap/index.vue";

// 高德地图
import AmapVue from "@amap/amap-vue";

AmapVue.config.version = "2.0"; // 默认2.0，这里可以不修改
AmapVue.config.key = "95a5c34a3843a93fba8378fae6893491";
AmapVue.config.plugins = [
  "AMap.ToolBar",
  "AMap.MoveAnimation",
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];
Vue.use(AmapVue);

Vue.use(constant);

//挂载全局上
Vue.prototype.download = download; //全局下载
Vue.prototype.downloadOss = downloadOss; //全局下载
Vue.prototype.MAP2JSON = MAP2JSON; //格式转换
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.prototype.$message = newMessage;//写use后面，不然覆盖不掉原生的
Vue.use(BaiduMap, { ak: "GYqTBDGtkKZ9Tq8mhsSx1TkgxGA9PKmC" });
Vue.component("baidu-map", BaiduMapComponent);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
