<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <div class="center_detail">
                <el-tabs v-model="activeName2" type="card">
                    <tab1 :editData="editData"></tab1>
                </el-tabs>
            </div>
        </template>

    </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import tab1 from "../components/tab1.vue";
export default {
    name: "detailDiagle",
    props: {
        editData: {
            type: Object,
            default: null,
        },
    },
    components: {
        HcDiaglePage,
        HcButton,
        tab1,
    },
    watch: {
        dialogType(item) {
            //监听弹框类型变化
            console.log(item, "hello");
        },
    },
    data() {
        return {
            dialogType: "",
            activeName2: "tab1",
        };
    },
    methods: {
        showDiagle(data) {
            this.$refs.hcDiaglePage.showLog = true;
            this.dialogType = data;
        },
        resetForm(formName) {
            if (this.$route.query.type == 'tabWarming') {
                this.$router.push({
                    name: 'alarmList',//新增跳转到报警列表页面
                })
            }
            if (this.$route.query.type == 'storageDetail') {
                this.$router.push({
                    name: 'storage',//新增跳转到报警列表页面
                })
            }
            if (this.$route.query.type == 'outboundDetail') {
                this.$router.push({
                    name: 'outbound',//新增跳转到报警列表页面
                })
            }
            if (this.$route.query.type == 'inventoryDetail') {
                this.$router.push({
                    name: 'inventory',//新增跳转到报警列表页面
                })
            }
            this.$refs.hcDiaglePage.close();
        },
    },
};
</script>

<style scoped lang="less">
::v-deep .el-tabs--card>.el-tabs__header {
    margin-top: -14px;
}
</style>
