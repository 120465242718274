<template>
    <div class="tab2">
        <div class="operate">
            <HcButton :type="'primary'" icon="el-icon-plus" class="button" size="small" @click="gostorageListAdd"
                v-if="screen !== 'screen'">新增
            </HcButton>
            <div v-else></div>
            <div class="search">
                <el-form ref="form" :model="params" label-width="80px" size="small" :inline="true">
                    <el-form-item label="">
                        <el-input clearable v-model="params.fuzzyField"></el-input>
                    </el-form-item>
                </el-form>
                <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="searchTable">查询
                </HcButton>
                <HcButton :type="'refresh'" icon="el-icon-refresh" size="small" class="rest" @click="restFrom">重置
                </HcButton>
            </div>
        </div>
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading">
            <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
            <el-table-column prop="enterpriseName" label="当前所属企业" width="220"></el-table-column>
            <el-table-column prop="deviceNo" label="台账ID" width="220"></el-table-column>
            <el-table-column label="设备版本" width="130">
                <template slot-scope="scope">
                    {{ global.StorageVersionMap.get(scope.row.deviceVersion) }}
                </template>
            </el-table-column>
            <el-table-column label="设备型号" width="100">
                <template slot-scope="scope">
                    {{ global.StorageModelMap.get(scope.row.deviceModel) }}
                </template>
            </el-table-column>
            <el-table-column prop="deviceName" label="设备名称" width="160"></el-table-column>
            <el-table-column prop="categoryCode" label="危废类别" width="120"></el-table-column>
            <el-table-column prop="categoryName" label="危废类别名称" width="200"></el-table-column>
            <el-table-column prop="harmWasteCode" label="废物代码" width="120"></el-table-column>
            <el-table-column prop="harmWasteName" label="废物名称"></el-table-column>
            <el-table-column fixed="right" label="操作" width="150" v-if="screen !== 'screen'">
                <template slot-scope="scope">
                    <el-button type="text" size="default" @click="openEditDiagle(scope.row)">编辑</el-button>
                    <el-popconfirm title="是否确定删除所选数据？" @confirm="removeOne(scope.row)">
                        <el-button slot="reference" type="text" size="default" class="button_text_del">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 50px 0px;text-align: right;">
            <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="params.pageNum" :page-size="params.pageSize" :total="params.total" class="pagination">
            </HcPagination>
        </div>
    </div>
</template>
<script>
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from '@/api/deviceApi';

export default {
    name: 'tab9',
    components: { HcPagination, HcButton },
    props: {
        editData: {
            type: Object,
        },
        screen: {
            type: String
        }
    },
    data() {
        return {
            mainHeight: null,//中间高度
            tableHeight: null,//表格高度
            tableData: [],
            loading: false,
            params: {
                enterpriseId: this.editData.enterpriseId,
                pageSize: 20,
                pageNum: 1,
                total: null,
                totalPage: null,
                deviceType: "2",
                fuzzyField: '',//模糊查询字段
            },
        }
    },
    methods: {
        //table序号
        indexMethod(index) {
            return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
        },

        // 获取table表格数据
        getList(params) {
            this.loading = true //请求时打开加载中
            server.list(params).then((res) => {
                this.loading = false //成功关闭加载中
                // 将获取的list赋值给table
                this.tableData = res.data.list

                // 设置分页的数据
                this.params.totalPage = res.data.totalPage;
                this.params.total = res.data.total;
                this.params.pageNum = this.params.pageNum;
                this.params.pageSize = this.params.pageSize;

            }).catch((err) => {
                setTimeout(() => {//失败2秒后关闭加载中
                    this.loading = false
                }, 2000)
                this.$message({
                    showClose: true,
                    message: err.message,
                    type: 'warning'
                });
            })
        },
        //查询列表
        searchTable() {
            this.getList(this.params)
        },
        //重置
        restFrom() {
            this.params.fuzzyField = '';
            this.getList(this.params);
        },
        handleSizeChange(data) {
            this.params.pageSize = data;//每页多少条
            this.getList(this.params)
        },
        handleCurrentChange(data) {
            this.params.pageNum = data;//当前多少页
            this.getList(this.params)
        },
        //点击新增前往新增危废列表
        gostorageListAdd() {
            console.log('打开危废贮存列表')
            this.$router.push({
                name: 'storageList',//跳转到危废贮存列表
                query: {
                    type: 'storageListAdd',
                    enterpriseId: this.editData.enterpriseId,
                    enterpriseName: this.editData.enterpriseName
                }
            })
        },
        // 编辑
        openEditDiagle(row) {
            let data = JSON.stringify(row)
            sessionStorage.setItem('storageEdit', data)
            this.$router.push({
                name: 'storageList',//跳转到智能危废箱列表
                query: {
                    type: 'storageEdit',
                    enterpriseId: this.editData.enterpriseId,
                    enterpriseName: this.editData.enterpriseName
                }
            })
        },
        //删除列表中的一条信息
        removeOne(row) {
            server.remove({
                ids: row.deviceNo
            }).then(res => {
                if (res.code == 200) {
                    this.$message({
                        showClose: true,
                        message: "删除成功",
                        type: "success",
                    });
                    this.searchTable();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: "warning",
                    });
                }
            })
        },
    },
    watch: {
        'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
            this.tableHeight = item - 244
        },
        '$route.query': function (item) {
            //路由改变刷新列表
            if (item.type == 'storageListAdd2' || item.type == 'storageEdit2') {
                this.getList({ pageNum: 1, pageSize: 20, enterpriseId: this.editData.enterpriseId, deviceType: "2" });
            }
        },
    },
    mounted() {
        // 新增或编辑
        if (this.$route.query.type == 'storageListAdd2' || this.$route.query.type == 'storageEdit2') {
            this.getList({ pageNum: 1, pageSize: 20, enterpriseId: this.editData.enterpriseId, deviceType: "2" })
        }
        this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
    },
}

</script>
<style  lang="less" scoped>
.tab2 {

    ::v-deep .el-pagination {
        text-align: right !important;
        margin-top: 16px !important;
    }

    .operate {
        display: flex;
        justify-content: space-between;

        .search {
            display: flex;
        }

        .rest {
            margin-left: 8px;
        }
    }
}

//表格里的 删除按钮
.button_text_del {
    color: #F56C6C !important;
    padding-left: 10px;
}
</style>