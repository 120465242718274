<template>
    <!-- 联单详情中联单节点展示 -->
    <div class="steps">
        <el-steps :active="activeNo" align-center>
            <el-step title="联单申请" icon="el-icon-edit-outline" :description=editData.createTime></el-step>
            <el-step title="运输确认" icon="el-icon-receiving" :description=editData.processTime></el-step>
            <el-step title="危废上车" icon="el-icon-finished" :description=editData.confirmTime></el-step>
            <el-step title="联单运输" icon="el-icon-truck" :description=editData.transportTime></el-step>
            <el-step title="五必查确认" icon="el-icon-tickets" :description=editData.checkTime></el-step>
            <el-step title="入库确认" icon="el-icon-document-checked" :description=editData.storeTime></el-step>
            <el-step title="联单办结" icon="el-icon-circle-check" :description=editData.storeTime></el-step>
        </el-steps>
    </div>
</template>
<script>
export default {
    name: 'steps',
    data() {
        return {
            activeNo: this.activeNo
        }
    },
    props: {
        activeNo: {
            type: Number,
            default: '' 
        },
        editData: {
            type: String,
            default: '' 
        }
    },
    methods: {
        
    },
    mounted() {
        
    },
}
</script>

<style type="less" scoped>
.tabTit {
    padding: 16px 0px;
}

.steps {
    margin-top: 20px;
}
</style>