<template>
  <el-container>
    <el-header>
      <hcHeader></hcHeader>
    </el-header>
    <el-container :style="{ height: setHeight + 'px' }">
      <el-aside style="width: auto">
        <hc-menu :activeNav="activeNav"></hc-menu>
      </el-aside>
      <el-main :style="{ height: setHeight + 'px', padding: 0 }">
        <el-tabs
          v-model="activeTab"
          type="card"
          @tab-remove="removeTab"
          @tab-click="tabClick"
          class="tab"
          @contextmenu.native.prevent="handleClickContextMenu($event)"
        >
          <el-tab-pane
            v-for="(item, index) in tabsItem"
            :key="item.name"
            :label="item.title"
            :name="item.name"
            :closable="item.closable"
            :ref="item.ref"
          >
            <span slot="label" v-if="seleTab == item.title">
              <el-dropdown
                placement="bottom"
                @command="handleCommand"
                trigger="click"
              >
                <span :class="seleTab == item.title ? 'seleTab' : null">{{
                  item.title
                }}</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-dropdown-item command="a"
                      >关闭所有页面</el-dropdown-item
                    >
                    <el-dropdown-item command="b">刷新页面</el-dropdown-item>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>

            <component :is="item.content"></component>
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import welcome from "../weclome";
//首页
import home from "../weclome/index.vue";
//企业管理
import wasteProduction from "../business/wasteProduction/wasteProduction.vue"; //产废企业
import transport from "../business/transport/transport.vue"; //运输企业
import handle from "../business/handle/handle.vue"; //处置企业
import hazardousWaste from "../business/hazardousWaste/hazardousWaste.vue"; //收集企业
import ledger from "../business/ledger/ledger.vue"; //企业台账导入
//合同管理
import contractList from "../contract/contractList.vue"; //合同管理-合同列表
//管理计划
import planList from "../managePlan/planList.vue"; //管理计划-计划列表
//联单管理
import fiveMustCheckList from "../jointOrder/fiveMustCheckList.vue"; //联单管理-五必查
import jointOrderList from "../jointOrder/jointOrderList.vue"; //联单管理-联单列表
import pendingApprovalList from "../jointOrder/pendingApprovalList.vue"; //联单管理-待审批联单
import pendingInStorageList from "../jointOrder/pendingInStorageList.vue"; //联单管理-待入库列表
//设备管理
import overview from "../device/overview2.vue"; //智能危废箱总览
import binList from "../device/binList.vue";
import storageList from "../device/storageList.vue";
//库存管理
import inventory from "../inventory/inventory/inventory.vue";
import storage from "../inventory/storage/storage.vue";
import outbound from "../inventory/outbound/outbound.vue";
import inventoryStat from "../inventory/inventoryStat.vue";
//知识库
import knowledgeList from "../knowledge/knowledgeList.vue";
//系统管理
import user from "../system/user/user.vue";
import role from "../system/role/role.vue";
import menus from "../system/menus/menus.vue";
import dictionary from "../system/dictionary/dictionary.vue";
import directory from "../system/directory/directory.vue";
import guide from "../system/guide.vue";
//报警管理
import alarmList from "../alarm/alarmList.vue";
import alarmStat from "../alarm/alarmStat.vue";

import HcMenu from "@/components/HcMenu";
import hcHeader from "@/components/HcHeader/index.vue";
import * as serve from "../../api/loginApi";

export default {
  name: "Home",
  components: {
    home, //首页
    welcome,
    wasteProduction,
    transport,
    handle,
    hazardousWaste,
    ledger, //台账导入
    contractList, //合同列表
    planList, //计划列表
    jointOrderList, //联单列表
    fiveMustCheckList, //待五必查联单
    pendingApprovalList, //待审批联单
    pendingInStorageList, //待入库联单
    overview, //智能危废箱总览
    binList, //智能危废箱列表
    storageList, //危废贮存列表
    inventory, //实时库存
    storage, //入库记录
    outbound, //出库记录
    inventoryStat, //库存统计
    knowledgeList, //知识库
    user, //用户管理
    role, //角色管理
    menus, //菜单管理
    dictionary, //字典管理
    directory, //危废名录
    guide, //操作指南
    alarmList, //报警列表
    alarmStat, //报警统计

    HcMenu,
    hcHeader,
  },
  data() {
    return {
      isCollapse: false, //false为展开 true为收缩
      activeTab: "1", //默认显示的tab
      tabIndex: 1, //tab目前显示数
      tabsItem: [
        {
          title: "首页",
          name: "1",
          closable: false,
          ref: "tabs",
          content: home,
        },
      ],
      tabsPath: [
        {
          name: "1",
          path: "/home",
        },
      ],
      seleTab: "",
      tabOpen: true,
    };
  },
  computed: {
    setHeight() {
      return document.documentElement.clientHeight - 65;
    },
    activeNav() {
      //当前激活的导航
      return this.$route.path;
    },
  },

  methods: {
    removeTab(targetName) {
      //删除Tab
      let tabs = this.tabsItem; //当前显示的tab数组
      let activeName = this.activeTab; //点前活跃的tab

      //如果当前tab正活跃 被删除时执行
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
              this.tabClick(nextTab);
            }
          }
        });
      }
      this.activeTab = activeName;
      this.tabsItem = tabs.filter((tab) => tab.name !== targetName);
      //在tabsPath中删除当前被删除tab的path
      this.tabsPath = this.tabsPath.filter((item) => item.name !== targetName);
    },
    tabClick(thisTab) {
      /*
       * thisTab:当前选中的tabs的实例
       * 通过当前选中tabs的实例获得当前实例的path 重新定位路由
       * */
      let val = this.tabsPath.filter((item) => thisTab.name == item.name);
      this.$router.push({
        path: val[0].path,
      });
    },
    // 去除tab上默认右键事件
    removeTabFun() {
      let objDemo = document.getElementsByClassName("tab")[0];
      console.log(objDemo, "objDemo");
      objDemo.oncontextmenu = (e) => {
        e.preventDefault();
      };
    },
    handleClickContextMenu(event) {
      console.log(this.activeTab, this.tabsItem, "tabsItem");
    },
    handleCommand(data) {
      console.log(data, 222);
      if (data == "b") {
        //刷新页面
        this.$router.go(0);
      } else if (data == "a") {
        //关闭所有页面
        this.tabsItem = [
          {
            title: "首页",
            name: "1",
            closable: false,
            ref: "tabs",
            content: home,
          },
        ];
        this.$router.push("/home"); //跳转到根目录，会自动定位到首页，不然可能会出现空白
      }
    },
  },
  mounted() {
    this.removeTabFun();
    /*
     * 监听页面刷新事件
     * 页面刷新前 需要保存当前打开的tabs的位置，刷新后按刷新前的顺序展示
     * 使用js的sessionStorage保存刷新页面前的数据
     * */
    window.addEventListener("beforeunload", (e) => {
      sessionStorage.setItem(
        "tabsItem",
        JSON.stringify({
          currTabsItem: this.tabsItem.filter((item) => item.name !== "1"),
          currTabsPath: this.tabsPath.filter((item) => item.name !== "1"),
          currActiveTabs: this.activeTab,
          currIndex: this.tabIndex,
        })
      );
    });
    serve.getLoginByToken().then((res) => {
      this.menu = res.data.menuInfo;
      window.sessionStorage.setItem(
        "userInfo",
        JSON.stringify(res.data.userBo)
      );
      //console.log(res.data.userBo, 'userBo')
      this.menu.map((item, index) => {
        item.children.map((item2) => {
          if (this.$route.path == item2.path) {
            this.selectIndex = index;
          }
        });
      });
    });
  },
  created() {
    /*
     * 使用js的sessionStorage读取刷新前的数据，并按刷新前的tabs顺序重新生成tabs
     * */
    const sessionTabs = JSON.parse(sessionStorage.getItem("tabsItem"));
    if (
      sessionTabs.currTabsItem.length != 0 &&
      sessionTabs.currTabsPath.length != 0
    ) {
      for (let i = 0; i < sessionTabs.currTabsItem.length; i++) {
        this.tabsItem.push({
          title: sessionTabs.currTabsItem[i].title,
          name: sessionTabs.currTabsItem[i].name,
          closable: true,
          ref: sessionTabs.currTabsItem[i].ref,
          content: sessionTabs.currTabsItem[i].content,
        });
      }
      for (let j = 0; j < sessionTabs.currTabsPath.length; j++) {
        this.tabsPath.push({
          name: sessionTabs.currTabsPath[j].name,
          path: sessionTabs.currTabsPath[j].path,
        });
      }
      this.activeTab = sessionTabs.currActiveTabs;
      this.seleTab = sessionTabs.currActiveTabs;
      console.log(sessionTabs, "seleTab");
      sessionTabs.currTabsItem.map((item) => {
        //循环返回的菜单，将所选的菜单和所有的比对，匹配的赋值给seleTab
        if (sessionTabs.currActiveTabs == item.name) {
          this.seleTab = item.title;
        }
      });

      this.tabIndex = sessionTabs.currIndex;
      //避免强制修改url 出现浏览器的url输入框的路径和当前tabs选中的路由路径不匹配
      const activePath = this.tabsPath.filter(
        (item) => item.name == this.activeTab
      );
      this.$router.push({
        path: activePath[0].path,
      });
    }
  },

  watch: {
    $route: function (to, nex) {
      let flag = true; //判断是否需要新增页面
      const path = to.path;
      this.tabOpen = false;
      console.log(this.tabsItem, "tabsItem");
      if (Object.keys(to.meta).length != 0) {
        let tab = this.tabsItem.find((item) => {
          if (item.content.name == "home") {
            return to.meta.componentName == item.content.name;
          } else {
            return to.meta.componentName == item.content;
          }
        });

        for (let i = 0; i < this.$refs.tabs.length; i++) {
          this.seleTab = to.meta.title;
          if (i != 0) {
            //首页不判断 如果页面已存在，则直接定位当页面，否则新增tab页面
            console.log(this.$refs.tabs[i].label, to.meta.title, "342424");

            if (this.$refs.tabs[i].label == to.meta.title) {
              this.activeTab = this.$refs.tabs[i].name; //定位到已打开页面
              // console.log(this.activeTab, 'activeTab')
              flag = false;
              this.tabOpen = true;
              break;
            } else if (to.meta.title == "首页") {
              //如果是首页单独判断
              this.activeTab = "1";
              flag = false;
              break;
            }
          }
        }
        //新增页面
        // console.log(to, 'to')
        // console.log(nex, 'nex')
        // console.log(this.tabsItem, 'tabsItem')

        if (!tab) {
          //获得路由元数据的name和组件名
          const thisName = to.meta.title;
          const thisComp = to.meta.componentName;
          //对tabs的当前激活下标和tabs数量进行自加
          let newActiveIndex = ++this.tabIndex + "";
          //动态双向追加tabs
          if (thisComp !== "home") {
            this.tabsItem.push({
              title: thisName,
              name: String(newActiveIndex),
              closable: true,
              ref: "tabs",
              content: thisComp,
            });
            this.activeTab = newActiveIndex;

            /*
             * 当添加tabs的时候，把当前tabs的name作为key，path作为value存入tabsPath数组中
             * key:tabs的name
             * value:tabs的path
             * {
             *   key: name,
             *   value: path
             * }
             * ///后面需要得到当前tabs的时候可以通过当前tabs的name获得path
             * */
            if (this.tabsPath.indexOf(path) == -1) {
              this.tabsPath.push({
                name: newActiveIndex,
                path: path,
              });
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  height: 100%;
}

.el-menu--collapse {
  height: 100%;
}

.el-aside {
  background: #ffffff;
}

::v-deep .el-container.is-vertical {
  height: 100%;
}

.el-header {
  background-color: #00bfff;
  color: #333;
  line-height: 64px;
}

.el-aside {
  color: #333;
}

.el-submenu [class^="fa"] {
  vertical-align: middle;
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 16px;
}

.content_box {
  background: #ffffff;
}

::v-deep .el-main {
  padding: 0 20px !important;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  margin: 0 5px;
  background: #ffffff;
  border: none;
  border-radius: 2px;
  height: 32px;
  line-height: 32px;
}

::v-deep .el-tabs--card > .el-tabs__header {
  border: none;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
  margin-left: -5px;
}

::v-deep .el-tabs__header {
  margin: 15px 0px;
}

::v-deep .el-tabs__item.is-active {
  font-size: 14px;
  color: #00bfff;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0px;
}

.seleTab {
  color: #00bfff;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ffffff;
  color: #66b1ff;
}
</style>
