<template>
    <!-- 联单详情信息 -->
    <div>
        <steps v-if="sheetStatus != 7 && sheetStatus != 8" :activeNo="activeNo" :editData="editData"></steps>
        <processTable v-if="sheetStatus != 7 && sheetStatus != 8" :tableData="tableData"></processTable>
        <sheetDetail :editData="editData"></sheetDetail>
        <enterpriseInfoYS v-if="sheetStatus != 1 && sheetStatus != 7 && sheetStatus != 8" :enterpriseId="editData.transformEnterpriseId" :editData="editData"></enterpriseInfoYS>
        <fiveCheckTable v-if="sheetStatus == 5 || sheetStatus == 6" :editData="editData"></fiveCheckTable>
        <enterpriseInfoSJ v-if="sheetStatus == 6" :enterpriseId="editData.receiveEnterpriseId" :editData="editData"></enterpriseInfoSJ>

        <div v-if="sheetStatus == 8">
            <div class="tabTit">驳回原因</div>
            <el-descriptions class="margin-top" border>
                <el-descriptions-item>
                    <template slot="label" :contentStyle="{'width': '100px'}">
                        驳回原因
                    </template>
                    {{ remark }}
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
</template>

<script>
import steps from './steps.vue';
import processTable from './processTable.vue';
import sheetDetail from './sheetDetail.vue';
import enterpriseInfoYS from './enterpriseInfoYS.vue';
import fiveCheckTable from './fiveCheckTable.vue';
import enterpriseInfoSJ from './enterpriseInfoSJ.vue';

export default {
    name: 'tab1',
    components:{
        steps, processTable, sheetDetail, enterpriseInfoYS, fiveCheckTable, enterpriseInfoSJ, 
    },
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            activeNo: 0,//当前正在进行的流程节点
            sheetStatus:0,//联单状态
            remark:''//驳回原因
        }
    },
    props: {
        editData: {
            type: Object,
            default: null
        }
    },
    methods: {
        //初始化各组件数据
        init(){
            this.tableData = [];
            let status = parseInt(this.editData.status);
            this.sheetStatus = parseInt(this.editData.status);

            let tr1 = {"processNo":"联单申请","user":this.editData.createUser,"updateTime":this.editData.createTime,"enterpriseType":"产废企业","enterpriseName":this.editData.producterEnterpriseName};
            let tr2 = {"processNo":"运输确认","user":this.editData.processUser,"updateTime":this.editData.processTime,"enterpriseType":"收集企业","enterpriseName":this.editData.receiveEnterpriseName};
            let tr3 = {"processNo":"危废上车","user":this.editData.confirmUser,"updateTime":this.editData.confirmTime,"enterpriseType":"产废企业","enterpriseName":this.editData.producterEnterpriseName};
            let tr4 = {"processNo":"联单运输","user":this.editData.transportUser,"updateTime":this.editData.transportTime,"enterpriseType":"运输企业","enterpriseName":this.editData.transformEnterpriseName};
            let tr5 = {"processNo":"五必查确认","user":this.editData.checkUser,"updateTime":this.editData.checkTime,"enterpriseType":"收集企业","enterpriseName":this.editData.receiveEnterpriseName};
            let tr6 = {"processNo":"入库确认","user":this.editData.storeUser,"updateTime":this.editData.storeTime,"enterpriseType":"收集企业","enterpriseName":this.editData.receiveEnterpriseName};
            let tr7 = {"processNo":"联单办结","user":this.editData.storeUser,"updateTime":this.editData.storeTime,"enterpriseType":"收集企业","enterpriseName":this.editData.receiveEnterpriseName};
            
            if(status == 1 || status == 7 || status == 8){
                this.tableData.push(tr1);

                this.editData.processTime = '';
                this.editData.confirmTime = '';
                this.editData.checkTime = '';
                this.editData.storeTime = '';
            }else{
                this.activeNo = status - 1;
            }

            //入库确认后，全是蓝色
            if(status == 6){
                this.activeNo = 7;
            }

            if(status == 2){
                this.tableData.push(tr1);
                this.tableData.push(tr2);

                this.editData.confirmTime = '';
                this.editData.checkTime = '';
                this.editData.storeTime = '';
            }
            if(status == 3){
                this.tableData.push(tr1);
                this.tableData.push(tr2);
                this.tableData.push(tr3);

                this.editData.checkTime = '';
                this.editData.storeTime = '';
            }
            if(status == 4){
                this.tableData.push(tr1);
                this.tableData.push(tr2);
                this.tableData.push(tr3);
                this.tableData.push(tr4);

                this.editData.checkTime = '';
                this.editData.storeTime = '';
            }
            if(status == 5){
                this.tableData.push(tr1);
                this.tableData.push(tr2);
                this.tableData.push(tr3);
                this.tableData.push(tr4);
                this.tableData.push(tr5);

                this.editData.storeTime = '';
            }
            if(status == 6){
                this.tableData.push(tr1);
                this.tableData.push(tr2);
                this.tableData.push(tr3);
                this.tableData.push(tr4);
                this.tableData.push(tr5);
                this.tableData.push(tr6);
                this.tableData.push(tr7);
            }
            if(status == 8){
                this.remark = this.editData.remark;
            }
        }
    },
    mounted() {
        this.init();
    },
}
</script>

<style lang="less" scoped>
.tabTit {
    padding: 16px 0px;
}

// ::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
//     width: 200px;
// }

::v-deep .el-descriptions-row th {
    width: 10vw;
}
</style>