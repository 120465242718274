<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
    </template>
    <template v-slot:center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="目录名称" prop="knowledgeParentName">
              <el-input v-model="ruleForm.knowledgeParentName" clearable placeholder="请输入目录名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-slot:footer>
      
      <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
    </template>
  </HcDiaglePage>
</template>
    
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";

import * as server from "@/api/knowledgeApi";

export default {
  name: "editMenu",
  props: {
    editMenuData: {
      type: Object,
      default: null
    }
  },
  components: {
    HcDiaglePage,
    HcButton,
  },
  mounted() {
  },

  data() {
    return {
      titleList: [],//所属栏目下拉框
      ruleForm: {
        knowledgeParentName: '',//题目
        knowledgeParentId:''
      },
      params: {
        //   knowledgeParentId: '1',//给定默认目录id
      },
      rules: {
        knowledgeParentName: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    showDiagle(data) {
      this.$refs.hcDiaglePage.showLog = true;
      // 判断弹框是编辑页面还是新增页面或者是详情页面
      this.dialogType = data
      if (this.dialogType == 'edit') {
        console.log(this.editMenuData, 'hello1111111111')
        this.ruleForm.knowledgeParentName = this.editMenuData.knowledgeParentName;//标题
        this.ruleForm.knowledgeParentId = this.editMenuData.knowledgeParentId
      }
      else if (this.dialogType == 'add') {
        this.ruleForm.knowledgeParentName = ''
      }
    },
    add(params) {
      server.addParentName(params).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.$refs.hcDiaglePage.enter();//关闭弹框  
          this.$parent.getKnowledgeParentList(this.params)//调用列表页面，刷新列表
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: 'warning'
          });
        });
    },
    edit(params) {
      if (this.dialogType == 'edit') {
        server.updataParentName(params).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            });
            this.$refs.hcDiaglePage.enter();//关闭弹框  
            this.$parent.getKnowledgeParentList(this.params)//调用列表页面，刷新列表
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
          .catch((err) => {
            this.$message({
              message: err.message,
              type: 'warning'
            });
          });
      }

    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType == 'edit') {
            this.edit(this.ruleForm)//调用编辑接口
          } if (this.dialogType == 'add') {
            this.add(this.ruleForm) //调用新增接口
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$refs.hcDiaglePage.close();
      this.ruleForm = {
        knowledgeParentName: '',//题目
      }
    },
  },
};
</script>
    
<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}

::v-deep .el-input.el-input--small.el-input--suffix{
width: 300px;
}
::v-deep .el-textarea__inner {
  width: 800px;
}

.addressClass {
  display: flex;

  .addressDiv {
    cursor: pointer;
  }

  .addressIcon {
    font-size: 24px;
    color: #E6A23C;
    margin-left: 6px;
  }

  span {
    font-size: 14px;
    color: #E6A23C;
  }
}

.demo-ruleForm {
  margin-top: 20px;
}
</style>
    