<template>
    <!-- 联单详情中  运输企业  信息展示 -->
    <div class="enterpriseInfoYS">
        <div class="tabTit">运输企业信息</div>
        <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
                <template slot="label">
                    运输企业名称
                </template>
                {{ enterpriseDeailObj.enterpriseName }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    运输车辆
                </template>
                {{ editData.transportation}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    运输司机
                </template>
                {{ editData.transformDriver}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    联系人
                </template>
                {{ enterpriseDeailObj.contactor }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    联系电话
                </template>
                {{ enterpriseDeailObj.telephone }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    转移量(kg)
                </template>
                {{ editData.transferWeight}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    运输时间
                </template>
                {{ editData.transformTime }}
            </el-descriptions-item>
        </el-descriptions>

        <div v-if="editData.transportTime != null && editData.transportTime != ''" class="yinye">
            <div>转移图片</div>
            <div class="yinye_img">
                <span v-if="editData.transferPath == null">暂未上传</span>
                <span v-if="editData.transferPath != null">
                    <HcUploadImage v-model="editData.transferPath" :value="editData.transferPath" :isAllowDel="true"
                        :isShowTip="false"></HcUploadImage>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import HcUploadImage from "@/components/HcUploadImage/index.vue";

import * as server from '@/api/planApi';

export default {
    name: 'enterpriseInfoYS',
    components: {
        HcUploadImage
    },
    data() {
        return {
            enterpriseDeailObj: {},
        }
    },
    props: {
        enterpriseId: {
            type: String,
            default: '' 
        },
        editData: {
            type: String,
            default: '' 
        }
    },
    methods: {
        //TODO 这个页面的css样式为什么不能级联？
        getEnterpriseDeatil() {
            if(this.enterpriseId == ''){

            }else{
                server.enterpriseDetail({
                    enterpriseId: this.enterpriseId,
                }).then((res) => {
                    this.enterpriseDeailObj = res.data;
                })
            }
        },
    },
    mounted() {
        this.getEnterpriseDeatil();
    },
}
</script>

<style type="less" scoped>
.tabTit {
    padding: 16px 0px;
}

.yinye {
    display: flex;
    margin: 24px 0;
}

.yinye_img {
    margin-left: 16px;
}
::v-deep .el-descriptions-row th {
    width: 10vw;
}

</style>