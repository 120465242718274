<template>
  <div class="inventoryStat" ref="inventoryStat">
    <!--库存管理-库存统计 -->
    <div class="home_cent1">
      <el-row :gutter="16">
        <el-col :span="5">
          <div class="liandan">
            <div class="liandan_tit">
              <div>实时库存统计</div>
              <div></div>
            </div>
            <div class="liandan_chart">
              <div id="statisticEchart1" style="width: 100%; height: 180px"></div>
            </div>
            <div class="chart1_info">
              <span>入库量：{{ storeWeight }}吨 </span>
              <span>出库量：{{ outboundWeight }}吨</span>
            </div>
          </div>
        </el-col>
        <el-col :span="11">
          <div class="liandan">
            <div class="liandan_tit">
              <div>库存趋势</div>
              <div>
                <!-- <el-date-picker v-model="monthScope" type="monthrange" range-separator="至" start-placeholder="请选择开始月份"
                  clearable end-placeholder="请选择结束月份" value-format="yyyy-MM" @change="monthPickerChange">
                </el-date-picker> -->
                <el-date-picker v-model="params2.year" type="year" placeholder="请选择年度" format="yyyy" value-format="yyyy"
                  @change="chooseYearTrend" class="yearchange" clearable>
                </el-date-picker>
              </div>
            </div>
            <el-row class="liandan_cen">
              <div id="StockTrendEchart" style="width: 100%; height: 210px;"></div>
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="liandan">
            <div class="liandan_tit">
              <div>实时危废类别占比</div>
              <div></div>
            </div>
            <div class="liandan_chart">
              <div id="categoryCodeEchart2" style="width: 100%; height: 205px;padding: 5px 0 0px 0;"></div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="home_cent">
      <el-row>
        <el-col>
          <div class="liandan liandan2" ref="liandan2">
            <div class="liandan_tit">
              <div>年度库存报表</div>
              <div>
                <el-date-picker v-model="params.year" type="year" placeholder="请选择年度" format="yyyy" value-format="yyyy"
                  @change="chooseYear" class="yearchange" clearable>
                </el-date-picker>
              </div>
            </div>
            <div class="liandan_cen">
              <el-table :data="tableData" :height="tableHeight" :summary-method="getSummaries" show-summary ref="table"
                style="width: 100%;" border>
                <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
                <el-table-column prop="month" label="统计月份"></el-table-column>
                <el-table-column prop="receive" label="入库量(吨)"></el-table-column>
                <el-table-column prop="outbound" label="出库量(吨)"></el-table-column>
                <el-table-column prop="storage" label="库存量(吨)"></el-table-column>
              </el-table>
            </div>
            <el-popconfirm title="是否确认导出列表数据？" @confirm="exportExcel" placement="left" class="liandanExport">
              <HcButton slot="reference" :type="'info'" icon="el-icon-upload2" size="small">导出
              </HcButton>
            </el-popconfirm>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import HcButton from '@/components/HcButton/index.vue';
import * as server from '@/api/stockApi';

export default {
  name: "inventoryStat",
  components: {
    HcButton
  },
  data() {
    return {
      monthScope: [],//月份
      tableData: [],
      echartData: [],
      stockTrendData: [],//库存趋势
      storeWeight: '',//入库量
      outboundWeight: '',//出库量
      outboundRate: '',//出库率
      params: {
        pageNum: 1,
        pageSize: 20,
        startMonth: '',
        endMonth: '',
        year: '',
      },
      params2: {
        pageNum: 1,
        pageSize: 20,
        startMonth: '',
        endMonth: '',
        year: '',
      },
      bodyHeight: null,//中间高度
      tableHeight: null,//表格高度
    };
  },

  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1
    },

    //实时库存统计
    getStatistics() {
      server.nventoryStatistics().then((res) => {
        if (res.code = 200) {
          this.statistic(res.data);
          console.log(res.data, '左侧饼图数据')
          this.storeWeight = res.data.storeWeight
          this.outboundWeight = res.data.outboundWeight
          this.outboundRate = res.data.outboundRate
        }
      })
    },
    //库存趋势-折线图
    getStockTrend() {
      server.stockTrend(this.params2).then((res) => {
        if (res.code = 200) {
          let storage = []
          let instorage = []
          let outbound = []
          // 补充月份
          let yearMonth = ['01月', '02月', '03月', '04月', '05月', '06月', '07月', '08月', '09月', '10月', '11月', '12月'];
          this.stockTrendData = res.data;
          if (!res.data || res.data.length == 0) {
            this.stockTrend(yearMonth, name, storage, instorage, outbound)
            return;
          }

          let data = res.data;
          let month = data[0].month;
          let year = month.split("-")[0];

          let storageMap = new Map();
          let instorageMap = new Map();
          let outboundMap = new Map();


          for (let i = 0; i < data.length; i++) {
            storageMap.set(data[i].month.split("-")[1] + '月', data[i].storage);
            instorageMap.set(data[i].month.split("-")[1] + '月',data[i].receive);
            outboundMap.set(data[i].month.split("-")[1] + '月', data[i].outbound);
          }

          for (let i = 0; i < yearMonth.length; i++) {
            storage.push(storageMap.get(yearMonth[i]) == null ? '0.0000' : storageMap.get(yearMonth[i]));
            instorage.push(instorageMap.get(yearMonth[i]) == null ? '0.0000' : instorageMap.get(yearMonth[i]));
            outbound.push(outboundMap.get(yearMonth[i]) == null ? '0.0000' : outboundMap.get(yearMonth[i]));
          }

          this.stockTrend(yearMonth, name, storage, instorage, outbound)
          console.log(storage, instorage, outbound, 'storage, instorage, outbound库存趋势-折线图')
        }
      })
    },

    //实时危废类别占比
    getCategory() {
      server.categoryCodeStatistics(this.params).then((res) => {
        if (res.code == 200) {
          this.transforChartData(res.data);
        }
      })
    },
    //转换图表所需数据-危废类别占比
    transforChartData(data) {
      this.echartData = []
      data.map((item) => {
        this.echartData.push({ name: item.categoryCode, value: item.storage })
      })
      this.categoryCode(this.echartData);
    },
    // 年度库存报表
    getOutboundYear() {
      server.stockTrend(this.params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data
          console.log(res.data, '年度报表表格数据')
        }
      })
    },
    //合计列表
    getSummaries(param) {
      console.log(param)
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (index === 4) {
          sums[index] = ' ';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index].toFixed(4) + '';
        } else {
          sums[index] = ' ';
        }
      });

      return sums;
    },
    //实时库存统计-环形图
    statistic(data) {
      var myChart = this.$echarts.init(document.getElementById("statisticEchart1"));
      let option = {
        // tooltip: {
        //   trigger: 'item',
        //   formatter: '{b} : {c} 吨',
        // }, //提示框，鼠标悬浮交互时的信息提示
        // legend: {
        //   left: 'center',
        //   top: '0%',
        //   itemGap: 10,
        //   itemWidth: 22,
        //   itemHeight: 12,
        // },
        series: [
          {
            //name: '', //tooltip提示框中显示内容
            type: 'pie', //图形类型，如饼状图，柱状图等
            radius: ['80%', '95%'], //第一项是内半径，第二项是外半径。
            center: ['50%', '50%'],
            color: ['#00BFFF', '#dcdcdc'],
            label: {
              show: true,
              position: 'center',
              formatter: [`{a|${data.outboundRate}%}`, '{b|出库率}'].join('\n'),
              width: 120,
              height: 120,
              fontWeight: 'bold',
              backgroundColor: '#ffffff',
              shadowColor: '#e3e3e3',
              shadowBlur: 10,
              borderRadius: 200,
              rich: {
                a: {
                  padding: [80, 0, 0, 0],
                  color: '#0f0f0f',
                  fontSize: 30,
                  lineHeight: 36,
                },
                b: {
                  padding: [100, 0, 0, 0],
                  color: '#c8c8c8',
                  fontSize: 15,
                  lineHeight: 16,
                },
              },
            },
            labelLine: {
              show: true,
            }, //线条颜色

            data: [
              { value: data.outboundRate, name: '蓝色' },
              { value: 100 - data.outboundRate, name: '灰色' },
              // { value: data.storeWeight, name: '入库量' },
              // { value: data.outboundWeight + data.storeWeight, name: '出库量' },
            ], //数据，数据中其他属性，查阅文档
          },
        ],
      };
      myChart.setOption(option);
    },

    //库存趋势-折线图
    stockTrend(month, name, storage, instorage, outbound) {
      console.log(month, name, storage, instorage, outbound, '折线图取到的数据')
      let myChart = this.$echarts.init(document.getElementById("StockTrendEchart"));
      //转换成数字类型
      let newstorage = [];
      let totalArry = [];
      for (let i = 0; i < storage.length; i++) {
        newstorage.push(Number(storage[i]))
        totalArry.push(Number(storage[i]))
      }
      let newinstorage = []
      for (let i = 0; i < instorage.length; i++) {
        newinstorage.push(Number(instorage[i]))
        totalArry.push(Number(instorage[i]))
      }
      let newoutbound = []
      for (let i = 0; i < outbound.length; i++) {
        newoutbound.push(Number(outbound[i]))
        totalArry.push(Number(outbound[i]))
      }
      console.log(totalArry, 'xinde')

      // 获取坐标轴刻度最大值
      const maxVal = Number(Math.max(...totalArry));
      const maxVal2 = maxVal.toFixed(0);
      // 获取坐标轴刻度最小值
      const minVal = Number(Math.min(...totalArry));
      const minVal2 = minVal.toFixed(0)
      // 计算坐标轴分割间隔
      let interval = ((maxVal2 - minVal2) / 8).toFixed(0);
      console.log(interval, 'intervals')
      let option = {
        legend: {
          show: false,
        },
        grid: {
          top: '5%',
          bottom: '30%',
          left: '8%',
          right: '5%'
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#C9CDD4',	//显示竖线颜色
              type: "solid",
              width: 1
            }
          },
          backgroundColor: 'rgba(255, 255, 255, 0.96)',	//tooltip背景色
          borderColor: '#EBEBF2',		//tooltip边框颜色
          borderWidth: 1,
          textStyle: {
            width: '100%',
            height: '100%',
            lineHeight: 24,
            color: '#7270A6',
            fontSize: '16',
            fontFamily: 'OPPOSans-M'
          },
          formatter: params => {
            // 获取xAxis data中的数据
            let dataStr = `<div><p style="font-size: 16px;
font-weight: 500;
color: #9E9E9E;
line-height: 20px;margin:0 5px 5px;">
    
        ${params[0].name}
   
    
      </p></div>`
            params.forEach(item => {
              dataStr += `<div>
          <div style="margin: 0 8px;">

            <span style="display:inline-block;width: 8px;
            margin-bottom:1px;border-radius:50%;
height: 8px;background-color:${item.color};"></span>
            <span style="font-size: 14px;

color: #808080;
line-height: 14px;">${item.seriesName}</span>
        
            <span style="font-size: 14px;
font-weight: 400;
color: #808080;
line-height: 16px;margin-left:20px;">${item.data}吨</span>
          </div>
        </div>`
            })
            return dataStr
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: month,
          axisTick: {

            show: false
          },
          axisLabel: {
            // interval: 0,
            inside: false,
            textStyle: {
              color: '#49478D',
              fontSize: '14',
            }
          },
          axisLine: {
            lineStyle: {
              width: 1,
              color: '#E5E6EB',

            }
          },
          splitLine: {
            //x网格样式
            show: true,

            lineStyle: {
              color: "#F7F8FA",
              type: "solid",
              width: "1",
            },
          },

        },
        yAxis: [{
          // min: minVal2, // 坐标轴刻度最小值。
          // max: maxVal2, // 坐标轴刻度最大值。
          // splitNumber: 100, // 坐标轴的分割段数，是一个预估值，实际显示会根据数据稍作调整。
          // interval: interval, // 强制设置坐标轴分割间隔。
          // min: 0,
          // max: 40,
          type: 'value',
          nameTextStyle: {
            fontSize: 22,
            color: 'rgba(243, 246, 252, 0.8)',
            verticalAlign: 'top', // top名称显示在坐标轴外，bottom显示在坐标轴内部
            padding: 0
          },
          // interval: 1000, //设置坐标轴的分割段数
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              show: true,
              color: '#F7F8FA',
              width: 1,
              type: 'dashed'
            }
          },
          axisLabel: {
            inside: false,
            formatter: "{value} 吨",
            textStyle: {
              color: '#49478D',
              fontSize: '12',
            }
          },

        },

        ],
        series: [
          {
            name: '库存量',
            smooth: false,
            symbol: 'circle', // 折线点设定为实心点
            symbolSize: 1, // 设定折线点的大小
            data: storage,
            type: 'line',
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#5470C6",
                borderColor: '#5470C6',
                borderWidth: 1,
              }
            },//在单个图表实例中存在多个y轴的时候有用
          },
          {
            name: '出库量',
            smooth: false,
            symbol: 'circle', // 折线点设定为实心点
            symbolSize: 1, // 设定折线点的大小
            data: outbound,
            type: 'line',
            yAxisIndex: 0, //在单个图表实例中存在多个y轴的时候有用
            itemStyle: {
              normal: {
                color: "#00BFFF",
                borderColor: '#00BFFF',
                borderWidth: 1,

              }
            },//在单个图表实例中存在多个y轴的时候有用
          },
          {
            name: '入库量',
            smooth: false,
            symbol: 'circle', // 折线点设定为实心点
            symbolSize: 1, // 设定折线点的大小
            data: instorage,
            type: 'line',
            yAxisIndex: 0, //在单个图表实例中存在多个y轴的时候有用
            itemStyle: {
              normal: {
                color: "#91CC75",
                borderColor: '#91CC75',
                borderWidth: 1,

              }
            },//在单个图表实例中存在多个y轴的时候有用
          }

        ]
      };
      myChart.setOption(option);
    },
    //库存趋势-折线图
    chooseYearTrend(yearData) {
      console.log(yearData, '切换年份22222222222')
      this.params2.year = yearData
      this.getStockTrend(this.params2)
    },
    //年度
    chooseYear(yearData) {
      console.log(yearData, '切换年份')
      this.params.year = yearData
      this.getOutboundYear(this.params)
    },
    // 获取
    getTimes() {
      this.getTimesInterval()
    },
    getTimesInterval: function () {
      let _this = this;
      let year = new Date().getFullYear(); //获取当前时间的年份
      //拼接格式化当前时间
      _this.params.year = year + "";
      _this.params.startMonth = year + '-' + '01';
      _this.params.endMonth = year + '-' + '12';
      _this.params2.year = year + "";
      _this.params2.startMonth = year + '-' + '01';
      _this.params2.endMonth = year + '-' + '12';
      // _this.monthScope.push(_this.params.startMonth, _this.params.endMonth)
      // console.log(_this.monthScope, '_this.params.year')
    },


    //危废类别占比-环形图
    categoryCode(data) {
      var myChart = this.$echarts.init(document.getElementById("categoryCodeEchart2"));
      var scale = 1;
      var echartData = data;
      var rich = {
        // 每种危废的重量
        yellow: {
          color: "rgba(158, 158, 158, 0.6)",
          fontSize: 14 * scale,
          padding: [5, 4],
          align: "center",
        },
        // 圆圈里的总量
        total: {
          color: "#00BFFF",
          fontSize: 22 * scale,
          align: "center",
        },
        // 危废代码
        white: {
          color: "rgba(158, 158, 158,1)",
          align: "center",
          fontSize: 14 * scale,
          padding: [4, 0],
        },
        // 百分比
        blue: {
          color: "rgba(158, 158, 158, 1)",
          fontSize: 16 * scale,
          align: "center",
        },
      };
      let option = {
        backgroundColor: "#fff",
        title: {
          text: "库存量(吨)",
          left: "center",
          top: "50%",
          padding: [14, 0],
          textStyle: {
            color: "#9E9E9E",
            fontSize: 14 * scale,
            align: "center",
          },
        },
        grid: {
          top: '0%',
          left: '0%',
          right: '0%',
          bottom: '0%',
        },
        legend: {
          selectedMode: false,
          formatter: function (name) {
            var total = 0;
            echartData.forEach(function (value, index, array) {
              total += Number(value.value);
            });
            total = total.toFixed(4)
            return "{total|" + total + "}";
          },
          data: [echartData[0].name],
          left: "center",
          top: "center",
          icon: "none",
          align: "center",
          textStyle: {
            color: "#9E9E9E",
            fontSize: 16 * scale,
            rich: rich,
          },
        },
        series: [
          {
            name: "库存量(吨)",
            type: "pie",
            radius: ["55%", "70%"],
            hoverAnimation: false,
            // color: ["#55D4FF", "#CCECFF", "#5CE2CD", "#B2FFEC", "#64B7F6"],
            label: {
              normal: {
                formatter: function (params, ticket, callback) {
                  var total = 0; //类别总数
                  var percent = 0; //类别占比
                  echartData.forEach(function (value, index, array) {
                    total += Number(value.value);
                  });
                  percent = ((Number(params.value) / Number(total)) * 100).toFixed(2);
                  return (
                    "{white|" +
                    params.name +
                    "} {yellow|" + params.value + '吨'
                    +
                    "}\n{blue|" +
                    percent +
                    "%}"
                  );

                },
                rich: rich,
              },
            },
            labelLine: {
              normal: {
                length: 15 * scale,//指示线的长度
                length2: 15 * scale,
                lineStyle: {
                  color: "rgba(158, 158, 158, 0.6)",
                },
              },
            },
            data: echartData,
          },
        ],
      };
      myChart.setOption(option);
    },

    //导出表格
    exportExcel() {
      this.download('/cloud/stock/stockYearTrendExport', this.params, `年度库存报表_${new Date().getTime()}.xlsx`)
    },

    //月份选择器-库存趋势
    // monthPickerChange(monthrange) {
    //   console.log(monthrange, 'monthrange')
    //   this.params.startMonth = '';
    //   this.params.endMonth = '';
    //   if (monthrange[0]) {
    //     this.params.startMonth = monthrange[0];
    //     this.params.endMonth = monthrange[1];
    //   }
    //   this.getStockTrend()
    // },


    getHeight() {//动态计算中间内容部分高度
      this.bodyHeight = document.body.clientHeight
      let contentHeight = this.bodyHeight - 148
      this.tableHeight = this.bodyHeight - 590
      let bottomHeight = this.bodyHeight - 488
      console.log(this.bodyHeight, contentHeight, 'height')
      this.$refs.inventoryStat.style.height = contentHeight + 'px'
      this.$refs.liandan2.style.height = bottomHeight + 'px'
      sessionStorage.setItem('inventoryStatHeight', contentHeight)

    }
  },

  created() {
    this.getTimes()
  },
  mounted() {

    this.getStatistics();//实时库存统计
    this.getCategory();//实时危废类别占比
    this.getStockTrend();//库存趋势-折线图
    this.getOutboundYear();//年度报表
    this.getHeight();//页面高度计算

  },
  beforeDestroy() {
    if (this.params.year) {
      clearInterval(this.getTimesInterval);
    }
  },
  watch: {
    // 'params.year': function (item) {
    //   this.getTimes()
    // },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout();
    });
  },
};
</script>

<style scoped lang="less">
.inventoryStat {
  height: 750px;
  overflow-y: auto;
  overflow-x: hidden;

  .home_cent {
    margin-top: 16px;
  }

  .liandan {
    background-color: #fff;
    border-radius: 4px;
    height: 220px;
    padding: 26px 30px;

    .liandan_tit {
      font-size: 16px;
      color: #666666;
      display: flex;
      justify-content: space-between;
      font-weight: 700;
    }

  }

  .liandan2 {
    height: 518px;
  }

  .liandan_chart {
    display: flex;
    align-items: center;

    .liandan_chart_num {
      font-size: 32px;
      color: #666;
    }

    .liann_chart_detail {
      display: flex;
      align-items: center;
      line-height: 40px;
    }

    .lian_chart_li {
      width: 10px;
      height: 10px;
      background-color: #00BFFF;
      margin-right: 6px;
    }
  }

  .liandanExport {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
  }

}

.chart1_info {
  color: #808080;
  font-size: 14px;
  display: flex;
  justify-content: space-between
}

.yearchange {
  margin-bottom: 15px;
}
</style>