<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm"></i>
    </template>
    <template v-slot:center>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" size="small" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="危废类别" prop="categoryCode">
              <el-select clearable filterable v-model="ruleForm.categoryCode" @change="updateSub1" placeholder="请选择危废类别">
                <el-option v-for="item in arr" :key="item.value" :label="item.value" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="危废类别名称">
              <el-input v-model="ruleForm.categoryName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="废物代码" prop="harmWasteCode">
              <el-select clearable filterable v-model="ruleForm.harmWasteCode" @change="updateSub2" placeholder="请选择废物代码">
                <el-option v-for="item in subArr" :key="item.value" :label="item.value" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="废物名称">
              <el-input v-model="ruleForm.harmWasteName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="年申报量(kg)" prop="declareNum">
              <!-- <el-input v-model="ruleForm.declareNum"></el-input> -->
              <el-input-number v-model="ruleForm.declareNum" :controls="false" :min="0" :precision="2"
                clearable></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-slot:footer>
      <HcButton :type="'cancel'" size="small" @click="resetForm()">取消</HcButton>
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>

    </template>
  </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";

import * as server from "@/api/businessApi";
import * as server2 from '@/api/systemApi';

export default {
  name: "tab2.vue",
  components: {
    HcDiaglePage,
    HcButton,
  },
  props: {
    editData: {
      type: Object,
    },
  },
  data() {
    return {
      arr: [],//所有类别
      subArr: [],//选择的类别下所有的代码

      ruleForm: {
        categoryCode: "",
        categoryName: "",
        harmWasteCode: "",
        harmWasteName: "",
        declareNum: "",
        enterpriseId: "",
        enterpriseType: "1",
      },
      rules: {
        categoryCode: [{ required: true, message: '请选择危废类别', trigger: 'change' }],
        harmWasteCode: [{ required: true, message: '请选择废物代码', trigger: 'change' }],
        declareNum: [{ required: true, message: "请填写年申报量(kg)", trigger: "blur" }],
      },
    };
  },
  methods: {
    showtab2Add() {
      this.$refs.hcDiaglePage.showLog = true;
      this.$parent.detailTit = "新增危废信息";
      this.ruleForm.enterpriseId = this.editData.enterpriseId;
      this.ruleForm.categoryCode = "";
      this.ruleForm.categoryName = "";
      this.ruleForm.harmWasteCode = "";
      this.ruleForm.harmWasteName = "";
      this.ruleForm.declareNum = "";
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.add(this.ruleForm); //调用新增接口
        } else {
          return false;
        }
      });
    },
    add(params) {
      server.addWaste(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "新增成功",
            type: "success",
          });
          this.resetForm();

          let newParams = {
            enterpriseId: this.editData.enterpriseId,
            enterpriseType: '1',
            pageSize: 20,
            pageNum: 1,
            total: null,
            totalPage: null,
          };
          this.$parent.getWasteList(newParams); //调用父组件的刷新 table列表方法
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: "warning",
          });
        });
    },

    resetForm() {
      this.$parent.detailTit = "产废企业详情";
      this.$refs.hcDiaglePage.close();
      this.ruleForm = {
        categoryCode: "",
        categoryName: "",
        harmWasteCode: "",
        harmWasteName: "",
        declareNum: "",
        enterpriseId: this.editData.enterpriseId,
        enterpriseType: "1",
      }
    },

    //废物类别选择后更新废物代码选择集
    updateSub1(categoryCode) {
      //设置类别名称
      let obj = this.arr.find((item) => {
        return item.value === categoryCode;
      });
      this.ruleForm.categoryName = obj.label;

      //设置类别代码
      this.ruleForm.harmWasteCode = '';
      this.ruleForm.harmWasteName = '';
      let newParams = { 'categoryCode': categoryCode };
      this.getWasteCode(newParams);
    },

    //废物代码选择后更新废物名称
    updateSub2(harmWasteCode) {
      //设置废物名称
      this.ruleForm.harmWasteName = this.ruleForm.categoryName + harmWasteCode;
    },

    //请求危废类别数据（一级）
    async getCategoryCode() {
      let res = await server2.categoryCodeList();
      if (res.code == 200) {
        this.arr = res.data;
      }
    },
    //请求废物代码数据（二级）
    async getWasteCode(params) {
      let res = await server2.wasteCodeList(params);
      if (res.code == 200) {
        this.subArr = res.data;
      }
    },
  },
  mounted() {
    this.getCategoryCode();
  },
};
</script>

<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}

::v-deep .el-input-number .el-input__inner {
  text-align: left;
}

::v-deep .el-input--small .el-input__inner {
  width: 300px;
}

::v-deep .el-textarea__inner {
  width: 800px;
}

.demo-ruleForm {
  margin-top: 20px;
}
</style>