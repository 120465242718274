<template>
    <div class="user">
        <!-- 字典管理 -->
        <HcListMain>
            <template v-slot:header_from>
                <el-form ref="form" :model="params" label-width="80px" size="small" :inline="true">
                    <el-form-item label="字典名称">
                        <el-input v-model="params.dicName" clearable placeholder="请输入字典名称"></el-input>
                    </el-form-item>
                </el-form>
            </template>
            <template v-slot:header_button>
                <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="queryList">查询
                </HcButton>
                <HcButton :type="'refresh'" icon="el-icon-refresh" size="small" @click="reset">重置</HcButton>
            </template>
            <template v-slot:content_button>
                <HcButton :type="'primary'" icon="el-icon-plus" class="button" size="small" @click="addDiagle">新增</HcButton>
                <!-- <HcButton :type="'info'" icon="el-icon-refresh" size="small">导出</HcButton> -->
            </template>
            <template v-slot:content_table>
                <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading"
                    element-loading-text="加载中">
                    <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
                    <el-table-column prop="dicName" label="字典名称"></el-table-column>
                    <el-table-column prop="dicId" label="编号"></el-table-column>
                    <el-table-column prop="actualValue" label="实际值"></el-table-column>
                    <el-table-column prop="displayValue" label="显示值"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button type="text" size="default" @click="editTable(scope.row)">编辑</el-button>
                            <el-popconfirm title="是否确定删除所选数据？" @confirm="DeleteDictionary(scope.row)">
                                <el-button slot="reference" type="text" size="default"
                                    class="button_text_del">删除</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <template v-slot:content_pag>
                <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="params.pageNum" :page-size="params.pageSize" :total="params.total">
                </HcPagination>
            </template>
        </HcListMain>
        <!-- 编辑页面 -->
        <editDiagle ref="editDiagle" :editData="editData"></editDiagle>
    </div>
</template>
    
<script>
import HcButton from '@/components/HcButton/index.vue';
import HcInput from '@/components/HcInput/index.vue';
import HcListMain from "@/components/HcListMain/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import editDiagle from "./pages/editDiagle.vue";//编辑页面
import * as server from '@/api/systemApi';

export default {
    components: {
        HcButton,
        HcInput,
        HcListMain,
        HcPagination,
        editDiagle,
    },
    name: 'dictionary',
    data() {
        return {
            tableData: [],
            params: {
                pageSize: 20,
                pageNum: 1,
                total: null,
                totalPage: null,
                dicName: '',//字典名称
            },
            loading: false,//加载中
            detailTit: '',
            editData: null,//编辑页面数据,
            mainHeight: null,//中间高度
            tableHeight: null,//表格高度
        }
    },
    provide() {
        //依赖注入，传递值 使用函数的形式可以修改值
        return {
            title: () => this.detailTit,
        };
    },
    methods: {
        //table序号
        indexMethod(index) {
            return (this.params.pageNum - 1) * this.params.pageSize + index + 1
        },
        handleClick(row) {
            console.log(row);
        },
        handleSizeChange(data) { //每页多少条
            console.log(data)
            this.params.pageSize = data//赋值给params
            this.getdictionaryList(this.params)//重新请求获取table的接口

        },
        handleCurrentChange(data) {//当前多少页
            console.log(data)
            this.params.pageNum = data//当前页
            this.getdictionaryList(this.params)//重新获取table的接口

        },
        // 查询按钮筛选表格
        queryList() {
            this.params.pageNum = 1//将当前页面设置为第一页
            this.getdictionaryList(this.params)
        },
        // 重置按钮
        reset() {
            this.params.dicName = "";//字典名称
            this.queryList();
        },
        // 获取table表格数据
        getdictionaryList(params) {
            this.loading = true //请求时打开加载中
            server.dictionaryList(params).then((res) => {
                if (res.code == 200) {
                    this.loading = false //成功关闭加载中
                    // 将获取的list赋值给table
                    this.tableData = res.data.list
                    // 设置分页的数据
                    this.params.pageNum = res.data.pageNum //当前第几页
                    this.params.pageSize = res.data.pageSize//每页显示多少条
                    this.params.total = res.data.total//共多少条
                    this.params.totalPage = res.data.totalPage
                }
            }).catch((err) => {
                setTimeout(() => {//失败2秒后关闭加载中
                    this.loading = false
                }, 2000)
                this.$message({
                    showClose: true,
                    message: err.message,
                    type: 'warning'
                });
            })
        },
        //删除列表中的一条信息
        DeleteDictionary(row) {
            console.log(row, 'delet_deleteDictionary')
            server.deleteDictionary({
                dicId: row.dicId
            }).then(res => {
                if (res.code == 200) {
                    this.$message({
                        showClose: true,
                        message: "删除成功",
                        type: "success",
                    });
                    this.getdictionaryList(this.params)//重新请求获取table的接口
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: "warning",
                    });
                }
            }).catch((err) => {
                this.$message({
                    message: err.message,
                    type: 'warning'
                });
            });
        },
        // 新增弹框
        addDiagle() {
            this.detailTit = '新增字典'
            // 调用打开弹框的方法
            setTimeout(() => {
                this.$refs.editDiagle.showDiagle();
            });
        },
        editTable(row) {//打开编辑页面
            // 编辑页面数据
            this.editData = row
            this.detailTit = '编辑字典'
            setTimeout(() => {
                this.$refs.editDiagle.showDiagle('edit');
            });
        },
    },
    mounted() {
        //页面挂载时第一次调用接口请求
        this.getdictionaryList(this.params)
        this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度

    },
    watch: {
        'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
            console.log(item, '高度')
            this.tableHeight = item - 226
        }
    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.user {
    min-height: 500px;
    background: #ffffff;
}

.button_div {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
}

.button {
    padding: 0 10px 0 0;
}

//表格里的 删除按钮
.button_text_del {
    color: #F56C6C !important;
    padding-left: 10px;
}

/deep/.el-button--primary {
    background: #00BFFF;
}
</style>
    