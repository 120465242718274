import {httpRequest,baseUrl} from '../utils/request'

const contractList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/agreement/agreementlist',
       method:'post' ,
       params
    })
)
const removecontractlist=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/agreement/delete',
       method:'post' ,
       params
    })
)
const getAgreementDeviceInfo=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/agreement/agreementDeviceInfo',
        method:'post',
        params
    })
)
// 新增合同选择设备的信息列表
const getAddDeviceInfo=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/agreement/addDeviceInfo',
        method:'post',
        params
    })
)
//新增合同信息
const getAdd=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/agreement/add',
        method:'post',
        params
    })
)
//删除合同信息
const getDelete=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/agreement/delete',
        method:'post',
        params
    })
)
export {
    contractList,//首页table列表
    removecontractlist,//删除表格中的一条数据
    getAgreementDeviceInfo,//查询合同中设备的信息列表
    getAddDeviceInfo,//新增合同选择设备的信息列表
    getAdd,//新增合同信息
    getDelete,//删除合同信息
    
}

