import { render, staticRenderFns } from "./enterpriseInfoYS.vue?vue&type=template&id=24bcbefb&scoped=true&"
import script from "./enterpriseInfoYS.vue?vue&type=script&lang=js&"
export * from "./enterpriseInfoYS.vue?vue&type=script&lang=js&"
import style0 from "./enterpriseInfoYS.vue?vue&type=style&index=0&id=24bcbefb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24bcbefb",
  null
  
)

export default component.exports