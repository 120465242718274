import {httpRequest,baseUrl} from '../utils/request'
export {
    stockAllList,stockList,outboundList,stockYearTrend,disposalEnterpriseNamelist,outboundById,
    nventoryStatistics,categoryCodeStatistics,stockTrend,
}

// 入库记录-出库操作
const outboundById=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/stock/outboundById',
       method:'post' ,
       params
    })
)


// 查询处置企业名称-下拉框
const disposalEnterpriseNamelist=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/stock/disposalEnterpriseNamelist',
       method:'post' ,
       params
    })
)

// 查询实时库存列表
const stockAllList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/stock/stockAllList',
       method:'post' ,
       params
    })
)

// 查询入库记录列表
const stockList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/stock/stockList',
       method:'post' ,
       params
    })
)

// 查询出库记录列表
const outboundList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/stock/outboundList',
       method:'post' ,
       params
    })
)

// 实时库存统计-环形图
const nventoryStatistics=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/stock/nventoryStatistics',
       method:'post' ,
       params
    })
)

// 实时危废类别占比-环形图（百分比）
const categoryCodeStatistics=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/stock/categoryCodeStatistics',
       method:'post' ,
       params
    })
)

// 实时危废类别占比-环形图（百分比）
const stockTrend=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/stock/stockTrend',
       method:'post' ,
       params
    })
)

// 实时危废类别占比-环形图（百分比）
const stockYearTrend=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/stock/stockYearTrend',
       method:'post' ,
       params
    })
)