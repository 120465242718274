import CryptoJS from 'crypto-js'

// 此处key为16进制
const CBCKEY = CryptoJS.enc.Utf8.parse('385f33cb91484b04a177828829081ab7');

// AES-128-CBC偏移量
const CBCIV = CryptoJS.enc.Utf8.parse('abcdefgabcdefg12');

export default {

    /**
     * 加密
     * @param {*} word
     * @param {*} keyStr
     * @param {*} ivStr
     */
     encrypt (word, keyStr, ivStr) {
        // console.log('原值1：', word,keyStr,ivStr);

        let key = CBCKEY;
        let iv = CBCIV;
        if (keyStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
            iv = CryptoJS.enc.Utf8.parse(ivStr);
        }
        let srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });

        //console.log('加密后：', CryptoJS.enc.Base64.stringify(encrypted.ciphertext));
        return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    },

    /**
     * md5加密
     * @param {*} word
     * @param {*} keyStr
     * @param {*} ivStr
     */
    encryptByMd5 (word, keyStr, ivStr) {
        //console.log('原值2：', word);
        word = CryptoJS.MD5(word).toString();
        //console.log('md5加密后：', word);

        let key = CBCKEY;
        let iv = CBCIV;
        if (keyStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
            iv = CryptoJS.enc.Utf8.parse(ivStr);
        }
        let srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });

        //console.log('加密后：', CryptoJS.enc.Base64.stringify(encrypted.ciphertext));
        return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    },

    /**
     * 解密
     * @param {*} word
     * @param {*} keyStr
     * @param {*} ivStr
     */
    decrypt (word, keyStr, ivStr) {
        let key = CBCKEY;
        let iv = CBCIV;

        if (keyStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
            iv = CryptoJS.enc.Utf8.parse(ivStr);
        }

        let base64 = CryptoJS.enc.Base64.parse(word);
        let src = CryptoJS.enc.Base64.stringify(base64);

        let decrypt = CryptoJS.AES.decrypt(src, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });

        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        //console.log('解密后：', decryptedStr.toString());
        return decryptedStr.toString();
    },

}