import { render, staticRenderFns } from "./inventory.vue?vue&type=template&id=5a54b72c&scoped=true&"
import script from "./inventory.vue?vue&type=script&lang=js&"
export * from "./inventory.vue?vue&type=script&lang=js&"
import style0 from "./inventory.vue?vue&type=style&index=0&id=5a54b72c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a54b72c",
  null
  
)

export default component.exports