<template>
    <div class="tab2">
        <div class="operate">
            <HcButton :type="'primary'" icon="el-icon-plus" class="button" size="small" @click="addTab4" v-if="screen !=='screen'">新增</HcButton>
            <div v-else></div>
            <div class="search">
                <el-form ref="form" :model="params" label-width="80px" size="small" :inline="true">
                    <el-form-item label="">
                        <el-input clearable v-model="params.fuzzyField"></el-input>
                    </el-form-item>
                </el-form>
                <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="searchTable">查询
                </HcButton>
                <HcButton :type="'refresh'" icon="el-icon-refresh" size="small" class="rest" @click="restFrom">重置
                </HcButton>
            </div>
        </div>
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading">
            <el-table-column type="index" :index="indexMethod" label="序号" width="50">
            </el-table-column>
            <el-table-column prop="enterpriseName" label="企业名称">
            </el-table-column>
            <el-table-column prop="agreementInfoId" label="合同编号" width="220">
            </el-table-column>
            <el-table-column prop="signDate" label="签订时间" width="150">
            </el-table-column>
            <el-table-column prop="effectDate" label="生效日期" width="150">
            </el-table-column>
            <el-table-column prop="endDate" label="截至日期" width="150">
            </el-table-column>
            <el-table-column label="押金状态" width="120">
                <template slot-scope="scope">
                    <el-tag type="success" size="medium" v-if="scope.row.deviceFeePayStatus == 0">未支付</el-tag>
                    <el-tag type="warning" size="medium" v-if="scope.row.deviceFeePayStatus == 1">已支付</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="serviceFeeExpirationTime" label="服务费到期时间" width="120">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
            <el-table-column label="合同状态" width="150">
                <template slot-scope="scope">
                    <el-tag type="success" size="medium" v-if="scope.row.agreementStatus == 2">履行中</el-tag>
                    <el-tag type="warning" size="medium" v-if="scope.row.agreementStatus == 1">未生效</el-tag>
                    <el-tag type="danger" size="medium" v-if="scope.row.agreementStatus == 3">已失效</el-tag>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150" v-if="screen !=='screen'">
                <template slot-scope="scope">
                    <el-button @click="openDetailDiagle(scope.row)" type="text" size="default">详情</el-button>
                    <el-button type="text" size="default" @click="editTable(scope.row)">编辑</el-button>
                    <el-popconfirm title="是否确定删除所选数据？" @confirm="removeenterprise(scope.row)">
                        <el-button slot="reference" type="text" size="default" class="button_text_del">
                            删除
                        </el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 50px 0px;text-align: right;">
            <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="params.pageNum" :page-size="params.pageSize" :total="params.total" class="pagination">
            </HcPagination>
        </div>
    </div>
</template>
<script>
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from '@/api/contractApi';

export default {
    name: 'tab2',
    components: { HcPagination, HcButton },
    props: {
        editData: {
            type: Object
        },
        screen:{
            type:String
        }
    },
    data() {
        return {
            mainHeight: null,//中间高度
            tableHeight: null,//表格高度
            tableData: [],
            loading: false,
            params: {
                pageSize: 20,
                pageNum: 1,
                total: null,
                totalPage: null,
                enterpriseId: this.editData.enterpriseId,
                fuzzyField: '',//模糊查询字段
            },
        }
    },
    methods: {
        searchTable() {
            this.getContractList(this.params)
        },
        handleSizeChange(data) { //每页多少条
            this.params.pageSize = data//赋值给params
            this.getContractList(this.params)
        },
        handleCurrentChange(data) {//当前多少页
            this.params.pageNum = data//当前页
            this.getContractList(this.params)
        },
        //table序号
        indexMethod(index) {
            return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
        },
        // 获取table表格数据
        getContractList(params) {
            this.loading = true //请求时打开加载中
            server.contractList(params).then((res) => {
                if (res.code == 200) {
                    this.loading = false //成功关闭加载中
                    // 将获取的list赋值给table
                    this.tableData = res.data.list
                    // 设置分页的数据
                    this.params.totalPage = res.data.totalPage;//共多少页
                    this.params.total = res.data.total;//共多少条
                    this.params.pageNum = this.params.pageNum;//当前第几页
                    this.params.pageSize = this.params.pageSize;//每页显示多少条
                    this.params.enterpriseType = '1';
                }
            }).catch((err) => {
                setTimeout(() => {//失败2秒后关闭加载中
                    this.loading = false
                }, 2000)
                this.$message({
                    showClose: true,
                    message: err.message,
                    type: 'warning'
                });
            })
        },
        //重置
        restFrom() {
            this.params.fuzzyField = '';
            this.getContractList(this.params);
        },
        // 新增
        addTab4() {
            let data = JSON.stringify(this.editData);
            window.sessionStorage.setItem('wasteTab4Add', data);
            this.$router.push({
                name: 'contractList',//新增跳转到合同列表页面
                query: {
                    type: 'wasteTab4Add',
                    enterpriseId: this.editData.enterpriseId
                }
            })
        },
        openDetailDiagle(row) {//详情
            console.log(row, '详情')
            let data = JSON.stringify(row)
            window.sessionStorage.setItem('wasteTab4Detail', data)
            this.$router.push({
                name: 'contractList',//新增跳转到合同列表页面
                query: {
                    type: 'wasteTab4Detail'
                }
            })
        },
        editTable(row) {//编辑
            console.log(row, '详情')
            let data = JSON.stringify(row)
            window.sessionStorage.setItem('wasteTab4Edit', data)
            this.$router.push({
                name: 'contractList',//新增跳转到合同列表页面
                query: {
                    type: 'wasteTab4Edit'
                }
            })
        },
    },
    watch: {
        '$route.query': function (item) {//监听路由，如果是从合同列表进来，再次请求合同接口
            if (item.type == 'contract') {
                this.getContractList(this.params)
            }
        },
        'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
            this.tableHeight = item - 244
        }
    },
    mounted() {
        this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
    },
}

</script>
<style  lang="less" scoped>
.tab2 {

    ::v-deep .el-pagination {
        text-align: right !important;
        margin-top: 16px !important;
    }

    .operate {
        display: flex;
        justify-content: space-between;

        .search {
            display: flex;
        }

        .rest {
            margin-left: 8px;
        }
    }
}

.button_text_del {
    color: #F56C6C !important;
    padding-left: 10px;
}
</style>