<template>
    <div class="tab1">
        <div class="tabTit">基本资料</div>
        <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
                <template slot="label">
                    企业名称
                </template>
                {{ editData.enterpriseName }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    企业类型
                </template>
                {{ global.EnterpriseTypeMap.get(editData.enterpriseType) }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    所属区域
                </template>
                {{ editData.townAddress }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    详细地址
                </template>
                {{ editData.enterpriseAddress }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    经/纬度
                </template>
                {{ editData.longitude }},{{ editData.latitude }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    经营许可证
                </template>
                {{ editData.licenceNo }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    负责人
                </template>
                {{ editData.legalRepresent }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    联系人
                </template>
                {{ editData.contactor }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    联系电话
                </template>
                {{ editData.telephone }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    核准经营规模(吨)
                </template>
                {{ editData.businessScale }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    邮箱
                </template>
                {{ editData.email }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    备注
                </template>
                {{ editData.remark }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    许可证附件
                </template>
                <el-button size="mini" type="text" @click="handleDownload(editData.licencePath)">
                    {{ editData.licenceNo }}
                </el-button>
            </el-descriptions-item>
        </el-descriptions>
        <div class="yinye">
            <div>营业执照</div>
            <div class="yinye_img">
                <span v-if="editData.businessLicensePath == null">暂未上传</span>
                <span v-if="editData.businessLicensePath != null">
                    <HcUploadImage v-model="editData.businessLicensePath" :value="editData.businessLicensePath"
                        :isAllowDel="true" :isShowTip="false"></HcUploadImage>
                </span>
            </div>
        </div>
        <div class="tabTit">环评情况</div>
        <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
                <template slot="label">
                    是否有环评
                </template>
                {{ editData.isEia == '1' ? '是' : '否' }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    环评编号
                </template>
                <span v-if="editData.isEia == '1'">
                    {{ editData.eiaNo }}
                </span>
                <span v-if="editData.isEia != '1'">
                    暂未上传
                </span>
            </el-descriptions-item>
        </el-descriptions>
        <div class="yinye">
            <div>环评证书</div>
            <div class="yinye_img">
                <span v-if="editData.isEia != '1'">暂未上传</span>
                <span v-if="editData.isEia == '1'">
                    <HcUploadImage v-model="editData.eiaPath" :value="editData.eiaPath" :isAllowDel="true"
                        :isShowTip="false"></HcUploadImage>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import HcUploadImage from "@/components/HcUploadImage/index.vue";
export default {
    name: 'tab1',
    components: {
        HcUploadImage
    },
    props: {
        editData: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {

        }
    },
    methods: {
        // 下载附件
        handleDownload(ossId) {
            this.downloadOss(ossId);
        },
    },

}

</script>

<style lang="less" scoped>
.tab1 {
    padding: 0 10px 0 20px;
    margin-bottom: 80px;

    .tabTit {
        padding-bottom: 16px;
    }

    .yinye {
        display: flex;
        margin: 24px 0;

        .yinye_img {
            margin-left: 16px;
        }
    }

    ::v-deep .el-descriptions-item__label.is-bordered-label {
        background-color: #F5F5F5;
    }
}

::v-deep .el-descriptions-row th {
    width: 10vw;
}</style>