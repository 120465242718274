<template>
    <div class="tab1">

        <enterpriseDetail :enterpriseId="this.editData.enterpriseId"></enterpriseDetail>
        <div class="yinye">
            <div>营业执照</div>
            <div class="yinye_img">
                <span v-if="editData.businessLicensePath == null">暂未上传</span>
                <span v-if="editData.businessLicensePath != null">
                    <HcUploadImage v-model="editData.businessLicensePath" :value="editData.businessLicensePath"
                        :isAllowDel="true" :isShowTip="false"></HcUploadImage>
                </span>
            </div>
        </div>

        <div class="tabTit">环评情况</div>
        <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
                <template slot="label">是否有环评</template>{{ editData.isEia == 1 ? '是' : '否' }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    环评编号
                </template>
                <span v-if="editData.isEia == '1'">
                    {{ editData.eiaNo }}
                </span>
                <span v-if="editData.isEia != '1'">
                    暂未上传
                </span>
            </el-descriptions-item>
        </el-descriptions>
        <div class="yinye">
            <div>环评证书</div>
            <div class="yinye_img">
                <span v-if="editData.isEia != '1'">暂未上传</span>
                <span v-if="editData.isEia == '1'">
                    <HcUploadImage v-model="editData.eiaPath" :value="editData.eiaPath" :isAllowDel="true"
                        :isShowTip="false"></HcUploadImage>
                </span>
            </div>
        </div>

        <div class="tabTit">统计数据</div>
        <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item><template slot="label">当前设备数(个)</template>{{ statObj.deviceCount }}</el-descriptions-item>
            <el-descriptions-item><template slot="label">当前储存量(kg)</template>{{ statObj.ccNum }}</el-descriptions-item>
            <el-descriptions-item><template slot="label">总转移联单数(单)</template>{{ statObj.sheetCount }}</el-descriptions-item>
            <el-descriptions-item><template slot="label">总收处量(kg)</template>{{ statObj.scNum }}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>
<script>
import HcUploadImage from "@/components/HcUploadImage/index.vue";
import enterpriseDetail from '@/components/HcEnteMation/index.vue';
import * as server from "@/api/businessApi";

export default {
    name: 'tab1',
    components: {
        enterpriseDetail,
        HcUploadImage
    },
    props: {
        editData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            statObj: {
                deviceCount: 0,
                ccNum: 0.0,
                sheetCount: 0,
                scNum: 0.0,
            },
            params: {
                enterpriseType: '1',//产废企业
                enterpriseId: this.editData.enterpriseId,
            },
        }
    },
    methods: {
        // 获取table表格数据
        getStatMap(params) {
            server.statMapById(params).then((res) => {
                if (res.code == 200) {
                    this.statObj = res.data;
                }
            })
                .catch((err) => {
                    this.$message({
                        showClose: true,
                        message: err.message,
                        type: "warning",
                    });
                });
        },
    },
    mounted() {
        this.getStatMap(this.params);
    },
}
</script>

<style lang="less" scoped>
.tab1 {
    margin-bottom: 80px;

    .tabTit {
        padding-bottom: 16px;
    }

    .yinye {
        display: flex;
        margin: 24px 0;

        .yinye_img {
            margin-left: 16px;
        }
    }

    ::v-deep .el-descriptions-item__label.is-bordered-label {
        background-color: #F5F5F5;
    }
}

::v-deep .el-descriptions-row th {
    width: 10vw;
}
</style>