<template>
    <!-- 联单详情中  节点流程表格  信息展示 -->
    <div class="processTable">
        <div class="tabTit">流程记录</div>
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
            <el-table-column prop="processNo" label="流程节点" ></el-table-column>
            <el-table-column prop="user" label="操作人" ></el-table-column>
            <el-table-column prop="updateTime" label="操作时间" ></el-table-column>
            <el-table-column prop="enterpriseType" label="所属企业类型" ></el-table-column>
            <el-table-column prop="enterpriseName" label="所属企业名称" ></el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    name: 'processTable',
    data() {
        return {
            tableData: this.tableData
        }
    },
    props: {
        tableData: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        indexMethod(index) {
            return index + 1
        },
    },
    mounted() {
        
    },
}
</script>

<style type="less" scoped>
.tabTit {
    padding: 16px 0px;
}
</style>