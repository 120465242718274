<template>
  <div class="waste_production" v-if="showLog">
    <div class="title">
      <p class="tilte_p">{{ diagle }}</p>
      <slot name="close"></slot>
    </div>

    <div class="body">
      <div class="center">
        <slot name="center"></slot>
      </div>
      <div class="footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import HcButton from "@/components/HcButton/index.vue";
import HcInput from "@/components/HcInput/index.vue";
import HcSelect from "@/components/HcSelect/index.vue";
export default {
  components: { HcButton, HcInput, HcSelect },

  name: "AddWasteProduction",

  inject: ["title"],
  computed: {
    diagle() {
      return this.title();
    },
  },
  data() {
    return {
      showLog: false,
    };
  },
  methods: {
    close() {
      this.showLog = false; //关闭弹框
    },
    enter() {
      this.showLog = false; //关闭弹框
    },
  },
};
</script>

<style lang="less" scoped>
.waste_production {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: #fff;
  z-index: 99;
}

.title {
  display: flex;
  width: 100%;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  color: #808080;
  position: absolute;
  // padding: 20px 32px;
  // font-family: Source Han Sans CN;
  background-color: #f3f8ff;

  align-items: center;
  justify-content: space-between;
}

.tilte_p {
  padding: 0px 32px;
}

.title_close {
  padding: 0px 26px;
  color: #999999;
  cursor: pointer;
}

.body {
  top: 60px;
  position: relative;
  height: 100%;
  overflow: auto;
}

.body_title {
  font-size: 16px;
  color: #666666;
  padding: 24px 32px;
  font-weight: 500;
}

.body_detail_1 {
  height: 200px;
  padding: 0 32px;
  background: blanchedalmond;
}

.center {
  padding: 0px 32px;
  // width: 1000px;
  min-height: 580px;
}

.footer {
  margin-bottom: 40px;
  display: flex;
  padding: 20px 32px;
  justify-content: flex-end;

  :nth-child(2) {
    margin-left: 8px;
  }
}
</style>
