<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
    </template>
    <template v-slot:center>
      <el-form
        :model="params"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        size="small"
        class="demo-ruleForm"
      >
        <div class="card_tit">基本资料</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="企业名称" prop="enterpriseId">
              <el-select
                v-if="dialogType != 'edit'"
                placeholder="请选择企业名称"
                v-model="params.enterpriseId"
                clearable
                filterable
                @change="selectEnterpriseInfo"
                :disabled="isDisabled"
              >
                <el-option
                  v-for="item in enterpriseOptionArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select
                v-if="dialogType == 'edit'"
                v-model="params.enterpriseId"
                disabled
              >
                <el-option
                  v-for="item in enterpriseOptionArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业编号">
              <el-input
                v-model="params.enterpriseId"
                disabled
                placeholder="请输入企业编号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属区域">
              <HcAreaSelect
                @area="getArea"
                ref="HcAreaSelect"
                :selectedArea="enterpriseArea"
                v-model="enterpriseArea"
                placeholder="请选择所属区域"
                :key="componentsKey"
                :fatherDisabled="true"
              ></HcAreaSelect>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex" class="addressClass">
            <el-form-item label="详细地址">
              <el-input
                v-model="params.enterpriseAddress"
                disabled
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人">
              <el-input
                v-model="params.contactor"
                disabled
                clearable
                placeholder="请输入联系人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话">
              <el-input
                v-model="params.telephone"
                disabled
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="签订时间" prop="signDate">
              <el-date-picker
                v-model="params.signDate"
                type="date"
                placeholder="请选择签订时间"
                value-format="yyyy-MM-dd"
                :picker-options="pickerCreateTime"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生效日期" prop="effectDate">
              <el-date-picker
                v-model="params.effectDate"
                type="date"
                placeholder="请选择生效日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerStartTime"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="截止日期" prop="endDate">
              <el-date-picker
                v-model="params.endDate"
                type="date"
                placeholder="请选择截止日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerEndTime"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="合同附件" prop="agreementFilePath">
              <HcUploadFile
                v-model="params.agreementFilePath"
                :value="params.agreementFilePath"
              >
              </HcUploadFile>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="card_tit">设备费用情况</div>
        <el-row>
          <div style="width: 156px">
            <el-tabs v-model="feeActiveName" type="card">
              <el-tab-pane label="押金" name="1"></el-tab-pane>
              <el-tab-pane label="购买费" name="2"></el-tab-pane>
            </el-tabs>
          </div>
        </el-row>

        <div v-show="feeActiveName == '1'">
          <el-row>
            <el-col :span="12">
              <el-form-item label="押金缴纳金额(元)">
                <el-input-number
                  v-model="params.deviceFeeAmount"
                  :controls="false"
                  :min="0"
                  :precision="2"
                ></el-input-number>
                <!-- <el-input v-model="params.deviceFeeAmount"></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="缴纳状态">
                <el-select
                  v-model="params.deviceFeePayStatus"
                  clearable
                  placeholder="请选择缴纳状态"
                >
                  <el-option label="未支付" value="0"></el-option>
                  <el-option label="已支付" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-show="feeActiveName == '2'">
          <el-row>
            <el-col :span="12">
              <el-form-item label="购买费缴纳金额(元)">
                <el-input-number
                  v-model="params.deviceBuyFeeAmount"
                  :controls="false"
                  :min="0"
                  :precision="2"
                ></el-input-number>
                <!-- <el-input v-model="params.deviceBuyFeeAmount"></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="缴纳状态">
                <el-select
                  v-model="params.deviceBuyFeePayStatus"
                  clearable
                  placeholder="请选择缴纳状态"
                >
                  <el-option label="未支付" value="0"></el-option>
                  <el-option label="已支付" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-show="feeActiveName == '1' && params.deviceFeePayStatus == 1">
          <el-row>
            <el-col :span="12">
              <el-form-item label="缴纳时间" prop="deviceFeePayTime">
                <el-date-picker
                  v-model="params.deviceFeePayTime"
                  type="date"
                  placeholder="请选择缴纳时间"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付方式" prop="deviceFeePayType">
                <el-select
                  v-model="params.deviceFeePayType"
                  clearable
                  placeholder="请选择支付方式"
                >
                  <el-option label="现金" value="1"></el-option>
                  <el-option label="支付宝" value="2"></el-option>
                  <el-option label="微信" value="3"></el-option>
                  <el-option label="银联" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="凭证附件" prop="deviceFeePayCredit">
                <HcUploadFile
                  v-model="params.deviceFeePayCredit"
                ></HcUploadFile>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <div v-show="feeActiveName == '2' && params.deviceBuyFeePayStatus == 1">
          <el-row>
            <el-col :span="12">
              <el-form-item label="缴纳时间" prop="deviceBuyFeePayTime">
                <el-date-picker
                  v-model="params.deviceBuyFeePayTime"
                  type="date"
                  placeholder="请选择缴纳时间"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付方式" prop="deviceBuyFeePayType">
                <el-select
                  v-model="params.deviceBuyFeePayType"
                  clearable
                  placeholder="请选择支付方式"
                >
                  <el-option label="现金" value="1"></el-option>
                  <el-option label="支付宝" value="2"></el-option>
                  <el-option label="微信" value="3"></el-option>
                  <el-option label="银联" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="凭证附件" prop="deviceBuyFeePayCredit">
                <HcUploadFile
                  v-model="params.deviceBuyFeePayCredit"
                ></HcUploadFile>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <div class="card_tit">服务费用情况</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="缴纳金额(元)">
              <el-input-number
                v-model="params.serviceFeeAmount"
                :controls="false"
                :min="0"
                :precision="2"
              ></el-input-number>
              <!-- <el-input v-model="params.serviceFeeAmount"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="缴纳状态">
              <el-select
                v-model="params.serviceFeePayStatus"
                clearable
                placeholder="请选择缴纳状态"
              >
                <el-option label="未支付" value="0"></el-option>
                <el-option label="已支付" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-show="params.serviceFeePayStatus == 1">
          <el-row>
            <el-col :span="12">
              <el-form-item label="缴纳时间" prop="serviceFeePayTime">
                <el-date-picker
                  v-model="params.serviceFeePayTime"
                  type="date"
                  placeholder="请选择缴纳时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付方式" prop="serviceFeePayType">
                <el-select
                  v-model="params.serviceFeePayType"
                  clearable
                  placeholder="请选择支付方式"
                >
                  <el-option label="现金" value="1"></el-option>
                  <el-option label="支付宝" value="2"></el-option>
                  <el-option label="微信" value="3"></el-option>
                  <el-option label="银联" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="到期时间" prop="serviceFeeExpirationTime">
                <el-date-picker
                  v-model="params.serviceFeeExpirationTime"
                  type="date"
                  placeholder="请选择到期时间"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="凭证附件" prop="serviceFeePayCredit">
                <HcUploadFile
                  v-model="params.serviceFeePayCredit"
                ></HcUploadFile>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </template>
    <template v-slot:footer>
      <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')"
        >取消</HcButton
      >
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')"
        >确定</HcButton
      >
    </template>
  </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import HcUploadFile from "@/components/HcUploadFile/index.vue";
import HcAreaSelect from "@/components/HcAreaSelect/index.vue";
import * as server from "@/api/contractApi";

export default {
  name: "editDiagle",
  props: {
    editData: {
      type: Object,
      default: null,
    },
    enterpriseOptionArr: {
      type: Array,
      default: null,
    },
  },
  components: {
    HcDiaglePage,
    HcButton,
    HcUploadFile,
    HcAreaSelect,
  },
  watch: {
    "$route.query": function () {
      if (this.$route.query.type == "wasteTab4Add") {
        let data = JSON.parse(window.sessionStorage.getItem("wasteTab4Add"));
        this.setEnterpriseInfo(data);
      }
    },
  },
  mounted() {
    if (this.$route.query.type == "wasteTab4Add") {
      let data = JSON.parse(window.sessionStorage.getItem("wasteTab4Add"));
      this.setEnterpriseInfo(data);
    }
  },
  computed: {
    pickerCreateTime() {
      let _this = this;
      return {
        disabledDate: (time) => {
          if (_this.params.effectDate) {
            let planStartDate = _this.params.effectDate.replace(/-/g, "/");
            return time.getTime() > new Date(planStartDate);
          }
        },
      };
    },
    pickerStartTime() {
      let _this = this;
      return {
        disabledDate: (time) => {
          if (_this.params.signDate && _this.params.endDate) {
            let signDate = _this.params.signDate.replace(/-/g, "/");
            let endDate = _this.params.endDate.replace(/-/g, "/");
            return (
              time.getTime() < new Date(signDate) ||
              time.getTime() > new Date(endDate)
            );
          }

          if (_this.params.signDate && !_this.params.endDate) {
            let signDate = _this.params.signDate.replace(/-/g, "/");
            return time.getTime() < new Date(signDate);
          }

          if (!_this.params.signDate && _this.params.endDate) {
            let endDate = _this.params.endDate.replace(/-/g, "/");
            return time.getTime() > new Date(endDate);
          }
        },
      };
    },
    pickerEndTime() {
      let _this = this;
      return {
        disabledDate: (time) => {
          if (_this.params.effectDate) {
            let effectDate = _this.params.effectDate.replace(/-/g, "/");
            return time.getTime() < new Date(effectDate);
          }
        },
      };
    },
  },

  data() {
    //押金
    const validateDeviceFee1 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (this.params.deviceFeePayStatus == "0") {
          //未支付不校验
          callback();
        } else {
          if (value != "") {
            callback();
          } else {
            callback(new Error("请选择押金缴纳时间"));
          }
        }
      }
    };
    const validateDeviceFee2 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (this.params.deviceFeePayStatus == "0") {
          //未支付不校验
          callback();
        } else {
          if (value != null && value != "") {
            callback();
          } else {
            callback(new Error("请选择押金支付方式"));
          }
        }
      }
    };
    const validateDeviceFee3 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (this.params.deviceFeePayStatus == "0") {
          //未支付不校验
          callback();
        } else {
          if (value != null && value != "") {
            callback();
          } else {
            callback(new Error("请上传押金缴纳凭证"));
          }
        }
      }
    };

    //购买费
    const validateBuyFee1 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (this.params.deviceBuyFeePayStatus == "0") {
          //未支付不校验
          callback();
        } else {
          if (value != "") {
            callback();
          } else {
            callback(new Error("请选择购买费缴纳时间"));
          }
        }
      }
    };
    const validateBuyFee2 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (this.params.deviceBuyFeePayStatus == "0") {
          //未支付不校验
          callback();
        } else {
          if (value != null && value != "") {
            callback();
          } else {
            callback(new Error("请选择购买费支付方式"));
          }
        }
      }
    };
    const validateBuyFee3 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (this.params.deviceBuyFeePayStatus == "0") {
          //未支付不校验
          callback();
        } else {
          if (value != null && value != "") {
            callback();
          } else {
            callback(new Error("请上传购买费缴纳凭证"));
          }
        }
      }
    };

    //服务费
    const validateServiceFee1 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (this.params.serviceFeePayStatus == "0") {
          //未支付不校验
          callback();
        } else {
          if (value != "") {
            callback();
          } else {
            callback(new Error("请选择服务费用缴纳时间"));
          }
        }
      }
    };
    const validateServiceFee2 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (this.params.serviceFeePayStatus == "0") {
          //未支付不校验
          callback();
        } else {
          if (value != null && value != "") {
            callback();
          } else {
            callback(new Error("请选择服务费用支付方式"));
          }
        }
      }
    };
    const validateServiceFee3 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (this.params.serviceFeePayStatus == "0") {
          //未支付不校验
          callback();
        } else {
          if (value != null && value != "") {
            callback();
          } else {
            callback(new Error("请选择服务费用缴到期时间"));
          }
        }
      }
    };
    const validateServiceFee4 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (this.params.serviceFeePayStatus == "0") {
          //未支付不校验
          callback();
        } else {
          if (value != null && value != "") {
            callback();
          } else {
            callback(new Error("请上传服务费用缴纳凭证"));
          }
        }
      }
    };
    const validateServiceFee5 = (rule, value, callback) => {
      if (this.params == undefined) {
      } else {
        if (value != null && value != "") {
          callback();
        } else {
          callback(new Error("请上传合同附件"));
        }
      }
    };
    return {
      isDisabled: false,
      feeActiveName: "1",
      params: {
        agreementInfoNo: "", //序号
        agreementInfoId: "", //合同编号
        enterpriseName: "", //企业名称
        enterpriseId: "", //企业编号
        enterpriseAddress: "", //详细地址
        contactor: "", //联系人
        telephone: "", //
        effectDate: "", //生效日期
        endDate: "", //截止日期
        signDate: "", //签订日期
        agreementStatus: "", //合同状态
        agreementFilePath: "", //合同文件保存/下载路径
        agreementFileName: "", //合同文件名
        deviceFeeStatus: "", //设备费用情况：1代表押金，2代表购买

        deviceFeeAmount: "", //设备费用缴纳金额（押金）
        deviceFeePayType: "", //设备费用缴纳方式（押金）
        deviceFeePayStatus: "0", //设备费用缴纳状态（押金）
        deviceFeePayTime: "", //设备费用缴纳时间（押金）
        deviceFeePayCredit: "", //设备费用缴纳凭证（押金）

        deviceBuyFeeAmount: "", //设备费用缴纳金额（购买）
        deviceBuyFeePayType: "", //设备费用缴纳方式（购买）
        deviceBuyFeePayStatus: "0", //设备费用缴纳状态（购买）
        deviceBuyFeePayTime: "", //设备费用缴纳时间（购买）
        deviceBuyFeePayCredit: "", //设备费用缴纳凭证（购买）

        serviceFeeAmount: "", //服务费用缴纳金额
        serviceFeePayType: "", //服务费用缴纳方式
        serviceFeePayStatus: "0", //服务费用缴纳状态
        serviceFeePayTime: "", //服务费用缴纳时间
        serviceFeePayCredit: "", //服务费用缴纳凭证
        serviceFeeExpirationTime: "", //服务费用缴到期时间
      },
      componentsKey: 1,
      rules: {
        enterpriseId: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        signDate: [
          { required: true, message: "请选择签订时间", trigger: "blur" },
        ],
        effectDate: [
          { required: true, message: "请选择生效日期", trigger: "blur" },
        ],
        endDate: [
          { required: true, message: "请选择截止日期", trigger: "blur" },
        ],
        agreementFilePath: [
          { required: true, trigger: "change", validator: validateServiceFee5 },
        ],

        //押金
        deviceFeePayTime: [
          { required: true, trigger: "blur", validator: validateDeviceFee1 },
        ],
        deviceFeePayType: [
          { required: true, trigger: "blur", validator: validateDeviceFee2 },
        ],
        deviceFeePayCredit: [
          { required: true, trigger: "change", validator: validateDeviceFee3 },
        ],

        //购买费
        deviceBuyFeePayTime: [
          { required: true, trigger: "blur", validator: validateBuyFee1 },
        ],
        deviceBuyFeePayType: [
          { required: true, trigger: "blur", validator: validateBuyFee2 },
        ],
        deviceBuyFeePayCredit: [
          { required: true, trigger: "change", validator: validateBuyFee3 },
        ],

        //服务费
        serviceFeePayTime: [
          { required: true, trigger: "blur", validator: validateServiceFee1 },
        ],
        serviceFeePayType: [
          { required: true, trigger: "blur", validator: validateServiceFee2 },
        ],
        serviceFeeExpirationTime: [
          { required: true, trigger: "blur", validator: validateServiceFee3 },
        ],
        serviceFeePayCredit: [
          { required: true, trigger: "change", validator: validateServiceFee4 },
        ],
      },
      dialogType: "",
      enterpriseArea: [], //区域
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
    },

    /**
     * 获取当前时间
     * 格式YYYY-MM-DD
     */
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },

    showDiagle(data, data2) {
      console.log(data, data2, "合同1111111111111");
      this.$refs.hcDiaglePage.showLog = true;
      // 判断弹框是编辑页面还是新增页面
      this.dialogType = data;
      if (this.dialogType == "edit") {
        this.params.enterpriseName = this.editData.enterpriseName;
        this.params.enterpriseId = this.editData.enterpriseId;
        this.params.agreementInfoId = this.editData.agreementInfoId;
        this.params.enterpriseAddress = this.editData.enterpriseAddress;
        this.params.contactor = this.editData.contactor;
        this.params.telephone = this.editData.telephone;
        this.params.signDate = this.editData.signDate;
        this.params.effectDate = this.editData.effectDate;
        this.params.endDate = this.editData.endDate;
        this.params.deviceFeeStatus = this.editData.deviceFeeStatus; //设备费用情况：1代表押金，2代表购买费；

        this.params.deviceFeeAmount = this.editData.deviceFeeAmount;
        // this.$set(this.params,'deviceFeeAmount',this.editData.deviceFeeAmount)
        this.params.deviceFeePayStatus = this.editData.deviceFeePayStatus;
        this.params.deviceFeePayTime = this.editData.deviceFeePayTime;
        this.params.deviceFeePayType = this.editData.deviceFeePayType;
        this.params.deviceFeePayCredit = this.editData.deviceFeePayCredit;

        this.params.deviceBuyFeeAmount = this.editData.deviceBuyFeeAmount;
        this.params.deviceBuyFeePayStatus = this.editData.deviceBuyFeePayStatus;
        this.params.deviceBuyFeePayTime = this.editData.deviceBuyFeePayTime;
        this.params.deviceBuyFeePayType = this.editData.deviceBuyFeePayType;
        this.params.deviceBuyFeePayCredit = this.editData.deviceBuyFeePayCredit;

        this.params.serviceFeeAmount = this.editData.serviceFeeAmount;
        this.params.serviceFeePayStatus = this.editData.serviceFeePayStatus;
        this.params.serviceFeePayTime = this.editData.serviceFeePayTime;
        this.params.serviceFeePayType = this.editData.serviceFeePayType;
        this.params.serviceFeePayCredit = this.editData.serviceFeePayCredit;
        this.params.serviceFeeExpirationTime =
          this.editData.serviceFeeExpirationTime;

        this.params.agreementStatus = this.editData.agreementStatus;
        this.params.agreementFilePath = this.editData.agreementFilePath;
        this.params.agreementFileName = this.editData.agreementFileName;
        // this.enterpriseArea =
        //   this.editData.enterpriseProvince +
        //   this.editData.enterpriseCity +
        //   this.editData.enterpriseRegion +
        //   this.editData.enterpriseTown;
        this.enterpriseArea[0] = this.editData.enterpriseProvince;
        this.enterpriseArea[1] = this.editData.enterpriseCity;
        this.enterpriseArea[2] = this.editData.enterpriseRegion;
        this.enterpriseArea[3] = this.editData.enterpriseTown;
        this.params = JSON.parse(JSON.stringify(this.params));
      } else {
        //清空表单数据
        this.params = {
          agreementInfoNo: "", //序号
          agreementInfoId: "", //合同编号
          enterpriseName: "", //企业名称
          enterpriseId: "", //企业编号
          enterpriseAddress: "", //详细地址
          contactor: "", //联系人
          telephone: "", //
          effectDate: "", //生效日期
          endDate: "", //截止日期
          signDate: "", //签订日期
          agreementStatus: "", //合同状态
          agreementFilePath: "", //合同文件保存/下载路径
          agreementFileName: "", //合同文件名
          deviceFeeStatus: "", //设备费用情况：1代表押金，2代表购买

          deviceFeeAmount: "", //设备费用缴纳金额（押金）
          deviceFeePayType: "", //设备费用缴纳方式（押金）
          deviceFeePayStatus: "0", //设备费用缴纳状态（押金）
          deviceFeePayTime: "", //设备费用缴纳时间（押金）
          deviceFeePayCredit: "", //设备费用缴纳凭证（押金）

          deviceBuyFeeAmount: "", //设备费用缴纳金额（购买）
          deviceBuyFeePayType: "", //设备费用缴纳方式（购买）
          deviceBuyFeePayStatus: "0", //设备费用缴纳状态（购买）
          deviceBuyFeePayTime: "", //设备费用缴纳时间（购买）
          deviceBuyFeePayCredit: "", //设备费用缴纳凭证（购买）

          serviceFeeAmount: "", //服务费用缴纳金额
          serviceFeePayType: "", //服务费用缴纳方式
          serviceFeePayStatus: "0", //服务费用缴纳状态
          serviceFeePayTime: "", //服务费用缴纳时间
          serviceFeePayCredit: "", //服务费用缴纳凭证
          serviceFeeExpirationTime: "", //服务费用缴到期时间
        };

        this.enterpriseArea = [];
        console.log(this.enterpriseArea, "ppppp");
      }

      if (data2 != undefined && data2 != "") {
        this.setEnterpriseInfo(data);
      }
    },

    // 选择企业信息
    selectEnterpriseInfo(chooseId) {
      //查找符合企业id的其他信息
      let obj = this.enterpriseOptionArr.find((item) => {
        if (item.value == chooseId) {
          return item;
        }
      });
      this.params.enterpriseId = obj.value; //企业编号

      this.enterpriseArea[0] = obj.enterpriseProvince;
      this.enterpriseArea[1] = obj.enterpriseCity;
      this.enterpriseArea[2] = obj.enterpriseRegion;
      this.enterpriseArea[3] = obj.enterpriseTown;
      //   this.$refs.HcAreaSelect.selectedAreaArr = this.enterpriseArea;
      this.componentsKey += 1;
      //   console.log(this.$refs.HcAreaSelect.selectedAreaArr, "222");
      this.params.enterpriseAddress = obj.enterpriseAddress; //详细地址
      this.params.contactor = obj.contactor; //联系人
      this.params.telephone = obj.telephone; //联系电话
    },

    // 产废企业详情进入，没有企业列表选择企业名称
    setEnterpriseInfo(obj) {
      this.params.enterpriseId = obj.enterpriseId; //企业编号
      this.enterpriseOptionArr = [
        { value: obj.enterpriseId, label: obj.enterpriseName },
      ];
      this.enterpriseArea = obj.townAddress;
      this.params.enterpriseAddress = obj.enterpriseAddress; //详细地址
      this.params.contactor = obj.contactor; //联系人
      this.params.telephone = obj.telephone; //联系电话
      this.isDisabled = true;
    },

    add(params) {
      server
        .getAdd(params)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.$refs.hcDiaglePage.enter(); //关闭弹框
            if (
              this.$route.query.type == "wasteTab4Add" ||
              this.$route.query.type == "wasteTab4Edit"
            ) {
              //产废企业详情里的合同列表新增或编辑过来
              this.$router.push({
                name: "wasteProduction", //新增跳转到合同列表页面
                query: {
                  type: "contract",
                },
              });
            }
            this.$parent.queryList(); //调用列表页面，刷新列表
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: "warning",
          });
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$parent.detailTit = "企业详情";
          this.add(this.params); //调用新增接口
          this.feeActiveName = "1";
          this.enterpriseArea = [];
          this.params = {
            //清空表单数据
            agreementInfoNo: "", //序号
            agreementInfoId: "", //合同编号
            enterpriseName: "", //企业名称
            enterpriseId: "", //企业编号
            enterpriseAddress: "", //详细地址
            contactor: "", //联系人
            telephone: "", //
            effectDate: "", //生效日期
            endDate: "", //截止日期
            signDate: "", //签订日期
            agreementStatus: "", //合同状态
            agreementFilePath: "", //合同文件保存/下载路径
            agreementFileName: "", //合同文件名
            deviceFeeStatus: "", //设备费用情况：1代表押金，2代表购买

            deviceFeeAmount: "", //设备费用缴纳金额（押金）
            deviceFeePayType: "", //设备费用缴纳方式（押金）
            deviceFeePayStatus: "0", //设备费用缴纳状态（押金）
            deviceFeePayTime: "", //设备费用缴纳时间（押金）
            deviceFeePayCredit: "", //设备费用缴纳凭证（押金）

            deviceBuyFeeAmount: "", //设备费用缴纳金额（购买）
            deviceBuyFeePayType: "", //设备费用缴纳方式（购买）
            deviceBuyFeePayStatus: "0", //设备费用缴纳状态（购买）
            deviceBuyFeePayTime: "", //设备费用缴纳时间（购买）
            deviceBuyFeePayCredit: "", //设备费用缴纳凭证（购买）

            serviceFeeAmount: "", //服务费用缴纳金额
            serviceFeePayType: "", //服务费用缴纳方式
            serviceFeePayStatus: "0", //服务费用缴纳状态
            serviceFeePayTime: "", //服务费用缴纳时间
            serviceFeePayCredit: "", //服务费用缴纳凭证
            serviceFeeExpirationTime: "", //服务费用缴到期时间
          };
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.feeActiveName = "1";
      this.$parent.detailTit = "企业详情";
      if (
        this.$route.query.type == "wasteTab4Add" ||
        this.$route.query.type == "wasteTab4Edit"
      ) {
        this.$router.push({
          name: "wasteProduction", //新增跳转到合同列表页面
        });
      }
      this.isDisabled = false;
      this.$refs.hcDiaglePage.close();
      //清空表单数据
      this.params = {
        agreementInfoNo: "", //序号
        agreementInfoId: "", //合同编号
        enterpriseName: "", //企业名称
        enterpriseId: "", //企业编号
        enterpriseAddress: "", //详细地址
        contactor: "", //联系人
        telephone: "", //
        effectDate: "", //生效日期
        endDate: "", //截止日期
        signDate: "", //签订日期
        agreementStatus: "", //合同状态
        agreementFilePath: "", //合同文件保存/下载路径
        agreementFileName: "", //合同文件名
        deviceFeeStatus: "", //设备费用情况：1代表押金，2代表购买

        deviceFeeAmount: "", //设备费用缴纳金额（押金）
        deviceFeePayType: "", //设备费用缴纳方式（押金）
        deviceFeePayStatus: "0", //设备费用缴纳状态（押金）
        deviceFeePayTime: "", //设备费用缴纳时间（押金）
        deviceFeePayCredit: "", //设备费用缴纳凭证（押金）

        deviceBuyFeeAmount: "", //设备费用缴纳金额（购买）
        deviceBuyFeePayType: "", //设备费用缴纳方式（购买）
        deviceBuyFeePayStatus: "0", //设备费用缴纳状态（购买）
        deviceBuyFeePayTime: "", //设备费用缴纳时间（购买）
        deviceBuyFeePayCredit: "", //设备费用缴纳凭证（购买）

        serviceFeeAmount: "", //服务费用缴纳金额
        serviceFeePayType: "", //服务费用缴纳方式
        serviceFeePayStatus: "0", //服务费用缴纳状态
        serviceFeePayTime: "", //服务费用缴纳时间
        serviceFeePayCredit: "", //服务费用缴纳凭证
        serviceFeeExpirationTime: "", //服务费用缴到期时间
      };
    },
    // 省市区镇选择
    getArea(val) {
      this.ruleForm.enterpriseProvince = val.enterpriseProvince; //省
      this.ruleForm.enterpriseCity = val.enterpriseCity; //市
      this.ruleForm.enterpriseRegion = val.enterpriseRegion; //区
      this.ruleForm.enterpriseTown = val.enterpriseTown; //镇
      this.ruleForm.townAddress = val.townAddress; //省市区镇中文

      if (val.enterpriseProvince == "") {
        this.ruleForm.enterArea = [];
        this.ruleForm.townAddress = "";
      } else {
        this.ruleForm.enterArea = [
          val.enterpriseProvince,
          val.enterpriseCity,
          val.enterpriseRegion,
          val.enterpriseTown,
        ];
      }
    },
  },
};
</script>

<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}

::v-deep .el-input--small .el-input__inner {
  width: 300px;
}

::v-deep .el-input.el-input--small.el-input--suffix {
  width: 300px;
}

::v-deep .el-textarea__inner {
  width: 800px;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  background-color: #f3f8ff !important;
  margin: 0 !important;
  border-radius: 0 !important;
  color: #808080;
}

::v-deep .el-input-number .el-input__inner {
  text-align: left;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: #00bfff !important;
  background-color: #fff !important;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed !important;
  width: 100%;
  background-color: #f3f8ff !important;
}

.addressClass {
  display: flex;

  // align-items: center;
  .addressDiv {
    cursor: pointer;
  }

  .addressIcon {
    font-size: 24px;
    color: #e6a23c;
    margin-left: 6px;
  }

  span {
    font-size: 14px;
    color: #e6a23c;
  }
}

.zhineng {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

  .zhineng_add {
    font-size: 24px;
    color: #00bfff;
  }
}

.demo-ruleForm {
  margin-top: 20px;
}

::v-deep .footer {
  margin-bottom: 72px;
}
</style>
