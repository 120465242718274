<template>
    <!-- 企业详情展示 -->
    <div class="enterpriseDetail">
        <div class="tabTit">企业基本资料</div>
        <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
                <template slot="label">
                    企业名称
                </template>
                {{ enterpriseDeailObj.enterpriseName }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    企业类型
                </template>
                {{ global.EnterpriseTypeMap.get(enterpriseDeailObj.enterpriseType) }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    所属行业
                </template>
                {{ global.IndustryMap.get(enterpriseDeailObj.industry) }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    产废规模
                </template>
                {{ global.WasteScaleMap.get(enterpriseDeailObj.wasteScale) }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    所属区域
                </template>
                {{ enterpriseDeailObj.townAddress }}{{
                    enterpriseDeailObj.enterpriseTownStr }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    详细地址
                </template>
                {{ enterpriseDeailObj.enterpriseAddress }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    经纬度
                </template>
                {{ enterpriseDeailObj.longitude }} / {{ enterpriseDeailObj.latitude }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    统一信用代码
                </template>
                {{ enterpriseDeailObj.creditCode }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    法定代表人
                </template>
                {{ enterpriseDeailObj.legalRepresent }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    开户行
                </template>
                {{ enterpriseDeailObj.accountBank }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    账户
                </template>
                {{ enterpriseDeailObj.account }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    电话
                </template>
                {{ enterpriseDeailObj.phone }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    联系人
                </template>
                {{ enterpriseDeailObj.contactor }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    联系电话
                </template>
                {{ enterpriseDeailObj.telephone }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    邮箱
                </template>
                {{ enterpriseDeailObj.email }}
            </el-descriptions-item>
            <el-descriptions-item class="remarkmor">
                <template slot="label">
                    备注
                </template>
                <div class="">
                    {{ enterpriseDeailObj.remark }}
                </div>
            </el-descriptions-item>
        </el-descriptions>
    </div>
</template>
<script>
import * as server from '@/api/planApi';

export default {
    name: 'enterpriseDetail',
    data() {
        return {
            enterpriseDeailObj: {}
        }
    },
    props: {
        enterpriseId: {
            type: String,
            default: ''
        }
    },
    methods: {
        getEnterpriseDeatil() {
            if (this.enterpriseId == '') {

            } else {
                server.enterpriseDetail({
                    enterpriseId: this.enterpriseId,
                }).then((res) => {
                    this.enterpriseDeailObj = res.data;
                })
            }
        },
    },
    mounted() {
        this.getEnterpriseDeatil();
    },
    watch: {
        enterpriseId: function () {
            this.getEnterpriseDeatil();
        },
    },
}
</script>

<style lang="less" scoped>
.tabTit {
    padding: 16px 0px;
}

::v-deep .el-descriptions-row th {
    width: 10vw;
}

::v-deep .el-descriptions-item__content{
    width: 560px;
}
</style>