<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm"></i>
        </template>
        <template v-slot:center>
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm" size="small">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="危废类别" prop="categoryCode">
                            <el-select clearable filterable v-model="ruleForm.categoryCode" @change="updateSub1"
                                placeholder="请选择危废类别">
                                <el-option v-for="item in arr" :key="item.value" :label="item.value"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="危废类别名称">
                            <el-input v-model="ruleForm.categoryName" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="废物代码" prop="harmWasteCode">
                            <el-select clearable filterable v-model="ruleForm.harmWasteCode" @change="updateSub2"
                                placeholder="请选择废物代码">
                                <el-option v-for="item in subArr" :key="item.value" :label="item.value"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="废物名称">
                            <el-input v-model="ruleForm.harmWasteName" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="年经营规模(吨)" prop="businessScale">
                            <!-- <el-input v-model="ruleForm.businessScale"></el-input> -->
                            <el-input-number v-model="ruleForm.businessScale" :controls="false" :min="0" :precision="2"
                                clearable></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="经营方式" prop="businessMode">
                            <el-checkbox-group v-model="ruleForm.businessMode">
                                <el-checkbox label="1">收集</el-checkbox>
                                <el-checkbox label="2">贮存</el-checkbox>
                                <el-checkbox label="3">利用</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

        </template>
        <template v-slot:footer>
            <HcButton :type="'cancel'" size="small" @click="resetForm()">取消</HcButton>
            <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
        </template>
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";

import * as server from "@/api/businessApi";
import * as server2 from '@/api/systemApi';

export default {
    name: "tab3.vue",
    components: {
        HcDiaglePage,
        HcButton,
    },
    props: {
        editData: {
            type: Object,
        },
    },
    data() {
        return {
            arr: [],//所有类别
            subArr: [],//选择的类别下所有的代码

            ruleForm: {
                categoryCode: "",//危废代码
                categoryName: "",
                harmWasteCode: "",//废物代码
                harmWasteName: "",
                businessScale: "",//经营规模
                businessMode: [],//经营方式
                enterpriseId: "",//企业编号
                enterpriseType: "4",//企业类型
            },
            rules: {
                categoryCode: [
                    { required: true, message: '请选择危废类别', trigger: 'change' }
                ],
                harmWasteCode: [
                    { required: true, message: '请选择废物代码', trigger: 'change' }
                ],
                businessScale: [
                    { required: true, message: '请输入年经营规模(吨)', trigger: 'blur' }
                ],
                businessMode: [
                    { required: true, message: '请选择经营方式', trigger: 'blur', type: 'array', }
                ]
            },
        };
    },
    methods: {
        //废物类别选择后更新废物代码选择集
        updateSub1(categoryCode) {
            //设置类别名称
            let obj = this.arr.find((item) => {
                return item.value === categoryCode;
            });
            this.ruleForm.categoryName = obj.label;

            //设置类别代码
            this.ruleForm.harmWasteCode = '';
            this.ruleForm.harmWasteName = '';
            let newParams = { 'categoryCode': categoryCode };
            this.getWasteCode(newParams);
        },

        //废物代码选择后更新废物名称
        updateSub2(harmWasteCode) {
            //设置废物名称
            this.ruleForm.harmWasteName = this.ruleForm.categoryName + harmWasteCode;
        },

        //请求危废类别数据（一级）
        async getCategoryCode() {
            let res = await server2.categoryCodeList();
            if (res.code == 200) {
                this.arr = res.data;
            }
        },
        //请求废物代码数据（二级）
        async getWasteCode(params) {
            let res = await server2.wasteCodeList(params);
            if (res.code == 200) {
                this.subArr = res.data;
            }
        },

        showtab3Add() {
            this.$refs.hcDiaglePage.showLog = true;
            this.$parent.detailTit = "新增收处类别";
            this.ruleForm.categoryCode = "";
            this.ruleForm.categoryName = "";
            this.ruleForm.harmWasteCode = "";
            this.ruleForm.harmWasteName = "";
            this.ruleForm.businessScale = "";
            this.ruleForm.businessMode = [];
            this.ruleForm.enterpriseId = this.editData.enterpriseId;
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.add(this.ruleForm); //调用新增接口
                } else {
                    return false;
                }
            });
        },

        add(params) {
            server.addWaste(params).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        message: "新增成功",
                        type: "success",
                    });
                    this.resetForm();
                    let newParams = {
                        enterpriseId: this.editData.enterpriseId,
                        enterpriseType: '4',
                        pageSize: 20,
                        pageNum: 1,
                        total: null,
                        totalPage: null,
                    };
                    this.$parent.getSclbList(newParams); //调用父组件的刷新 table列表方法
                } else {
                    this.$message({
                        message: res.message,
                        type: "warning",
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: "warning",
                    });
                });
        },
        resetForm() {
            this.$refs.hcDiaglePage.close();
            this.$parent.detailTit = "处置企业详情";
            this.ruleForm = {
                categoryCode: "",//危废代码
                categoryName: "",
                harmWasteCode: "",//废物代码
                harmWasteName: "",
                businessScale: "",//经营规模
                businessMode: [],//经营方式
                enterpriseId: this.editData.enterpriseId,//企业编号
                enterpriseType: "4",//企业类型
            };
        },
    },
    mounted() {
        this.getCategoryCode();
    },
};
</script>
  
<style scoped lang="less">
.card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}

::v-deep .el-input--small .el-input__inner {
    width: 300px;
}

::v-deep .el-textarea__inner {
    width: 800px;
}

.demo-ruleForm {
    margin-top: 20px;
}
</style>