<template>
    <!-- 管理计划 -> 新增计划 ->新增危险废品贮存 -->
    <HcDiaglePage ref="hcDiaglePage">
      <template v-slot:close>
        <i class="el-icon-close title_close" @click="resetForm"></i>
      </template>
      <template v-slot:center>
        <div class="tabTit">新增危废贮存(收集)情况</div>
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="230px" class="demo-ruleForm" size="small">
          <el-row>
            <el-col :span="12" style="display: flex;">
              <el-form-item label="贮存设施(收集点)名称" prop="storageName">
                <el-input v-model="ruleForm.storageName" placeholder="请输入贮存设施(收集点)名称" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" style="display: flex;">
              <el-form-item label="贮存设施(收集点)类型" prop="storageType">
                <el-select v-model="ruleForm.storageType" clearable filterable placeholder="请选择贮存设施(收集点)类型">
                  <el-option label="生产性产生源" value="1"></el-option>
                  <el-option label="非生产性产生源" value="2"></el-option></el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12"  style="display: flex;">
              <el-form-item label="贮存设施(收集点)编码" prop="storageCode">
                <el-input v-model="ruleForm.storageCode" placeholder="请输入贮存设施(收集点)编码" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12"  style="display: flex;">
              <el-form-item label="面积/容积(㎡/m³)" prop="storageSize">
                <!-- <el-input v-model="ruleForm.storageSize" clearable></el-input> -->
                <el-input-number v-model="ruleForm.storageSize" :controls="false" :min="0" :precision="2"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12"  style="display: flex;">
              <el-form-item label="贮存(收集)能力(吨)" prop="storageCapacity">
                <!-- <el-input v-model="ruleForm.storageCapacity" clearable></el-input> -->
                <el-input-number v-model="ruleForm.storageCapacity" :controls="false" :min="0" :precision="4"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12"  style="display: flex;">
              <el-form-item label="截止上年度累计贮存(收集)量(吨)" prop="storageTotalQuantity">
                <!-- <el-input v-model="ruleForm.storageTotalQuantity" clearable></el-input> -->
                <el-input-number v-model="ruleForm.storageTotalQuantity" :controls="false" :min="0" :precision="4"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-slot:footer>        
        <HcButton :type="'cancel'" size="small" @click="resetForm()">取消</HcButton>
        <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
      </template>
    </HcDiaglePage>
  </template>
      
  <script>
  import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
  import HcButton from '@/components/HcButton/index.vue';
  
  export default {
    name: "addWasteStorage",
    components: {
      HcDiaglePage,
      HcButton
    },
    data() {
      return {
        ruleForm: {
          storageName: "",
          storageType: "",
          storageCode: "",
          storageSize: "",
          storageCapacity: "",
          storageTotalQuantity: "",
        },
        rules: {
          storageName: [
              { required: true, message: "请填写贮存设施(收集点)名称", trigger: "blur" },
          ],
          storageType: [
              { required: true, message: "请选择贮存设施(收集点)类型", trigger: "change" },
          ],
          storageCode: [
              { required: true, message: "请选择贮存设施(收集点)编码", trigger: "change" },
          ],
          storageSize: [
              { required: true, message: "请填写面积/容积", trigger: "blur" },
          ],
          storageCapacity: [
              { required: true, message: "请填写贮存(收集)能力", trigger: "blur" },
          ],
          storageTotalQuantity: [
              { required: true, message: "请填写截止上年度累计贮存(收集)量", trigger: "blur" },
          ],
        },
      };
    },
    methods: {
      showAddWasteStorage() {
        this.$refs.hcDiaglePage.showLog = true;
        
        this.ruleForm = {
          storageName: "",
          storageType: "",
          storageCode: "",
          storageSize: "",
          storageCapacity: "",
          storageTotalQuantity: "",
        };
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
              this.$message({
                message: '新增危废贮存(收集)情况成功',
                type: 'success'
              });

              //本页面不提交到数据库，在外层提交数据库
              this.$emit('change', this.ruleForm);
              this.$refs.hcDiaglePage.enter();//关闭弹框

            } else {
                return false;
            }
        });
      },
  
      resetForm() {
        this.$refs.hcDiaglePage.close();
        this.$parent.detailTit = '新增管理计划'
      },
  
    },
  };
  </script>
      
  <style scoped lang="less">
  .card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
  }
  
  ::v-deep .el-input-number .el-input__inner{
    text-align: left;
  }
  
  ::v-deep .el-input--small .el-input__inner {
    width: 300px;
  }
  
  ::v-deep .el-textarea__inner {
    width: 800px;
  }
  .tabTit {
        padding-bottom: 16px;
        margin-top: 20px;
    }
  </style>
      