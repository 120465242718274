<template>
  <div class="hc_header_box">
    <div class="header_name">
      <i class="iconfont icon-zijian"></i>
      <span>小微危废监控云平台</span>
    </div>
    <div class="header_user">
      <div class="daping" @click="goScreen">
        <i class="el-icon-monitor"></i>
      </div>
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link user_img">
          <img src="../../assets/userImg2.png" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a">修改密码</el-dropdown-item>
          <el-dropdown-item command="b">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 修改密码弹框 -->
      <el-dialog
        title="修改密码"
        :visible.sync="dialogTableVisible"
        center
        width="30%"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          @keydown.enter.native="submitForm('ruleForm')"
        >
          <el-form-item label="原密码" prop="oldPassword">
            <el-input
              clearable
              v-model="ruleForm.oldPassword"
              size="mini"
              show-password
              auto-complete="new-password"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword1">
            <el-input
              clearable
              v-model="ruleForm.newPassword1"
              size="mini"
              show-password
              auto-complete="new-password"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="newPassword2">
            <el-input
              clearable
              v-model="ruleForm.newPassword2"
              size="mini"
              show-password
              auto-complete="new-password"
            ></el-input>
          </el-form-item>
          <div class="sub_mit">
            <el-button @click="resetForm('ruleForm')">取消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确定</el-button
            >
          </div>
        </el-form>
      </el-dialog>
      <!-- 退出登录弹框 -->
      <el-dialog
        title="退出登录"
        :visible.sync="dialogTableVisible2"
        center
        width="30%"
      >
        <div class="log_out">是否确认退出登录？</div>
        <div class="sub_mit">
          <el-button @click="logOutres('ruleForm')">取消</el-button>
          <el-button type="primary" @click="logOutEnter('ruleForm')"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import * as serve from "@/api/loginApi";
import aes from "@/utils/aes";

export default {
  name: "hcHeader",
  data() {
    return {
      dialogTableVisible: false,
      dialogTableVisible2: false,
      ruleForm: {
        userId: "",
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: "blur",
          },
        ],
        newPassword1: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "密码长度在 6 到 16 个字符",
            trigger: "blur",
          },
          {
            validator: this.isValidateTxtNonSpec,
            message: "密码不能包含空格",
            trigger: "blur",
          },
        ],
        newPassword2: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "密码长度在 6 到 16 个字符",
            trigger: "blur",
          },
          {
            validator: this.isValidateTxtNonSpec,
            message: "密码不能包含空格",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 校验密码是否有空格
    isValidateTxtNonSpec(rule, value, callback) {
      if (value != null && value !== "") {
        if (value.indexOf(" ") !== -1) {
          callback(new Error("密码不能包含空格"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },

    handleCommand(command) {
      //判断如果点击了退出，清理token 并跳转到登录页面
      if (command === "b") {
        this.dialogTableVisible2 = true;
      } else if (command === "a") {
        //如果点击了修改密码，弹框
        this.dialogTableVisible = true;
      }
    },
    // 退出登录确定
    logOutEnter() {
      let userId = JSON.parse(sessionStorage.getItem("userInfo")).userId;
      console.log(userId, "useId");
      serve
        .getlogOut({
          userId: userId,
          openid: "",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "登出成功",
              type: "success",
            });
            localStorage.removeItem("token");
            sessionStorage.removeItem("userInfo");
            sessionStorage.removeItem("menu");
            sessionStorage.removeItem("tabsItem");
            localStorage.removeItem("userMation");
            this.$router.push({
              name: "login",
            });
          } else {
            this.$message({
              showClose: true,
              message: "登出失败",
              type: "warning",
            });
          }
        });
    },
    // 退出登录取消
    logOutres() {
      this.dialogTableVisible2 = false;
    },
    // 修改密码确定
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //如果验证成功
          if (this.ruleForm.newPassword1 == this.ruleForm.newPassword2) {
            //新密码和确认密码相同
            this.ruleForm.userId = JSON.parse(
              sessionStorage.getItem("userInfo")
            ).userId;
            let params = {
              ///重新定义一个变量接受参数，为了防止解密后验证不通过
              userId: this.ruleForm.userId,
            };
            params.oldPassword = aes.encrypt(this.ruleForm.oldPassword);
            params.newPassword1 = aes.encrypt(this.ruleForm.newPassword1);
            params.newPassword2 = aes.encrypt(this.ruleForm.newPassword2);
            serve.getUpdatePassword(params).then((res) => {
              //调用更新密码接口
              if (res.code == 200) {
                //成功提示密码修改成功，重新登录，并清空token,跳转到登录页
                this.$message({
                  message: res.message,
                  type: "success",
                });
                localStorage.removeItem("token");
                this.$router.push({
                  name: "login",
                });
              } else if (res.code == 500) {
                this.$message({
                  message: res.message,
                  type: "warning",
                });
              }
            });
          } else {
            //新密码和确认密码不同的情况，提示信息
            this.$message({
              message: "新密码和确认密码不一致，请重新输入",
              type: "warning",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 修改密码取消
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogTableVisible = false;
    },
    goScreen() {
      this.$router.push("/screen");
    },
  },
};
</script>
<style scoped lang="less">
.hc_header_box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header_name {
    color: #ffffff;
    display: flex;
    align-items: center;

    i {
      font-size: 30px;
    }

    span {
      font-size: 18px;
      padding: 0 4px;
    }
  }

  .header_user {
    cursor: pointer;
    display: flex;
  }

  .user_img {
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      margin-top: 14px;
    }
  }

  .daping {
    font-size: 24px;
    color: #ffffff;
    padding-right: 40px;
    cursor: pointer;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
  }

  .sub_mit {
    display: flex;
    justify-content: center;
  }

  .log_out {
    text-align: center;
    font-size: 16px;
    margin-bottom: 24px;
  }
}
</style>
