<template>
    <!-- 联单详情中  联单详细  信息展示 -->
    <div class="sheetDetail">
        <div class="tabTit">联单基本信息</div>
        <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
                <template slot="label">
                    联单编号
                </template>
                {{ sheetObj.sheetInfoId }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                   创建时间
                </template>
                {{ sheetObj.createTime }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    联单状态
                </template>
                {{ global.SheetStatusMap.get(sheetObj.status)}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    危废类别
                </template>
                {{ sheetObj.categoryCode }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    废物代码
                </template>
                {{ sheetObj.harmWasteCode }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    废物名称
                </template>
                {{ sheetObj.harmWasteName }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    产废企业名称
                </template>
                {{ sheetObj.producterEnterpriseName }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    运输企业名称
                </template>
                {{ sheetObj.transformEnterpriseName }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    收处企业名称
                </template>
                {{ sheetObj.receiveEnterpriseName }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    申报量(kg)
                </template>
                {{ sheetObj.applyWeight }}
            </el-descriptions-item>
        </el-descriptions>

        <div class="tabTit">产废企业信息</div>
        <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
                <template slot="label">
                    产废企业名称
                </template>
                {{ enterpriseDeailObj.enterpriseName }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    所属区域
                </template>
                {{ enterpriseDeailObj.townAddress}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    产废企业地址
                </template>
                {{ enterpriseDeailObj.enterpriseAddress }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    联系人
                </template>
                {{ enterpriseDeailObj.contactor }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    联系电话
                </template>
                {{ enterpriseDeailObj.telephone }}
            </el-descriptions-item>
        </el-descriptions>
    </div>
</template>
<script>
import * as server from '@/api/planApi';

export default {
    name: 'sheetDetail',
    data() {
        return {
            sheetObj:this.editData,
            enterpriseId:this.editData.producterEnterpriseId,
            enterpriseDeailObj: {}
        }
    },
    props: {
        editData: {
            type: Object,
            default: '' 
        }
    },
    methods: {
        getEnterpriseDeatil() {
            if(this.enterpriseId == ''){

            }else{
                server.enterpriseDetail({
                    enterpriseId: this.enterpriseId,
                }).then((res) => {
                    this.enterpriseDeailObj = res.data;
                })
            }
        },
    },
    mounted() {
        this.getEnterpriseDeatil();
    },
}
</script>

<style type="less" scoped>
.tabTit {
    padding: 16px 0px;
}
::v-deep .el-descriptions-row th {
    width: 10vw;
}

</style>