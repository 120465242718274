<template>
  <div class="knowledgeList" ref="knowledgeList">

    <!-- 左侧列表 -->
    <div class="left_list">
      <div class="left_list_topbox">
        <span class="left_list_title">知识目录</span>
        <i class="el-icon-circle-plus" @click="addMenu"></i>
      </div>
      <div class="left_list_main">
        <div class="list_box" v-for="(item) in titleList" :key="item.knowledgeParentId"
          :style="titleType == item.knowledgeParentId ? getStyle(item) : null">
          <div>
            <i class="el-icon-s-management" :style="titleType == item.knowledgeParentId ? getfontcolor(item) : null"></i>

            <el-popover placement="bottom-start" width="200" trigger="hover" v-if="item.knowledgeParentName.length > 5">
              <p style="margin:0">{{ item.knowledgeParentName }}</p>
              <span slot="reference" class="list_name1" @click="titleClick('title', item.knowledgeParentId)"
                :style="titleType == item.knowledgeParentId ? getfontcolor(item) : null">{{ item.knowledgeParentName
                }}</span>
            </el-popover>

            <span v-if="item.knowledgeParentName.length <= 5" class="list_name1"
              @click="titleClick('title', item.knowledgeParentId)"
              :style="titleType == item.knowledgeParentId ? getfontcolor(item) : null">{{
                item.knowledgeParentName }}</span>
            <span class="list_name2" :style="titleType == item.knowledgeParentId ? getfontcolor(item) : null"
              @click="titleClick('title', item.knowledgeParentId)">（{{
                item.total
              }}）</span>

          </div>
          <el-popover placement="right" width="auto" trigger="click">
            <el-button class="button_edit" @click="editName(item)">编辑</el-button>
            <template>
              <el-popconfirm confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-warning"
                @confirm="DeleteMeun(item)" class="confirm-button-style" icon-color="#E6A23C"
                title="是否确定删除所选栏目，该栏目下的所有文章将会删除？" placement="right">
                <el-button slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
            <i class="el-icon-more" :style="titleType == item.knowledgeParentId ? getfontcolor(item) : null"
              @click="editbutton" slot="reference">
            </i>
          </el-popover>
        </div>
      </div>
    </div>
    <!-- 右侧主体 -->
    <div class="body_main">
      <!-- 新增、搜索、查询  -->
      <div class="main_top">
        <HcButton :type="'primary'" icon="el-icon-plus" class="add_button" size="small" @click="addDiagle">新增内容</HcButton>
        <div class="top_right">
          <el-form ref="form" :model="params" size="small" :inline="true">
            <el-form-item label="">
              <el-input v-model="params.knowledgeName"></el-input>
            </el-form-item>
          </el-form>
          <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="queryList">查询</HcButton>
          <HcButton :type="'refresh'" icon="el-icon-refresh" size="small" @click="reset">重置</HcButton>
        </div>

      </div>
      <!-- 表格 -->
      <div class="main_body">
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading"
          element-loading-text="加载中">
          <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
          <el-table-column prop="knowledgeName" label="标题"></el-table-column>
          <el-table-column prop="createUser" label="创建人" width="200"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="250"></el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <!-- <el-button @click="openDetailDiagle(scope.row)" type="text" size="default">预览</el-button> -->
              <el-popconfirm title="是否确定下载所选数据？" @confirm="downloadtext(scope.row.ossId)">
                <el-button slot="reference" type="text" size="default">下载</el-button>
              </el-popconfirm>
              <!-- <el-button @click="downloadtext(scope.row.ossId)" type="text" size="default">下载</el-button> -->

              <el-button type="text" size="default" @click="editTable(scope.row)"
                style="margin-left: 10px;">编辑</el-button>
              <el-popconfirm title="是否确定删除所选数据？" @confirm="removeknowledge(scope.row)">
                <el-button slot="reference" type="text" size="default" class="button_text_del">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <!-- 分页 -->
      <div class="main_botton">
        <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.pageNum"
          :page-size="params.pageSize" :total="params.total" class="pagestyle">
        </HcPagination>
      </div>
    </div>
    <editDiagle ref="editDiagle" :editData="editData"></editDiagle>
    <editMenu ref="editMenu" :editMenuData="editMenuData"></editMenu>
  </div>
</template>
  
<script>
import HcButton from '@/components/HcButton/index.vue';
import HcPagination from "@/components/HcPagination/index.vue";
import editDiagle from "./pages/editDiagle.vue";//编辑页面
import editMenu from "./pages/editMenu.vue";

import * as server from "@/api/knowledgeApi"
// import { download } from '@/utils/request';

export default {
  name: 'knowledgeList',
  components: {
    HcButton,
    HcPagination,
    editDiagle,
    editMenu,
  },
  data() {
    return {
      parentId: '1',
      tableData: [],
      invisible: false,
      visible: false,
      titleType: '1',//默认加载第一个目录
      titleList: [],
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        knowledgeName: '',//题目
        knowledgeParentId: '1',//给定默认目录id
      },
      loading: false,//加载中
      detailTit: '新增内容',
      editData: null,//编辑页面数据,
      editMenuData: null,
      mainHeight: null,//中间高度
      tableHeight: null,//表格高度
    }
  },
  provide() {
    //依赖注入，传递值 使用函数的形式可以修改值
    return {
      title: () => this.detailTit,
      AddOpenMet: () => this.AddOpen(),
      // DetailOpenMet: (data) => this.DetailOpen(data),
      EditOpenMet: (data) => this.EditOpen(data),

    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1
    },
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(data) { //每页多少条
      console.log(data)
      this.params.pageSize = data//赋值给params
      this.getKnowledgelist(this.params)//重新请求获取table的接口
    },
    handleCurrentChange(data) {//当前多少页
      console.log(data)
      this.params.pageNum = data//当前页
      this.getKnowledgelist(this.params)//重新获取table的接口
    },
    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1//将当前页面设置为第一页
      this.params.knowledgeParentId = this.parentId
      this.getKnowledgelist(this.params)
    },
    // 重置按钮
    reset() {
      this.params.knowledgeName = ""; //题目
      this.queryList()
    },
    // 获取table表格数据
    getKnowledgelist(params) {
      this.loading = true //请求时打开加载中
      server.knowledgelist(params).then((res) => {
        if (res.code == 200) {
          this.loading = false //成功关闭加载中
          // 将获取的list赋值给table
          this.tableData = res.data.list
          // 设置分页的数据
          this.params.pageNum = res.data.pageNum
          this.params.pageSize = res.data.pageSize
          this.params.total = res.data.total
          this.params.totalPage = res.data.totalPage
        }
      }).catch((err) => {
        setTimeout(() => {//失败2秒后关闭加载中
          this.loading = false
        }, 2000)
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
    },
    //获取左侧目录
    getKnowledgeParentList() {
      this.loading = true //请求时打开加载中
      server.knowledgeParentList().then((res) => {
        if (res.code == 200) {
          this.loading = false //成功关闭加载中
          // 将获取的list赋值给table
          this.titleList = res.data
          console.log(this.titleList, 'titlelist')
        }
      }).catch((err) => {
        setTimeout(() => {//失败2秒后关闭加载中
          this.loading = false
        }, 2000)
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
    },

    //删除列表中的一条信息
    removeknowledge(row) {
      console.log(row, 'handle_remove')
      server.deleteKnowledge({
        knowledgeInfoNo: row.knowledgeInfoNo
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.params.knowledgeParentId = row.knowledgeParentId
          this.getKnowledgeParentList(this.params)
          this.getKnowledgelist(this.params)//重新请求获取table的接口
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      }).catch((err) => {
        this.$message({
          message: err.message,
          type: 'warning'
        });
      });
    },
    // 新增弹框
    addDiagle() {
      // 调用打开弹框的方法
      this.detailTit = '新增内容'
      setTimeout(() => {
        this.$refs.editDiagle.showDiagle('add');
      })
    },
    editTable(row) {//打开编辑页面
      // 编辑页面数据
      this.editData = row
      this.editData.titleList = this.titleList
      this.detailTit = '编辑内容'
      setTimeout(() => {
        this.$refs.editDiagle.showDiagle('edit');//打开编辑页面 
      })
    },
    //新增目录弹框
    addMenu() {
      this.detailTit = '新增目录'
      // 调用打开弹框的方法
      setTimeout(() => {
        this.$refs.editMenu.showDiagle('add');
      },);
    },
    //修改左侧目录
    editName(row) {
      this.detailTit = '编辑目录'
      this.editMenuData = row
      // 调用打开弹框的方法
      setTimeout(() => {
        this.$refs.editMenu.showDiagle('edit');

      },);

    },
    DeleteMeun(item) {
      server.deleteParentName({
        knowledgeParentId: item.knowledgeParentId
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.getKnowledgeParentList(this.params)
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      }).catch((err) => {
        this.$message({
          message: err.message,
          type: 'warning'
        });
      });
    },
    // 文件预览
    // openDetailDiagle(row) {
    //   window.open("https://blog.csdn.net/a1783118/article/details/127787464", "新窗口的名字")
    // },

    //下载文件
    downloadtext(ossId) {
      console.log('下载', ossId)
      this.downloadOss(ossId);
    },
    editbutton() {
      console.log(1111)
    },
    //选中的列表改变背景色
    getStyle(item) {
      if (item.knowledgeParentId) {
        return { 'background-color': 'rgba(0, 191, 255, 0.1)' }
      }
      return {}
    },
    // 选中的列表文字图标改变颜色
    getfontcolor(item) {
      if (item.knowledgeParentId) {
        return { 'color': '#00BFFF' }
      }
      return {}
    },
    // 点击title切换颜色
    titleClick(type, data) {
      console.log(data, '点击title切换颜色')
      this.titleType = data
      this.parentId = data
      this.params.knowledgeParentId = data
      this.getKnowledgelist(this.params)
    },

    getHeight() {//动态计算中间内容部分高度
      let bodyHeight = document.body.clientHeight
      let contentHeight = bodyHeight - 148
      console.log(bodyHeight, contentHeight, 'height')
      this.$refs.knowledgeList.style.height = contentHeight + 'px'
      sessionStorage.setItem('knowledgeListHeight', contentHeight)
    }
  },
  mounted() {
    // 初始化左侧目录
    this.getKnowledgeParentList();
    // 首页表格数据初始化
    this.getKnowledgelist(this.params);
    this.getHeight();
    this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度

  },
  watch: {
    'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
      console.log(item, '高度')
      this.tableHeight = item - 153
    }
  }

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.knowledgeList {
  min-height: 500px;
  background: #ffffff;
  display: flex;
}

.left_list {
  width: 12%;
  height: 100%;
  box-shadow: 2px 0px 0px 0px rgba(187, 187, 187, 0.2);
}

.left_list_topbox {
  background: white;
  width: 100%;
  height: 68px;
  display: flex;
  box-shadow: 0px 2px 0px 0px rgba(187, 187, 187, 0.2);
  justify-content: space-between
}

// 知识目录样式
.left_list_title {
  width: auto;
  height: 50%;
  color: #666666;
  font-size: 16px;
  padding-top: 22px;
  padding-left: 18px;
}

// 新增按钮图标
.el-icon-circle-plus {
  width: 27px;
  height: 27px;
  font-size: 27px;
  color: #00BFFF;
  padding-right: 12px;
  padding-top: 20px;
  cursor: pointer;
}

// 左侧列表主区
.left_list_main {
  height: 100%;
  width: 100%;
}

// 左侧列表图标
.el-icon-s-management {
  width: 14px;
  height: 14px;
  color: #808080;
  padding-top: 18px;
  padding-left: 21px;
  padding-right: 10px;
  font-size: 14px;
}

// 三个点
.el-icon-more {
  width: 14px;
  height: 14px;
  color: #808080;
  padding-top: 18px;
  padding-right: 18px;
  font-size: 14px;
}

.list_box {
  height: 48px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.button_edit {
  margin-right: 10px;
}

.body_main {
  width: 88%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.main_top {
  width: 100%;
  // height: 116px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // background: lightblue;
}

.add_button {
  padding: 40px 0 0 22px;
}

.top_right {
  display: flex;
  padding: 40px 22px 0 0;
}

.button {
  padding: 0 10px 0 0;
}

//表格里的 删除按钮
.button_text_del {
  color: #F56C6C !important;
  padding-left: 10px;
}

.main_body {
  width: 100%;
  padding: 0 20px;
  // background: lightgreen;

}

.main_botton {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  // background: lightcyan;
}

.pagestyle {
  padding-top: 42px;
  padding-bottom: 22px;
  padding-right: 20px;
}

// 表格样式
::v-deep .el-table thead th {
  background-color: #EEEEEE;
}

::v-deep .el-table .el-table__cell {
  padding: 0px;
}

::v-deep .el-table thead {
  height: 50px;

}

::v-deep .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
  border-right: 1px solid #DBDBDB;

}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #EFF0F2;
  border-right: 1px solid #DBDBDB;
}

.list_name1 {
  width: 80px; // (一定要加宽度）/ 
  overflow: hidden; //超出的文本隐藏 / 
  text-overflow: ellipsis; // 溢出用省略号 / 
  white-space: nowrap; // 溢出不换行 /
  display: inline-block;
  position: relative;
  top: 4px;
}

::v-deep .el-popover {
  padding: 0 !important;
}
</style>
  