<!-- 箩筐地图 -->
<template>
  <div class="overView">
    <HcListMain>
      <template v-slot:header_from>
        <el-form ref="form" label-width="80px" size="small" :inline="true">
          <el-form-item label="企业名称">
            <el-select
              v-model="params.enterpriseId"
              placeholder="请选择企业名称"
              clearable
              filterable
            >
              <el-option
                v-for="item in enterpriseOptionArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备状态">
            <el-select
              v-model="params.deviceStates"
              clearable
              filterable
              placeholder="请选择设备状态"
            >
              <el-option
                v-for="item in MAP2JSON(global.DeviceStatusMap)"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:header_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-search"
          class="button"
          size="small"
          @click="search('sarch')"
          >查询
        </HcButton>
        <HcButton
          :type="'refresh'"
          icon="el-icon-refresh"
          size="small"
          @click="reset"
          >重置</HcButton
        >
      </template>
      <template v-slot:content_table>
        <div id="map"></div>
        <div ref="conMapInfo" class="winInfo">
          <!-- <div class="topJiantou"></div> -->
          <div class="winInfoTit">
            <div class="info_tit" v-if="infoData">{{ infoData.name }}</div>
            <i class="el-icon-close" @click="closeWinInfoShow"></i>
          </div>
          <div class="info_cent" v-if="infoData">
            <div>设备名称：{{ infoData.deviceName }}</div>
            <div>危废代码：{{ infoData.harmWasteCode }}</div>
            <div>危废类别：{{ infoData.categoryName }}</div>
            <div>当前所属企业：{{ infoData.enterpriseName }}</div>
          </div>
          <div class="info_button">
            <HcButton
              :type="'primary'"
              class="button"
              size="mini"
              @click="openDetailDiagle(infoData)"
              >设备台账
            </HcButton>
            <HcButton
              :type="'primary'"
              class="button2"
              size="mini"
              @click="goCheck(infoData)"
              >危废监测</HcButton
            >
          </div>
        </div>
      </template>
    </HcListMain>

    <!-- 详情页面 -->
    <detailDiagle ref="detailDiagle"></detailDiagle>

    <!-- 设备检测 -->
    <check ref="check"></check>
  </div>
</template>

<script>
import HcListMain from "@/components/HcListMain/index.vue";
import HcButton from "@/components/HcButton";

import detailDiagle from "./pages/detailDiagle.vue"; //详情页面
import check from "./pages/check.vue"; //设备检测页面

import * as server from "@/api/deviceApi";
import * as server2 from "@/api/planApi";

export default {
  name: "overview",
  components: {
    HcListMain,

    HcButton,
    detailDiagle,
    check,
  },
  data() {
    return {
      mass: "",
      text: "",
      map: "",
      overData: [],
      infoData: "",
      detailTit: "",
      editData: null,
      params: {
        //搜索条件
        enterpriseId: "",
        deviceStates: "",
      },
      enterpriseOptionArr: [], //获取的企业名称数组
    };
  },
  provide() {
    return {
      title: () => this.detailTit,
    };
  },
  methods: {
    // 创建地图
    init() {
      var position = new LKMap.LngLat(120.27, 31.9);
      this.map = new LKMap.Map("map", {
        center: position,
        zoom: 10,
      });
      var style = [
        {
          url: "https://hcxhwf.com/hasm/icon/zhengchang.png",
          size: 0.8,
          style: 3,
        },
        {
          url: "https://hcxhwf.com/hasm/icon/guzhang.png",
          size: 0.8,
          style: 2,
        },
        {
          url: "https://hcxhwf.com/hasm/icon/baojing.png",
          size: 0.8,
          style: 1,
        },
        //企业
        {
          url: "https://hcxhwf.com/hasm/icon/chanfei.png",
          size: 0.8,
          style: 7,
        },
        {
          url: "https://hcxhwf.com/hasm/icon/yunshu.png",
          size: 0.8,
          style: 6,
        },
        {
          url: "https://hcxhwf.com/hasm/icon/shouji.png",
          size: 0.8,
          style: 5,
        },
        {
          url: "https://hcxhwf.com/hasm/icon/chuzhi.png",
          size: 0.8,
          style: 4,
        },
      ];

      // 添加海量点组件
      let that = this;
      that.map.plugin(["MassMarks"], function () {
        that.mass = new LKMap.MassMarks(that.overData, {
          map: that.map,
          style: style,
        });
      });

      //   窗体
      let inforWindow = null;
      // 添加信息窗体
      inforWindow = new LKMap.InforWindow({
        anchor: "bottom",
        className: "customClassName",
        content: that.$refs.conMapInfo,
        isCustom: true, //使用自定义窗体
        showShadow: true, // 控制是否显示信息窗体阴影
        offset: new LKMap.Pixel(98, -20),
        showShadowOffset: new LKMap.Pixel(0, -11), // 设置阴影偏移量
      });
      // 地图点位点击事件
      that.mass.on("click", (e) => {
        console.log(e, "111");
        that.infoData = e.data;
        inforWindow.open(
          this.map,
          new LKMap.LngLat(e.data.lnglat[0], e.data.lnglat[1])
        );
      });
    },
    // 关闭地图自定义窗口
    closeWinInfoShow() {
      this.map.clearInfoWindow();
    },
    // 获取危废箱

    getOverviewList(params, isSearch) {
      this.loading = true; //请求时打开加载中
      server
        .getAllSimpleDeviceList3(params)
        .then((res) => {
          this.loading = false; //成功关闭加载中
          console.log(res.data, "params");
          this.overData = res.data;
          //   this.overData.push(res.data[0]);
          this.init();
        })
        .catch((err) => {
          setTimeout(() => {
            //失败2秒后关闭加载中
            this.loading = false;
          }, 2000);
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });
    },
    // 获取产废企业下拉框数据
    async getEnterpriseOption() {
      let params = { enterpriseType: "1" };
      await server2.allEnterpriseList(params).then((res) => {
        this.enterpriseOptionArr = res.data;
      });
    },
    // 打开详情页面
    openDetailDiagle(row) {
      console.log(row, "去设备台账222");
      this.editData = row;
      this.detailTit = "智能危废箱详情";
      setTimeout(() => {
        this.$refs.detailDiagle.showDiagle("detail");
        this.$refs.detailDiagle.queryDeviceDetail({ deviceNo: row.deviceNo });
        this.$refs.detailDiagle.queryCurrentDate({ deviceId: row.deviceNo });
        this.$refs.detailDiagle.queryHistoryMonitor({
          deviceId: row.deviceNo,
          pageSize: 20,
          pageNum: 1,
        });
        this.$refs.detailDiagle.queryDeviceAlarm({
          deviceId: row.deviceNo,
          pageSize: 20,
          pageNum: 1,
        });
        this.$refs.detailDiagle.queryDeviceTrouble({
          deviceId: row.deviceNo,
          pageSize: 20,
          pageNum: 1,
        });
      });
    },
    search(type) {
      this.getOverviewList(this.params, type);
    },
    reset() {
      this.params = {
        enterpriseId: "",
        deviceStates: "",
      };
      this.search();
    },

    // 打开设备监测
    goCheck(row) {
      this.editData = row;
      //console.log(row, 'row')
      this.$refs.check.queryDeviceDetail({ deviceNo: row.deviceNo });
      this.$refs.check.showDiagle(row);
      this.detailTit = "危废监测";
    },
  },
  mounted() {
    setTimeout(() => {
      this.getOverviewList();
    }, 1000);
    this.getEnterpriseOption();
  },
  watch: {},
};
</script>
<style lang="less" scoped>
@import url("https://lbs.luokuang.com/jsdemo/example/css/demo.css");
.overView {
  min-height: 700px;
  // height: 100%;
  background: #ffffff;

  .map {
    width: 100%;
    height: 600px;
  }

  .info_tit {
    font-size: 14px;
    color: #00bfff;
    padding-bottom: 8px;
  }

  .info_cent {
    font-size: 14px;
    color: #808080;
    line-height: 24px;
  }

  .button2 {
    margin-left: 8px;
  }

  .button {
    padding: 0 10px 0 0;
  }

  .HcBotton_box .el-button {
    padding: 8px 16px;
  }
}

.bm-view {
  width: 1000px;
  height: 1000px;
}

// 高德地图
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 800px;
  margin-bottom: 20px;
}

.winInfo {
  width: 220px;
  // height: 180px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 16px;
  position: relative;

  .topJiantou {
    width: 0;
    height: 0;
    border-width: 0 12px 10px;
    border-style: solid;
    border-color: transparent transparent #ffffff;
    position: absolute;
    top: 220px;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }

  .winInfoTit {
    display: flex;
    justify-content: space-between;

    .el-icon-close {
      cursor: pointer;
    }
  }

  .info_button {
    display: flex;
    justify-content: space-between;

    ::v-deep .list_main .el-button {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }
}

::v-deep .waste_production {
  z-index: 999;
}
</style>
