import { render, staticRenderFns } from "./addWasteProduct.vue?vue&type=template&id=9066289e&scoped=true&"
import script from "./addWasteProduct.vue?vue&type=script&lang=js&"
export * from "./addWasteProduct.vue?vue&type=script&lang=js&"
import style0 from "./addWasteProduct.vue?vue&type=style&index=0&id=9066289e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9066289e",
  null
  
)

export default component.exports