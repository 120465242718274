/**
 * 所有下拉框的字典定义
 */

//产生源类型
const ProductSourceType = new Map([
    ["1", "生产性产生源"],
    ["2", "非生产性产生源"],
]);

//物理性状
const PhysicalCharacter = new Map([
    ["1", "固态"],
    ["2", "液态"],
    ["3", "半固态"],
]);

//单位类型
const EnterpriseTypeMap = new Map([
    ["1", "产废企业"],
    ["2", "运输企业"],
    ["3", "收集企业"],
    ["4", "处置企业"],
]);

//产废规模
const WasteScaleMap = new Map([
    ["1", "1吨/年以下"],
    ["2", "1(含)-10吨/年"],
    ["3", "10(含)-100吨/年"],
    ["4", "100(含)-500吨/年"],
    ["5", "500(含)-1000吨/年"],
    ["6", "1000吨/年及以上"],
]);

//所属行业
const IndustryMap = new Map([
    ["A", "农、林、牧、渔业"],
    ["B", "采矿业"],
    ["C", "制造业"],
    ["D", "电力、热力、燃气及水生产和供应业"],
    ["E", "建筑业"],
    ["F", "批发和零售业"],
    ["G", "交通运输、仓储和邮政业"],
    ["H", "住宿和餐饮业"],
    ["I", "信息传输、软件和信息技术服务业"],
    ["J", "金融业"],
    ["K", "房地产业"],
    ["L", "租赁和商务服务业"],
    ["M", "科学研究和技术服务业"],
    ["N", "水利、环境和公共设施管理业"],
    ["O", "居民服务、修理和其他服务业"],
    ["P", "教育"],
    ["Q", "卫生和社会工作"],
    ["R", "文化、体育和娱乐业"],
    ["S", "公共管理、社会保障和社会组织"],
    ["T", "国际组织"],
]);

//联单状态
const SheetStatusMap = new Map([
    ["1", "已申请"],
    ["2", "已派车"],
    ["3", "已上车"],
    ["4", "运输中"],
    ["5", "五必查确认"],
    ["6", "已入库"],
    ["7", "已撤销"],
    ["8", "已驳回"],
]);

//流程节点
const ProcessMap = new Map([
    ["1", "联单申请"],
    ["2", "运输确认"],
    ["3", "危废上车"],
    ["4", "联单运输"],
    ["5", "五必查确认"],
    ["6", "联单办结"],
]);

//设备状态
const DeviceStatusMap = new Map([
    ["1", "正常"],
    ["2", "报警"],
    ["3", "故障"],
]);

//设备型号
const DeviceModelMap = new Map([
    ["1", "50L"],
    ["2", "200L"],
    ["3", "1T"],
]);

//设备版本
const DeviceVersionMap = new Map([
    ["1", "智能危废箱1.0"],
    ["2", "智能危废箱2.0"],
]);

//设备型号【贮存处】
const StorageModelMap = new Map([
    ["4", "W"],
]);

//设备版本【贮存处】
const StorageVersionMap = new Map([
    ["1", "危废贮存1.0"],
    ["2", "危废贮存2.0"],
]);

//经营方式
const BusinessModeMap = new Map([
    ["1", "收集"],
    ["2", "贮存"],
    ["3", "利用"],
]);

//车辆类型
const CarTypeMap = new Map([
    ["1", "罐式专用运输车"],
    ["2", "箱式专用运输车"],
]);

//处置类型
const DisposalTypeMap = new Map([
    ["1", "焚烧"],
    ["2", "填埋"],
    ["3", "利用"],
]);
//废物形态
const WasteFormMap = new Map([
    ["1", "液体"],
    ["2", "固体"],
    ["3", "气体"],
]);

//支付方式
const PayTypeMap = new Map([
    ["1", "现金"],
    ["2", "支付宝"],
    ["3", "微信"],
    ["4", "银联"],
]);

//危废类别与名称【20230705已废弃使用】
const CategoryCodeNameMap = new Map([
    ["HW01", "医疗废物"],
    ["HW02", "医药废物"],
    ["HW03", "废药物、药品"],
    ["HW04", "农药废物"],
    ["HW05", "木材防腐剂废物"],
    ["HW06", "废有机溶剂与含有机溶剂废"],
    ["HW07", "热处理含氰废物"],
    ["HW08", "废矿物油与含矿物油废物"],
    ["HW09", "油/水、烃/水混合物或乳化液"],
    ["HW10", "多氯（溴联苯类废物）"],
    ["HW11", "精（蒸）馏残渣"],
    ["HW12", "染料、涂料废物"],
    ["HW13", "有机树脂类废物"],
    ["HW14", "新化学物质废物"],
    ["HW15", "爆炸性废物"],
    ["HW16", "感光材料废物"],
    ["HW17", "表面处理废物"],
    ["HW18", "焚烧处置残渣"],
    ["HW19", "含金属羰基化合物废物"],
    ["HW20", "含铍废物"],
    ["HW21", "含铬废物"],
    ["HW22", "含铜废物"],
    ["HW23", "含锌废物"],
    ["HW24", "含砷废物"],
    ["HW25", "含硒废物"],
    ["HW26", "含镉废物"],
    ["HW27", "含锑废物"],
    ["HW28", "含碲废物"],
    ["HW29", "含汞废物"],
    ["HW30", "含铊废物"],
    ["HW31", "含铅废物"],
    ["HW32", "无机氟化物废物"],
    ["HW33", "无机氰化物废物"],
    ["HW34", "废酸"],
    ["HW35", "废碱"],
    ["HW36", "石棉废物"],
    ["HW37", "有机磷化合物废物"],
    ["HW38", "有机氰化物废物"],
    ["HW39", "含酚废物"],
    ["HW40", "含醚废物"],
    ["HW45", "含有机卤化物废物"],
    ["HW46", "含镍废物"],
    ["HW47", "含钡废物"],
    ["HW48", "有色金属采选和冶炼废物"],
    ["HW49", "其他废物"],
    ["HW50", "废催化剂"]
]);

/**
 * 将上面定义的map转成下拉框能直接使用的json
 * @param {*} map  已定义的map
 */
export function MAP2JSON(map) {
    let arr = [];
    for (let [key, value] of map) {
        arr.push({
            "value": key,
            "label": value
        });
    }
    return arr;
}

export default {
    install(Vue, options) {
        Vue.prototype.global = {
            EnterpriseTypeMap: EnterpriseTypeMap,
            WasteScaleMap: WasteScaleMap,
            IndustryMap: IndustryMap,
            CategoryCodeNameMap: CategoryCodeNameMap,
            SheetStatusMap: SheetStatusMap,
            ProcessMap: ProcessMap,
            DeviceStatusMap: DeviceStatusMap,
            ProductSourceType: ProductSourceType,
            PhysicalCharacter: PhysicalCharacter,
            BusinessModeMap: BusinessModeMap,
            PayTypeMap: PayTypeMap,
            DeviceModelMap: DeviceModelMap,
            DisposalTypeMap: DisposalTypeMap,
            StorageModelMap: StorageModelMap,
            DeviceVersionMap: DeviceVersionMap,
            StorageVersionMap: StorageVersionMap,
            CarTypeMap: CarTypeMap,
            WasteFormMap: WasteFormMap,
        };
    }
}