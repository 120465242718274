<template>
    <div class="tab2">
        <div class="operate">
            <div></div>
            <div class="search">
                <el-form ref="form" :model="params" label-width="80px" size="small" :inline="true">
                    <el-form-item label="">
                        <el-input clearable v-model="params.fuzzyField"></el-input>
                    </el-form-item>
                </el-form>
                <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="searchTable">查询
                </HcButton>
                <HcButton :type="'refresh'" icon="el-icon-refresh " size="small" class="rest" @click="restFrom">重置
                </HcButton>
            </div>
        </div>
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading">
            <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
            <el-table-column prop="sheetInfoId" label="联单编号" width="180"></el-table-column>
            <el-table-column prop="producterEnterpriseName" label="产废企业名称" width="200"></el-table-column>
            <el-table-column prop="townAddress" label="所属区域" min-width="200"></el-table-column>
            <el-table-column prop="transformEnterpriseName" label="运输企业名称" width="200"></el-table-column>
            <el-table-column prop="transformDriver" label="运输司机" width="100"></el-table-column>
            <el-table-column prop="transportation" label="运输车辆" width="100"></el-table-column>
            <el-table-column prop="receiveEnterpriseName" label="收处企业名称" width="200"></el-table-column>
            <el-table-column prop="categoryCode" label="危废类别" width="120"></el-table-column>
            <el-table-column prop="harmWasteCode" label="废物代码" width="120"></el-table-column>
            <el-table-column prop="harmWasteName" label="废物名称" width="150"></el-table-column>
            <el-table-column prop="applyWeight" label="申报量(kg)" width="120"></el-table-column>
            <el-table-column prop="transferWeight" label="转移量(kg)" width="120"></el-table-column>
            <el-table-column prop="receiveWeight" label="接收量(kg)" width="120"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
            <el-table-column prop="transformTime" label="运输时间" width="180"></el-table-column>
            <el-table-column prop="storeTime" label="收处时间" width="180"></el-table-column>
            <el-table-column label="联单状态" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.status == '1'"><el-tag type="info" size="medium">已申请</el-tag></span>
                    <span v-if="scope.row.status == '2'"><el-tag type="success" size="medium">已派车</el-tag></span>
                    <span v-if="scope.row.status == '3'"><el-tag type="success" size="medium">已上车</el-tag></span>
                    <span v-if="scope.row.status == '4'"><el-tag type="success" size="medium">运输中</el-tag></span>
                    <span v-if="scope.row.status == '5'"><el-tag type="success" size="medium">五必查确认</el-tag></span>
                    <span v-if="scope.row.status == '6'"><el-tag type="success" size="medium">已入库</el-tag></span>
                    <span v-if="scope.row.status == '7'"><el-tag type="warning" size="medium">已撤销</el-tag></span>
                    <span v-if="scope.row.status == '8'"><el-tag type="danger" size="medium">已驳回</el-tag></span>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 50px 0px;text-align: right;">
            <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="params.pageNum" :page-size="params.pageSize" :total="params.total" class="pagination">
            </HcPagination>
        </div>
    </div>
</template>
<script>
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from '@/api/sheetApi';

export default {
    name: 'tab6',
    components: { HcPagination, HcButton },
    props: {
        editData: {
            type: Object,
        },
    },
    data() {
        return {
            mainHeight: null,//中间高度
            tableHeight: null,//表格高度
            tableData: [],
            loading: false,
            params: {
                enterpriseId: this.editData.enterpriseId,
                pageSize: 20,
                pageNum: 1,
                total: null,
                totalPage: null,
                fuzzyField: '',//模糊查询字段
            },
        }
    },
    methods: {
        //table序号
        indexMethod(index) {
            return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
        },
        // 获取table表格数据
        getList(params) {
            this.loading = true //请求时打开加载中
            server.list(params).then((res) => {
                this.loading = false //成功关闭加载中
                // 将获取的list赋值给table
                this.tableData = res.data.list

                // 设置分页的数据
                this.params.totalPage = res.data.totalPage;
                this.params.total = res.data.total;
                this.params.pageNum = this.params.pageNum;
                this.params.pageSize = this.params.pageSize;

            }).catch((err) => {
                setTimeout(() => {//失败2秒后关闭加载中
                    this.loading = false
                }, 2000)
                this.$message({
                    showClose: true,
                    message: err.message,
                    type: 'warning'
                });
            })
        },
        //查询列表
        searchTable() {
            this.getList(this.params);
        },
        //重置
        restFrom() {
            this.params.fuzzyField = '';
            this.getList(this.params);
        },
        handleSizeChange(data) {
            this.params.pageSize = data;//每页多少条
            this.getList(this.params)
        },
        handleCurrentChange(data) {
            this.params.pageNum = data;//当前多少页
            this.getList(this.params);
        },
    },
    watch: {
        'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
            this.tableHeight = item - 244
        }
    },
    mounted() {
        this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
    },
}

</script>
<style  lang="less" scoped>
.tab2 {

    ::v-deep .el-pagination {
        text-align: right !important;
        margin-top: 16px !important;
    }

    .operate {
        display: flex;
        justify-content: space-between;

        .search {
            display: flex;
        }

        .rest {
            margin-left: 8px;
        }
    }
}</style>