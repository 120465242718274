<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm"></i>
        </template>
        <template v-slot:center>
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm" size="small">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="司机姓名" prop="driverName">
                            <el-input clearable v-model.trim="ruleForm.driverName" placeholder="请输入司机姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="司机电话" prop="driverPhone">
                            <el-input clearable v-model.trim="ruleForm.driverPhone" placeholder="请输入司机电话"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

        </template>
        <template v-slot:footer>
            <HcButton :type="'cancel'" size="small" @click="resetForm()">取消</HcButton>
            <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>

        </template>
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/businessApi";

export default {
    name: "tab3.vue",
    components: {
        HcDiaglePage,
        HcButton,
    },
    props: {
        editData: {
            type: Object,
        },
    },
    data() {
        const checkPhone = (rule, value, callback) => {
            const reg = /^(((\d{3,4}-)?[0-9]{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/;
            if (!value) {
                callback();
            }
            setTimeout(() => {
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error("请输入正确的司机电话格式"));
                }
            }, 100);
        };
        return {
            ruleForm: {
                driverName: "",//司机姓名
                driverPhone: "",//司机电话
                enterpriseId: "",//企业编号
            },
            rules: {
                // 司机姓名
                driverName: [
                    { required: true, message: "请输入司机姓名", trigger: "blur" },
                ],
                // 司机电话
                driverPhone: [
                    { required: true, message: "请输入司机电话", trigger: "blur", },
                    { validator: checkPhone, trigger: "blur" }
                ],
            },
        };
    },
    methods: {
        showtab3Add() {
            this.$refs.hcDiaglePage.showLog = true;
            this.$parent.detailTit = "新增司机信息";
            this.ruleForm.driverName = "";
            this.ruleForm.driverPhone = "";
            this.ruleForm.enterpriseId = this.editData.enterpriseId;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.add(this.ruleForm); //调用新增接口
                } else {
                    return false;
                }
            });
        },
        add(params) {
            server.addDriver(params).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        message: "新增成功",
                        type: "success",
                    });
                    this.resetForm();

                    let newParams = {
                        enterpriseId: this.editData.enterpriseId,
                        pageSize: 20,
                        pageNum: 1,
                        total: null,
                        totalPage: null,
                    };
                    this.$parent.getDriverList(newParams); //调用父组件的刷新 table列表方法
                } else {
                    this.$message({
                        message: res.message,
                        type: "warning",
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: "warning",
                    });
                });
        },
        resetForm() {
            this.$refs.hcDiaglePage.close();
            this.$parent.detailTit = "运输企业详情";
            this.ruleForm = {
                driverName: "",//司机姓名
                driverPhone: "",//司机电话
                enterpriseId: this.editData.enterpriseId,//企业编号
            }
        },
    },
};
</script>
  
<style scoped lang="less">
.card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}


::v-deep .el-input--small .el-input__inner {
    width: 300px;
}

::v-deep .el-textarea__inner {
    width: 800px;
}

.demo-ruleForm {
    margin-top: 20px;
}

::v-deep .el-input.el-input--small.el-input--suffix {
    width: 300px;
}
</style>