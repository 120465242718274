<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm"></i>
        </template>
        <template v-slot:center>
            <div class="tab1">
                <el-descriptions class="margin-top" :column="2" border>
                    <el-descriptions-item>
                        <template slot="label">
                            企业名称
                        </template>
                        {{ detailData.enterpriseName }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            走访人账号
                        </template>
                        {{ detailData.userId }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            走访时间
                        </template>
                        {{ detailData.interviewTime }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            走访原因
                        </template>
                        {{ detailData.interviewDescript }}
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </template>
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";

export default {
    name: "tab7detail.vue",
    components: {
        HcDiaglePage,
    },
    props: {
        detailData: {
            type: Object,
        },
    },
    data() {
        return {

        };
    },
    methods: {
        showtab7Detail() {
            this.$refs.hcDiaglePage.showLog = true;
            this.$parent.detailTit = "走访记录详情";
        },
        resetForm() {
            this.$refs.hcDiaglePage.close();
            this.$parent.detailTit = "产废企业详情";
        },
    },
};
</script>
  
<style scoped lang="less">
.card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}


::v-deep .el-input--small .el-input__inner {
    width: 300px;
}

::v-deep .el-textarea__inner {
    width: 800px;
}

.tab1 {
    margin-top: 20px;
}
::v-deep .el-descriptions-row th {
    width: 10vw;
}
</style>