import {
    httpRequest,
    baseUrl
} from '../utils/request'

//企业名称下拉框
const enterpriseList = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/enterprise/allEnterpriseList',
        method: 'post',
        params
    })
)
// 查用户列表
const userList = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/userInfo/userList',
        method: 'post',
        params
    })
)
// 新增一条用户数据
const addUser = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/userInfo/addUser',
        method: 'post',
        params
    })
)

// 删除一条用户数据
const deleteUser = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/userInfo/deleteUser',
        method: 'post',
        params
    })
)
// 编辑用户数据
const editUser = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/userInfo/edit',
        method: 'post',
        params
    })
)
// 查所属角色下拉框
const queryRoleNameList = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/userInfo/queryRoleNameList',
        method: 'post',
        params
    })
)
// 查角色列表
const roleList = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/roleInfo/roleList',
        method: 'post',
        params
    })
)
// 新增一条角色数据
const addRole = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/roleInfo/addRole',
        method: 'post',
        params
    })
)
// 删除一条角色数据
const deleteRole = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/roleInfo/deleteRole',
        method: 'post',
        params
    })
)
// 编辑角色数据
const editRole = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/roleInfo/edit',
        method: 'post',
        params
    })
)
// 查询角色权限
const getRoleAuthority = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/auth/getRoleAuthority',
        method: 'get',
        params
    })
)

// 查询OSS对象存储列表
const listOss = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/oss/list',
        method: 'get',
        params
    })
)

// 查询OSS对象基于id串
const listByIds = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/oss/listByIds/' + params,
        method: 'get'
    })
)

// 删除OSS对象存储
const delOss = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/oss/remove/' + params,
        method: 'delete'
    })
)

//危废名录首页查询
const wasteCategoryList = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/wasteInfo/wasteCategoryList',
        method: 'post',
        params
    })
)
//危废名录首页删除
const deleteWasteCategory = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/wasteInfo/deleteWasteCategory',
        method: 'post',
        params
    })
)
//危废代码-查询
const wasteInfoList = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/wasteInfo/wasteInfoList',
        method: 'post',
        params
    })
)
//危废代码-编辑
const updateWasteInfo = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/wasteInfo/updateWasteInfo',
        method: 'post',
        data: params
    })
)
//危废代码-新增
const addWasteInfo = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/wasteInfo/addWasteInfo',
        method: 'post',
        data: params
    })
)

// 修改用户状态
const updateUserStatus = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/userInfo/updateUserStatus',
        method: 'post',
        params
    })
)

// 重置密码
const resetPassword = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/userInfo/resetPassword',
        method: 'post',
        params
    })
)

// 查询字典列表
const dictionaryList = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/dictionary/list',
        method: 'post',
        params
    })
)

// 删除字典列表
const deleteDictionary = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/dictionary/deleteDictionary',
        method: 'post',
        params
    })
)

// 新增字典列表
const addDictionary = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/dictionary/addDictionary',
        method: 'post',
        params
    })
)
// 编辑字典列表
const updateDictionary = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/dictionary/updateDictionary',
        method: 'post',
        params
    })
)
// 导入危废名录
const explodeWasteInfo = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/wasteInfo/explodeWasteInfo',
        method: 'post',
        params
    })
)
// 导入危废名录
const saveRoleAuthority = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/auth/saveRoleAuthority',
        method: 'post',
        params
    })
)

//查询危废类别数据（一级）
const categoryCodeList = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/wasteInfo/categoryCodeList',
        method: 'post',
        params
    })
)

//查询废物代码数据（二级）
const wasteCodeList = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/wasteInfo/wasteCodeList',
        method: 'post',
        params
    })
)

export {
    enterpriseList,
    // 用户
    userList,
    addUser,
    deleteUser,
    editUser,
    queryRoleNameList,
    // 角色
    roleList,
    addRole,
    deleteRole,
    editRole,
    //角色权限
    getRoleAuthority, //查询权限树
    saveRoleAuthority, //保存权限
    // 危废名录
    wasteCategoryList, //首页table
    deleteWasteCategory, //首页删除
    wasteInfoList, //危废代码-查询
    updateWasteInfo, //危废代码-编辑
    addWasteInfo, //危废代码-新增
    explodeWasteInfo, //批量导入危废名录
    listOss,
    listByIds,
    delOss,
    updateUserStatus,
    resetPassword,
    // 字典
    dictionaryList,
    deleteDictionary,
    addDictionary,
    updateDictionary,
    categoryCodeList,
    wasteCodeList
}