<template>
    <!-- 联单详情中  五必查表格  信息展示 -->
    <div class="fiveCheckTable">
        <div class="tabTit">五必查信息</div>
        <el-table :data="tableData" border style="width: 100%;">
            <el-table-column prop="no" label="序号" width="50"></el-table-column>
            <el-table-column prop="name" label="检查项目" ></el-table-column>
            <el-table-column label="检查情况">
                <template slot-scope="scope">
                    <span v-if="scope.row.result == '1'">是</span>
                    <span v-else>否</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import * as server from '@/api/sheetApi';

export default {
    name: 'fiveCheckTable',
    props: {
        editData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            sheetInfoNo:this.editData.sheetInfoNo,
            tableData: [
                {"no":"1", "name":"车辆是否具有有效行驶证、营运证", "result":"1"},
                {"no":"2", "name":"驾驶人、押运人是否具有有效资质证件", "result":"1"},
                {"no":"3", "name":"运输车辆、罐式运输罐体、可移动储柜、罐箱是否在检验合格有效期内", "result":"1"},
                {"no":"4", "name":"所充装或者转载的危险货物是否与危险货物运单标明的事项一致", "result":"1"},
                {"no":"5", "name":"所充装的危险货物是否在罐式车辆罐体的适装介质列表范围内，或者满足可移动罐柜导则、罐箱适用代码的要求", "result":"1"},
            ]
        }
    },
    methods: {
        getFiveMustCheckResult() {
            server.fiveMustCheckResult({
                sheetInfoNo: this.sheetInfoNo
            }).then((res) => {
                for(let i in res.data){
                    this.tableData[i].result = res.data[i];
                }
            })
        },
    },
    mounted() {
        this.getFiveMustCheckResult();
    },
}
</script>

<style type="less" scoped>
.tabTit {
    padding: 16px 0px;
}
</style>