<template>
    <!-- 报警管理 -> 处理或详情 -->
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <div :style="centerwrap">
                <el-descriptions class="margin-top" :column="2" border>
                    <el-descriptions-item>
                        <template slot="label">当前所属企业</template>
                        {{ editData.enterpriseName }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">设备ID</template>
                        {{ editData.deviceId }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">设备名称</template>
                        {{ editData.deviceName }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">设备型号</template>
                        {{ global.DeviceModelMap.get(editData.deviceModel) }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">报警时间</template>
                        {{ editData.warnDate }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">报警内容</template>
                        {{ editData.warnContent }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="handleStatus == 1">
                        <template slot="label">备注</template>
                        {{ editData.remark }}
                    </el-descriptions-item>
                </el-descriptions>
                <div class="tabTip" v-if="handleStatus == 0">
                    <el-form :model="params" :rules="rules" ref="ruleForm" label-width="70px" size="small">
                        <el-form-item label="备注" prop="remark">
                            <el-input clearable type="textarea" v-model="params.remark" placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

        </template>
        <template v-slot:footer>
            <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')" v-if="handleStatus == 0">取消</HcButton>
            <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')" v-if="handleStatus == 0">确定</HcButton>
        </template>
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";

import * as server from '@/api/warnApi';

export default {
    name: "handleDetail",
    props: {
        editData: {
            type: Object,
            default: null,
        },
    },
    components: {
        HcDiaglePage, HcButton,
    },
    data() {
        return {
            centerwrap: {
                height: ''
            },
            mainHeight: null,//中间高度
            tableHeight: null,//表格高度
            dialogVisible: false,
            handleStatus: 0,//处理状态
            params: {
                warnNo: '',
                remark: '',
            },
            rules: {
                remark: [
                    { required: true, message: "请填写处理备注", trigger: "blur" },
                ],
            },
        };
    },

    methods: {
        showDiagle(data) {
            this.handleStatus = parseInt(data.handleStatus);
            this.params.warnNo = data.warnNo;
            this.$refs.hcDiaglePage.showLog = true;
        },

        //处理告警
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    server.handleWarn(this.params).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                message: '处理告警成功',
                                type: 'success'
                            });
                            this.$parent.queryList()//调用列表页面，刷新列表
                            this.$refs.hcDiaglePage.enter();//关闭弹框  
                        } else {
                            this.$message({
                                message: res.message,
                                type: 'warning'
                            });
                        }
                    })
                        .catch((err) => {
                            this.$message({
                                message: res.message,
                                type: 'warning'
                            });
                        });
                } else {
                    return false;
                }
            });
        },
        resetForm() {
            this.$refs.hcDiaglePage.close();
        },
    },
    mounted() {

        this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度

    },
    watch: {
        'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
            this.tableHeight = item - 60 + 'px'
            this.centerwrap.height = item - 235 + "px";
            console.log(this.tableHeight, 'this.center_wrap.height')
        }
    }

};
</script>

<style scoped lang="less">
.tabTip {
    margin: 20px 0 0 0;
}

.margin-top {
    margin-top: 20px;
}

::v-deep .center {
    min-height: 400px;
    padding: 0px;
}

::v-deep .footer {
    margin-bottom: 0px;
    padding: 20px 0px;
}

::v-deep .el-descriptions-row th {
    width: 10vw;
}

::v-deep .body {
    margin: 0 20px;
}</style>