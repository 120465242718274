<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm"></i>
    </template>
    <template v-slot:center>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" size="small" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="企业名称">
              <el-input v-model="ruleForm.enterpriseName" disabled placeholder="请输入企业名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="走访人账号" prop="userId">
              <el-input clearable="true" v-model="ruleForm.userId" placeholder="请输入走访人账号"></el-input>
            </el-form-item>
          </el-col>
       
          <el-col :span="12">
            <template>
              <el-form-item label="走访时间" prop="interviewTime">
                <el-date-picker v-model="ruleForm.interviewTime" type="datetime" placeholder="请选择走访时间"
                  format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
            </template>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="走访原因" prop="interviewDescript">
              <el-input v-model="ruleForm.interviewDescript" type="textarea" placeholder="请输入走访原因"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-slot:footer>
      <HcButton :type="'cancel'" size="small" @click="resetForm()">取消</HcButton>
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
      
    </template>
  </HcDiaglePage>
</template>
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from '@/components/HcButton/index.vue';
import * as server from "@/api/businessApi";

export default {
  name: "tab7.vue",
  components: {
    HcDiaglePage,
    HcButton
  },
  props: {
    editData: {
      type: Object,
    }
  },
  data() {
    return {
      ruleForm: {
        enterpriseId: "",
        enterpriseName: "",
        userId: "",
        interviewTime: "",
        interviewDescript: "",
      },
      rules: {
        userId: [
          { required: true, message: '请输入走访人账号', trigger: 'blur' }
        ],
        interviewTime: [
          { required: true, message: '请选择走访时间', trigger: 'change' }
        ],
        interviewDescript: [
          { required: true, message: '请输入走访原因', trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    showtab7Add() {
      this.$refs.hcDiaglePage.showLog = true;
      this.$parent.detailTit = '新增走访记录'
      this.ruleForm.enterpriseId = this.editData.enterpriseId;
      this.ruleForm.enterpriseName = this.editData.enterpriseName;
      this.ruleForm.userId = "";
      this.ruleForm.interviewTime = "";
      this.ruleForm.interviewDescript = "";
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.add(this.ruleForm) //调用新增接口
        } else {
          return false;
        }
      });
    },
    add(params) {
      server.addInterview(params).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.resetForm();
          let newParams = {
            enterpriseId: this.editData.enterpriseId, 
            pageSize: 20,
            pageNum: 1,
            total: null,
            totalPage: null,
          };
          this.$parent.getInterviewList(newParams);//调用父组件的刷新 table列表方法
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        this.$message({
          message: err.message,
          type: 'warning'
        });
      });
    },

    resetForm() {
      this.$refs.hcDiaglePage.close();
      this.$parent.detailTit = '产废企业详情'
      this.ruleForm = {
        enterpriseId: this.editData.enterpriseId,
        enterpriseName: "",
        userId: "",
        interviewTime: "",
        interviewDescript: "",
      }
    }
  },
};
</script>
      
<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}


::v-deep .el-input--small .el-input__inner {
  width: 300px;
}

::v-deep .el-textarea__inner {
  width: 800px;
}

.demo-ruleForm {
    margin-top: 20px;
}
::v-deep .el-input.el-input--small.el-input--suffix{
width: 300px;
}
</style>
      