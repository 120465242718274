<template>
  <div class="alarmStat" ref="alarmStat">
    <!--库存管理-库存统计 -->
    <div class="home_cent1">
      <el-row :gutter="16">
        <el-col :span="8">

          <div class="liandan" style="height: 100px">
            <div class="liandan_tit">
              <div>报警数量统计</div>
              <div></div>
            </div>
            <el-row class="liandan_cen" :gutter="14" style="margin-top: 15px;">
              <el-col :span="8">
                <div class="liandan_li liandanBack">
                  <div class="liandan_li_detail">
                    <div class="detail_tit">{{ warnObj.warnTotal }}</div>
                    <div class="detail_cen">报警总计</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="8" class="">
                <div class="liandan_li liandanBack">
                  <div class="liandan_li_detail">
                    <div class="detail_tit">{{ warnObj.unhandled }}</div>
                    <div class="detail_cen">未处理</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="liandan_li liandanBack">
                  <div class="liandan_li_detail">
                    <div class="detail_tit">{{ warnObj.handled }}</div>
                    <div class="detail_cen">已处理</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="liandan" style="height: 230px; margin-top: 16px">
            <div class="liandan_tit">
              <div>报警处理情况</div>
              <div></div>
            </div>
            <div class="liandan_chart">
              <div id="handleCountDiv" style="width: 50vw; height: 230px"></div>
            </div>
          </div>

        </el-col>

        <el-col :span="16">
          <div class="liandan" style="height: 400px">
            <div class="liandan_tit">
              <div>报警类型统计</div>
              <div></div>
            </div>
            <div class="liandan_chart">
              <div id="typeDiv" style="width: 52vw; height: 380px"></div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="home_cent">
      <el-row :gutter="16">
        <el-col :span="8">
          <div class="liandan liandan2">
            <div class="liandan_tit">
              <div>设备报警次数排行TOP10</div>
              <div></div>
            </div>
            <div class="liandan_cen">
              <el-table :data="tableData" style="width: 100%" height="440">
                <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
                <el-table-column prop="deviceId" label="设备ID"></el-table-column>
                <el-table-column prop="warnCount" label="报警次数(次)" width="100"></el-table-column>
                <el-table-column label="占比(%)" width="80">
                  <template slot-scope="scope">
                    {{ scope.row.handledPercentage }}
                    <!-- <el-progress :text-inside="true" :stroke-width="24" :percentage="scope.row.per" color="#00BFFF"></el-progress> -->
                  </template>
                </el-table-column>
              </el-table>
              <div class="page_btm">
                <!-- <el-pagination background layout="prev, pager, next" :page-size="params.pageSize" :total="params.total" @current-change="handleCurrentChange">
                </el-pagination> -->
                <!-- <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="params.pageNum" :page-size="params.pageSize" :total="params.total">
                </HcPagination> -->
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="liandan liandan2">
            <div class="liandan_tit">
              <div>设备报警分析</div>
              <div>
                <el-select v-model="params.deviceId" clearable filterable @change="chooseDevice">
                  <el-option v-for="item in deviceOptionArr" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <el-row class="liandan_cen">
              <div id="analysisDiv" style="width: 52vw; height: 460px;"></div>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import HcButton from '@/components/HcButton/index.vue';
import HcPagination from "@/components/HcPagination/index.vue";

import * as server from '@/api/warnApi';

export default {
  name: "alarmStat",
  components: {
    HcButton, HcPagination
  },
  data() {
    return {
      tableData: [],
      warnObj: {
        warnTotal: 0,//报警总计
        unhandled: 0,//未处理
        handled: 0,//已处理
      },
      params: {
        // pageSize: 10,
        // pageNum: 1,
        // total: null,
        // totalPage: null,
        deviceId: ''//设备ID
      },
      deviceOptionArr: [],
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1
    },
    // handleSizeChange(data) {
    //   this.params.pageSize = data//每页多少条
    //   this.queryList(this.params)//重新请求获取table的接口
    // },
    // handleCurrentChange(data) {
    //   this.params.pageNum = data//当前多少页
    //   this.queryList(this.params);
    // },

    //查询报警数量统计
    queryCountStat() {
      server.warnQuantityStatistics().then((res) => {
        this.warnObj = res.data;
        this.showHandleCountChart(res.data);
      })
    },

    //展示报警处理情况
    showHandleCountChart(data) {
      var myChart = this.$echarts.init(document.getElementById("handleCountDiv"));
      let option = {
        title: {
          text: '{b|总计}',
          subtext: '',
          textStyle: { rich: { b: { fontSize: "18", color: "white" } } },
          subtextStyle: { rich: { a: { fontSize: "18", color: "white", fontWeight: '700' } } },
          left: 'center',
          top: "37%"
        },
        tooltip: {
          trigger: 'item',
          borderColor: 'white'

        },
        color: ['#229EF8', '#36C58D'],
        legend: {
          orient: 'vertical',
          x: 'left',
          y: 'center',
          data: ['未处理(条)', '已处理(条)']
        },
        label: {
          alignTo: 'edge',
          formatter: '{name|{b}} \n {value|{c}} {per|{d}%}',
          lineHeight: 20,
          rich: {
            name: {
              fontSize: 16,
              color: 'inherit'
            },
            value: {
              fontSize: 14,
              color: 'inherit'
            },
            per: {
              fontSize: 12,
              color: '#b8c2c8'
            }
          },
        },
        series: [{
          name: '告警情况',
          type: 'pie',
          radius: ['40%', '55%'],
          center: ['50%', '50%'],
          data: [
            { value: data.unhandled, name: '未处理(条)' },
            { value: data.handled, name: '已处理(条)' }
          ],
        }],
        confine: true
      };

      myChart.setOption(option);

      //自适应大小
      window.addEventListener("resize", () => {
        if (myChart) {
          myChart.resize();
        }
      });
    },

    //查询设备报警次数排行
    queryList() {
      server.queryDeviceWarnRank(this.params).then((res) => {
        this.tableData = res.data;
        // 设置分页的数据
        // this.params.totalPage = res.data.totalPage;
        // this.params.total = res.data.total;
        // this.params.pageNum = this.params.pageNum;
        // this.params.pageSize = this.params.pageSize;
      })
    },

    //报警类型统计
    queryTypeChart() {
      server.queryWarnTypeStatistics().then((res) => {
        this.showTypeChart(res.data);
      })
    },

    showTypeChart(data) {
      let myChart = this.$echarts.init(document.getElementById("typeDiv"));
      let option = {
        tooltip: {
          // formatter: '{b0} <br />{a0}: {c0} <br/> {a1} : {c1} <br/>{a2} : {c2}',
          trigger: 'axis',
        },
        color: ['#36C58D', '#229EF8', '#92CAFF'],
        legend: {
          data: ['已处理(条)', '未处理(条)', '报警总计(条)']
        },
        xAxis: [
          {
            type: 'category',
            data: ['满负荷', '无废预警', '超期预警', '减量预警', '故障预警', '电量预警', '倾倒预警', '高温预警', '申报预警', '合同到期'],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [{
          type: 'value',
          // interval: 10,
        }],
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '10%',
        },
        series: [
          {
            name: '已处理(条)',
            type: 'bar',
            data: data[0].countData
          },
          {
            name: '未处理(条)',
            type: 'bar',
            data: data[1].countData
          },
          {
            name: '报警总计(条)',
            type: 'bar',
            data: data[2].countData
          },
        ]
      };
      myChart.setOption(option);

      //自适应大小
      window.addEventListener("resize", () => {
        if (myChart) {
          myChart.resize();
        }
      });
    },

    //查询设备报警分析
    queryAnalysisChart() {
      server.queryDeviceWarnAnalysis(this.params).then((res) => {
        this.showAnalysisChart(res.data);
      })
    },

    //设备报警分析
    showAnalysisChart(data) {
      let myChart = this.$echarts.init(document.getElementById("analysisDiv"));
      let option = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: 'rgb(126,199,255)',
            },
          },
        },
        legend: {
          show: true,
          itemWidth: 30,
          itemHeight: 10,
          textStyle: {
            color: 'rgba(147, 147, 147, 1)',
            fontSize: 14,
            padding: [0, 8, 0, 8]
          }
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '10%',
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(41,188,245)',
            },
          },
          axisLabel: {
            textStyle: {
              color: 'rgba(112, 151, 184, 1)',
              fontSize: 12,
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: ['满负荷', '无废预警', '超期预警', '减量预警', '故障预警', '电量预警', '倾倒预警', '高温预警'],
        }],

        yAxis: [{
          // interval: 10,
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            padding: [0, 60, 0, 0]
          },
          type: 'value',
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'rgba(112, 151, 184, 1)',
              fontSize: 14
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '报警次数(次)',
            type: 'line',
            symbol: 'circle',
            smooth: true,
            lineStyle: {
              normal: {
                width: 1,
                color: '#00ffa2', // 线条颜色
              },
            },
            showSymbol: false,
            itemStyle: {
              normal: {
                color: '#00f0ff',//拐点颜色
                label: {
                  show: true, //开启显示
                  color: '#fff',
                  position: 'top', //在上方显示
                  formatter: function (res) {
                    if (res.value) {
                      return res.value
                    } else {
                      return 0
                    }
                  },
                },
              },
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [  // 渐变颜色
                  {
                    offset: 0,
                    color: 'rgba(36, 214, 214, 0.6)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(36, 214, 214, 0)',
                  },
                ],
                global: false,
              },
            },
            data: data
          },
        ]
      };
      myChart.setOption(option);

      //自适应大小
      window.addEventListener("resize", () => {
        if (myChart) {
          myChart.resize();
        }
      });
    },

    //报警分析统计切换设备
    chooseDevice(val) {
      this.params.deviceId = val;
      this.queryAnalysisChart();
    },

    // 获取设备下拉框数据
    getDeviceAption() {
      server.allDeviceList().then((res) => {
        this.deviceOptionArr = res.data;
      })
    },
    getHeight() {//动态计算中间内容部分高度
      let bodyHeight = document.body.clientHeight
      let contentHeight = bodyHeight - 148
      console.log(bodyHeight, contentHeight, 'height')
      this.$refs.alarmStat.style.height = contentHeight + 'px'
      sessionStorage.setItem('alarmStatHeight', contentHeight)
    }
  },

  mounted() {
    this.getDeviceAption();
    //查询报警数量统计
    this.queryCountStat();

    //查询设备报警次数排行
    this.queryList();

    //查询报警类型统计
    this.queryTypeChart();

    //查询设备报警分析
    this.queryAnalysisChart();
    // 高度计算
    this.getHeight()
  },
};
</script>

<style scoped lang="less">
.alarmStat {
  height: 680px;
  overflow-y: auto;
  overflow-x: hidden;

  .home_cent1 {
    .liandan {
      background-color: #fff;
      border-radius: 4px;
      height: 172px;
      padding: 26px 30px;

      .liandan_tit {
        font-size: 16px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
      }

      .liandan_cen {
        margin-top: 30px;
      }

      .liandan_li {
        display: flex;
        align-items: center;

        .liandan_li_detail {
          margin-left: 10px;

          .detail_tit {
            font-size: 32px;
            color: #666;
          }

          .detail_cen {
            font-size: 16px;
            color: #939393;
          }
        }
      }
    }

    .liandan2 {
      height: 518px;
    }

    .liandan_chart {
      display: flex;
      align-items: center;

      .liandan_chart_num {
        font-size: 32px;
        color: #666;
      }

      .liann_chart_detail {
        display: flex;
        align-items: center;
        line-height: 30px;
      }

      .lian_chart_li {
        width: 10px;
        height: 10px;
        background-color: #00BFFF;
        margin-right: 6px;
      }
    }
  }

  .home_cent {
    margin-top: 16px;

    .liandan {
      background-color: #fff;
      border-radius: 4px;
      height: 172px;
      padding: 26px 30px;

      .liandan_tit {
        font-size: 16px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
      }

      .liandan_cen {
        margin-top: 30px;
      }

      .liandan_li {
        display: flex;
        align-items: center;

        .liandan_li_detail {
          margin-left: 10px;

          .detail_tit {
            font-size: 32px;
            color: #666;
          }

          .detail_cen {
            font-size: 16px;
            color: #939393;
          }
        }
      }
    }

    .liandan2 {
      height: 518px;
    }

    .liandan_chart {
      display: flex;
      align-items: center;

      .liandan_chart_num {
        font-size: 32px;
        color: #666;
      }

      .liann_chart_detail {
        display: flex;
        align-items: center;
        line-height: 30px;
      }

      .lian_chart_li {
        width: 10px;
        height: 10px;
        background-color: #00BFFF;
        margin-right: 6px;
      }
    }
  }

  .liandanBack {
    background-color: #F0FBFF;
    padding: 10px 0 10px 10px;
  }

}

.page_btm {
  text-align: right;
  margin-top: 10px;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #00BFFF;
}
</style>