<template>
    <!-- 管理计划 -> 计划详情 -->
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <div class="tabTit">管理计划</div>
            <el-descriptions class="margin-top" :column="2" border>
                <el-descriptions-item>
                    <template slot="label">
                        企业名称
                    </template>
                    {{ editData.enterpriseName }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        计划开始时间
                    </template>
                    {{ editData.planStartDate }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        计划结束时间
                    </template>
                    {{ editData.planEndDate }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        制定日期
                    </template>
                    {{ editData.planCreateTime }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        规章制度
                    </template>
                    <span v-if="editData.planRule == '1'">有</span>
                    <span v-if="editData.planRule == '0'">无</span>
                </el-descriptions-item>
            </el-descriptions>

            <enterpriseDetail :enterpriseId="editData.enterpriseId"></enterpriseDetail>

            <div class="tabTit">危险废物产生情况</div>
            <el-table :data="tableData1.slice((currentPage1-1)*pagesize1,currentPage1*pagesize1)" border style="width: 100%" height="400">
                <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
                <el-table-column prop="productSourceName" label="产生源名称"></el-table-column>
                <el-table-column label="产生源类型">
                    <template slot-scope="scope">
                        {{ global.ProductSourceType.get(scope.row.productSourceType)}}
                    </template>
                </el-table-column>
                <el-table-column prop="productSourceCode" label="产生源编码" ></el-table-column>
                <el-table-column prop="categoryCode" label="危废类别" ></el-table-column>
                <el-table-column prop="harmWasteCode" label="废物代码" ></el-table-column>
                <el-table-column label="物理性状">
                    <template slot-scope="scope">
                        {{ global.PhysicalCharacter.get(scope.row.physicalCharacter)}}
                    </template>
                </el-table-column>
                <el-table-column prop="harmFeature" label="危险特性" ></el-table-column>
                <el-table-column prop="mpsQuantity" label="本年度计划产生量(吨)" ></el-table-column>
                <el-table-column prop="actualQuantity" label="上年度实际产生量(吨)" ></el-table-column>
            </el-table>
            <div class="page_btm">
                <el-pagination 
                    @size-change="handleSizeChange1"
                    @current-change="handleCurrentChange1"
                    :current-page="currentPage1"
                    :page-sizes="[3,5,7,10]"
                    :page-size="pagesize1"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableData1.length"
                    background>
                </el-pagination>
            </div>

            <div class="tabTit">危废贮存(收集)情况</div>
            <el-table :data="tableData2.slice((currentPage2-1)*pagesize2, currentPage2*pagesize2)" border style="width: 100%" height="400">
                <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
                <el-table-column prop="storageName" label="贮存设施(收集点)名称"></el-table-column>
                <el-table-column label="贮存设施(收集点)类型">
                    <template slot-scope="scope">
                        {{ global.ProductSourceType.get(scope.row.storageType)}}
                    </template>
                </el-table-column>
                <el-table-column prop="storageCode" label="贮存设施(收集点)编码" ></el-table-column>
                <el-table-column prop="storageSize" label="面积/容积(㎡/m³)" ></el-table-column>
                <el-table-column prop="storageCapacity" label="贮存(收集)能力(吨)" ></el-table-column>
                <el-table-column prop="storageTotalQuantity" label="截止上年度累计贮存(收集)量(吨)" ></el-table-column>
            </el-table>
            <div class="page_btm">
                <el-pagination 
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                    :current-page="currentPage2"
                    :page-sizes="[3,5,7,10]"
                    :page-size="pagesize2"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableData2.length"
                    background>
                </el-pagination>
            </div>
        </template>
   
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import enterpriseDetail from '@/components/HcEnteMation';
import * as server from '@/api/planApi';

export default {
    name: "planDetail",
    props: {
        editData: {
            type: Object,
            default: null,
        },
    },
    components: {
        HcDiaglePage,
        HcPagination,
        HcButton,
        enterpriseDetail
    },
    data() {
        return {
            dialogVisible: false,

            //危险废物产生情况手动分页
            currentPage1: 1,
            pagesize1: 4,

            //危废贮存(收集)情况手动分页
            currentPage2: 1,
            pagesize2: 4,

            tableData1: [],
            tableData2: [],
            
            params1: {
                planNo: '',
            },
            params2: {
                planNo: '',
            },
        };
    },

    methods: {
        indexMethod(index) {
            return index + 1
        },
        
        showDiagle(data) {
            this.params1.planNo = data.planNo;
            this.params2.planNo = data.planNo;

            this.queryWasteProduct(this.params1);
            this.queryWasteStorage(this.params2);

            this.$refs.hcDiaglePage.showLog = true;
        },

        handleSizeChange1(val) {
            this.pagesize1 = val;
        },
        handleCurrentChange1(val) {
            this.currentPage1= val
        },

        handleSizeChange2(val) {
            this.pagesize2 = val;
        },
        handleCurrentChange2(val) {
            this.currentPage2= val
        },

        //查询危险废物产生列表
        async queryWasteProduct(params){
            await server.wasteProductList(params).then((res) => {
                this.tableData1 = res.data;
            }).catch((err) => {
                this.$message({
                    showClose: true,
                    message: err.message,
                    type: 'warning'
                });
            })
        },

        //查询危险废品贮存（收集）列表
        async queryWasteStorage(params){
            await server.wasteStorageList(params).then((res) => {
                this.tableData2 = res.data;
            }).catch((err) => {
                this.$message({
                    showClose: true,
                    message: err.message,
                    type: 'warning'
                });
            })
        },

        resetForm(formName) {
            if(this.$route.query.type=='wasteTab5Detail'){
                this.$router.push({
                    name: 'wasteProduction',//关闭跳转到产废企业列表页面
                })
            }
            this.$refs.hcDiaglePage.close();
            //清空表单数据
            this.params1 = {
                planNo: '',
            };
            this.params2 = {
                planNo: '',
            };
        },
    },
};
</script>

<style scoped lang="less">

.tabTit {
    padding: 16px 0px;
}

.page_btm {
    text-align: right;
    margin-top: 10px;
}
::v-deep .el-pager li.active {
    background-color: #00BFFF;
    color: #ffffff;
    border-radius: 4px;
    min-width: 28px;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #00BFFF;
}
::v-deep .el-descriptions-row th {
    width: 10vw;
}
</style>