import {httpRequest,baseUrl} from '../utils/request'
export {
    stockList,agreementList,sheetList,sheetChart,declareList
}

// 查询库存情况
const stockList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/home/stockList',
       method:'post' ,
       params
    })
)

// 查询合同情况
const agreementList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/home/agreementList',
       method:'post' ,
       params
    })
)


// 查询联单数量情况
const sheetList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/home/sheetList',
       method:'post' ,
       params
    })
)

// 查询联单统计情况
const sheetChart=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/home/sheetChart',
       method:'post' ,
       params
    })
)

// 查询申报量情况
const declareList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/home/declareList',
       method:'post' ,
       params
    })
)