import {httpRequest,baseUrl} from '../utils/request'
// 登录
const login=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/login',
       method:'post' ,
       params
    })
)
// 退出
const getlogOut=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/logOut',
       method:'post' ,
       params
    })
)
// 修改密码
const getUpdatePassword=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/userInfo/updatePassword',
       method:'post' ,
       params
    })
)
// 重新获取用户等信息
const getLoginByToken=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/loginByToken',
       method:'post' ,
       params
    })
)
export {
    login,
    getlogOut,
    getUpdatePassword,
    getLoginByToken
}