<template>
    <!-- 管理计划 -> 新增计划 -->
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <el-form :model="params" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
                <!-- <div class="card_tit">管理计划</div> -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="危废类别" prop="categoryCode">
                            <el-input v-model="params.categoryCode" clearable placeholder="请输入危废类别">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="危废类别名称" prop="categoryName">
                            <el-input v-model="params.categoryName" clearable placeholder="请输入危废类别名称">
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="主要成分" prop="mainComponent">
                            <el-input type="textarea" :autosize="{ minRows: 3 }" v-model="params.mainComponent" clearable
                                style="width: 300px;" placeholder="请输入主要成分">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="有害成分" prop="harmfulComponent">
                            <el-input type="textarea" :autosize="{ minRows: 3 }" v-model="params.harmfulComponent" clearable
                                style="width: 300px;" placeholder="请输入有害成分">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="zhineng">
                    <div class="card_tit">废物代码</div>
                    <div class="zhineng_add" @click="openaddWasteCode(params.categoryCode)">
                        <i class="el-icon-circle-plus-outline"></i>
                    </div>
                </div>
                <el-table :data="tableData1.slice((currentPage1 - 1) * pageSize1, currentPage1 * pageSize1)" border
                    style="width: 100%" height="340">
                    <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
                    <el-table-column prop="wasteType" label="行业来源"></el-table-column>
                    <el-table-column prop="wasteCode" label="废物代码"></el-table-column>
                    <el-table-column prop="wasteCategory" label="废物名称" min-width="100"></el-table-column>
                    <el-table-column prop="harmWaste" label="危险危废"></el-table-column>
                    <el-table-column prop="feature" label="危险特性"></el-table-column>
                    <el-table-column prop="wasteForm" label="废物形态">
                        <template slot-scope="scope">
                            <span>{{ scope.row.wasteForm == '1' ? '液体' : '' }}</span>
                            <span>{{ scope.row.wasteForm == '2' ? '固体' : '' }}</span>
                            <span>{{ scope.row.wasteForm == '3' ? '气体' : '' }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" width="150">
                        <template slot-scope="scope">
                            <el-popconfirm title="是否确定删除所选数据？" @confirm="removeOne1(scope.$index)">
                                <el-button slot="reference" type="text" size="default"
                                    class="button_text_del">删除</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page_btm">
                    <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
                        :current-page="currentPage1" :page-sizes="[3, 5, 7, 10]" :page-size="pageSize1"
                        layout="total, sizes, prev, pager, next, jumper" :total="tableData1.length">
                    </el-pagination>
                </div>


            </el-form>
        </template>
        <template v-slot:footer>
            <div style="margin: 0 0 20px 0;display: flex;">
                <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
                <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
            </div>

        </template>
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/systemApi";

export default {
    name: "addDiagle",
    props: {
        selectData1: {
            type: Array,
            default: null
        },
        newTableData: {
            type: Object,
            default: null
        },
    },
    components: {
        HcDiaglePage,
        HcPagination,
        HcButton,
    },
    watch: {
        selectData1(val) {
            this.tableData1 = val;
        },
        newTableData(val) {
            this.tableData1.push(val)
        }
    },
    data() {
        return {
            // 手动分页
            currentPage1: 1,
            pageSize1: 3,
            tableData1: [],
            params: {
                categoryCode: '',
                categoryName: '',
                wasteInfoList: [],
                mainComponent: '',
                harmfulComponent: '',
            },
            dialogVisible: false,
            rules: {
                categoryCode: [
                    { required: true, message: "请输入危废类别", trigger: "blur" },
                ],
                categoryName: [
                    { required: true, message: "请输入危废类别名称", trigger: "blur" },
                ],
                mainComponent: [
                    { required: true, message: "请输入主要成分", trigger: "blur" },
                ],
                harmfulComponent: [
                    { required: true, message: "请输入有害成分", trigger: "blur" },
                ],
            },
            dialogType: '',
        };
    },
    methods: {
        indexMethod(index) {
            return index + (this.currentPage1 - 1) * this.pageSize1 + 1
        },
        showDiagle() {
            this.$refs.hcDiaglePage.showLog = true;
            this.tableData1 = [];
            this.params.categoryCode = '';
            this.params.categoryName = '';
            this.params.mainComponent = '';
            this.params.harmfulComponent = '';
            this.params.wasteInfoList = [];
            this.currentPage1 = 1;
            this.pageSize1 = 3;
        },

        //新增管理计划
        add(params) {
            server.addWasteInfo(params).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '新增成功',
                        type: 'success'
                    });
                    this.$parent.queryList()//调用列表页面，刷新列表
                    this.$refs.hcDiaglePage.enter();//关闭弹框
                    this.$nextTick(() => {
                        this.params.categoryCode = '';
                        this.params.categoryName = '';
                        this.params.mainComponent = '';
                        this.params.harmfulComponent = '';
                        this.params.wasteInfoList = [];
                        this.tableData1 = []

                    })
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: 'warning'
                    });
                });
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    //如果有新增的废物产生或贮存，就入库保存
                    // if (this.tableData1.length > 0) {
                    //this.params["dataList1"] = this.tableData1;  //这种写法，传不到后台

                    this.params.categoryCode = this.params.categoryCode;
                    this.params.categoryName = this.params.categoryName;
                    this.params.mainComponent = this.params.mainComponent;
                    this.params.harmfulComponent = this.params.harmfulComponent;
                    this.params.wasteInfoList = this.tableData1

                    //需要手动遍历下
                    // for (let i = 0; i < this.tableData1.length; i++) {
                    //     let wasteInfo = {};
                    //     wasteInfo.wasteCode = this.tableData1[i].wasteCode;
                    //     wasteInfo.wasteType = this.tableData1[i].wasteType;
                    //     wasteInfo.wasteCategory = this.tableData1[i].wasteCategory;
                    //     wasteInfo.harmWaste = this.tableData1[i].harmWaste;
                    //     wasteInfo.categoryCode = this.params.categoryCode;
                    //     wasteInfo.feature = this.tableData1[i].feature.toString();
                    //     this.params.wasteInfoList.push(wasteInfo);//把表格数据放到需要提交的整个数据集中
                    // }
                    this.add(this.params);
                    // }
                } else {
                    return false;
                }
            });
            // this.$nextTick(() => {
            //     this.params.categoryCode = '';
            //     this.params.categoryName = '';
            //     this.params.wasteInfoList = [];
            //     this.tableData1 = []

            // })
        },
        resetForm(formName) {
            this.$parent.detailTit = '新增危废名录'
            this.$refs.hcDiaglePage.close();
            //清空表单数据
            this.params.categoryCode = '';
            this.params.categoryName = '';
            this.params.mainComponent = '';
            this.params.harmfulComponent = '';
            this.params.wasteInfoList = [];
            this.tableData1 = []
        },

        handleSizeChange1(val) { //每页多少条
            this.pageSize1 = val//赋值给params
            //this.queryWasteProduct(this.params1)//重新请求获取table的接口
        },
        handleCurrentChange1(val) {//当前多少页
            this.currentPage1 = val//当前页
            //this.queryWasteProduct(this.params1)//重新获取table的接口
        },

        // 打开新增危废废物生产
        openaddWasteCode(data) {
            this.$parent.openaddWasteCode(data)
        },
        //删除列表中的一条信息
        removeOne1(i) {
            this.$message({
                showClose: true,
                message: "删除成功",
                type: "success",
            });
            this.tableData1.splice(i, 1);
        },
    },
};
</script>

<style scoped lang="less">
::v-deep .el-input.el-input--small.el-input--suffix {
    width: 300px;
}


.zhineng {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .zhineng_add {
        font-size: 24px;
        color: #00BFFF;
    }
}

.card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}

.el-icon-circle-plus-outline {
    cursor: pointer;
}

.page_btm {
    text-align: right;
    margin-top: 10px;
}

::v-deep .el-pager li.active {
    background-color: #00BFFF;
    color: #ffffff;
    border-radius: 4px;
    min-width: 28px;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #00BFFF;
}

.demo-ruleForm {
    margin-top: 20px;
}

//表格里的 删除按钮
.button_text_del {
    color: #F56C6C !important;
    padding-left: 10px;
}
</style>
  