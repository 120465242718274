/**重置message，防止重复点击重复弹出message弹框 */
import { Message } from 'element-ui';
let messageInstance = null;
const resetMessage = (options) => {
    // 如果已经存在实例则关闭
    if (messageInstance) {
        messageInstance.close()
    }

    let flag = window.localStorage.getItem("hasMsgBox");
    if(flag === '0'){
        console.log('zzzzzzzzzzzzz = ' + flag);
        
        // 不存在实例则赋予实例
        messageInstance = Message(options)
    }else{
        console.log('kkkkkkkkkkkkk = ' + flag);
    }
}; 

['error', 'success', 'info', 'warning'].forEach(type => {
    resetMessage[type] = options => {
        if (typeof options === 'string') {
            options = {
                message: options
            }
        }
        options.type = type
        return resetMessage(options)
    }
})
export const newMessage = resetMessage