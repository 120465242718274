<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
    </template>
    <template v-slot:center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="字典名称" prop="dicName">
              <el-input v-model="ruleForm.dicName" clearable placeholder="请输入字典名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" prop="dicId">
              <el-input v-model="ruleForm.dicId" clearable placeholder="请输入编号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="实际值" prop="actualValue">
              <el-input v-model="ruleForm.actualValue" clearable placeholder="请输入实际值"></el-input>

            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示值" prop="displayValue">
              <el-input v-model="ruleForm.displayValue" clearable placeholder="请输入显示值"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-slot:footer>

      <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
    </template>
  </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/systemApi";

export default {
  name: "editDiagle",
  props: {
    editData: {
      type: Object,
      default: null
    }
  },
  components: {
    HcDiaglePage,
    HcButton,
  },
  mounted() {
  },

  data() {
    return {
      ruleForm: {
        dicName: "",//字典名称
        dicId: "",//编号
        actualValue: "",//实际值
        displayValue: "",//显示值
        dicNo: "",//key
      },
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
      },
      rules: {
        dicName: [
          { required: true, message: "请输入字典名称", trigger: "blur" },
        ],
        dicId: [
          { required: true, message: "请输入编号", trigger: "blur" },
        ],
        actualValue: [
          { required: true, message: "请输入实际值", trigger: "blur" },
        ],
        displayValue: [
          { required: true, message: "请输入显示值", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    showDiagle(data) {
      this.ruleForm.dicName = "";//字典名称
      this.ruleForm.dicId = "";//编号
      this.ruleForm.actualValue = "";//实际值
      this.ruleForm.displayValue = "";//显示值
      this.ruleForm.dicNo = "";//key值
      this.$refs.hcDiaglePage.showLog = true;
      // 判断弹框是编辑页面还是新增页面或者是详情页面
      this.dialogType = data
      if (this.dialogType == 'edit') {
        console.log(this.editData, 'hello1')
        this.ruleForm.dicName = this.editData.dicName;//字典名称
        this.ruleForm.dicId = this.editData.dicId;//编号
        this.ruleForm.actualValue = this.editData.actualValue;//实际值
        this.ruleForm.displayValue = this.editData.displayValue;//显示值
        this.ruleForm.dicNo = this.editData.dicNo;
      }
    },
    add(params) {
      server.addDictionary(params).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.$refs.hcDiaglePage.enter();//关闭弹框  
          this.$parent.getdictionaryList(this.params)//调用列表页面，刷新列表
        }
        else if (res.code == 500) {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
        .catch((err) => {
          console.log(err)
          this.$message({
            message: err.message,
            type: 'warning'
          });
        });
    },
    edit(params) {
      if (this.dialogType == 'edit') {
        server.updateDictionary(params).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            });
            this.$refs.hcDiaglePage.enter();//关闭弹框  
            this.$parent.getdictionaryList(this.params)//调用列表页面，刷新列表
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
          .catch((err) => {
            this.$message({
              message: err.message,
              type: 'warning'
            });
          });
      }

    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType == 'edit') {
            this.edit(this.ruleForm)//调用编辑接口
          } else {
            this.add(this.ruleForm) //调用新增接口
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$refs.hcDiaglePage.close();

      this.ruleForm.dicName = "";//字典名称
      this.ruleForm.dicId = "";//编号
      this.ruleForm.actualValue = "";//实际值
      this.ruleForm.displayValue = "";//显示值
      this.ruleForm.dicNo = ''

    },
  },
};
</script>
  
<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}

::v-deep .el-input.el-input--small.el-input--suffix {
  width: 300px;
}

::v-deep .el-textarea__inner {
  width: 800px;
}

.addressClass {
  display: flex;

  .addressDiv {
    cursor: pointer;
  }

  .addressIcon {
    font-size: 24px;
    color: #E6A23C;
    margin-left: 6px;
  }

  span {
    font-size: 14px;
    color: #E6A23C;
  }
}

.demo-ruleForm {
  margin-top: 20px;
}
</style>
  