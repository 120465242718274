<template>
  <div>
    <HcDiaglePage ref="hcDiaglePage">
      <template v-slot:close>
        <i class="el-icon-close title_close" @click="resetForm"></i>
      </template>
      <template v-slot:center>
        <el-form>
          <el-row :gutter="24">
            <el-col>
              <el-col :span="8">
                <el-form-item label="搜索地址">
                  <el-input
                    v-model="model.address"
                    clearable
                    size="medium"
                  ></el-input>
                  <div class="searchData-box" v-if="serarchAddres.length > 0">
                    <div
                      v-for="(item, index) in serarchAddres"
                      :key="index"
                      class="item_list"
                      @click="clickList(item.label)"
                    >
                      <div class="item_name">{{ item.name }}</div>
                      <div class="item_label">{{ item.label }}</div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="位置经度" prop="lng">
                  <el-input
                    clearable
                    v-model="model.lng"
                    type="number"
                    class="input_number"
                    @mousewheel.native.prevent
                    size="medium"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="位置纬度" prop="lat">
                  <el-input
                    clearable
                    v-model="model.lat"
                    type="number"
                    class="input_number"
                    @mousewheel.native.prevent
                    size="medium"
                  />
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <div
                  id="mapDeep"
                  style="width: 100%; height: calc(100vh - 420px)"
                ></div>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-slot:footer>
        <HcButton :type="'cancel'" size="small" @click="resetForm()"
          >取消</HcButton
        >
        <HcButton :type="'primary'" size="small" @click="submitForm()"
          >确定</HcButton
        >
      </template>
    </HcDiaglePage>
  </div>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
// import HcBaiduMap from "@/components/HcBaiduMap/index.vue";
import HcButton from "@/components/HcButton/index.vue";
export default {
  name: "editDiagle.vue",
  components: {
    HcDiaglePage,
    // HcBaiduMap,
    HcButton,
  },

  data() {
    return {
      address: "",
      longilatde: "",
      map: "",
      model: {
        lng: "",
        lat: "",
        address: "",
      },
      analysisSearchService: "",
      searchDataList: null,
      markerList: [],
      polygon: "",
      serarchAddres: [],
      serarchLabelShow: false,
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    showBaiduMap() {
      this.$refs.hcDiaglePage.showLog = true;
      setTimeout(() => {
        this.init();
      }, 500);
    },
    getAddress(data) {
      this.address = data;
    },
    getLongilatde(data) {
      console.log(data, "getLongilatde");
      this.longilatde = data;
    },

    // 创建地图
    init() {
      var position = new LKMap.LngLat(120.27, 31.9);
      this.map = new LKMap.Map("mapDeep", {
        center: position,
        zoom: 10,
      });
      let that = this;
      this.map.on("load", function () {
        // 搜索
        that.map.plugin(["AnalysisSearch"], function () {
          // 示例化对象
          that.analysisSearchService = new LKMap.AnalysisSearch({
            size: 10,
            adcode: 110000, // 城市代码
          });
          console.log(that.analysisSearchService);
        });
      });
      this.map.on("click", (e) => {
        console.log(e, "地图点击");

        this.map.plugin(["Geocoder"], function () {
          // 实例化对象
          var Geocoder = new LKMap.Geocoder({
            size: 1, // 返回结果数量
          });
          // 正地理编码

          Geocoder.getAddress(
            new LKMap.LngLat(e.lngLat.lng, e.lngLat.lat),
            function (status, result) {
              console.log("逆地理编码=", status, result);
              if (status == "complete") {
                console.log(that.model, "model");
                that.model.lng = e.lngLat.lng;
                that.model.lat = e.lngLat.lat;
                that.model.address = result[0].properties.label;
              }
            }
          );
        });
      });

      // this.map.plugin(["Polygon"], function () {
      //   // 示例化对象
      //   that.Polygon = new LKMap.Polygon({
      //     size: 10,
      //   });
      // });
      // this.map.plugin(["Marker"], function () {
      //   // 示例化对象
      //   that.Marker = new LKMap.Marker({
      //     size: 10,
      //   });
      // });
    },

    addList(result) {
      let that = this;
      console.log(result);
      console.log(result.hasOwnProperty("pois"));
      console.log(result.hasOwnProperty("region"));
      // this.searchDataList.innerHTML = "";
      this.clearMapMarker();
      this.removePolygon();
      if (result.hasOwnProperty("pois")) {
        for (var i in result.pois) {
          this.serarchAddres.push(result.pois[i].properties);
        }

        for (var i in result.pois) {
          var position = new LKMap.LngLat(
            result.pois[i].coordinates[0],
            result.pois[i].coordinates[1]
          );
          this.markerList[i] = new LKMap.Marker({
            position: position,
            label: {
              content: result.pois[i].properties.name,
            },
            anchor: "bottom",
          });
          this.markerList[i].setMap(this.map);
        }
        this.map.setFitView(null, {
          padding: {
            top: 100,
            bottom: 100,
            left: 0,
            right: 0,
          },
        });
        if (this.serarchAddres.length > 1) {
          this.$nextTick(() => {
            this.serarchLabelShow = true;
          });
        } else {
          this.$nextTick(() => {
            this.serarchLabelShow = false;
          });
        }
      }
      if (result.hasOwnProperty("region")) {
        if (result.region.geometry.type == "MultiPolygon") {
          that.polygon = new LKMap.Polygon({
            path: result.region.geometry.coordinates[0],
            fillOpacity: 0.4,
            fillColor: "#7C87FF",
            strokeColor: "#fff",
            strokeWeight: 2,
          });
          that.polygon.setMap(that.map);
          that.map.setFitView(null, {
            padding: {
              top: 100,
              bottom: 100,
              left: 0,
              right: 0,
            },
          });
        }
        if (result.region.geometry.type == "Polygon") {
          that.polygon = new LKMap.Polygon({
            path: result.region.geometry.coordinates[0],
            fillOpacity: 0.4,
            fillColor: "#7C87FF",
            strokeColor: "#fff",
            strokeWeight: 2,
          });
          that.polygon.setMap(that.map);
          that.map.setFitView(null, {
            padding: {
              top: 100,
              bottom: 100,
              left: 0,
              right: 0,
            },
          });
        }
      }
    },
    clearMapMarker() {
      console.log(this.markerList);
      if (this.markerList.length == 0) return;
      for (var k in this.markerList) {
        this.markerList[k].remove();
      }
      this.markerList = [];
      // markerListObj = {};
    },
    clickList(val) {
      console.log(val);
      this.model.address = val;
      this.serarchAddres = [];
    },
    removePolygon() {
      if (this.polygon) this.polygon.remove();
    },
    submitForm() {
      console.log(
        this.model.address,
        this.model.lng + "," + this.model.lat,
        "address1"
      );

      this.$parent.detailTit = "新增产废企业列表";
      this.$emit("mapDetail", {
        address: this.model.address,
        longilatde: this.model.lng + "," + this.model.lat,
      });
      this.$refs.hcDiaglePage.enter();
    },

    resetForm() {
      this.$refs.hcDiaglePage.close();
      this.$parent.detailTit = "新增产废企业列表";
    },
  },
  watch: {
    "model.address"(newVal, oldVal) {
      // 渲染列表
      // this.searchDataList = document.getElementById("searchDataList");
      var keyword = newVal;
      if (keyword == "") {
        // this.searchDataList.innerHTML = "";
      }
      let that = this;
      console.log(keyword);
      this.analysisSearchService.search(keyword, function (status, result) {
        console.log("输入提示列表=", status, result);
        that.serarchAddres = [];
        that.addList(result); // 渲染列表
      });
    },
  },
};
</script>

<style scoped lang="less">
@import url("https://lbs.luokuang.com/jsdemo/example/css/demo.css");
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}

// ::v-deep .el-form-item__content {}

::v-deep .el-input--small .el-input__inner {
  width: 300px;
}

::v-deep .el-textarea__inner {
  width: 800px;
}
#map {
  height: 800px;
}
.searchData-box {
  height: 200px;
  width: 100%;
  overflow-y: scroll;
  position: absolute;
  top: 100px;
  z-index: 99;
  background-color: #fff;
  .item_list {
    height: 50px;
    cursor: pointer;
    .item_name {
      font-size: 14px;
      color: #333;
      height: 24px;
      line-height: 24px;
    }
    .item_label {
      font-size: 12px;
      color: #666666;
      height: 24px;
      line-height: 24px;
      white-space: nowrap; //不支持换行
      overflow: hidden; //隐藏多出部分文字
      text-overflow: ellipsis; //用省略号代替多出部分文字
    }
  }
  .item_list:hover {
    background-color: #f5f7fa;
  }
  img[src=""],
  img:not([src]) {
    opacity: 0;
  }
}
</style>
