<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm"></i>
        </template>
        <template v-slot:center>
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" size="small" class="demo-ruleForm">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="车辆类型" prop="carType">
                            <el-select v-model="ruleForm.carType" placeholder="请选择车辆类型" filterable clearable>
                                <el-option label="罐式专用运输车" value="1"></el-option>
                                <el-option label="箱式专用运输车" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="车牌号" prop="carNum">
                            <el-input clearable v-model="ruleForm.carNum" placeholder="请输入车牌号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="载重量(kg)" prop="loadWeight">
                            <!-- <el-input v-model="ruleForm.loadWeight"></el-input> -->
                            <el-input-number v-model="ruleForm.loadWeight" :controls="false" :min="0" :precision="2"
                                clearable></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="运输证号" prop="transportNo">
                            <el-input clearable v-model="ruleForm.transportNo" placeholder="请输入运输证号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </template>
        <template v-slot:footer>
            <HcButton :type="'cancel'" size="small" @click="resetForm()">取消</HcButton>
            <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>

        </template>
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/businessApi";

export default {
    name: "tab2Edit.vue",
    components: {
        HcDiaglePage,
        HcButton,
    },
    props: {
        detailData: {
            type: Object,
        },
    },
    data() {
        const vehicleNumber = (rule, value, callback) => {
            if (this.isVehicleNumber(value)) {
                callback();
            } else {
                return callback(new Error("请输入正确的车牌号格式"));
            }
        };
        return {
            ruleForm: {
                carType: "",//车辆类型
                carNum: "",//车牌号
                loadWeight: "",//载重量
                transportNo: "",//运输证号
                enterpriseId: '',
            },
            rules: {
                //车辆类型
                carType: [
                    { required: true, message: '请选择车辆类型', trigger: 'change' }
                ],
                //车牌号
                carNum: [
                    { required: true, message: '请输入车牌号', trigger: 'blur' },
                    { validator: vehicleNumber, trigger: "blur", },
                ],
                // 载重量
                loadWeight: [
                    { required: true, message: "请输入载重量(kg)", trigger: "blur" },
                ],
                // 运输证号
                transportNo: [
                    { required: true, message: "请输入运输证号", trigger: "blur" },
                ],
            },
        };
    },
    methods: {
        isVehicleNumber(vehicleNumber) {
            // var xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
            var creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
            if (vehicleNumber.length == 7) {
                return creg.test(vehicleNumber);
            } 
            // else if (vehicleNumber.length == 8) {
            //     return xreg.test(vehicleNumber);
            // } 
            else {
                callback(new Error("请输入正确的车牌号格式"));
            }
        },
        showtab2Edit() {
            this.$refs.hcDiaglePage.showLog = true;
            this.$parent.detailTit = "编辑车辆信息";
            this.ruleForm.carType = this.detailData.carType;
            this.ruleForm.carNum = this.detailData.carNum;
            this.ruleForm.loadWeight = this.detailData.loadWeight;
            this.ruleForm.transportNo = this.detailData.transportNo;
            this.ruleForm.carNo = this.detailData.carNo;
            this.ruleForm.enterpriseId = this.detailData.enterpriseId;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.edit(this.ruleForm); //调用新增接口
                } else {
                    return false;
                }
            });
        },
        edit(params) {
            server.editCar(params).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        message: "编辑成功",
                        type: "success",
                    });
                    this.resetForm();

                    let newParams = {
                        enterpriseId: this.detailData.enterpriseId,
                        pageSize: 20,
                        pageNum: 1,
                        total: null,
                        totalPage: null,
                    };
                    this.$parent.getCarList(newParams); //调用父组件的刷新 table列表方法
                } else {
                    this.$message({
                        message: res.message,
                        type: "warning",
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: "warning",
                    });
                });
        },

        resetForm() {
            this.$refs.hcDiaglePage.close();
            this.$parent.detailTit = "运输企业详情";
            this.ruleForm = {
                carType: "",//车辆类型
                carNum: "",//车牌号
                loadWeight: "",//载重量
                transportNo: "",//运输证号
                enterpriseId: this.editData.enterpriseId,//企业编号
            }
        },
    },
};
</script>
  
<style scoped lang="less">
.card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}

::v-deep .el-input--small .el-input__inner {
    width: 300px;
}

::v-deep .el-textarea__inner {
    width: 800px;
}

::v-deep .el-select {
    width: 300px !important;
}

.demo-ruleForm {
    margin-top: 20px;
}

::v-deep .el-input.el-input--small.el-input--suffix {
    width: 300px;
}
</style>