<!-- 新增，编辑页面 -->
<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" size="small" class="demo-ruleForm">
                <div class="card_tit">基本资料</div>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="企业名称" prop="enterpriseName">
                            <el-input clearable v-model="ruleForm.enterpriseName" placeholder="请输入企业名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="企业类型">
                            <el-input placeholder="收集企业" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="所属区域" prop="enterArea">
                            <HcAreaSelect @area="getArea" ref="HcAreaSelect" :selectedArea="ruleForm.enterArea"
                                v-model="ruleForm.enterArea" placeholder="请选择所属区域"></HcAreaSelect>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="display: flex;" class="addressClass">
                        <el-form-item label="详细地址" prop="enterpriseAddress">
                            <el-input v-model="ruleForm.enterpriseAddress" disabled placeholder="请输入详细地址"></el-input>
                        </el-form-item>
                        <div @click="OpenDitu" class="addressDiv">
                            <i class="el-icon-map-location addressIcon"></i><span>地图</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="经/纬度">
                            <el-input v-model="ruleForm.longilatde" disabled placeholder="请输入经/纬度"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="经营许可证" prop="licenceNo">
                            <el-input clearable v-model="ruleForm.licenceNo" placeholder="请输入经营许可证"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="负责人" prop="legalRepresent">
                            <el-input clearable v-model="ruleForm.legalRepresent" placeholder="请输入负责人"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系人" prop="contactor">
                            <el-input clearable v-model="ruleForm.contactor" placeholder="请输入联系人"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="联系电话" prop="telephone">
                            <el-input clearable v-model="ruleForm.telephone" placeholder="请输入联系电话"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="核准经营规模(吨)" prop="businessScale">
                            <!-- <el-input clearable v-model="ruleForm.businessScale" placeholder="请输入核准经营规模(吨)"></el-input> -->
                            <el-input-number v-model="ruleForm.businessScale" :controls="false" :min="0" :precision="4"
                                clearable placeholder="请输入核准经营规模(吨)"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="email">
                            <el-input clearable v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="备注">
                            <el-input clearable v-model="ruleForm.remark" type="textarea" maxlength="120" show-word-limit
                                placeholder="请输入备注"></el-input> </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="许可证附件" prop="licencePath">
                            <HcUploadFile v-model="ruleForm.licencePath" :value="ruleForm.licencePath"></HcUploadFile>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="营业执照" prop="businessLicensePath">
                        <HcUploadImage v-model="ruleForm.businessLicensePath" :value="ruleForm.businessLicensePath">
                        </HcUploadImage>
                    </el-form-item>
                </el-row>
                <div class="card_tit">环评情况</div>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否有环评" prop="isEia">
                            <el-select v-model="ruleForm.isEia" placeholder="请选择是否有环评" filterable clearable>
                                <el-option label="是" value="1"></el-option>
                                <el-option label="否" value="0"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="ruleForm.isEia == '1'" :span="12">
                        <el-form-item label="环评编号" prop="eiaNo">
                            <el-input clearable v-model="ruleForm.eiaNo" placeholder="请输入环评编号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="ruleForm.isEia == '1'" :span="12">
                        <el-form-item label="环评证书" prop="eiaPath">
                            <HcUploadImage v-model="ruleForm.eiaPath"></HcUploadImage>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </template>
        <template v-slot:footer>
            <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
            <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
        </template>
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import HcUploadImage from "@/components/HcUploadImage/index.vue";
import HcUploadFile from "@/components/HcUploadFile/index.vue";
import HcAreaSelect from "@/components/HcAreaSelect/index.vue";

import * as server from "@/api/businessApi";

export default {
    name: "editDiagle",
    props: {
        baiDuMapDetail: {
            type: Object,
            default: {
                address: "",
                longilatde: ""
            },
        },
        editData: {
            type: Object,
            default: null
        },
    },
    components: {
        HcDiaglePage,
        HcButton,
        HcUploadImage,
        HcUploadFile,
        HcAreaSelect,
    },
    watch: {
        'baiDuMapDetail'(item) {//事件监听地图信息，将最新的地址赋值到表单中
            this.ruleForm.enterpriseAddress = item.address
            this.ruleForm.longilatde = item.longilatde
            let str = item.longilatde.split(",");
            this.ruleForm.longitude = str[0];//经度
            this.ruleForm.latitude = str[1];//纬度
        },
    },
    mounted() {

    },
    data() {
        const validateEia1 = (rule, value, callback) => {
            if (this.ruleForm == undefined) {

            } else {
                if (this.ruleForm.isEia == "0") {
                    //没有环评不校验
                    callback();
                } else {
                    if (value != null && value != "") {
                        callback();
                    } else {
                        callback(new Error("请输入环评编号"));
                    }
                }
            }
        };
        const validateEia2 = (rule, value, callback) => {
            if (this.ruleForm == undefined) {

            } else {
                if (this.ruleForm.isEia == "0") {
                    //没有环评不校验
                    callback();
                } else {
                    if (value != null && value != "") {
                        callback();
                    } else {
                        callback(new Error("请上传环评证书"));
                    }
                }
            }
        };
        const checkTelephone = (rule, value, callback) => {
            const reg = /^(((\d{3,4}-)?[0-9]{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/;
            if (!value) {
                callback();
            }
            setTimeout(() => {
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error("请输入正确的联系电话格式"));
                }
            }, 100);
        };
        const checkEmail = (rule, value, callback) => {
            // const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/;
            const reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
            if (!value) {
                callback();
            }
            setTimeout(() => {
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error("请输入正确的邮箱格式"));
                }
            }, 100);
        };
        return {
            ruleForm: {
                enterpriseNo: "", //企业序列号
                enterpriseId: "", //企业编号
                enterpriseName: "", //企业名称
                enterpriseType: "3", //企业类型
                enterArea: [],//所属区域汇总，为了表单验证
                enterpriseProvince: '', //省
                enterpriseCity: '',     //市
                enterpriseRegion: '',   //区
                enterpriseTown: '',     //街道镇
                enterpriseAddress: '',  //企业地址
                townAddress: '',        //省市区镇中文
                longilatde: '',//经纬度
                longitude: '',//经度
                latitude: '',//纬度
                legalRepresent: "", //法定代表人、、暂代负责人字段
                contactor: "", //联系人
                telephone: "", //联系电话
                businessScale: '',//核准经营规模(吨)
                email: "", //邮箱
                remark: "", //备注
                licenceNo: '',//经营许可证编号
                licencePath: '',//经营许可证文件
                isEia: '',//是否有环评
                eiaNo: '',//环评编号
                eiaPath: '',//环评证书图片
                businessLicensePath: '',//营业执照图片
            },
            rules: {
                enterpriseName: [
                    { required: true, message: "请输入企业名称", trigger: "blur" },
                ],
                enterArea: [
                    { required: true, message: "请选择所属区域", trigger: "blur", type: 'array' },
                ],
                enterpriseAddress: [
                    { required: true, message: "请选择打开地图选择详细地址", trigger: "change" },
                ],
                legalRepresent: [
                    { required: true, message: "请输入法定代表人", trigger: "blur" },
                ],
                contactor: [
                    { required: true, message: "请输入联系人", trigger: "blur" },
                ],
                telephone: [
                    { required: true, trigger: "blur", message: "请输入联系电话", },
                    { validator: checkTelephone, trigger: "blur", }
                ],
                businessScale: [
                    { required: true, message: "请输入核准经营规模(吨)", trigger: "blur" },
                ],
                licenceNo: [
                    { required: true, message: "请输入经营许可证编号", trigger: "blur" },
                ],
                licencePath: [
                    { required: true, message: "请上传许可证附件", trigger: "change" },
                ],
                businessLicensePath: [
                    { required: true, message: "请上传营业执照", trigger: "change" },
                ],
                isEia: [
                    { required: true, message: "请选择是否有环评", trigger: "change" },
                ],
                eiaNo: [
                    { required: true, trigger: "blur", message: "请输入环评编号" },
                    { validator: validateEia1, trigger: "blur", }
                ],
                eiaPath: [
                    { required: true, trigger: "change", message: "请上传环评证书" },
                    { validator: validateEia2, trigger: "change" }
                ],
                email: [
                    { required: false, trigger: 'blur' },
                    { validator: checkEmail, trigger: "blur" }
                ],
            },
            dialogType: ''
        };
    },
    methods: {
        showDiagle(data) {
            this.$refs.hcDiaglePage.showLog = true;
            // 判断弹框是编辑页面还是新增页面
            this.dialogType = data
            if (this.dialogType == 'edit') {
                console.log(this.editData, '编辑收集企业页面')
                this.ruleForm.enterpriseNo = this.editData.enterpriseNo;
                this.ruleForm.enterpriseId = this.editData.enterpriseId;
                this.ruleForm.enterpriseName = this.editData.enterpriseName;
                // this.ruleForm.enterpriseType = this.editData.enterpriseType;
                this.ruleForm.industry = this.editData.industry;
                this.ruleForm.wasteScale = this.editData.wasteScale;

                this.ruleForm.enterArea = [this.editData.enterpriseProvince, this.editData.enterpriseCity, this.editData.enterpriseRegion, this.editData.enterpriseTown];
                this.ruleForm.enterpriseProvince = this.editData.enterpriseProvince
                this.ruleForm.enterpriseCity = this.editData.enterpriseCity;
                this.ruleForm.enterpriseRegion = this.editData.enterpriseRegion;
                this.ruleForm.enterpriseTown = this.editData.enterpriseTown;

                this.ruleForm.enterpriseAddress = this.editData.enterpriseAddress;
                this.ruleForm.longilatde = `${this.editData.longitude},${this.editData.latitude}`
                this.ruleForm.longitude = this.editData.longitude
                this.ruleForm.latitude = this.editData.latitude;
                this.ruleForm.creditCode = this.editData.creditCode;
                this.ruleForm.legalRepresent = this.editData.legalRepresent;
                this.ruleForm.phone = this.editData.phone;
                this.ruleForm.accountBank = this.editData.accountBank;
                this.ruleForm.account = this.editData.account;
                this.ruleForm.contactor = this.editData.contactor;
                this.ruleForm.telephone = this.editData.telephone;
                this.ruleForm.email = this.editData.email;
                this.ruleForm.remark = this.editData.remark;
                this.ruleForm.isEia = this.editData.isEia;
                this.ruleForm.eiaNo = this.editData.eiaNo;
                this.ruleForm.eiaPath = this.editData.eiaPath;
                this.ruleForm.businessLicensePath = this.editData.businessLicensePath;
                this.ruleForm.licenceNo = this.editData.licenceNo;
                this.ruleForm.licencePath = this.editData.licencePath;
                this.ruleForm.businessScale = this.editData.businessScale;
            } else if (this.dialogType == 'add' || this.dialogType == null) {
                this.ruleForm = {
                    enterpriseNo: "", //企业序列号
                    enterpriseId: "", //企业编号
                    enterpriseName: "", //企业名称
                    enterpriseType: "3", //企业类型
                    enterArea: [],//所属区域汇总，为了表单验证
                    enterpriseProvince: '', //省
                    enterpriseCity: '',     //市
                    enterpriseRegion: '',   //区
                    enterpriseTown: '',     //街道镇
                    enterpriseAddress: '',  //企业地址
                    townAddress: '',        //省市区镇中文
                    longilatde: '',//经纬度
                    longitude: '',//经度
                    latitude: '',//纬度
                    legalRepresent: "", //法定代表人、、暂代负责人字段
                    contactor: "", //联系人
                    telephone: "", //联系电话
                    businessScale: '',//核准经营规模(吨)
                    email: "", //邮箱
                    remark: "", //备注
                    licenceNo: '',//经营许可证编号
                    licencePath: '',//经营许可证文件
                    isEia: '',//是否有环评
                    eiaNo: '',//环评编号
                    eiaPath: '',//环评证书图片
                    businessLicensePath: '',//营业执照图片
                }
            }
        },

        add(params) {
            server.addEnterprise(params).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '新增成功',
                        type: 'success'
                    });
                    console.log(this.ruleForm.licencePath)
                    this.$refs.hcDiaglePage.enter();//关闭弹框  
                    this.$parent.queryList()//调用列表页面，刷新列表
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: 'warning'
                    });
                });
        },

        //编辑
        edit(params) {
            server.editEnterprise(params).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                    this.$refs.hcDiaglePage.enter();//关闭弹框  
                    this.$parent.queryList()//调用列表页面，刷新列表

                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            })
                .catch((err) => {
                    this.$message({
                        message: err.message,
                        type: 'warning'
                    });
                });
        },

        submitForm(formName) {
            //this.ruleForm.enterpriseAddress = this.baiDuMapDetail.address//将地址赋值
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.dialogType == 'edit') {
                        if (this.ruleForm.isEia == '0') {
                            this.ruleForm.eiaNo = ' ';
                            this.ruleForm.eiaPath = ' ';
                            this.edit(this.ruleForm) //调用编辑接口
                        }
                        if (this.ruleForm.isEia == '1') {
                            this.edit(this.ruleForm) //调用编辑接口
                        }

                    } else {
                        if (this.ruleForm.isEia == '0') {
                            this.ruleForm.eiaNo = ' ';
                            this.ruleForm.eiaPath = ' ';
                            this.add(this.ruleForm) //调用新增接口
                        }
                        if (this.ruleForm.isEia == '1') {
                            this.add(this.ruleForm) //调用新增接口
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$refs.hcDiaglePage.close();
            this.ruleForm = {
                enterpriseNo: "", //企业序列号
                enterpriseId: "", //企业编号
                enterpriseName: "", //企业名称
                enterpriseType: "3", //企业类型
                enterArea: [],//所属区域汇总，为了表单验证
                enterpriseProvince: '', //省
                enterpriseCity: '',     //市
                enterpriseRegion: '',   //区
                enterpriseTown: '',     //街道镇
                enterpriseAddress: '',  //企业地址
                townAddress: '',        //省市区镇中文
                longilatde: '',//经纬度
                longitude: '',//经度
                latitude: '',//纬度
                legalRepresent: "", //法定代表人、、暂代负责人字段
                contactor: "", //联系人
                telephone: "", //联系电话
                businessScale: '',//核准经营规模(吨)
                email: "", //邮箱
                remark: "", //备注
                licenceNo: '',//经营许可证编号
                licencePath: '',//经营许可证文件
                isEia: '',//是否有环评
                eiaNo: '',//环评编号
                eiaPath: '',//环评证书图片
                businessLicensePath: '',//营业执照图片
            }
            this.$parent.baiDuMapDetail == {};
            this.$refs.HcAreaSelect.rest();
        },

        // 省市区镇选择
        getArea(val) {
            this.ruleForm.enterpriseProvince = val.enterpriseProvince;  //省
            this.ruleForm.enterpriseCity = val.enterpriseCity;          //市
            this.ruleForm.enterpriseRegion = val.enterpriseRegion;      //区
            this.ruleForm.enterpriseTown = val.enterpriseTown;          //镇
            this.ruleForm.townAddress = val.townAddress;                //省市区镇中文

            if (val.enterpriseProvince == "") {
                this.ruleForm.enterArea = [];
                this.ruleForm.townAddress = '';
            } else {
                this.ruleForm.enterArea = [val.enterpriseProvince, val.enterpriseCity, val.enterpriseRegion, val.enterpriseTown];
            }
        },

        // 打开百度地图
        OpenDitu() {
            this.$parent.detailTit = '获取坐标'
            this.$parent.baiduMapOpen();
        },
    },
};
</script>
  
<style scoped lang="less">
.card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}

::v-deep .el-input--small .el-input__inner {
    width: 300px;
}

// ::v-deep .el-textarea__inner {
//     width: 800px;
// }

.addressClass {
    display: flex;

    .addressDiv {
        cursor: pointer;
    }

    .addressIcon {
        font-size: 24px;
        color: #E6A23C;
        margin-left: 6px;
    }

    span {
        font-size: 14px;
        color: #E6A23C;
    }
}

.demo-ruleForm {
    margin-top: 20px;
}

::v-deep .footer {
    margin-bottom: 72px;
}

::v-deep .el-input.el-input--small.el-input--suffix {
    width: 300px;
}
</style>
  