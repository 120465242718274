<template>
  <div class="tabOne">
    <div class="tabOne_heard">
      <el-form
        ref="form"
        :model="params"
        label-width="80px"
        size="small"
        :inline="true"
        class="form_margin"
      >
        <el-form-item label="报警时间">
          <el-date-picker
            v-model="timeScope"
            type="daterange"
            range-separator="至"
            start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间"
            value-format="yyyy-MM-dd"
            @change="datePickerChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="处理状态">
          <el-select
            v-model="params.handleStatus"
            clearable
            filterable
            placeholder="请选择处理状态"
          >
            <el-option label="未处理" value="0"></el-option>
            <el-option label="已处理" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备ID">
          <el-input
            v-model="params.deviceId"
            clearable
            placeholder="请输入设备ID"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="tabOne_heard_button">
        <HcButton
          :type="'primary'"
          icon="el-icon-search"
          class="button"
          size="small"
          @click="queryList"
          >查询
        </HcButton>
        <HcButton
          :type="'refresh'"
          icon="el-icon-refresh"
          size="small"
          @click="reset"
          >重置</HcButton
        >
      </div>
    </div>
    <div class="tabOne_cen">
      <div class="tabOne_cen_button">
        <HcButton
          :type="'primary'"
          icon="el-icon-finished"
          class="button"
          size="small"
          @click="batchFix"
          >一键处理
        </HcButton>
        <el-popconfirm
          title="是否确认导出列表数据？"
          @confirm="exportExcel"
          placement="right"
        >
          <HcButton
            slot="reference"
            :type="'info'"
            icon="el-icon-upload2"
            size="small"
            >导出</HcButton
          >
        </el-popconfirm>
      </div>
      <div class="tabOne_cen_table">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :height="tableHeight"
          v-loading="loading"
          element-loading-text="加载中"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="enterpriseName"
            label="当前所属企业"
            width="190"
          ></el-table-column>
          <el-table-column label="设备ID" width="150">
            <template slot-scope="scope">
              <div @click="goDevice(scope.row)" class="deviceIdStyle">
                {{ scope.row.deviceId }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="deviceName"
            label="设备名称"
            width="180"
          ></el-table-column>
          <el-table-column prop="deviceModel" label="设备型号" width="80">
            <template slot-scope="scope">
              {{ global.DeviceModelMap.get(scope.row.deviceModel) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="warnContent"
            label="报警内容"
          ></el-table-column>
          <el-table-column label="处理状态" width="120">
            <template slot-scope="scope">
              <el-tag
                type="success"
                size="medium"
                v-if="scope.row.handleStatus == 1"
                >已处理</el-tag
              >
              <el-tag
                type="danger"
                size="medium"
                v-if="scope.row.handleStatus == 0"
                >未处理</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="warnDate"
            label="报警时间"
            width="160"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.handleStatus == 1"
                @click="openDetailDiagle(scope.row)"
                type="text"
                size="default"
                >详情</el-button
              >
              <el-button
                v-if="scope.row.handleStatus == 0"
                @click="openEdit(scope.row)"
                type="text"
                size="default"
                >处理</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="tabOne_btm">
      <HcPagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNum"
        :page-size="params.pageSize"
        :total="params.total"
      >
      </HcPagination>
    </div>
  </div>
</template>
<script>
import HcButton from "@/components/HcButton/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";

import * as server from "@/api/warnApi";

export default {
  name: "tabOne",
  components: {
    HcButton,
    HcPagination,
  },

  props: ["activeName", "openDetail", "openEditFun"],

  data() {
    return {
      timeScope: [],
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        warnStartDate: "",
        warnEndDate: "",
        warnType: "",
        deviceId: "",
        handleStatus: "",
      },
      loading: false, //加载中
      tableData: [],

      editData: {}, //编辑页面数据,
      mainHeight: null, //中间高度
      tableHeight: null, //表格高度
    };
  },
  inject: ["detailFun"],
  methods: {
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
    },

    handleSizeChange(data) {
      //每页多少条
      this.params.pageSize = data; //赋值给params
      this.getList(this.params); //重新请求获取table的接口
    },

    handleCurrentChange(data) {
      //当前多少页
      this.params.pageNum = data; //当前页
      this.getList(this.params); //重新获取table的接口
    },

    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1; //将当前页面设置为第一页
      this.params.warnType = this.activeName.split("tab")[1];
      this.getList(this.params);
    },
    reset() {
      this.timeScope = [];
      this.params.warnStartDate = "";
      this.params.warnEndDate = "";
      this.params.handleStatus = "";
      this.params.deviceId = "";
      this.queryList();
    },

    // 获取table表格数据
    getList(params) {
      this.loading = true; //请求时打开加载中
      server
        .list(params)
        .then((res) => {
          this.loading = false; //成功关闭加载中
          // 将获取的list赋值给table
          this.tableData = res.data.list;

          // 设置分页的数据
          this.params.totalPage = res.data.totalPage;
          this.params.total = res.data.total;
          this.params.pageNum = this.params.pageNum;
          this.params.pageSize = this.params.pageSize;
        })
        .catch((err) => {
          setTimeout(() => {
            //失败2秒后关闭加载中
            this.loading = false;
          }, 2000);
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });
    },

    //时间范围选择
    datePickerChange(daterange) {
      this.params.warnStartDate = "";
      this.params.warnStartDate = "";
      if (daterange[0]) {
        this.params.warnStartDate = daterange[0] + " 00:00:00";
        this.params.warnEndDate = daterange[1] + " 23:59:59";
      }
    },

    //导出表格
    exportExcel() {
      let excelName = "";
      if (this.activeName == "tab1") {
        excelName = "满负荷";
        this.params.warnType = 1;
      }
      if (this.activeName == "tab2") {
        excelName = "无废预警";
        this.params.warnType = 2;
      }
      if (this.activeName == "tab3") {
        excelName = "超期预警";
        this.params.warnType = 3;
      }
      if (this.activeName == "tab4") {
        excelName = "减量预警";
        this.params.warnType = 4;
      }
      if (this.activeName == "tab5") {
        excelName = "故障预警";
        this.params.warnType = 5;
      }
      if (this.activeName == "tab6") {
        excelName = "电量预警";
        this.params.warnType = 6;
      }
      if (this.activeName == "tab7") {
        excelName = "倾倒预警";
        this.params.warnType = 7;
      }
      if (this.activeName == "tab8") {
        excelName = "高温预警";
        this.params.warnType = 8;
      }
      this.download(
        "/cloud/warn/exportWarn",
        this.params,
        excelName + `报警数据_${new Date().getTime()}.xlsx`
      );
    },

    //一键处理
    batchFix() {
      server.batchFix(this.params).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "一键处理成功",
            type: "success",
          });
          this.queryList();
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      });
    },

    // 打开详情页面
    openDetailDiagle(row) {
      this.openDetail(row);
    },

    // 打开处理页面
    openEdit(row) {
      this.openEditFun(row);
    },
    // 点击设备ID获取当前行数据
    goDevice(row) {
      console.log(row, "row");
      let data = JSON.stringify(row);
      sessionStorage.setItem("tabPage", data);
      this.$router.push({
        name: "binList", //跳转到智能危废箱列表
        query: {
          type: "tabPage",
        },
      });
    },
  },
  mounted() {
    this.mainHeight = sessionStorage.getItem("listMainHeight"); //获取中间区域的高度
  },

  watch: {
    mainHeight: function (item) {
      //通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 290;
    },
    activeName: function () {
      this.params.warnType = this.activeName.substr(3, this.activeName.length);
      // this.queryList();
    },
  },
};
</script>
<style lang="less" scoped>
.tabOne {
  .tabOne_heard {
    display: flex;
    justify-content: space-between;

    .form_margin {
      margin-top: 18px;
    }

    .tabOne_heard_button {
      display: flex;
      width: 180px;
      justify-content: space-between;
    }
  }

  .tabOne_cen {
    .tabOne_cen_button {
      display: flex;
    }

    .deviceIdStyle {
      color: #409eff;
      cursor: pointer;
    }
  }

  .tabOne_btm {
    text-align: right;
    margin-top: 50px;
  }
}

.button {
  padding: 0 10px 0 0;
}
</style>
