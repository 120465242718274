import { httpRequest, baseUrl } from '../utils/request'
export {
    getProvince, getCity, getRegion, getTown
}

// 查询省数据
const getProvince = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/area/provinceList',
        method: 'post',
        params
    })
)

// 查询市数据
const getCity = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/area/cityList',
        method: 'post',
        params
    })
)

// 查询区数据
const getRegion = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/area/regionList',
        method: 'post',
        params
    })
)

// 查询镇数据
const getTown = (params) => (
    httpRequest({
        url: baseUrl + '/cloud/area/townList',
        method: 'post',
        params
    })
)