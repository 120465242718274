<template>
    <div class="tab3">
        <div class="operate">
            <div></div>
            <div class="search">
                <el-form :model="params" label-width="80px" size="small" :inline="true">
                    <el-form-item label="">
                        <el-input clearable v-model="params.fuzzyField"></el-input>
                    </el-form-item>
                </el-form>
                <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="searchTable">查询</HcButton>
                <HcButton :type="'refresh'" icon="el-icon-refresh " size="small" class="rest" @click="reset">重置</HcButton>
            </div>
        </div>
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading">
            <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
            <el-table-column prop="enterpriseId" label="企业编号"></el-table-column>
            <el-table-column prop="enterpriseName" label="企业名称"></el-table-column>
            <el-table-column prop="contactor" label="联系人"></el-table-column>
            <el-table-column prop="telephone" label="联系电话"></el-table-column>
            <el-table-column prop="email" label="邮箱"></el-table-column>
        </el-table>
        <div style="margin: 50px 0px;text-align: right;">
            <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="params.pageNum" :page-size="params.pageSize" :total="params.total" class="pagination">
            </HcPagination>
        </div>
    </div>
</template>
<script>
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/businessApi";

export default {
    name: 'tab3',
    components: { HcPagination, HcButton },
    data() {
        return {
            mainHeight: null,//中间高度
            tableHeight: null,//表格高度
            tableData: [],
            loading: false,
            params: {
                enterpriseType: '1',
                pageSize: 20,
                pageNum: 1,
                total: null,
                totalPage: null,
                fuzzyField: '',//模糊查询字段
            },
        }
    },
    methods: {
        // 获取table表格数据
        getEnterpriseList(params) {
            this.loading = true; //请求时打开加载中
            server.enterprise(params).then((res) => {
                if (res.code == 200) {
                    this.loading = false; //成功关闭加载中
                    // 将获取的list赋值给table
                    this.tableData = res.data.list;
                    // 设置分页的数据
                    this.params.totalPage = res.data.totalPage;
                    this.params.pageNum = res.data.pageNum;
                    this.params.pageSize = res.data.pageSize;
                    this.params.total = res.data.total;
                }
            })
            .catch((err) => {
                setTimeout(() => {
                    //失败2秒后关闭加载中
                    this.loading = false;
                }, 2000);
                this.$message({
                    showClose: true,
                    message: err.message,
                    type: "warning",
                });
            });
        },
        //table序号
        indexMethod(index) {
            return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
        },
        handleSizeChange(data) {
            this.params.pageSize = data; //赋值给params
            this.getEnterpriseList(this.params); //重新请求获取table的接口
        },
        handleCurrentChange(data) {
            this.params.pageNum = data; //当前页
            this.getEnterpriseList(this.params); //重新获取table的接口
        },
        searchTable() {
            this.getEnterpriseList(this.params)
        },
        reset(){
            this.params.fuzzyField = '';
            this.getEnterpriseList(this.params)
        }
    },
    mounted() {
        this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
    },
    watch: {
        'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
            this.tableHeight = item - 244
        }
    }
}

</script>
<style  lang="less" scoped>
.tab3 {
padding: 0 10px 0 20px;
    ::v-deep .el-pagination {
        text-align: right !important;
        margin-top: 16px !important;
    }

    .operate {
        display: flex;
        justify-content: space-between;
        // margin-bottom: 16px;

        .search {
            display: flex;
        }

        .rest {
            margin-left: 8px;
        }
    }
}

//表格里的 删除按钮
.button_text_del {
    color: #F56C6C !important;
    padding-left: 10px;
}

/deep/.el-button--primary {
    background: #00BFFF;
}
</style>