import {httpRequest,baseUrl} from '../utils/request'
export {
    list,add,remove,overviewList,performanceInfo,
    queryCurrentDate,queryHistoryMonitor,
    queryDeviceAlarm,queryDeviceTrouble,updateDevice,
    getAllSimpleDeviceList,deviceDetail,getAllSimpleDeviceList2,getAllSimpleDeviceList3
}

// 查询设备列表
const list=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/list',
       method:'post' ,
       params
    })
)

// 新增设备信息
const add=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/device/add',
        method:'post' ,
        params
     })
)

// 删除设备信息
const remove=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/remove',
       method:'post' ,
       params
    })
)

// 设备总览信息
const overviewList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/overviewList',
       method:'post' ,
       params
    })
)

// 查询设备性能数据
const performanceInfo=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/performanceInfo',
       method:'post' ,
       params
    })
)

// 实时监测数据
const queryCurrentDate=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/queryCurrentDate',
       method:'post' ,
       params
    })
)

// 历史数据监测
const queryHistoryMonitor=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/queryHistoryMonitor',
       method:'post' ,
       params
    })
)

// 报警数据检测
const queryDeviceAlarm=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/queryDeviceAlarm',
       method:'post' ,
       params
    })
)

// 设备故障检测
const queryDeviceTrouble=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/queryDeviceTrouble',
       method:'post' ,
       params
    })
)

// 编辑
const updateDevice=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/updateDevice',
       method:'post' ,
       params
    })
)

// 查询所有简略企业信息列表（无分页）
const getAllSimpleDeviceList=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/allSimpleDeviceList',
       method:'post' ,
       params
    })
)

// 查询所有简略企业信息列表（无分页）
const getAllSimpleDeviceList2=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/allSimpleDeviceList2',
       method:'post' ,
       params
    })
)

// 查询所有简略企业信息列表（无分页）
const getAllSimpleDeviceList3=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/allSimpleDeviceList3',
       method:'post' ,
       params
    })
)

// 查询设备详细数据
const deviceDetail=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/device/deviceDetail',
       method:'post' ,
       params
    })
)