<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm"></i>
        </template>
        <template v-slot:center>
            <div class="tab1">
                <el-descriptions class="margin-top" :column="2" border>
                    <el-descriptions-item>
                        <template slot="label">
                            危废类别
                        </template>
                        {{ detailData.categoryCode }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            危废类别名称
                        </template>
                        {{ detailData.categoryName }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            废物代码
                        </template>
                        {{ detailData.harmWasteCode }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            废物名称
                        </template>
                        {{ detailData.harmWasteName }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            年经营规模(吨)
                        </template>
                        {{ detailData.businessScale }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            经营方式
                        </template>
                        <span v-for="item in detailData.businessMode">{{ global.BusinessModeMap.get(item)}} </span>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </template>
        <template v-slot:footer>
<!--             
            <HcButton :type="'cancel'" size="small" @click="resetForm()">取消</HcButton>
            <HcButton :type="'primary'" size="small" @click="resetForm()">确定</HcButton> -->
        </template>
    </HcDiaglePage>
</template>
  
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";

export default {
    name: "tab3detail.vue",
    components: {
        HcDiaglePage,
        HcButton,
    },
    props: {
        detailData: {
            type: Object,
        },
    },
    data() {
        return {

        };
    },
    methods: {
        showtab3Detail() {
            this.$refs.hcDiaglePage.showLog = true;
            this.$parent.detailTit = "收处类别详情";
        },
        resetForm() {
            this.$refs.hcDiaglePage.close();
            this.$parent.detailTit = "处置企业详情";
        },
    },
};
</script>
  
<style scoped lang="less">
.card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}

::v-deep .el-input--small .el-input__inner {
    width: 300px;
}

::v-deep .el-textarea__inner {
    width: 800px;
}
.margin-top {
    margin-top: 20px;
}
::v-deep .el-descriptions-row th {
    width: 10vw;
}
</style>