<template>
    <!-- 管理计划 -> 新增计划 ->新增危险废物产生 -->
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm"></i>
        </template>
        <template v-slot:center>
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="170px" class="demo-ruleForm" size="small">
                <el-row>
                    <el-col :span="12">
                        <!-- <el-form-item label="行业来源" prop="wasteType">
                            <el-select v-model="ruleForm.wasteType" clearable filterable placeholder="请选择行业来源">
                                <el-option v-for="item in MAP2JSON(global.IndustryMap)" :key="item.value"
                                    :label="item.label" :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="行业来源" prop="wasteType">
                            <el-input v-model="ruleForm.wasteType" clearable placeholder="请输入行业来源"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="废物代码" prop="wasteCode">
                            <el-input v-model="ruleForm.wasteCode" clearable placeholder="请输入废物代码"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="废物名称" prop="wasteCategory">
                            <el-input v-model="ruleForm.wasteCategory" clearable placeholder="请输入废物名称">

                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="危险危废" prop="harmWaste">
                            <el-input v-model="ruleForm.harmWaste" clearable placeholder="请输入危险危废"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="危险特性" prop="feature">
                            <template>
                                <el-checkbox-group v-model="ruleForm.feature">
                                    <el-checkbox label="T"></el-checkbox>
                                    <el-checkbox label="C"></el-checkbox>
                                    <el-checkbox label="I"></el-checkbox>
                                    <el-checkbox label="R"></el-checkbox>
                                    <el-checkbox label="In"></el-checkbox>
                                </el-checkbox-group>
                            </template>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="废物形态" prop="wasteForm">
                            <el-select v-model="ruleForm.wasteForm" clearable placeholder="请选择废物形态" style="width: 300px;">
                                <el-option v-for="item in MAP2JSON(global.WasteFormMap)" :key="item.value"
                                    :label="item.label" :value="item.value">
                                </el-option></el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </template>
        <template v-slot:footer>

            <HcButton :type="'cancel'" size="small" @click="resetForm()">取消</HcButton>
            <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>
        </template>
    </HcDiaglePage>
</template>
      
<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from '@/components/HcButton/index.vue';

export default {
    name: "addWasteCode",
    components: {
        HcDiaglePage,
        HcButton
    },
    data() {
        return {
            ruleForm: {
                wasteType: "",
                wasteCode: "",
                wasteCategory: "",
                harmWaste: "",
                feature: [],
                categoryCode: '',
                wasteForm: ''
            },
            rules: {
                wasteType: [
                    { required: true, message: "请输入行业来源", trigger: "blur" },
                ],
                wasteCode: [
                    { required: true, message: "请输入废物代码", trigger: "blur" },
                ],
                wasteCategory: [
                    { required: true, message: "请输入废物名称", trigger: "blur" },
                ],
                harmWaste: [
                    { required: true, message: "请输入危险危废", trigger: "blur" },
                ],
                feature: [
                    { required: true, message: "请选择危险特性", trigger: "change" },
                ],
                wasteForm: [
                    { required: true, message: "请选择废物形态", trigger: "change" },
                ]
            },
        };
    },
    methods: {
        showaddWasteCode(data) {
            this.$refs.hcDiaglePage.showLog = true;
            let categoryCode = data
            console.log(data, '把categoryCode传到新增页')
            this.ruleForm = {
                wasteType: "",
                wasteCode: "",
                wasteCategory: "",
                harmWaste: "",
                feature: [],
                wasteForm: '',
                categoryCode: categoryCode
            };
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$message({
                        message: '添加废物代码成功',
                        type: 'success'
                    });

                    //本页面不提交到数据库，在外层提交数据库
                    this.$emit('change', this.ruleForm);
                    this.$refs.hcDiaglePage.enter();//关闭弹框

                } else {
                    return false;
                }
            });
        },

        resetForm() {
            this.$refs.hcDiaglePage.close();
            this.$parent.detailTit = '新增危废名录'
        },

    },
};
</script>
      
<style scoped lang="less">
.card_tit {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}

//   ::v-deep .el-form-item__content {}

::v-deep .el-input.el-input--small.el-input--suffix {
    width: 300px;
}

::v-deep .el-textarea__inner {
    width: 800px;
}

.tabTit {
    padding-bottom: 16px;
}

.demo-ruleForm {
    margin-top: 20px;
}
</style>
      