<!-- 省市区镇四级联动【懒加载】 -->
<template>
  <el-cascader
    ref="cascader"
    size="small"
    :props="props"
    v-model="selectedArea"
    @change="handleChange"
    clearable
    placeholder="请选择所属区域"
    :disabled="fatherDisabled"
  ></el-cascader>
</template>
<script>
import * as server from "@/api/areaApi";

export default {
  name: "areaSelect",
  props: {
    selectedArea: {
      type: Array,
      default: () => [],
    },
    fatherDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedArea: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal, 22222);
      },
      deep: true,
    },
  },
  data() {
    let that = this;
    return {
      props: {
        checkStrictly: true,
        //级联选择器懒加载
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          //console.log(level, node);
          if (level == 0) {
            that.getProvince((list1) => {
              let arr = list1.map((e) => ({ value: e.pid, label: e.pname }));
              resolve(arr); // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            });
          }
          if (level == 1) {
            let { value } = node;
            that.getCity({ pId: value }).then((list2) => {
              let arr = list2.map((e) => ({ value: e.cid, label: e.cname }));
              resolve(arr);
            });
          }
          if (level == 2) {
            let { value } = node;
            that.getRegion({ cId: value }).then((list3) => {
              let arr = list3.map((e) => ({ value: e.rid, label: e.rname }));
              resolve(arr);
            });
          }
          if (level == 3) {
            let { value } = node;
            that.getTown({ rId: value }).then((list4) => {
              let arr = list4.map((e) => ({
                value: e.tid,
                label: e.tname,
                leaf: true,
              }));
              resolve(arr);
            });
          }
          if (level == 4) {
            resolve([]);
          }
        },
      },
      params: {
        enterpriseProvince: "", //省
        enterpriseCity: "", //市
        enterpriseRegion: "", //区
        enterpriseTown: "", //街道镇
        townAddress: "", //省市区镇中文
      },
    };
  },

  methods: {
    // 省市区镇选择
    handleChange(val) {
      //console.log(val, '所选省市区镇')

      //清空
      if (val.length == 0) {
        this.params.enterpriseProvince = ""; //省
        this.params.enterpriseCity = ""; //市
        this.params.enterpriseRegion = ""; //区
        this.params.enterpriseTown = ""; //镇
        this.params.townAddress = ""; //省市区镇中文
        this.$emit("area", this.params);
        return;
      }

      let checkedNodes = this.$refs.cascader.getCheckedNodes()[0];

      //console.log("筛选层级level = " + checkedNodes.level + "筛选层级path = " + checkedNodes.path + "筛选层级pathLabels = " + checkedNodes.pathLabels);

      //如果level返回1，则是第一层节点，赋值给省，并把市区镇三个清空，防止切换的时候把上一次的市区镇的数据带过来，下面以此类推
      if (checkedNodes.level == 1) {
        this.params.enterpriseProvince = checkedNodes.path[0]; //省
        this.params.enterpriseCity = ""; //市
        this.params.enterpriseRegion = ""; //区
        this.params.enterpriseTown = ""; //镇
        this.params.townAddress = checkedNodes.pathLabels[0];
      } else if (checkedNodes.level == 2) {
        this.params.enterpriseProvince = checkedNodes.path[0]; //省
        this.params.enterpriseCity = checkedNodes.path[1]; //市
        this.params.enterpriseRegion = ""; //区
        this.params.enterpriseTown = ""; //镇
        this.params.townAddress =
          checkedNodes.pathLabels[0] + checkedNodes.pathLabels[1];
      } else if (checkedNodes.level == 3) {
        this.params.enterpriseProvince = checkedNodes.path[0]; //省
        this.params.enterpriseCity = checkedNodes.path[1]; //市
        this.params.enterpriseRegion = checkedNodes.path[2]; //区
        this.params.enterpriseTown = ""; //镇
        this.params.townAddress =
          checkedNodes.pathLabels[0] +
          checkedNodes.pathLabels[1] +
          checkedNodes.pathLabels[2];
      } else if (checkedNodes.level == 4) {
        this.params.enterpriseProvince = checkedNodes.path[0]; //省
        this.params.enterpriseCity = checkedNodes.path[1]; //市
        this.params.enterpriseRegion = checkedNodes.path[2]; //区
        this.params.enterpriseTown = checkedNodes.path[3]; //镇
        this.params.townAddress =
          checkedNodes.pathLabels[0] +
          checkedNodes.pathLabels[1] +
          checkedNodes.pathLabels[2] +
          checkedNodes.pathLabels[3];
      }
      this.$emit("area", this.params);
    },

    //请求省数据
    async getProvince(callback) {
      let res = await server.getProvince();
      if (res.code == 200) {
        callback(res.data);
      }
    },
    //请求市数据
    async getCity(params) {
      let res = await server.getCity(params);
      if (res.code == 200) {
        return res.data;
      }
    },
    //请求区数据
    async getRegion(params) {
      let res = await server.getRegion(params);
      if (res.code == 200) {
        return res.data;
      }
    },
    //请求镇数据
    async getTown(params) {
      let res = await server.getTown(params);
      if (res.code == 200) {
        return res.data;
      }
    },
    // 清空
    rest() {
      this.$refs.cascader.$refs.panel.clearCheckedNodes(); // 清空级联选择器选中状态
      this.$refs.cascader.$refs.panel.checkedValue = []; // 清空选中值
      this.$refs.cascader.$refs.panel.activePath = []; // 清除高亮
      this.$refs.cascader.$refs.panel.syncActivePath(); // 初始化（只展示一级节点）
    },
  },
};
</script>
<style lang="less" scoped></style>
