<template>
    <HcDiaglePage ref="hcDiaglePage">
        <template v-slot:close>
            <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
        </template>
        <template v-slot:center>
            <div class="center_detail">
                <el-tabs v-model="activeName2" type="card" @tab-click="handleClick">
                    <el-tab-pane label="企业详情" name="tab1">
                        <tab1 ref="tab1" :editData="editData"></tab1>
                    </el-tab-pane>
                    <el-tab-pane label="运输车辆" name="tab2">
                        <tab2 ref="tab2" :screen="screen" :editData="editData"></tab2>
                    </el-tab-pane>
                    <el-tab-pane label="司机列表" name="tab3">
                        <tab3 ref="tab3" :screen="screen" :editData="editData"></tab3>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </template>
       
    </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";

//详情页面里tab切换组件
import tab1 from "../components/tab1.vue";
import tab2 from "../components/tab2.vue";
import tab3 from "../components/tab3.vue";

export default {
    name: "detailDiagle",
    props: {
        editData: {
            type: Object,
            default: null,
        },
        screen:{
            type:String
        }
    },
    components: {
        HcDiaglePage,
        HcButton,
        tab1,
        tab2,
        tab3,
    },
    data() {
        return {
            dialogType: "",
            activeName2: "tab1",
        };
    },
    methods: {
        showDiagle(data) {
            this.$refs.hcDiaglePage.showLog = true;
            this.dialogType = data;
        },
        handleClick(tab, event) {
            let tabName = this.activeName2
            switch (tabName) {
                case 'tab2':
                    this.$refs.tab2.getList(this.$refs.tab2.params)
                    break;
                case 'tab3':
                    this.$refs.tab3.getList(this.$refs.tab3.params)
                    break
            }
        },
        resetForm(formName) {
            this.activeName2 = "tab1";
            this.$refs.hcDiaglePage.close();
        },
    },
};
</script>

<style scoped lang="less">

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item {
    background-color: #f3f8ff !important;
    margin: 0 !important;
    border-radius: 0 !important;
    color: #808080;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    color: #00bfff !important;
    background-color: #fff !important;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: 1px solid #e4e7ed !important;
    width: 100%;
    background-color: #f3f8ff !important;
}
::v-deep .center{
    padding: 0px;
}
::v-deep .footer{
    display: none;
}
::v-deep .body{
    top: 45px;
}
</style>
