<template>
  <!-- 管理计划-计划列表 -->
  <div class="planList">
    <HcListMain>
      <template v-slot:header_from>
        <el-form ref="form" :model="params" label-width="100px" size="small" :inline="true">
          <el-form-item label="危废类别">
            <el-input v-model="params.categoryCode" clearable placeholder="请输入危废类别">
            </el-input>
          </el-form-item>
          <el-form-item label="危废类别名称">
            <el-input v-model="params.categoryName" clearable placeholder="请输入危废类别名称">
            </el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:header_button>
        <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="queryList">查询</HcButton>
        <HcButton :type="'refresh'" icon="el-icon-refresh" size="small" @click="reset">重置</HcButton>
      </template>
      <template v-slot:content_button>
        <HcButton :type="'primary'" icon="el-icon-plus" size="small" @click="openAdd" class="button">新增</HcButton>
        <HcButton :type="'info'" icon="el-icon-upload2" size="small" @click="upLoadDirectory">导入</HcButton>
        <!-- 导入对话框 -->
        <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body>
          <el-upload ref="upload" name="multipartFile" :limit="1" accept=".xlsx, .xls" :headers="upload.headers"
            :action="upload.url" :multipartFile=upload.multipartFile :disabled="upload.isUploading"
            :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" drag>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip text-center" slot="tip">
              <span>仅允许导入xls、xlsx格式文件。</span>
            </div>
          </el-upload>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitFileForm">确 定</el-button>
            <el-button @click="upload.open = false">取 消</el-button>
          </div>
        </el-dialog>
      </template>
      <template v-slot:content_table>
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading"
          element-loading-text="加载中">
          <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
          <el-table-column prop="categoryCode" label="危废类别" width="180"></el-table-column>
          <el-table-column prop="categoryName" label="危废类别名称" width="180"></el-table-column>
          <el-table-column prop="mainComponent" label="主要成分" min-width="200"></el-table-column>
          <el-table-column prop="harmfulComponent" label="有害成分" min-width="200"></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button @click="openEdit(scope.row)" type="text" size="default">编辑</el-button>
              <el-popconfirm title="是否确定删除所选数据？" @confirm="removeOne(scope.row)">
                <el-button slot="reference" type="text" size="default" class="button_text_del">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-slot:content_pag>
        <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.pageNum"
          :page-size="params.pageSize" :total="params.total">
        </HcPagination>
      </template>
    </HcListMain>

    <!-- 计划新增页面 -->
    <addDiagle ref="addDiagle" :selectData1="tableData1" :newTableData="addData"></addDiagle>

    <!-- 计划编辑页面 -->
    <editDiagle ref="editDiagle" :newTableData="addData" :selectData1="tableData1"></editDiagle>
    <!-- 新增危险废物产生 -->
    <addWasteCode ref="addWasteCode" @change="pushData1"></addWasteCode>

  </div>
</template>

<script>
import HcButton from '@/components/HcButton/index.vue';
import HcInput from '@/components/HcInput/index.vue';
import HcListMain from "@/components/HcListMain/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import * as server from '@/api/systemApi';

// import planDetail from "./pages/planDetail.vue";//详情页面
import addDiagle from './pages/addDiagle.vue';//新增计划
import editDiagle from './pages/editDiagle.vue';//编辑计划
import addWasteCode from './pages/addWasteCode.vue';//新增危险废物产生
// import addWasteStorage from './pages/addWasteStorage';//新增危废废品贮存

export default {
  components: { HcButton, HcInput, HcListMain, HcPagination, addDiagle, editDiagle, addWasteCode },
  name: 'directory',
  data() {
    return {
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        categoryCode: '',
        categoryName: '',
      },
      tableData: [],
      loading: false,//加载中
      detailTit: '新增危废名录',
      editData: [],//编辑页面数据,
      tableData1: [],
      addData: {},
      mainHeight: null,//中间高度
      tableHeight: null,//表格高度
      // 导入参数
      upload: {
        open: false,// 是否显示弹出层（用户导入）
        title: "",// 弹出层标题（用户导入）
        isUploading: false,// 是否禁用上传
        multipartFile: false,// 是否更新已经存在的用户数据
        headers: { token: window.localStorage.getItem("token"), },
        url: process.env.VUE_APP_BASE_URL + "/cloud/wasteInfo/explodeWasteInfo"// 上传的地址
      },
    }
  },
  provide() {
    //依赖注入，传递值 使用函数的形式可以修改值
    return {
      title: () => this.detailTit,
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1
    },

    handleClick(row) {
      console.log(row);
    },

    handleSizeChange(data) { //每页多少条
      this.params.pageSize = data//赋值给params
      this.getList(this.params)//重新请求获取table的接口
    },

    handleCurrentChange(data) {//当前多少页
      this.params.pageNum = data//当前页
      this.getList(this.params)//重新获取table的接口
    },

    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1//将当前页面设置为第一页
      this.getList(this.params)
    },
    // 重置按钮
    reset() {
      this.params.categoryCode = ""; //企业类型
      this.params.categoryName = ""; //企业名称
      this.queryList()
    },
    // 获取table表格数据
    getList(params) {
      this.loading = true //请求时打开加载中
      server.wasteCategoryList(params).then((res) => {
        this.loading = false //成功关闭加载中
        // 将获取的list赋值给table
        this.tableData = res.data.list
        // 设置分页的数据
        this.params.totalPage = res.data.totalPage;
        this.params.pageNum = res.data.pageNum;
        this.params.pageSize = res.data.pageSize;
        this.params.total = res.data.total;

      }).catch((err) => {
        setTimeout(() => {//失败2秒后关闭加载中
          this.loading = false
        }, 2000)
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
    },

    // 打开新增危废名录
    openAdd() {
      this.detailTit = '新增危废名录'
      this.$refs.addDiagle.showDiagle();
    },

    // 打开编辑计划
    openEdit(row) {
      this.detailTit = '编辑危废名录'
      this.editData = row;
      setTimeout(() => {
        this.$refs.editDiagle.showDiagle(row);
      });
    },

    //删除列表中的一条信息
    removeOne(row) {
      server.deleteWasteCategory({
        categoryCode: row.categoryCode
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.queryList();
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "warning",
          });
        }
      }).catch((err) => {
        this.$message({
          message: err.message,
          type: 'warning'
        });
      });
    },

    // 打开新增危废废物生产
    openaddWasteCode(data) {
      // console.log(data, 'categoryCode数据')
      this.detailTit = '新增废物代码'
      this.$refs.addWasteCode.showaddWasteCode(data);
    },
    //往新增或编辑页面加数据
    async pushData1(trArr) {
      this.addData = trArr
      this.addData.feature = trArr.feature.toString();
      // console.log(this.addData, '新增的数据')
      // this.tableData1.push(trArr);
    },
    // 导入按钮
    upLoadDirectory() {
      this.upload.title = "危废名录导入";
      this.upload.open = true;
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },

    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.data + "</div>", "导入结果", { dangerouslyUseHTMLString: true });
    },

    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },
  },

  mounted() {
    this.queryList();
    this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度


  },

  watch: {
    'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 226
    }
  }

}
</script>
  
<style scoped lang="less">
.planList {
  min-height: 500px;
  background: #ffffff;
}

::v-deep .el-select {
  width: 100%;
}

.input_div {
  display: flex;
}

.button_div {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}

.button {
  padding: 0 10px 0 0;
}

//表格里的 删除按钮
.button_text_del {
  color: #F56C6C !important;
  padding-left: 10px;
}

/deep/.el-button--primary {
  background: #00BFFF;
}
</style>
  