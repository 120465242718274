<template>
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
    </template>
    <template v-slot:center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="角色名称" prop="roleName">
              <el-input v-model="ruleForm.roleName" clearable placeholder="请输入角色名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="角色描述">
              <el-input v-model="ruleForm.remark" type="textarea" placeholder="请输入角色描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item class="cities local" label="角色权限">
              <div>
                <el-cascader-panel ref="rule" :options="options" :props="props" v-model="ruleForm.authorityList"
                  @change="showcheckedname"> </el-cascader-panel>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-tag :key="tag.id" v-for="tag in dynamicTags" closable :disable-transitions="false"
              @close="handleClose(tag)">
              {{ tag.name }}
            </el-tag>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="小程序权限">
              <el-radio-group v-model="ruleForm.wxAuthority">
                <el-radio label="1">产废端小程序</el-radio>
                <el-radio label="2">运输端小程序</el-radio>
                <el-radio label="3">收处端小程序</el-radio>
                <!-- <el-radio label="4">处置端小程序</el-radio> -->
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-slot:footer>
      <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')">取消</HcButton>
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')">确定</HcButton>

    </template>
  </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/systemApi";

export default {
  name: "editDiagle",
  props: {
    editData: {
      type: Object,
      default: null
    }
  },
  components: {
    HcDiaglePage,
    HcButton,
  },

  mounted() {

  },

  data() {
    return {
      CheckedNodes: [],
      dynamicTags: [],
      dynamicTagsId: [],
      props: { multiple: true, emitPath: false, label: 'title' },
      options: [],
      ruleForm: {
        roleName: '',//角色名称
        remark: '',//角色描述
        authorityList: [],
        wxAuthority: ''
      },
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        roleId: "",
      },
      rules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleClose(tag) {
      console.log(tag.id, '被关闭的标签id')
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);//被关闭的tag内容 id name
      let newList = []//新的数组
      for (let i = 0; i < this.dynamicTags.length; i++) {
        newList.push(this.dynamicTags[i].id)//根据tag标签返回数组
      }
      this.ruleForm.authorityList = newList
      // console.log(this.dynamicTags, this.ruleForm.authorityList)
      // console.log(newList, 'newlist')

    },
    showcheckedname(item) {
      // 级联面板自带的方法，获取选择的数据
      this.CheckedNodes = this.$refs['rule'].getCheckedNodes()
      // console.log(this.CheckedNodes, this.CheckedNodes[1].children, 'CheckedNodes')
      let allArr = []
      for (let i = 0; i < this.CheckedNodes.length; i++) {
        allArr.push({ name: this.CheckedNodes[i].label, id: this.CheckedNodes[i].value })//把勾选的数据的name和id取出来
      }
      this.dynamicTags = allArr//赋值给标签数组
      // this.dynamicTagsId = idArr
    },
    getRoleAuthority() {
      server.getRoleAuthority({
        roleId: this.ruleForm.roleId
      }).then((res) => {
        if (res.code == 200) {
          this.loading = false //成功关闭加载中
          // 将获取的list赋值给table
          this.options = res.data
        }
      }).catch((err) => {
        setTimeout(() => {//失败2秒后关闭加载中
          this.loading = false
        }, 2000)
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
    },
    showDiagle(data) {
      this.dialogType = data
      this.$refs.hcDiaglePage.showLog = true;
      this.getRoleAuthority()
      this.$nextTick(() => {
        if (this.dialogType == 'edit') {
          console.log(this.editData, 'hello1')
          this.ruleForm.roleName = this.editData.roleName;
          this.ruleForm.remark = this.editData.remark;
          this.ruleForm.roleId = this.editData.roleId;
          this.ruleForm.wxAuthority = this.editData.wxAuthority;//微信小程序权限
          for (let i = 0; i < this.editData.authorityList.length; i++) {
            this.ruleForm.authorityList.push(this.editData.authorityList[i].id)
            this.dynamicTags.push(this.editData.authorityList[i])
          }
        }
        if (this.dialogType == 'add') {

        }
        console.log(this.ruleForm.authorityList, this.dynamicTags, '被选中的数组+tag标签');

      })
      // 判断弹框是编辑页面还是新增页面或者是详情页面

    },
    add(params) {
      if (this.dialogType == 'add') {
        server.addRole(params).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '新增成功',
              type: 'success'
            });
            this.$refs.hcDiaglePage.enter();//关闭弹框  
            this.$parent.getroleList(this.params)//调用列表页面，刷新列表
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
          .catch((err) => {
            this.$message({
              message: err.message,
              type: 'warning'
            });
          });
      }
    },
    edit(params) {
      if (this.dialogType == 'edit') {
        server.editRole(params).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            });
            this.$refs.hcDiaglePage.enter();//关闭弹框  
            this.$parent.getroleList(this.params)//调用列表页面，刷新列表
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
          .catch((err) => {
            this.$message({
              message: err.message,
              type: 'warning'
            });
          });
      }

    },
    editTree(params) {
      if (this.dialogType == 'edit') {
        server.saveRoleAuthority(params).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            });
            this.$refs.hcDiaglePage.enter();//关闭弹框  
            // this.$parent.getroleList(this.params)//调用列表页面，刷新列表
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
          .catch((err) => {
            this.$message({
              message: err.message,
              type: 'warning'
            });
          });
      }

    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.authorityList = this.ruleForm.authorityList.toString()
          if (this.dialogType == 'edit') {
            // this.editTree(this.ruleForm)
            // this.$nextTick(() => {
            this.edit(this.ruleForm)//调用编辑接口
            this.$nextTick(() => {
              this.ruleForm.roleName = '';//角色名称
              this.ruleForm.remark = '';//角色描述
              this.ruleForm.authorityList = [];
              this.ruleForm.wxAuthority = '';
              this.dynamicTags = [];
            })
            // })

          } else {
            this.add(this.ruleForm) //调用新增接口
            this.$nextTick(() => {
              this.ruleForm.roleName = '';//角色名称
              this.ruleForm.remark = '';//角色描述
              this.ruleForm.authorityList = [];
              this.ruleForm.wxAuthority = '';
              this.dynamicTags = [];
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$refs.hcDiaglePage.close();
      this.ruleForm = {
        //清空表单数据
        roleName: '',//角色名称
        remark: '',//角色描述
        authorityList: [],
        wxAuthority: '',
      }
      this.dynamicTags = []
    },
  },
};
</script>

<style scoped lang="less">
.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}

::v-deep .el-input.el-input--small.el-input--suffix {
  width: 300px;
}

::v-deep .el-textarea__inner {
  width: 100%;
}



// 菜单权限样式
.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

::v-deep.el-tag+.el-tag {
  margin-left: 10px;
  margin-top: 5px;
}

::v-deep .el-tag--light {
  margin-left: 10px;
  margin-top: 5px;
}

.demo-ruleForm {
  margin-top: 20px;
}
</style>
