import { render, staticRenderFns } from "./handleDetail.vue?vue&type=template&id=08fb40d5&scoped=true&"
import script from "./handleDetail.vue?vue&type=script&lang=js&"
export * from "./handleDetail.vue?vue&type=script&lang=js&"
import style0 from "./handleDetail.vue?vue&type=style&index=0&id=08fb40d5&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08fb40d5",
  null
  
)

export default component.exports