<template>
    <div class="tab2">
        <div class="operate">
            <HcButton :type="'primary'" icon="el-icon-plus" class="button" size="small" @click="addTab5" v-if="screen !=='screen'">新增</HcButton>
            <div v-else></div>
            <div class="search">
                <el-form ref="form" :model="params" label-width="80px" size="small" :inline="true">
                    <el-form-item label="">
                        <el-input clearable v-model="params.fuzzyField"></el-input>
                    </el-form-item>
                </el-form>
                <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="searchTable">查询
                </HcButton>
                <HcButton :type="'refresh'" icon="el-icon-refresh" size="small" class="rest" @click="restFrom">重置
                </HcButton>
            </div>
        </div>
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading">
            <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
            <el-table-column prop="enterpriseName" label="企业名称"></el-table-column>
            <el-table-column prop="planStartDate" label="计划开始时间" width="220"></el-table-column>
            <el-table-column prop="planEndDate" label="计划结束时间" width="220"></el-table-column>
            <el-table-column prop="planCreateTime" label="制定日期"></el-table-column>
            <el-table-column label="是否确认" width="180">
                <template slot-scope="scope">
                    <span v-if="scope.row.isConfirm == '1'"><span style="color:#409EFF">是</span></span>
                    <span v-if="scope.row.isConfirm == '0'"><span style="color:#F56C6C">否</span></span>
                </template>
            </el-table-column>
            <el-table-column prop="confirmTime" label="确认时间"></el-table-column>
            <el-table-column fixed="right" label="操作" width="150" v-if="screen !=='screen'">
                <template slot-scope="scope">
                    <span v-if="scope.row.isConfirm == '1'">
                        <el-button @click="openDetailDiagle(scope.row)" type="text" size="default">详情</el-button>
                    </span>
                    <span v-if="scope.row.isConfirm == '0'">
                        <el-button @click="openDetailDiagle(scope.row)" type="text" size="default">详情</el-button>
                        <el-button @click="editTable(scope.row)" type="text" size="default">编辑</el-button>
                        <el-popconfirm title="是否确定删除所选数据？" @confirm="removeenterprise(scope.row)">
                            <el-button slot="reference" type="text" size="default" class="button_text_del">删除</el-button>
                        </el-popconfirm>
                    </span>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 50px 0px;text-align: right;">
            <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="params.pageNum" :page-size="params.pageSize" :total="params.total" class="pagination">
            </HcPagination>
        </div>
    </div>
</template>
<script>
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from '@/api/planApi';

export default {
    name: 'tab2',
    components: { HcPagination, HcButton },
    props: {
        editData: {
            type: Object
        },
        screen:{
            type:String
        }
    },
    data() {
        return {
            mainHeight: null,//中间高度
            tableHeight: null,//表格高度
            tableData: [],
            loading: false,
            params: {
                pageSize: 20,
                pageNum: 1,
                total: null,
                totalPage: null,
                enterpriseId: this.editData.enterpriseId,
                fuzzyField: '',//模糊查询字段
            },
        }
    },
    methods: {
        //table序号
        indexMethod(index) {
            return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
        },
        handleSizeChange(data) {
            this.params.pageSize = data;//每页多少条
            this.getList(this.params)//刷新列表
        },
        handleCurrentChange(data) {
            this.params.pageNum = data;//当前多少页
            this.getList(this.params);
        },
        // 获取table表格数据
        getList(params) {
            this.loading = true //请求时打开加载中
            server.list(params).then((res) => {
                this.loading = false //成功关闭加载中
                // 将获取的list赋值给table
                this.tableData = res.data.list

                // 设置分页的数据
                this.params.totalPage = res.data.totalPage;
                this.params.pageNum = res.data.pageNum;
                this.params.pageSize = res.data.pageSize;
                this.params.total = res.data.total;
            }).catch((err) => {
                setTimeout(() => {//失败2秒后关闭加载中
                    this.loading = false
                }, 2000)
                this.$message({
                    showClose: true,
                    message: err.message,
                    type: 'warning'
                });
            })
        },
        //查询列表
        searchTable() {
            this.getList(this.params)
        },
        //重置
        restFrom() {
            this.params.fuzzyField = '';
            this.getList(this.params);
        },
        addTab5() {
            let data = JSON.stringify(this.editData)
            window.sessionStorage.setItem('wasteDetail', data)
            this.$router.push({
                name: 'planList',//点击新增跳转到管理计划列表页面
                query: {
                    type: 'waste'
                }
            })
        },
        openDetailDiagle(row) {
            let data = JSON.stringify(row)
            window.sessionStorage.setItem('wasteTab5Detail', data)
            this.$router.push({
                name: 'planList',//点击详情跳转到管理计划列表页面
                query: {
                    type: 'wasteTab5Detail'
                }
            })
        },
        editTable(row) {
            let data = JSON.stringify(row)
            window.sessionStorage.setItem('wasteTab5Edit', data)
            this.$router.push({
                name: 'planList',//编辑跳转到管理计划列表页面
                query: {
                    type: 'wasteTab5Edit'
                }
            })
        },
    },
    watch: {
        '$route.query': function (item) {
            if (item.type == 'planAdd') {//如果是从管理计划新增过来得重新刷新下列表
                this.getList(this.params)
            }
        },
        'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
            this.tableHeight = item - 244
        }
    },
    mounted() {
        this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
    },
}

</script>
<style  lang="less" scoped>
.tab2 {

    ::v-deep .el-pagination {
        text-align: right !important;
        margin-top: 16px !important;
    }

    .operate {
        display: flex;
        justify-content: space-between;

        .search {
            display: flex;
        }

        .rest {
            margin-left: 8px;
        }
    }
}

.button_text_del {
    color: #F56C6C !important;
    padding-left: 10px;
}
</style>