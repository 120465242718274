<template>
  <div class="outbound">
    <!-- 库存管理-出库记录 -->
    <HcListMain>
      <template v-slot:header_from>
        <el-form ref="form" :model="params" label-width="120px" size="small" :inline="true">
          <el-form-item label="出库编号">
            <el-input v-model="params.outboundInfoId" clearable placeholder="请输入出库编号"></el-input>
          </el-form-item>
          <el-form-item label="处置企业名称">
            <el-input v-model="params.disposalEnterpriseName" clearable placeholder="请输入处置企业名称"></el-input>
          </el-form-item>
          <el-form-item label="危废类别">
            <el-select clearable filterable v-model="params.categoryCode" @change="updateSub1" placeholder="请选择危废类别">
              <el-option v-for="item in arr" :key="item.value" :label="item.value" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="废物代码">
            <el-select clearable filterable v-model="params.harmWasteCode" placeholder="请选择废物代码">
              <el-option v-for="item in subArr" :key="item.value" :label="item.value" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="出库时间">
            <el-date-picker v-model="timeScopeYS" type="daterange" range-separator="至" start-placeholder="请选择开始时间"
              end-placeholder="请选择结束时间" value-format="yyyy-MM-dd" @change="datePickerChangeYS">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:header_button>
        <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="queryList">查询</HcButton>
        <HcButton :type="'refresh'" icon="el-icon-refresh" size="small" @click="reset">重置</HcButton>
      </template>
      <template v-slot:content_button>
        <el-popconfirm title="是否确认导出列表数据？" @confirm="exportExcel" placement="right">
          <HcButton slot="reference" :type="'info'" icon="el-icon-upload2" size="small">导出</HcButton>
        </el-popconfirm>
      </template>
      <template v-slot:content_table>
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading"
          element-loading-text="加载中">
          <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
          <el-table-column prop="outboundInfoId" label="出库编号" width="180"></el-table-column>
          <el-table-column prop="disposalEnterpriseName" label="处置企业名称" width="190"></el-table-column>
          <el-table-column prop="disposalType" label="处置类型">
            <template slot-scope="scope">
              <span v-if="scope.row.disposalType == '1'">焚烧</span>
              <span v-if="scope.row.disposalType == '2'">填埋</span>
              <span v-if="scope.row.disposalType == '3'">利用</span>
            </template>
          </el-table-column>
          <el-table-column prop="categoryCode" label="危废类别" width="100"></el-table-column>
          <el-table-column prop="harmWasteCode" label="废物代码" width="100"></el-table-column>
          <el-table-column prop="harmWasteName" label="废物名称"></el-table-column>
          <el-table-column prop="receiveWeight" label="出库量(吨)" width="100"></el-table-column>
          <el-table-column prop="outboundTime" label="出库时间" width="160"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click="openDetailDiagle(scope.row)" type="text">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-slot:content_pag>
        <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.pageNum"
          :page-size="params.pageSize" :total="params.total">
        </HcPagination>
      </template>
    </HcListMain>
    <!-- 编辑页面 -->
    <editDiagle ref="editDiagle" :editData="editData"></editDiagle>

  </div>
</template>
  
<script>
import HcButton from '@/components/HcButton/index.vue';
import HcInput from '@/components/HcInput/index.vue';
import HcListMain from "@/components/HcListMain/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import * as server from '@/api/stockApi';
import * as server2 from '@/api/systemApi';

import editDiagle from "./pages/editDiagle.vue";//编辑页面

export default {
  components: { HcButton, HcInput, HcListMain, HcPagination, editDiagle },
  name: 'outbound',
  data() {
    return {
      arr: [],//所有类别
      subArr: [],//选择的类别下所有的代码
      timeScopeYS: [],//运输时间范围
      checkList: [],
      tableData: [],
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        outboundInfoId: '',
        disposalEnterpriseName: '',
        outboundEndDate: '',
        transformTimeEnd: '',
        categoryCode: '',//废物类别
        harmWasteCode: '',//危废代码
      },
      loading: false,//加载中
      detailTit: '出库记录详情',
      editData: null,//编辑页面数据,
      mainHeight: null,//中间高度
      tableHeight: null,//表格高度
    }
  },
  provide() {
    return {
      title: () => this.detailTit,
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1
    },
    handleSizeChange(data) { 
      this.params.pageSize = data//每页多少条
      this.getList(this.params)//重新请求获取table的接口
    },
    handleCurrentChange(data) {
      this.params.pageNum = data//当前多少页
      this.getList(this.params)//重新获取table的接口
    },
    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1//将当前页面设置为第一页
      this.getList(this.params)
    },
    // 重置按钮
    reset() {
      this.params.outboundInfoId = '';
      this.params.disposalEnterpriseName = '';
      this.params.categoryCode = '';
      this.params.harmWasteCode = '';
      this.timeScopeYS = [];
      this.params.outboundStartDate = '';
      this.params.outboundEndDate = '';
      this.subArr = [];
      this.queryList()
    },

    // 获取table表格数据
    getList(params) {
      this.loading = true //请求时打开加载中
      server.outboundList(params).then((res) => {
        this.loading = false //成功关闭加载中
        // 将获取的list赋值给table
        this.tableData = res.data.list
        // 设置分页的数据
        this.params.totalPage = res.data.totalPage;
        this.params.total = res.data.total;
        this.params.pageNum = this.params.pageNum;
        this.params.pageSize = this.params.pageSize;

      }).catch((err) => {
        setTimeout(() => {//失败2秒后关闭加载中
          this.loading = false
        }, 2000)
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
    },
    // 打开详情页面
    openDetailDiagle(row) {
      this.editData = row;
      this.editData['enterpriseId'] = row.producterEnterpriseId;
      this.detailTit = '出库记录详情';
      //会有第一次传值慢问题
      setTimeout(() => {
        this.$refs.editDiagle.showDiagle('detail');
      });
    },

    //导出表格
    exportExcel() {
      this.download('/cloud/stock/OutboundExport', this.params, `出库记录数据_${new Date().getTime()}.xlsx`)
    },

    //时间范围选择
    datePickerChangeYS(daterange) {
      this.params.outboundStartDate = '';
      this.params.outboundEndDate = '';
      if (daterange[0]) {
        this.params.outboundStartDate = daterange[0] + " 00:00:00";
        this.params.outboundEndDate = daterange[1] + " 23:59:59";
      }
    },

    //废物类别选择后更新废物代码选择集
    updateSub1(categoryCode) {
      //设置类别代码
      this.params.harmWasteCode = '';
      let newParams = { 'categoryCode': categoryCode };
      this.getWasteCode(newParams);
    },

    //请求危废类别数据（一级）
    async getCategoryCode() {
      let res = await server2.categoryCodeList();
      if (res.code == 200) {
        this.arr = res.data;
      }
    },
    //请求废物代码数据（二级）
    async getWasteCode(params) {
      let res = await server2.wasteCodeList(params);
      if (res.code == 200) {
        this.subArr = res.data;
      }
    },
  },
  mounted() {
    this.getCategoryCode();
    this.queryList()
    this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
  },
  watch: {
    'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 259.5
    },
    '$route.query': function (item) {
      server.outboundList({
        pageNum: '1',
        pageSize: '20'
      }).then((res) => {
        this.tableData = res.data.list
      }).catch((err) => {
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
      setTimeout(() => {
        console.log(this.tableData, '11111')
      }, 1000);

    }
  }
}
</script>

<style scoped lang="less">
.outbound {
  min-height: 500px;
  background: #ffffff;
}


//表格里的 删除按钮
.button_text_del {
  color: #F56C6C !important;
  padding-left: 10px;
}

/deep/.el-button--primary {
  background: #00BFFF;
}

.button {
  padding: 0 10px 0 0;
}
</style>