import axios from "axios"; // 引入axios
import { Notification, MessageBox, Message, Loading } from "element-ui";
import { saveAs } from "file-saver";

// 是否显示重新登录
export let isRelogin = { show: false };

const httpRequest = axios.create({
  timeout: 1000 * 30, // 请求默认等待时间
  method: "post", // 默认请求方式
  // 默认请求头 [默认入参为JSON字符串]
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // 是否允许跨域
});

// 请求拦截器
httpRequest.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    let token = window.localStorage.getItem("token"); //从存储里获取token值，如果有放在请求中
    if (token) {
      config.headers.token = token;
    }
    if (window.localStorage.getItem("userMation")) {
      let userMation = JSON.parse(window.localStorage.getItem("userMation"));
      config.headers.userId = userMation.userId;
      config.headers.password = userMation.password;
    }

    return config;
  },
  (error) => {
    // 对请求错误做些什么
    Promise.reject(error);
  }
);

// 响应拦截器
httpRequest.interceptors.response.use(
  (response) => {
    const res = response;
    console.log(response, "response");
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;

    // 获取错误信息
    const msg = res.data.msg;

    // 二进制数据则直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }

    //重新登录
    if (code === 401) {
      if (response.config.url.indexOf("/alarmCount")) {
        window.location.href = "/loging";
      } else {
        if (!isRelogin.show) {
          isRelogin.show = true;
          window.localStorage.setItem("hasMsgBox", "1");
          MessageBox.confirm(res.data.data, {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              window.localStorage.setItem("hasMsgBox", "0");
              isRelogin.show = false;
              localStorage.removeItem("token");
              window.location.href = "#/login";
            })
            .catch(() => {
              isRelogin.show = false;
            });
        }
        return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
      }
    } /* else if (code === 500) {
      return Promise.reject(new Error(msg))
    }  else if (code === 601) {
      return Promise.reject('error')
    } else if (code !== 200) {
      Notification.error({ title: msg })
      return Promise.reject('error')
    }*/ else {
      window.localStorage.setItem("hasMsgBox", "0");
      return res.data;
    }
  },
  (error) => {
    console.log(
      "error = 777777777777777777777777777777777777777777777777777777"
    );
    console.log("err = " + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    //翻译一下
    error.message = message;
    return Promise.reject(error);
  }
);

const baseUrl = process.env.VUE_APP_BASE_URL;

/**
 * 通用下载方法
 */
export async function download(url, params, filename) {
  let downloadLoadingInstance = Loading.service({
    text: "正在下载数据，请稍候",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  try {
    const data = await httpRequest.post(baseUrl + url, params, {
      transformRequest: [
        (params_1) => {
          return tansParams(params_1);
        },
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: window.localStorage.getItem("token"),
      },
      responseType: "blob",
    });
    const isBlob = blobValidate(data);
    if (isBlob) {
      const blob = new Blob([data]);
      saveAs(blob, filename);
    } else {
      this.printErrMsg(data);
    }
    downloadLoadingInstance.close();
  } catch (r) {
    Message.error("下载文件出现错误，请联系管理员！");
    downloadLoadingInstance.close();
  }
}

/**
 * 通用下载OSS对象方法
 */
export function downloadOss(ossId) {
  let url = baseUrl + "/cloud/oss/download/" + ossId;
  let downloadLoadingInstance = Loading.service({
    text: "正在下载数据，请稍候",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  axios({
    method: "get",
    url: url,
    responseType: "blob",
    headers: { token: window.localStorage.getItem("token") },
  })
    .then((res) => {
      const isBlob = blobValidate(res.data);
      if (isBlob) {
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        saveAs(blob, decodeURIComponent(res.headers["download-filename"]));
      } else {
        this.printErrMsg(res.data);
      }
      downloadLoadingInstance.close();
    })
    .catch((r) => {
      console.error(r);
      Message.error("下载文件出现错误，请联系管理员！");
      downloadLoadingInstance.close();
    });
}

/**
 * 打印错误
 */
async function printErrMsg(data) {
  const resText = await data.text();
  const rspObj = JSON.parse(resText);
  //const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
  Message.error(rspObj.code);
}

// 验证是否为blob格式
function blobValidate(data) {
  return data.type !== "application/json";
}

/**
 * 参数处理
 * @param {*} params  参数
 */
function tansParams(params) {
  let result = "";
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof value !== "undefined") {
      if (typeof value === "object") {
        for (const key of Object.keys(value)) {
          if (
            value[key] !== null &&
            value[key] !== "" &&
            typeof value[key] !== "undefined"
          ) {
            let params = propName + "[" + key + "]";
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result;
}

export { httpRequest, baseUrl };
