<template>
  <div class="wasteProduction">
    <!-- 产废企业列表 -->
    <HcListMain>
      <template v-slot:header_from>
        <el-form
          ref="form"
          :model="params"
          label-width="80px"
          size="small"
          :inline="true"
        >
          <el-form-item label="企业编号">
            <el-input
              v-model="params.enterpriseId"
              clearable
              placeholder="请输入企业编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业名称">
            <el-input
              v-model="params.enterpriseName"
              clearable
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属区域">
            <HcAreaSelect @area="getArea" ref="HcAreaSelect"></HcAreaSelect>
          </el-form-item>
          <el-form-item label="危废类别">
            <el-select
              clearable
              filterable
              v-model="params.categoryCode"
              placeholder="请选择危废类别"
            >
              <el-option
                v-for="item in arr"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产废规模">
            <el-select
              v-model="params.wasteScale"
              clearable
              filterable
              placeholder="请选择产废规模"
            >
              <el-option
                v-for="item in MAP2JSON(global.WasteScaleMap)"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:header_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-search"
          class="button"
          size="small"
          @click="queryList"
          >查询</HcButton
        >
        <HcButton
          :type="'refresh'"
          icon="el-icon-refresh"
          class="button"
          size="small"
          @click="reset"
          >重置</HcButton
        >
      </template>
      <template v-slot:content_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-plus"
          class="button"
          size="small"
          @click="addDiagle"
          >新增</HcButton
        >
        <el-popconfirm
          title="是否确认导出列表数据？"
          @confirm="exportExcel"
          placement="right"
        >
          <HcButton
            slot="reference"
            :type="'info'"
            icon="el-icon-upload2"
            size="small"
            >导出</HcButton
          >
        </el-popconfirm>
      </template>
      <template v-slot:content_table>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :height="tableHeight"
          v-loading="loading"
          element-loading-text="加载中"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="enterpriseId"
            label="企业编号"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="enterpriseName"
            label="企业名称"
            width="190"
          ></el-table-column>
          <el-table-column
            prop="townAddress"
            label="所属区域"
            min-width="200"
          ></el-table-column>
          <el-table-column
            label="详细地址"
            show-overflow-tooltip
            min-width="200"
            prop="enterpriseAddress"
          ></el-table-column>
          <el-table-column prop="wasteScale" label="产废规模" width="150">
            <template slot-scope="scope">
              {{ global.WasteScaleMap.get(scope.row.wasteScale) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="wasteType"
            label="危废类别"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="contactor"
            label="联系人"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="telephone"
            label="联系电话"
            width="150"
          ></el-table-column>
          <el-table-column prop="isInstall" label="设备安装">
            <template slot-scope="scope">
              <el-tag
                type="success"
                size="medium"
                v-if="scope.row.isInstall == 0"
                >未安装</el-tag
              >
              <el-tag
                type="warning"
                size="medium"
                v-if="scope.row.isInstall == 1"
                >已安装</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                @click="openDetailDiagle(scope.row)"
                type="text"
                size="default"
                >详情</el-button
              >
              <el-button
                type="text"
                size="default"
                @click="editTable(scope.row)"
                >编辑</el-button
              >
              <el-popconfirm
                title="是否确定删除所选数据？"
                @confirm="removeenterprise(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="text"
                  size="default"
                  class="button_text_del"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-slot:content_pag>
        <HcPagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNum"
          :page-size="params.pageSize"
          :total="params.total"
        >
        </HcPagination>
      </template>
    </HcListMain>

    <!-- 编辑页面 -->
    <editDiagle
      ref="editDiagle"
      :baiDuMapDetail="baiDuMapDetail"
      :editData="editData"
    ></editDiagle>

    <!-- 详情页面 -->
    <detailDiagle ref="detailDiagle" :editData="editData"></detailDiagle>

    <!-- 调用百度弹框 -->
    <baiduMap ref="baiduMap" @mapDetail="mapDetail"></baiduMap>

    <!-- tab2页面的新增 -->
    <tab2Add ref="tab2Add" :editData="editData"></tab2Add>
    <tab2Edit ref="tab2Edit" :detailData="detailData"></tab2Edit>
    <tab2Detail ref="tab2Detail" :detailData="detailData"></tab2Detail>

    <tab7Add ref="tab7Add" :editData="editData"></tab7Add>
    <tab7Edit ref="tab7Edit" :detailData="detailData"></tab7Edit>
    <tab7Detail ref="tab7Detail" :detailData="detailData"></tab7Detail>
  </div>
</template>

<script>
import HcButton from "@/components/HcButton/index.vue";
import HcInput from "@/components/HcInput/index.vue";
import HcListMain from "@/components/HcListMain/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import HcAreaSelect from "@/components/HcAreaSelect/index.vue";

import editDiagle from "./pages/editDiagle.vue"; //编辑页面
import baiduMap from "./pages/baiduMap.vue"; //编辑页面百度地图
import detailDiagle from "./pages/detailDiagle.vue"; //详情页面

import tab2Add from "./components/components/tab2Add.vue";
import tab2Detail from "./components/components/tab2Detail.vue";
import tab2Edit from "./components/components/tab2Edit.vue";
import tab7Add from "./components/components/tab7Add.vue";
import tab7Detail from "./components/components/tab7Detail.vue";
import tab7Edit from "./components/components/tab7Edit.vue";

import * as server from "@/api/businessApi";
import * as server2 from "@/api/systemApi";

export default {
  components: {
    HcButton,
    HcInput,
    HcListMain,
    HcPagination,
    HcAreaSelect,
    editDiagle,
    baiduMap,
    detailDiagle,
    tab2Add,
    tab7Add,
    tab2Detail,
    tab2Edit,
    tab7Detail,
    tab7Edit,
  },
  name: "wasteProduction",
  data() {
    return {
      arr: [], //所有类别
      tableData: [],
      params: {
        enterpriseType: "1", //产废单位
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        enterpriseId: "", //企业编号
        enterpriseName: "", //企业名称
        enterpriseProvince: "", //省
        enterpriseCity: "", //市
        enterpriseRegion: "", //区
        enterpriseTown: "", //街道镇
        categoryCode: "", //危废类型
        wasteScale: "", //产废规模
      },
      loading: false, //加载中,
      baiDuMapDetail: {
        address: "",
        longilatde: "",
      },
      detailTit: "新增产废企业",
      editData: null, //编辑页面数据,
      detailData: null, //tab2详情页数据
      mainHeight: null, //中间高度
      tableHeight: null, //表格高度
    };
  },
  provide() {
    //依赖注入，传递值 使用函数的形式可以修改值
    return {
      title: () => this.detailTit,
      tab2AddOpenMet: () => this.tab2AddOpen(),
      tab2EditOpenMet: (data) => this.tab2EditOpen(data),
      tab2DetailOpenMet: (data) => this.tab2DetailOpen(data),
      tab7AddOpenMet: () => this.tab7AddOpen(),
      tab7EditOpenMet: (data) => this.tab7EditOpen(data),
      tab7DetailOpenMet: (data) => this.tab7DetailOpen(data),
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
    },
    handleSizeChange(data) {
      this.params.pageSize = data; //每页多少条
      this.getEnterPrise(this.params); //重新请求获取table的接口
    },
    handleCurrentChange(data) {
      this.params.pageNum = data; //当前多少页
      this.getEnterPrise(this.params); //重新获取table的接口
    },

    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1; //将当前页面设置为第一页
      this.getEnterPrise(this.params);
    },

    // 重置按钮
    reset() {
      this.params.enterpriseId = ""; //企业编号
      this.params.enterpriseName = ""; //企业名称
      this.params.categoryCode = ""; //危废类型
      this.params.wasteScale = ""; //产废规模
      this.params.enterpriseProvince = ""; //省
      this.params.enterpriseCity = ""; //市
      this.params.enterpriseRegion = ""; //区
      this.params.enterpriseTown = ""; //镇
      this.params.pageSize = 20;
      this.params.pageNum = 1;
      this.$refs.HcAreaSelect.rest();
      this.queryList();
    },

    // 获取table表格数据
    getEnterPrise(params) {
      this.loading = true; //请求时打开加载中
      server
        .enterprise(params)
        .then((res) => {
          if (res.code == 200) {
            this.loading = false; //成功关闭加载中

            // 将获取的list赋值给table
            this.tableData = res.data.list;

            // 设置分页的数据
            this.params.totalPage = res.data.totalPage; //共多少页
            this.params.total = res.data.total; //共多少条
            this.params.pageNum = this.params.pageNum; //当前第几页
            this.params.pageSize = this.params.pageSize; //每页显示多少条
            this.params.enterpriseType = "1";
          }
        })
        .catch((err) => {
          setTimeout(() => {
            //失败2秒后关闭加载中
            this.loading = false;
          }, 2000);
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });
    },

    //删除列表中的一条信息
    removeenterprise(row) {
      server
        .removeenterprise({
          ids: row.enterpriseId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success",
            });
            this.getEnterPrise(this.params); //重新请求获取table的接口
          } else {
            this.$message({
              showClose: true,
              message: res.message,
              type: "warning",
            });
          }
        });
    },

    // 新增弹框
    addDiagle() {
      this.detailTit = "新增产废企业";
      this.$refs.editDiagle.showDiagle();
    },

    //打开编辑页面
    editTable(row) {
      this.detailTit = "编辑产废企业";
      this.editData = row;

      //会有第一次传值慢问题
      setTimeout(() => {
        this.$refs.editDiagle.showDiagle("edit");
      });
    },

    // 打开详情页面
    openDetailDiagle(row) {
      this.detailTit = "企业详情";
      this.editData = row;
      setTimeout(() => {
        this.$refs.detailDiagle.showDiagle("detail");
      });
    },

    // 打开百度地图
    baiduMapOpen() {
      // 修改弹框头部名称
      this.$refs.baiduMap.showBaiduMap(); //打开百度地图弹框
    },

    //打开tab2新增
    tab2AddOpen() {
      this.$refs.tab2Add.showtab2Add(); //打开tab2Add弹框
    },
    //打开tab2详情页
    tab2DetailOpen(data) {
      this.detailData = data;
      setTimeout(() => {
        this.$refs.tab2Detail.showtab2Detail(); //打开tab2Detail详情
      });
    },
    //打开tab2编辑页
    tab2EditOpen(data) {
      this.detailData = data;
      setTimeout(() => {
        this.$refs.tab2Edit.showtab2Edit(); //打开tab2Edit详情
      });
    },

    // 打开tab7Add新增
    tab7AddOpen() {
      this.$refs.tab7Add.showtab7Add();
    },
    //打开tab7详情页
    tab7DetailOpen(data) {
      this.detailData = data;
      setTimeout(() => {
        this.$refs.tab7Detail.showtab7Detail(); //打开tab7Detail详情
      });
    },
    //打开tab7编辑页
    tab7EditOpen(data) {
      console.log(data, "rowtab7edit");
      this.detailData = data;
      setTimeout(() => {
        this.$refs.tab7Edit.showtab7Edit(); //打开tab7Edit详情
      });
    },

    mapDetail(data) {
      this.baiDuMapDetail = data;
      console.log(data, "地图信息");
    },

    //从detailDiagle页面传一个tab2页面的 刷新table列表的方法过来
    //下一步：去detailDiagle页面的tab2调用的地方写一个ref传值
    getWasteList(params) {
      this.$refs.detailDiagle.$refs.tab2.getList(params);
    },
    getInterviewList(params) {
      this.$refs.detailDiagle.$refs.tab7.getList(params);
    },

    //导出表格
    exportExcel() {
      this.download(
        "/cloud/enterprise/export",
        this.params,
        `产废企业数据_${new Date().getTime()}.xlsx`
      );
    },

    //请求危废类别数据（一级）
    async getCategoryCode() {
      let res = await server2.categoryCodeList();
      if (res.code == 200) {
        this.arr = res.data;
      }
    },

    // 省市区镇选择
    getArea(val) {
      this.params.enterpriseProvince = val.enterpriseProvince; //省
      this.params.enterpriseCity = val.enterpriseCity; //市
      this.params.enterpriseRegion = val.enterpriseRegion; //区
      this.params.enterpriseTown = val.enterpriseTown; //镇
    },
  },
  mounted() {
    //页面挂载时第一次调用接口请求
    this.getCategoryCode();
    this.getEnterPrise(this.params);
    this.mainHeight = sessionStorage.getItem("listMainHeight"); //获取中间区域的高度
  },
  watch: {
    mainHeight: function (item) {
      //通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 260;
    },
  },
};
</script>

<style scoped lang="less">
.wasteProduction {
  background: #ffffff;
}

.button {
  padding: 0 10px 0 0 !important;
}

//表格里的 删除按钮
.button_text_del {
  color: #f56c6c !important;
  padding-left: 10px;
}

/deep/ .el-button--primary {
  background: #00bfff;
}

::v-deep .el-radio__input {
  position: relative;
}

::v-deep .el-radio__input::after {
  content: "";
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
}
</style>
