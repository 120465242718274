<template>
  <!-- 管理计划 -> 新增计划 -->
  <HcDiaglePage ref="hcDiaglePage">
    <template v-slot:close>
      <i class="el-icon-close title_close" @click="resetForm('ruleForm')"></i>
    </template>
    <template v-slot:center>
      <el-form
        :model="params"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        size="small"
        class="demo-ruleForm"
      >
        <div class="card_tit">管理计划</div>
        <el-row>
          <el-col :span="8" style="display: flex">
            <el-form-item label="企业名称" prop="enterpriseId">
              <el-select
                placeholder="请选择企业名称"
                v-model="params.enterpriseId"
                clearable
                filterable
                ref="enterpriseNameRef"
                :disabled="isDisabled"
              >
                <el-option
                  v-for="item in enterpriseOptionArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="制定日期" prop="planCreateTime">
              <el-date-picker
                v-model="params.planCreateTime"
                type="date"
                placeholder="请选择制定日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划开始时间" prop="planStartDate">
              <el-date-picker
                v-model="params.planStartDate"
                type="date"
                placeholder="请选择计划开始时间"
                value-format="yyyy-MM-dd"
                :picker-options="pickerStartTime"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="计划结束时间" prop="planEndDate">
              <el-date-picker
                v-model="params.planEndDate"
                type="date"
                placeholder="请选择计划结束时间"
                value-format="yyyy-MM-dd"
                :picker-options="pickerEndTime"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex">
            <el-form-item label="规章制度" prop="planRule">
              <el-select
                clearable
                filterable
                v-model="params.planRule"
                placeholder="请选择有无规章制度"
              >
                <el-option label="有" value="1"></el-option>
                <el-option label="无" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <enterpriseDetail
          :enterpriseId="params.enterpriseId"
        ></enterpriseDetail>

        <div class="zhineng">
          <div class="card_tit">危险废物产生情况</div>
          <div class="zhineng_add" @click="openAddWasteProduct">
            <i class="el-icon-circle-plus-outline"></i>
          </div>
        </div>
        <el-table
          :data="
            tableData1.slice(
              (currentPage1 - 1) * pagesize1,
              currentPage1 * pagesize1
            )
          "
          border
          style="width: 100%"
          height="400"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="productSourceName"
            label="产生源名称"
          ></el-table-column>
          <el-table-column label="产生源类型">
            <template slot-scope="scope">
              {{ global.ProductSourceType.get(scope.row.productSourceType) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="productSourceCode"
            label="产生源编码"
          ></el-table-column>
          <el-table-column
            prop="categoryCode"
            label="危废类别"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="harmWasteCode"
            label="废物代码"
            width="120"
          ></el-table-column>
          <el-table-column label="物理性状" width="100">
            <template slot-scope="scope">
              {{ global.PhysicalCharacter.get(scope.row.physicalCharacter) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="harmFeature"
            label="危险特性"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="mpsQuantity"
            label="本年度计划产生量(吨)"
          ></el-table-column>
          <el-table-column
            prop="actualQuantity"
            label="上年度实际产生量(吨)"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-popconfirm
                title="是否确定删除所选数据？"
                @confirm="removeOne1(scope.$index)"
              >
                <el-button
                  slot="reference"
                  type="text"
                  size="default"
                  class="button_text_del"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_btm">
          <el-pagination
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-sizes="[3, 5, 7, 10]"
            :page-size="pagesize1"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData1.length"
          >
          </el-pagination>
        </div>

        <div class="zhineng">
          <div class="card_tit">危废贮存(收集)情况</div>
          <div class="zhineng_add" @click="openAddWasteStorage">
            <i class="el-icon-circle-plus-outline"></i>
          </div>
        </div>
        <el-table
          :data="
            tableData2.slice(
              (currentPage2 - 1) * pagesize2,
              currentPage2 * pagesize2
            )
          "
          border
          style="width: 100%"
          height="400"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="storageName"
            label="贮存设施(收集点)名称"
          ></el-table-column>
          <el-table-column label="贮存设施(收集点)类型">
            <template slot-scope="scope">
              {{ global.ProductSourceType.get(scope.row.storageType) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="storageCode"
            label="贮存设施(收集点)编码"
          ></el-table-column>
          <el-table-column
            prop="storageSize"
            label="面积/容积(㎡/m³)"
          ></el-table-column>
          <el-table-column
            prop="storageCapacity"
            label="贮存(收集)能力(吨)"
          ></el-table-column>
          <el-table-column
            prop="storageTotalQuantity"
            label="截止上年度累计贮存(收集)量(吨)"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-popconfirm
                title="是否确定删除所选数据？"
                @confirm="removeOne2(scope.$index)"
              >
                <el-button
                  slot="reference"
                  type="text"
                  size="default"
                  class="button_text_del"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_btm">
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            :page-sizes="[3, 5, 7, 10]"
            :page-size="pagesize2"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData2.length"
          >
          </el-pagination>
        </div>
      </el-form>
    </template>
    <template v-slot:footer>
      <HcButton :type="'cancel'" size="small" @click="resetForm('ruleForm')"
        >取消</HcButton
      >
      <HcButton :type="'primary'" size="small" @click="submitForm('ruleForm')"
        >确定</HcButton
      >
    </template>
  </HcDiaglePage>
</template>

<script>
import HcDiaglePage from "@/components/HcDiaglePage/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/planApi";

import enterpriseDetail from "@/components/HcEnteMation";

export default {
  name: "planAdd",
  props: {
    enterpriseOptionArr: {
      type: Array,
      default: null,
    },
    selectData1: {
      type: Array,
      default: null,
    },
    selectData2: {
      type: Array,
      default: null,
    },
  },
  components: {
    HcDiaglePage,
    HcPagination,
    HcButton,
    enterpriseDetail,
  },
  watch: {
    selectData1(val) {
      console.log(val);
      this.tableData1 = val;
    },
    selectData2(val) {
      this.tableData2 = val;
    },
    "$route.query": function () {
      if (this.$route.query.type == "waste") {
        let data = JSON.parse(window.sessionStorage.getItem("wasteDetail"));
        this.setEnterpriseInfo(data);
      }
    },
  },
  mounted() {
    if (this.$route.query.type == "waste") {
      let data = JSON.parse(window.sessionStorage.getItem("wasteDetail"));
      this.setEnterpriseInfo(data);
    }
  },
  computed: {
    pickerStartTime() {
      let _this = this;
      return {
        disabledDate: (time) => {
          if (_this.params.planEndDate) {
            let planEndDate = _this.params.planEndDate.replace(/-/g, "/");
            return time.getTime() > new Date(planEndDate);
          }
        },
      };
    },
    pickerEndTime() {
      let _this = this;
      return {
        disabledDate: (time) => {
          if (_this.params.planStartDate) {
            let planStartDate = _this.params.planStartDate.replace(/-/g, "/");
            return time.getTime() < new Date(planStartDate) - 8.64e6;
          }
        },
      };
    },
  },
  data() {
    return {
      isDisabled: false,

      //危险废物产生情况手动分页
      currentPage1: 1,
      pagesize1: 20,

      //危废贮存(收集)情况手动分页
      currentPage2: 1,
      pagesize2: 20,

      tableData1: [],
      tableData2: [],

      params: {
        enterpriseId: "", //企业编号
        enterpriseName: "", //企业名称
        planCreateTime: "", //计划制定日期
        planStartDate: "", //计划开始时间
        planEndDate: "", //计划结束时间
        planRule: "", //规章制度
      },
      rules: {
        enterpriseId: [
          { required: true, message: "请选择企业名称", trigger: "change" },
        ],
        planCreateTime: [
          { required: true, message: "请选择制定日期", trigger: "change" },
        ],
        planStartDate: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        planEndDate: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        planRule: [
          { required: true, message: "请选择规章制度", trigger: "change" },
        ],
      },
      dialogType: "",
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1;
    },

    showDiagle(data, data2) {
      this.$refs.hcDiaglePage.showLog = true;
      this.params = {
        enterpriseId: "", //企业编号
        enterpriseName: "", //企业名称
        planCreateTime: "", //计划制定日期
        planStartDate: "", //计划开始时间
        planEndDate: "", //计划结束时间
        planRule: "", //规章制度
      };
      this.tableData1 = [];
      this.tableData2 = [];
      if (data2 != undefined) {
        this.setEnterpriseInfo(data);
      }
      console.log(this.tableData1, "2222222222222");
    },

    // 产废企业详情进入，没有企业列表选择企业名称
    setEnterpriseInfo(obj) {
      this.params.enterpriseId = obj.enterpriseId; //企业编号
      this.enterpriseOptionArr = [
        { value: obj.enterpriseId, label: obj.enterpriseName },
      ];
      this.isDisabled = true;
    },

    //新增管理计划
    add(params) {
      server
        .add(params)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.$refs.hcDiaglePage.enter(); //关闭弹框
            if (this.$route.query.type == "waste") {
              //判断如果是产废企业详情过来的需再次跳转到产废企业tab
              this.$router.push({
                name: "wasteProduction",
                query: {
                  type: "planAdd",
                },
              });
            }
            this.$parent.queryList(); //调用列表页面，刷新列表
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: "warning",
          });
        });
    },

    submitForm(formName) {
      this.params.enterpriseName =
        this.$refs["enterpriseNameRef"].selected.label;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //如果有新增的废物产生或贮存，就入库保存
          if (this.tableData1.length > 0) {
            //this.params["dataList1"] = this.tableData1;  //这种写法，传不到后台

            //需要手动遍历下
            for (let i = 0; i < this.tableData1.length; i++) {
              this.params["dataList1[" + i + "].productSourceName"] =
                this.tableData1[i].productSourceName;
              this.params["dataList1[" + i + "].productSourceType"] =
                this.tableData1[i].productSourceType;
              this.params["dataList1[" + i + "].productSourceCode"] =
                this.tableData1[i].productSourceCode;
              this.params["dataList1[" + i + "].harmWasteCode"] =
                this.tableData1[i].harmWasteCode;
              this.params["dataList1[" + i + "].categoryCode"] =
                this.tableData1[i].categoryCode;
              this.params["dataList1[" + i + "].categoryName"] =
                this.tableData1[i].categoryName;
              this.params["dataList1[" + i + "].harmWasteName"] =
                this.tableData1[i].harmWasteName;
              this.params["dataList1[" + i + "].harmFeature"] =
                this.tableData1[i].harmFeature;
              this.params["dataList1[" + i + "].physicalCharacter"] =
                this.tableData1[i].physicalCharacter;
              this.params["dataList1[" + i + "].mpsQuantity"] =
                this.tableData1[i].mpsQuantity;
              this.params["dataList1[" + i + "].actualQuantity"] =
                this.tableData1[i].actualQuantity;
            }
          }
          if (this.tableData2.length > 0) {
            //this.params["dataList2"] = this.tableData2;

            for (let i = 0; i < this.tableData2.length; i++) {
              this.params["dataList2[" + i + "].storageName"] =
                this.tableData2[i].storageName;
              this.params["dataList2[" + i + "].storageType"] =
                this.tableData2[i].storageType;
              this.params["dataList2[" + i + "].storageCode"] =
                this.tableData2[i].storageCode;
              this.params["dataList2[" + i + "].storageSize"] =
                this.tableData2[i].storageSize;
              this.params["dataList2[" + i + "].storageCapacity"] =
                this.tableData2[i].storageCapacity;
              this.params["dataList2[" + i + "].storageTotalQuantity"] =
                this.tableData2[i].storageTotalQuantity;
            }
          }
          console.log(this.params);
          this.add(this.params);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      if (this.$route.query.type == "waste") {
        this.$router.push({
          name: "wasteProduction", //关闭跳转到产废企业列表页面
        });
      }
      this.isDisabled = false;
      //this.$parent.detailTit = '计划详情'
      this.$refs.hcDiaglePage.close();
      this.params.enterpriseId = ""; //企业编号
      this.params.enterpriseName = ""; //企业名称
      this.params.planCreateTime = ""; //计划制定日期
      this.params.planStartDate = ""; //计划开始时间
      this.params.planEndDate = ""; //计划结束时间
      this.params.planRule = ""; //规章制度
      this.tableData1 = [];
      this.tableData2 = [];
    },

    handleSizeChange1(val) {
      this.pagesize1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
    },

    handleSizeChange2(val) {
      this.pagesize2 = val;
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val;
    },

    // 打开新增危废废物生产
    openAddWasteProduct() {
      this.$parent.openAddWasteProduct("新增管理计划");
    },
    //删除列表中的一条信息
    removeOne1(i) {
      this.$message({
        showClose: true,
        message: "删除成功",
        type: "success",
      });
      this.tableData1.splice(i, 1);
    },

    // 打开新增危废废品贮存
    openAddWasteStorage() {
      this.$parent.openAddWasteStorage("新增管理计划");
    },
    //删除列表中的一条信息
    removeOne2(i) {
      this.$message({
        showClose: true,
        message: "删除成功",
        type: "success",
      });
      this.tableData2.splice(i, 1);
    },
  },
};
</script>

<style scoped lang="less">
.addressClass {
  display: flex;

  .addressDiv {
    cursor: pointer;
  }

  .addressIcon {
    font-size: 24px;
    color: #e6a23c;
    margin-left: 6px;
  }

  span {
    font-size: 14px;
    color: #e6a23c;
  }
}

.zhineng {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

  .zhineng_add {
    font-size: 24px;
    color: #00bfff;
  }
}

.card_tit {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 20px;
}

.el-icon-circle-plus-outline {
  cursor: pointer;
}

.page_btm {
  text-align: right;
  margin-top: 10px;
}

::v-deep .el-pager li.active {
  background-color: #00bfff;
  color: #ffffff;
  border-radius: 4px;
  min-width: 28px;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #00bfff;
}

.button_text_del {
  color: #f56c6c !important;
  padding-left: 10px;
}

.demo-ruleForm {
  margin-top: 20px;
}

::v-deep .footer {
  margin-top: 20px;
  margin-bottom: 72px;
}

::v-deep .el-input--small .el-input__inner {
  width: 300px;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 300px;
}
</style>
