<template>
  <!-- 管理计划-计划列表 -->
  <div class="planList">
    <HcListMain>
      <template v-slot:header_from>
        <el-form
          ref="form"
          :model="params"
          label-width="120px"
          size="small"
          :inline="true"
        >
          <el-form-item label="企业名称">
            <el-select
              v-model="params.enterpriseId"
              clearable
              placeholder="请选择企业名称"
              filterable
            >
              <el-option
                v-for="item in enterpriseOptionArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计划开始时间">
            <el-date-picker
              v-model="timeScope"
              type="daterange"
              range-separator="至"
              start-placeholder="请选择开始日期"
              end-placeholder="请选择结束日期"
              value-format="yyyy-MM-dd"
              @change="datePickerChange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="是否确认">
            <el-select
              v-model="params.isConfirm"
              clearable
              filterable
              placeholder="请选择是否确认"
            >
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:header_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-search"
          class="button"
          size="small"
          @click="queryList"
          >查询</HcButton
        >
        <HcButton
          :type="'refresh'"
          icon="el-icon-refresh"
          size="small"
          @click="reset"
          >重置</HcButton
        >
      </template>
      <template v-slot:content_button>
        <HcButton
          :type="'primary'"
          icon="el-icon-plus"
          size="small"
          @click="openAdd"
          >新增</HcButton
        >
      </template>
      <template v-slot:content_table>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :height="tableHeight"
          v-loading="loading"
          element-loading-text="加载中"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="enterpriseName"
            label="企业名称"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="planNo"
            label="计划编号"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="planStartDate"
            label="计划开始时间"
          ></el-table-column>
          <el-table-column
            prop="planEndDate"
            label="计划结束时间"
          ></el-table-column>
          <el-table-column
            prop="planCreateTime"
            label="制定日期"
          ></el-table-column>
          <el-table-column label="是否确认">
            <template slot-scope="scope">
              <span v-if="scope.row.isConfirm == '1'"
                ><span style="color: #409eff">是</span></span
              >
              <span v-if="scope.row.isConfirm == '0'"
                ><span style="color: #f56c6c">否</span></span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="confirmTime"
            label="确认时间"
          ></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.isConfirm == '1'">
                <el-button
                  @click="openDetailDiagle(scope.row)"
                  type="text"
                  size="default"
                  >详情</el-button
                >
              </span>
              <span v-if="scope.row.isConfirm == '0'">
                <el-button
                  @click="openDetailDiagle(scope.row)"
                  type="text"
                  size="default"
                  >详情</el-button
                >
                <el-button
                  @click="openEdit(scope.row)"
                  type="text"
                  size="default"
                  >编辑</el-button
                >
                <el-popconfirm
                  title="是否确定删除所选数据？"
                  @confirm="removeOne(scope.row)"
                >
                  <el-button
                    slot="reference"
                    type="text"
                    size="default"
                    class="button_text_del"
                    >删除</el-button
                  >
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-slot:content_pag>
        <HcPagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNum"
          :page-size="params.pageSize"
          :total="params.total"
        >
        </HcPagination>
      </template>
    </HcListMain>

    <!-- 计划新增页面 -->
    <planAdd
      ref="planAdd"
      :enterpriseOptionArr="enterpriseOptionArr"
      :selectData1="tableData1"
      :selectData2="tableData2"
    >
    </planAdd>

    <!-- 计划编辑页面 -->
    <planEdit
      ref="planEdit"
      :enterpriseOptionArr="enterpriseOptionArr"
      :selectData1="tableData1"
      :selectData2="tableData2"
    ></planEdit>

    <!-- 详情页面 -->
    <planDetail ref="planDetail" :editData="editData"></planDetail>

    <!-- 新增危险废物产生 -->
    <addWasteProduct
      ref="addWasteProduct"
      @change="pushData1"
    ></addWasteProduct>

    <!-- 新增危废废品贮存 -->
    <addWasteStorage
      ref="addWasteStorage"
      @change="pushData2"
    ></addWasteStorage>
  </div>
</template>

<script>
import HcButton from "@/components/HcButton/index.vue";
import HcInput from "@/components/HcInput/index.vue";
import HcListMain from "@/components/HcListMain/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import * as server from "@/api/planApi";

import planDetail from "./pages/planDetail.vue"; //详情页面
import planAdd from "./pages/planAdd.vue"; //新增计划
import planEdit from "./pages/planEdit.vue"; //编辑计划
import addWasteProduct from "./pages/addWasteProduct"; //新增危险废物产生
import addWasteStorage from "./pages/addWasteStorage"; //新增危废废品贮存

export default {
  components: {
    HcButton,
    HcInput,
    HcListMain,
    HcPagination,
    planAdd,
    planEdit,
    planDetail,
    addWasteProduct,
    addWasteStorage,
  },
  name: "planList",
  data() {
    return {
      enterpriseOptionArr: [],
      timeScope: [],
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        enterpriseId: "",
        planStartDate: "",
        planEndDate: "",
        isConfirm: "",
      },
      tableData: [],
      loading: false, //加载中
      detailTit: "",
      editData: {}, //编辑页面数据,
      tableData1: [],
      tableData2: [],
      mainHeight: null, //中间高度
      tableHeight: null, //表格高度
    };
  },
  provide() {
    //依赖注入，传递值 使用函数的形式可以修改值
    return {
      title: () => this.detailTit,
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
    },

    handleSizeChange(data) {
      this.params.pageSize = data; //每页多少条
      this.getList(this.params); //重新请求获取table的接口
    },

    handleCurrentChange(data) {
      this.params.pageNum = data; //当前多少页
      this.getList(this.params); //重新获取table的接口
    },

    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1; //将当前页面设置为第一页
      this.getList(this.params);
    },

    // 重置按钮
    reset() {
      this.params.pageSize = 20;
      this.params.pageNum = 1;
      this.timeScope = [];
      this.params.enterpriseId = ""; //企业编号
      this.params.planStartDate = ""; //签订开始时间
      this.params.planEndDate = ""; //签订结束时间
      this.params.isConfirm = ""; //合同状态
      this.queryList();
    },

    // 获取table表格数据
    getList(params) {
      this.loading = true; //请求时打开加载中
      server
        .list(params)
        .then((res) => {
          this.loading = false; //成功关闭加载中
          // 将获取的list赋值给table
          this.tableData = res.data.list;

          // 设置分页的数据
          this.params.totalPage = res.data.totalPage;
          this.params.pageNum = res.data.pageNum;
          this.params.pageSize = res.data.pageSize;
          this.params.total = res.data.total;
        })
        .catch((err) => {
          setTimeout(() => {
            //失败2秒后关闭加载中
            this.loading = false;
          }, 2000);
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });
    },

    // 打开新增计划
    openAdd() {
      this.tableData1 = [];
      this.tableData2 = [];
      this.detailTit = "新增管理计划";
      this.$refs.planAdd.showDiagle();
    },

    // 打开编辑计划
    openEdit(row) {
      this.tableData1 = [];
      this.tableData2 = [];
      this.detailTit = "编辑管理计划";
      this.editData = row;
      setTimeout(() => {
        this.$refs.planEdit.showDiagle(row);
      });
    },

    // 打开详情页面
    openDetailDiagle(row) {
      this.detailTit = "管理计划详情";
      this.editData = row;
      setTimeout(() => {
        this.$refs.planDetail.showDiagle(row);
      });
    },

    //删除列表中的一条信息
    removeOne(row) {
      server
        .remove({
          ids: row.planNo,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success",
            });
            this.queryList();
          } else {
            this.$message({
              showClose: true,
              message: res.message,
              type: "warning",
            });
          }
        });
    },

    // 获取产废企业下拉框数据
    async getEnterpriseOption() {
      let params = { enterpriseType: "1" };
      await server.allEnterpriseList(params).then((res) => {
        this.enterpriseOptionArr = res.data;
      });
    },

    //时间范围选择
    datePickerChange(daterange) {
      this.params.planStartDate = "";
      this.params.planEndDate = "";
      if (daterange[0]) {
        this.params.planStartDate = daterange[0] + " 00:00:00";
        this.params.planEndDate = daterange[1] + " 23:59:59";
      }
    },

    // 打开新增危废废物生产
    openAddWasteProduct(title) {
      this.detailTit = title;
      setTimeout(() => {
        this.$refs.addWasteProduct.showAddWasteProduct();
      });
    },
    //往新增或编辑页面加数据
    pushData1(trArr) {
      console.log(trArr);
      // this.tableData1 = [];
      this.tableData1.push(trArr);
    },
    // 打开新增危废废品贮存
    openAddWasteStorage(title) {
      this.detailTit = title;
      setTimeout(() => {
        this.$refs.addWasteStorage.showAddWasteStorage();
      });
    },
    //往新增或编辑页面加数据
    async pushData2(trArr) {
      // this.tableData2 = [];
      this.tableData2.push(trArr);
    },
  },

  mounted() {
    this.getEnterpriseOption();
    this.queryList();
    this.mainHeight = sessionStorage.getItem("listMainHeight"); //获取中间区域的高度

    if (this.$route.query.type == "waste") {
      //路由传值，如果是从产废企业列表详情进来，直接打开新增页面
      this.detailTit = "新增管理计划";
      let data = JSON.parse(window.sessionStorage.getItem("wasteDetail"));
      this.$refs.planAdd.showDiagle(data, "wasteDetail");
    } else if (this.$route.query.type == "wasteTab5Detail") {
      let data = JSON.parse(window.sessionStorage.getItem("wasteTab5Detail")); //获取产废企业过来的数据
      this.openEdit(data);
    } else if (this.$route.query.type == "wasteTab5Edit") {
      let data = JSON.parse(window.sessionStorage.getItem("wasteTab5Edit")); //获取产废企业过来的数据
      this.openDetailDiagle(data);
    }
  },

  watch: {
    mainHeight: function (item) {
      //通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 226;
    },
    "$route.query": function (item) {
      //使用watch监听路由信息，如果是从产废企业详情过来，直接打开新增页面
      if (item.type == "waste") {
        this.detailTit = "新增管理计划";
        let data = JSON.parse(window.sessionStorage.getItem("wasteDetail"));
        this.$refs.planAdd.showDiagle(data, "wasteDetail");
      } else if (this.$route.query.type == "wasteTab5Detail") {
        let data = JSON.parse(window.sessionStorage.getItem("wasteTab5Detail")); //获取产废企业过来的数据
        this.openEdit(data);
      } else if (this.$route.query.type == "wasteTab5Edit") {
        let data = JSON.parse(window.sessionStorage.getItem("wasteTab5Edit")); //获取产废企业过来的数据
        this.openDetailDiagle(data);
      }
    },
  },
};
</script>

<style scoped lang="less">
.planList {
  min-height: 500px;
  background: #ffffff;
}

::v-deep .el-select {
  width: 100%;
}

.input_div {
  display: flex;
}

.button_div {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}

.button {
  padding: 0 10px 0 0;
}

//表格里的 删除按钮
.button_text_del {
  color: #f56c6c !important;
  padding-left: 10px;
}

/deep/.el-button--primary {
  background: #00bfff;
}
</style>
