<template>
  <div class="tab2">
    <div class="operate">
      <HcButton
        :type="'primary'"
        icon="el-icon-plus"
        class="button"
        size="small"
        v-if="screen !== 'screen'"
        @click="godeviceAdd"
        >新增
      </HcButton>
      <div v-else></div>
      <div class="search">
        <el-form
          ref="form"
          :model="params"
          label-width="80px"
          size="small"
          :inline="true"
        >
          <el-form-item label="">
            <el-input clearable v-model="params.fuzzyField"></el-input>
          </el-form-item>
        </el-form>
        <HcButton
          :type="'primary'"
          icon="el-icon-search"
          class="button"
          size="small"
          @click="searchTable"
          >查询
        </HcButton>
        <HcButton
          :type="'refresh'"
          icon="el-icon-refresh"
          size="small"
          class="rest"
          @click="restFrom"
          >重置
        </HcButton>
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :height="tableHeight"
      v-loading="loading"
    >
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="enterpriseName"
        label="当前所属企业"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="townAddress"
        label="所属区域"
        min-width="200"
      ></el-table-column>
      <el-table-column label="设备版本" width="130">
        <template slot-scope="scope">
          {{ global.DeviceVersionMap.get(scope.row.deviceVersion) }}
        </template>
      </el-table-column>
      <el-table-column label="设备型号" width="100">
        <template slot-scope="scope">
          {{ global.DeviceModelMap.get(scope.row.deviceModel) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="deviceName"
        label="设备名称"
        width="120"
      ></el-table-column>
      <el-table-column prop="categoryCode" label="危废类别"></el-table-column>
      <el-table-column
        prop="categoryName"
        label="危废类别名称"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="harmWasteCode"
        label="废物代码"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="harmWasteName"
        label="废物名称"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="deviceNo"
        label="台账ID"
        width="220"
      ></el-table-column>
      <el-table-column
        prop="deviceId"
        label="设备ID"
        width="220"
      ></el-table-column>
      <el-table-column label="当前存储量(kg)" width="120">
        <template slot-scope="scope">
          <!-- <span v-if="scope.row.storageNum == null || scope.row.storageNum == ' '">0.00</span> -->
          <span
            v-if="scope.row.storageNum != null || scope.row.storageNum != ' '"
            >{{ scope.row.storageNum }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="设备经/纬度" width="180">
        <template slot-scope="scope">
          {{ scope.row.deviceLocationLongitude }} /
          {{ scope.row.deviceLocationLatitude }}
        </template>
      </el-table-column>
      <el-table-column label="在线状态" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.onlineStates == '1'"
            ><span style="color: #67c23a"
              ><span class="dot_green"></span>在线</span
            ></span
          >
          <span v-if="scope.row.onlineStates == '0'"
            ><span style="color: #909399"
              ><span class="dot_grey"></span>离线</span
            ></span
          >
        </template>
      </el-table-column>
      <el-table-column label="设备状态" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.deviceStates == '1'"
            ><el-tag type="success" size="medium">正常</el-tag></span
          >
          <span v-if="scope.row.deviceStates == '2'"
            ><el-tag type="warning" size="medium">报警</el-tag></span
          >
          <span v-if="scope.row.deviceStates == '3'"
            ><el-tag type="danger" size="medium">故障</el-tag></span
          >
        </template>
      </el-table-column>
      <el-table-column label="绑定状态" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.deviceId == null || scope.row.deviceId == ''"
            ><span style="color: #f56c6c">未绑定</span></span
          >
          <span v-if="scope.row.deviceId != null && scope.row.deviceId != ''"
            ><span style="color: #409eff">已绑定</span></span
          >
        </template>
      </el-table-column>
      <el-table-column label="开启/关闭" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.isOn === '1'">开启</span>
          <span v-if="scope.row.isOn === '0'">关闭</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="150"
        v-if="screen !== 'screen'"
      >
        <template slot-scope="scope">
          <el-button
            @click="openDetailDiagle(scope.row)"
            type="text"
            size="default"
            >详情</el-button
          >
          <el-button
            type="text"
            size="default"
            @click="openEditDiagle(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="是否确定删除所选数据？"
            @confirm="removeOne(scope.row)"
          >
            <el-button
              slot="reference"
              type="text"
              size="default"
              class="button_text_del"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin: 50px 0px; text-align: right">
      <HcPagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNum"
        :page-size="params.pageSize"
        :total="params.total"
        class="pagination"
      >
      </HcPagination>
    </div>
  </div>
</template>
<script>
import HcPagination from "@/components/HcPagination/index.vue";
import HcButton from "@/components/HcButton/index.vue";
import * as server from "@/api/deviceApi";

export default {
  name: "tab8",
  components: { HcPagination, HcButton },
  props: {
    editData: {
      type: Object,
    },
    screen: {
      type: String,
    },
  },
  data() {
    return {
      mainHeight: null, //中间高度
      tableHeight: null, //表格高度
      tableData: [],
      loading: false,
      params: {
        enterpriseId: this.editData.enterpriseId,
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        deviceType: "1",
        fuzzyField: "", //模糊查询字段
      },
    };
  },
  methods: {
    //table序号
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1;
    },
    // 获取table表格数据
    getList(params) {
      this.loading = true; //请求时打开加载中
      server
        .list(params)
        .then((res) => {
          this.loading = false; //成功关闭加载中
          // 将获取的list赋值给table
          this.tableData = res.data.list;

          // 设置分页的数据
          this.params.totalPage = res.data.totalPage;
          this.params.total = res.data.total;
          this.params.pageNum = this.params.pageNum;
          this.params.pageSize = this.params.pageSize;
        })
        .catch((err) => {
          setTimeout(() => {
            //失败2秒后关闭加载中
            this.loading = false;
          }, 2000);
          this.$message({
            showClose: true,
            message: err.message,
            type: "warning",
          });
        });
    },
    //查询列表
    searchTable() {
      this.getList(this.params);
    },
    //重置
    restFrom() {
      this.params.fuzzyField = "";
      this.getList(this.params);
    },
    handleSizeChange(data) {
      this.params.pageSize = data; //每页多少条
      this.getList(this.params);
    },
    handleCurrentChange(data) {
      this.params.pageNum = data; //当前多少页
      this.getList(this.params);
    },
    // 新增
    godeviceAdd() {
      console.log("打开智能危废箱列表");
      this.$router.push({
        name: "binList", //跳转到智能危废箱列表
        query: {
          type: "deviceAdd",
          enterpriseId: this.editData.enterpriseId,
          enterpriseName: this.editData.enterpriseName,
        },
      });
      this.getList(this.params);
    },
    // 详情
    openDetailDiagle(row) {
      let data = JSON.stringify(row);
      sessionStorage.setItem("alarmDetail", data);
      this.$router.push({
        name: "binList", //跳转到智能危废箱列表
        query: {
          type: "alarmDetail",
        },
      });
    },
    // 编辑
    openEditDiagle(row) {
      let data = JSON.stringify(row);
      sessionStorage.setItem("alarmEdit", data);
      this.$router.push({
        name: "binList", //跳转到智能危废箱列表
        query: {
          type: "alarmEdit",
          enterpriseId: this.editData.enterpriseId,
          enterpriseName: this.editData.enterpriseName,
        },
      });
    },
    //删除列表中的一条信息
    removeOne(row) {
      server
        .remove({
          ids: row.deviceNo,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success",
            });
            this.searchTable();
          } else {
            this.$message({
              showClose: true,
              message: res.message,
              type: "warning",
            });
          }
        });
    },
  },
  watch: {
    mainHeight: function (item) {
      //通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 244;
    },
    "$route.query": function (item) {
      //路由改变刷新列表
      if (item.type == "deviceAdd2" || item.type == "alarmEdit2") {
        this.getList({
          pageNum: 1,
          pageSize: 20,
          enterpriseId: this.editData.enterpriseId,
          deviceType: "1",
        });
      }
    },
  },
  mounted() {
    // 新增或编辑
    if (
      this.$route.query.type == "deviceAdd2" ||
      this.$route.query.type == "alarmEdit2"
    ) {
      this.getList({
        pageNum: 1,
        pageSize: 20,
        enterpriseId: this.editData.enterpriseId,
        deviceType: "1",
      });
    }
    this.mainHeight = sessionStorage.getItem("listMainHeight"); //获取中间区域的高度
  },
};
</script>
<style lang="less" scoped>
.tab2 {
  ::v-deep .el-pagination {
    text-align: right !important;
    margin-top: 16px !important;
  }

  .operate {
    display: flex;
    justify-content: space-between;

    .search {
      display: flex;
    }

    .rest {
      margin-left: 8px;
    }
  }
}

//表格里的 删除按钮
.button_text_del {
  color: #f56c6c !important;
  padding-left: 10px;
}

.dot_green {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #67c23a;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
}

.dot_grey {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #909399;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
}
</style>
