import {httpRequest,baseUrl} from '../utils/request'
export {
    enterprise,removeenterprise,addEnterprise,editEnterprise,statMapById,wasteList,
    addWaste,editWaste,removeWaste,interviewList,addInterview,editInterview,removeInterview,
    carList,addCar,editCar,removeCar,
    driverList,addDriver,editDriver,removeDriver,
    sclStat
}
//企业列表
const enterprise=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/enterprise/list',
       method:'post' ,
       params
    })
)
// 删除列表信息
const removeenterprise=(params)=>(
    httpRequest({
       url:baseUrl+'/cloud/enterprise/remove',
       method:'post' ,
       params
    })
)
//新增企业信息
const addEnterprise=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/add',
        method:'post' ,
        params
     })
)
//编辑企业信息
const editEnterprise=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/edit',
        method:'post' ,
        params
     })
)
//企业管理>产废企业列表>详情>统计数据
const statMapById=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/statMapById',
        method:'post' ,
        params
     })
)
//企业管理>产废企业列表>详情>危废列表table
const wasteList=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/wasteList',
        method:'post' ,
        params
     })
)
//企业管理>产废企业列表>详情>危废列表table>新增
const addWaste=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/addWaste',
        method:'post' ,
        params
     })
)
//企业管理>产废企业列表>详情>危废列表table>编辑
const editWaste=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/editWaste',
        method:'post' ,
        params
     })
)
//企业管理>产废企业列表>详情>危废列表table>删除
const removeWaste=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/removeWaste',
        method:'post' ,
        params
     })
)
//企业管理>产废企业列表>详情>走访列表>首页table
const interviewList=(params)=>(
     httpRequest({
        url:baseUrl+'/cloud/enterprise/interviewList',
        method:'post' ,
        params
     })
)
//企业管理>产废企业列表>详情>走访列表table>新增
const addInterview=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/addInterview',
        method:'post' ,
        params
     })
)
//企业管理>产废企业列表>详情>走访列表table>编辑
const editInterview=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/editInterview',
        method:'post' ,
        params
     })
)
//企业管理>产废企业列表>详情>走访列表table>删除
const removeInterview=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/removeInterview',
        method:'post' ,
        params
     })
)
//企业管理>运输企业列表>详情>运输车辆>首页table
const carList=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/carList',
        method:'post' ,
        params
     })
)
//企业管理>运输企业列表>详情>运输车辆>新增
const addCar=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/addCar',
        method:'post' ,
        params
     })
)
//企业管理>运输企业列表>详情>运输车辆>编辑
const editCar=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/editCar',
        method:'post' ,
        params
     })
)
//企业管理>运输企业列表>详情>运输车辆>删除
const removeCar=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/removeCar',
        method:'post' ,
        params
     })
)
//企业管理>运输企业列表>详情>司机列表>首页table
const driverList=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/driverList',
        method:'post' ,
        params
     })
)
//企业管理>运输企业列表>详情>司机列表>新增
const addDriver=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/addDriver',
        method:'post' ,
        params
     })
)
//企业管理>运输企业列表>详情>司机列表>编辑
const editDriver=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/editDriver',
        method:'post' ,
        params
     })
)
//企业管理>运输企业列表>详情>司机列表>删除
const removeDriver=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/removeDriver',
        method:'post' ,
        params
     })
)

//企业管理>收集企业列表>详情>收处量统计
const sclStat=(params)=>(
    httpRequest({
        url:baseUrl+'/cloud/enterprise/queryEnterpriseCount',
        method:'post' ,
        params
     })
)

//企业管理>处置企业列表>详情>收处类别>table  同//企业管理>产废企业列表>详情>危废列表table

//企业管理>处置企业列表>详情>收处类别>删除  同企业管理>产废企业列表>详情>危废列表table>删除