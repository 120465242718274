<template>
  <div id="home">
    <HcMain ></HcMain>
  </div>
</template>
<script>
import HcMain from '../views/main'
export default {
  name: 'home',
  components: {
    HcMain
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log(process.env.VUE_APP_BASE_URL, "VUE_APP_URL");
  }
}
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  color: #808080;
  height: 100%;
  background: #f2f3f5;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
