<template>
  <div class="jointOrderList">
    <!-- 联单管理-联单列表 -->
    <HcListMain>
      <template v-slot:header_from>
        <el-form ref="form" :model="params" label-width="120px" size="small" :inline="true">
          <el-form-item label="联单编号">
            <el-input v-model="params.sheetInfoId" clearable placeholder="请输入联单编号"></el-input>
          </el-form-item>
          <el-form-item label="产废企业名称">
            <el-input v-model="params.producterEnterpriseName" clearable placeholder="请输入产废企业名称"></el-input>
          </el-form-item>
          <el-form-item label="运输企业名称">
            <el-input v-model="params.transformEnterpriseName" clearable placeholder="请输入运输企业名称"></el-input>
          </el-form-item>
          <el-form-item label="收处企业名称">
            <el-input v-model="params.receiveEnterpriseName" clearable placeholder="请输入收处企业名称"></el-input>
          </el-form-item>
          <el-form-item label="所属区域">
            <HcAreaSelect @area="getArea" ref="HcAreaSelect"></HcAreaSelect>
          </el-form-item>
          <el-form-item label="危废类别">
            <el-select clearable filterable v-model="params.categoryCode" @change="updateSub1" placeholder="请选择危废类别">
              <el-option v-for="item in arr" :key="item.value" :label="item.value" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="废物代码">
            <el-select clearable filterable v-model="params.harmWasteCode" placeholder="请选择废物代码">
              <el-option v-for="item in subArr" :key="item.value" :label="item.value" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运输时间">
            <el-date-picker v-model="timeScopeYS" type="daterange" range-separator="至" start-placeholder="请选择开始时间"
              end-placeholder="请选择结束时间" value-format="yyyy-MM-dd" @change="datePickerChangeYS">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="收处时间">
            <el-date-picker v-model="timeScopeSC" type="daterange" range-separator="至" start-placeholder="请选择开始时间"
              end-placeholder="请选择结束时间" value-format="yyyy-MM-dd" @change="datePickerChangeSC">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:header_button>
        <HcButton :type="'primary'" icon="el-icon-search" class="button" size="small" @click="queryList">查询</HcButton>
        <HcButton :type="'refresh'" icon="el-icon-refresh" size="small" @click="reset">重置</HcButton>
      </template>
      <template v-slot:content_button>
        <el-popconfirm title="是否确认导出列表数据？" @confirm="exportExcel" placement="right">
          <HcButton slot="reference" :type="'info'" icon="el-icon-upload2" size="small">导出</HcButton>
        </el-popconfirm>
        <el-checkbox-group v-model="params.checkedStatus" @change="chooseStatus">
          <el-checkbox label="1">已申请</el-checkbox>
          <el-checkbox label="2">已派车</el-checkbox>
          <el-checkbox label="3">已上车</el-checkbox>
          <el-checkbox label="4">运输中</el-checkbox>
          <el-checkbox label="5">五必查确认</el-checkbox>
          <el-checkbox label="6">已入库</el-checkbox>
          <el-checkbox label="7">已撤销</el-checkbox>
          <el-checkbox label="8">已驳回</el-checkbox>
        </el-checkbox-group>
      </template>
      <template v-slot:content_table>
        <el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-loading="loading"
          element-loading-text="加载中">
          <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
          <el-table-column prop="sheetInfoId" label="联单编号" width="180"></el-table-column>
          <el-table-column prop="producterEnterpriseName" label="产废企业名称" width="190"></el-table-column>
          <el-table-column prop="townAddress" label="所属区域" min-width="200"></el-table-column>
          <el-table-column prop="transformEnterpriseName" label="运输企业名称" width="190"></el-table-column>
          <el-table-column prop="transformDriver" label="运输司机" width="100"></el-table-column>
          <el-table-column prop="transportation" label="运输车辆" width="100"></el-table-column>
          <el-table-column prop="receiveEnterpriseName" label="收处企业名称" width="190"></el-table-column>
          <el-table-column prop="categoryCode" label="危废类别" width="100"></el-table-column>
          <el-table-column prop="harmWasteCode" label="废物代码" width="100"></el-table-column>
          <el-table-column prop="harmWasteName" label="废物名称" width="150"></el-table-column>
          <el-table-column prop="applyWeight" label="申报量(kg)" width="120"></el-table-column>
          <el-table-column prop="transferWeight" label="转移量(kg)" width="120"></el-table-column>
          <el-table-column prop="receiveWeight" label="接收量(kg)" width="120"></el-table-column>
          <el-table-column sortable prop="createTime" label="创建时间" width="160"></el-table-column>
          <el-table-column sortable prop="transformTime" label="运输时间" width="160"></el-table-column>
          <el-table-column sortable prop="receiveTime" label="收处时间" width="160"></el-table-column>
          <el-table-column label="流程节点" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status == '1' || scope.row.status == '7' || scope.row.status == '8'">联单申请</span>
              <span v-if="scope.row.status == '2'">运输确认</span>
              <span v-if="scope.row.status == '3'">危废上车</span>
              <span v-if="scope.row.status == '4'">联单运输</span>
              <span v-if="scope.row.status == '5'">五必查确认</span>
              <span v-if="scope.row.status == '6'">联单办结</span>
            </template>
          </el-table-column>
          <el-table-column label="联单状态" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status == '1'"><el-tag type="info" size="medium">已申请</el-tag></span>
              <span v-if="scope.row.status == '2'"><el-tag type="success" size="medium">已派车</el-tag></span>
              <span v-if="scope.row.status == '3'"><el-tag type="success" size="medium">已上车</el-tag></span>
              <span v-if="scope.row.status == '4'"><el-tag type="success" size="medium">运输中</el-tag></span>
              <span v-if="scope.row.status == '5'"><el-tag type="success" size="medium">五必查确认</el-tag></span>
              <span v-if="scope.row.status == '6'"><el-tag type="success" size="medium">已入库</el-tag></span>
              <span v-if="scope.row.status == '7'"><el-tag type="warning" size="medium">已撤销</el-tag></span>
              <span v-if="scope.row.status == '8'"><el-tag type="danger" size="medium">已驳回</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click="openDetailDiagle(scope.row)" type="text">详情</el-button>

              <span v-if="scope.row.status == '1'"><el-button type="text" @click="changeStatus(scope.row)"
                  class="button_text_pri">审批</el-button></span>
              <span v-if="scope.row.status == '4'"><el-button type="text" @click="changeStatus(scope.row)"
                  class="button_text_pri">五必查</el-button></span>
              <span v-if="scope.row.status == '5'"><el-button type="text" @click="changeStatus(scope.row)"
                  class="button_text_pri">入库</el-button></span>

              <span v-if="scope.row.status == '7' || scope.row.status == '8'">
                <el-popconfirm title="是否确定删除所选数据？" @confirm="removeOne(scope.row)">
                  <el-button slot="reference" type="text" size="default" class="button_text_del">删除</el-button>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-slot:content_pag>
        <HcPagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.pageNum"
          :page-size="params.pageSize" :total="params.total">
        </HcPagination>
      </template>
    </HcListMain>

    <!-- 详情页面 -->
    <detailDiagle ref="detailDiagle" :editData="editData"></detailDiagle>

    <!-- 编辑页面 -->
    <editDiagle ref="editDiagle" :editData="editData"></editDiagle>
  </div>
</template>
  
<script>
import HcButton from '@/components/HcButton/index.vue';
import HcInput from '@/components/HcInput/index.vue';
import HcListMain from "@/components/HcListMain/index.vue";
import HcPagination from "@/components/HcPagination/index.vue";
import HcAreaSelect from "@/components/HcAreaSelect/index.vue";

import * as server from '@/api/sheetApi';
import * as server2 from '@/api/systemApi';

import detailDiagle from "./pages/detailDiagle.vue";//详情页面
import editDiagle from "./pages/editDiagle.vue";//编辑页面

export default {
  components: { HcButton, HcInput, HcListMain, HcPagination, HcAreaSelect, detailDiagle, editDiagle },
  name: 'jointOrderList',
  data() {
    return {
      arr: [],//所有类别
      subArr: [],//选择的类别下所有的代码
      timeScopeYS: [],//运输时间范围
      timeScopeSC: [],//收处时间访问
      tableData: [],
      params: {
        pageSize: 20,
        pageNum: 1,
        total: null,
        totalPage: null,
        sheetInfoId: '',
        producterEnterpriseName: '',
        transformEnterpriseName: '',
        receiveEnterpriseName: '',
        transformTimeStart: '',
        transformTimeEnd: '',
        receiveTimeStart: '',
        receiveTimeEnd: '',
        categoryCode: '',//废物类别
        harmWasteCode: '',//危废代码
        checkedStatus: ["1", "2", "3", "4", "5", "6", "7", "8"],//选中的联单状态
        enterpriseProvince: '', //省
        enterpriseCity: '',     //市
        enterpriseRegion: '',   //区
        enterpriseTown: '',     //街道镇
      },
      loading: false,//加载中
      detailTit: '',
      editData: null,//编辑页面数据,
      mainHeight: null,//中间高度
      tableHeight: null,//表格高度
    }
  },
  provide() {
    return {
      title: () => this.detailTit,
    };
  },
  methods: {
    indexMethod(index) {
      return (this.params.pageNum - 1) * this.params.pageSize + index + 1
    },

    handleSizeChange(data) {
      this.params.pageSize = data;//每页多少条
      this.getList(this.params)//重新请求获取table的接口
    },

    handleCurrentChange(data) {
      this.params.pageNum = data;//当前多少页
      this.getList(this.params)//重新获取table的接口
    },

    // 查询按钮筛选表格
    queryList() {
      this.params.pageNum = 1//将当前页面设置为第一页
      this.getList(this.params)
    },

    // 重置按钮
    reset() {
      this.params.sheetInfoId = ""; //联单编号
      this.params.producterEnterpriseName = ""; //产废企业名称
      this.params.transformEnterpriseName = ""; //运输企业名称
      this.params.receiveEnterpriseName = ""; //收集企业名称
      this.params.categoryCode = "";  //危废类别
      this.params.harmWasteCode = ""; //危废类别
      this.params.checkedStatus = ["1", "2", "3", "4", "5", "6", "7", "8"];//选中的联单状态
      this.timeScopeYS = [];
      this.params.transformTimeStart = ""; //运输开始时间
      this.params.transformTimeEnd = "";   //运输结束时间
      this.timeScopeSC = [];
      this.params.receiveTimeStart = "";  //收处开始时间
      this.params.receiveTimeEnd = "";    //收处结束时间
      this.params.enterpriseProvince = '';  //省
      this.params.enterpriseCity = '';      //市
      this.params.enterpriseRegion = '';    //区
      this.params.enterpriseTown = '';      //镇
      this.params.pageSize = 20;
      this.params.pageNum = 1;
      this.subArr = [];
      this.$refs.HcAreaSelect.rest();
      this.queryList();
    },

    // 获取table表格数据
    getList(params) {
      this.loading = true //请求时打开加载中
      server.list(params).then((res) => {
        this.loading = false //成功关闭加载中
        // 将获取的list赋值给table
        this.tableData = res.data.list

        // 设置分页的数据
        this.params.totalPage = res.data.totalPage;
        this.params.total = res.data.total;
        this.params.pageNum = this.params.pageNum;
        this.params.pageSize = this.params.pageSize;

      }).catch((err) => {
        setTimeout(() => {//失败2秒后关闭加载中
          this.loading = false
        }, 2000)
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
    },

    //联单状态复选框勾选
    chooseStatus(arr) {
      this.queryList();
    },

    //删除列表中的一条信息
    removeOne(row) {
      server.remove({
        ids: row.sheetInfoNo
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.queryList();
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "warning",
          });
        }
      })
    },

    //修改联单状态
    changeStatus(row) {
      if (row.status == '1') {
        this.detailTit = '联单审批';
      }
      if (row.status == '4') {
        this.detailTit = '五必查确认';
      }
      if (row.status == '5') {
        this.detailTit = '入库确认';
      }

      this.editData = row;
      this.editData['enterpriseId'] = row.producterEnterpriseId;
      setTimeout(() => {
        this.$refs.editDiagle.showDiagle('edit');
      });
    },

    // 打开详情页面
    openDetailDiagle(row) {
      this.editData = row;
      console.log(this.editData, '联单详情')
      this.editData['enterpriseId'] = row.producterEnterpriseId;
      this.detailTit = '联单详情';
      setTimeout(() => {
        this.$refs.detailDiagle.showDiagle('detail');
      });
    },

    // 从入库记录页面-打开详情页面
    openDetailDiagle2(row) {
      this.editData = row;
      this.editData['enterpriseId'] = row.producterEnterpriseId;
      this.editData['sheetInfoId'] = row.sheetInfoId;
      this.detailTit = '联单详情';
      this.$refs.detailDiagle.showDiagle('detail');
    },

    //导出表格
    exportExcel() {
      this.download('/cloud/sheet/export', this.params, `联单数据_${new Date().getTime()}.xlsx`);
    },

    //时间范围选择
    datePickerChangeYS(daterange) {
      this.params.transformTimeStart = '';
      this.params.transformTimeEnd = '';
      if (daterange[0]) {
        this.params.transformTimeStart = daterange[0] + " 00:00:00";
        this.params.transformTimeEnd = daterange[1] + " 23:59:59";
      }
    },

    //时间范围选择
    datePickerChangeSC(daterange) {
      this.params.receiveTimeStart = '';
      this.params.receiveTimeEnd = '';
      if (daterange[0]) {
        this.params.receiveTimeStart = daterange[0] + " 00:00:00";
        this.params.receiveTimeEnd = daterange[1] + " 23:59:59";
      }
    },

    //废物类别选择后更新废物代码选择集
    updateSub1(categoryCode) {
      //设置类别代码
      this.params.harmWasteCode = '';
      let newParams = { 'categoryCode': categoryCode };
      this.getWasteCode(newParams);
    },

    //请求危废类别数据（一级）
    async getCategoryCode() {
      let res = await server2.categoryCodeList();
      if (res.code == 200) {
        this.arr = res.data;
      }
    },
    //请求废物代码数据（二级）
    async getWasteCode(params) {
      let res = await server2.wasteCodeList(params);
      if (res.code == 200) {
        this.subArr = res.data;
      }
    },

    // 省市区镇选择
    getArea(val) {
      this.params.enterpriseProvince = val.enterpriseProvince;  //省
      this.params.enterpriseCity = val.enterpriseCity;          //市
      this.params.enterpriseRegion = val.enterpriseRegion;      //区
      this.params.enterpriseTown = val.enterpriseTown;          //镇
    },
  },
  mounted() {
    this.getCategoryCode();
    this.mainHeight = sessionStorage.getItem('listMainHeight') //获取中间区域的高度
    // 从入库记录过来
    if (this.$route.query.type == 'storageDetail') {
      const data = JSON.parse(window.sessionStorage.getItem('storageDetail'))
      let data2 = []
      server.list({
        sheetInfoId: data.sheetInfoId,
        checkedStatus: ["1", "2", "3", "4", "5", "6", "7", "8"],
        pageNum: '1',
        pageSize: '20'
      }).then((res) => {
        data2 = res.data.list
        this.openDetailDiagle2(res.data.list[0])
      }).catch((err) => {
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
    }
    // 出库记录过来
    else if (this.$route.query.type == 'outboundDetail') {
      const data = JSON.parse(window.sessionStorage.getItem('outboundDetail'))
      console.log(data, '000000')
      let data2 = []
      server.list({
        sheetInfoId: data.sheetInfoId,
        checkedStatus: ["1", "2", "3", "4", "5", "6", "7", "8"],
        pageNum: '1',
        pageSize: '20'
      }).then((res) => {
        data2 = res.data.list
        this.openDetailDiagle2(res.data.list[0])
      }).catch((err) => {
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
    }
    // 从实时库存来
    else if (this.$route.query.type == 'inventoryDetail') {
      const data = JSON.parse(window.sessionStorage.getItem('inventoryDetail'))
      console.log(data, '000000')
      let data2 = []
      server.list({
        sheetInfoId: data.sheetInfoId,
        checkedStatus: ["1", "2", "3", "4", "5", "6", "7", "8"],
        pageNum: '1',
        pageSize: '20'
      }).then((res) => {
        data2 = res.data.list
        this.openDetailDiagle2(res.data.list[0])
      }).catch((err) => {
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })

    }
    // 从申报预警过来
    else if (this.$route.query.type == 'tabWarming') {
      const data = JSON.parse(window.sessionStorage.getItem('tabWarming'))
      let data2 = []
      server.list({
        sheetInfoId: data.sheetInfoId,
        checkedStatus: ["1", "2", "3", "4", "5", "6", "7", "8"],
        pageNum: '1',
        pageSize: '20'
      }).then((res) => {
        data2 = res.data.list
        this.openDetailDiagle2(res.data.list[0])
      }).catch((err) => {
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })
    } else if (this.$route.query.type == 'welcome') {
      console.log("mounted  1111111111");
      const sheetParams = JSON.parse(window.sessionStorage.getItem('welcome'));
      this.params.checkedStatus = sheetParams.checkedStatus;
      this.getList(sheetParams);
    } else {
      this.queryList()
    }
  },
  watch: {
    'mainHeight': function (item) {//通过监听中间高度动态给table赋值高度
      this.tableHeight = item - 310
    },
    '$route.query': function (item) {
      // 路由改变时调用列表接口-查询首页table
      server.list({
        pageNum: '1',
        pageSize: '20',
        checkedStatus: ["1", "2", "3", "4", "5", "6", "7", "8"],//选中的联单状态
      }).then((res) => {
        this.loading = false //成功关闭加载中
        // 将获取的list赋值给table
        this.tableData = res.data.list
      }).catch((err) => {
        setTimeout(() => {//失败2秒后关闭加载中
          this.loading = false
        }, 2000)
        this.$message({
          showClose: true,
          message: err.message,
          type: 'warning'
        });
      })

      // 入库列表过来
      if (item.type == 'storageDetail') {
        const data = JSON.parse(window.sessionStorage.getItem('storageDetail'))
        console.log(data, '000000')
        let data2 = []
        server.list({
          sheetInfoId: data.sheetInfoId,
          checkedStatus: ["1", "2", "3", "4", "5", "6", "7", "8"],
          pageNum: '1',
          pageSize: '20'
        }).then((res) => {
          data2 = res.data.list
          this.openDetailDiagle2(res.data.list[0])
        }).catch((err) => {
          this.$message({
            showClose: true,
            message: err.message,
            type: 'warning'
          });
        })
      }
      // 出库记录过来
      if (item.type == 'outboundDetail') {
        const data = JSON.parse(window.sessionStorage.getItem('outboundDetail'))
        console.log(data, '000000')
        let data2 = []
        server.list({
          sheetInfoId: data.sheetInfoId,
          checkedStatus: ["1", "2", "3", "4", "5", "6", "7", "8"],
          pageNum: '1',
          pageSize: '20'
        }).then((res) => {
          data2 = res.data.list
          this.openDetailDiagle2(res.data.list[0])
        }).catch((err) => {
          this.$message({
            showClose: true,
            message: err.message,
            type: 'warning'
          });
        })
      }// 从实时库存来
      if (item.type == 'inventoryDetail') {
        const data = JSON.parse(window.sessionStorage.getItem('inventoryDetail'))
        console.log(data, '000000')
        let data2 = []
        server.list({
          sheetInfoId: data.sheetInfoId,
          checkedStatus: ["1", "2", "3", "4", "5", "6", "7", "8"],
          pageNum: '1',
          pageSize: '20'
        }).then((res) => {
          data2 = res.data.list
          this.openDetailDiagle2(res.data.list[0])
        }).catch((err) => {
          this.$message({
            showClose: true,
            message: err.message,
            type: 'warning'
          });
        })
      }
      // 报警列表-报警申报
      if (item.type == 'tabWarming') {
        const data = JSON.parse(window.sessionStorage.getItem('tabWarming'))
        console.log(data, '000000')
        let data2 = []
        server.list({
          sheetInfoId: data.sheetInfoId,
          checkedStatus: ["1", "2", "3", "4", "5", "6", "7", "8"],
          pageNum: '1',
          pageSize: '20'
        }).then((res) => {
          data2 = res.data.list
          this.openDetailDiagle2(res.data.list[0])
        }).catch((err) => {
          this.$message({
            showClose: true,
            message: err.message,
            type: 'warning'
          });
        })
      }
      //首页点击调整联单列表
      if (item.type == 'welcome') {
        console.log("watch  1111111111");
        const sheetParams = JSON.parse(window.sessionStorage.getItem('welcome'));
        this.params.checkedStatus = sheetParams.checkedStatus;
        this.getList(sheetParams);
      }
    }
  }
}
</script>

<style scoped lang="less">
.jointOrderList {
  min-height: 500px;
  background: #ffffff;
}

::v-deep .el-select {
  width: 100%;
}

.input_div {
  display: flex;
}

.button_div {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}

.button {
  padding: 0 10px 0 0;
}

//表格里的 删除按钮
.button_text_del {
  color: #F56C6C !important;
  padding-left: 10px;
}

.button_text_pri {
  padding-left: 10px;
}

/deep/.el-button--primary {
  background: #00BFFF;
}

/deep/.list_main .content_button {
  display: flex;
  justify-content: space-between;
}
</style>